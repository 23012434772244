import { Outlet } from "react-router-dom";
import SideMenu from "../components/UI/SideMenu";
import { getAdminOptions, getLockedDownAdminOptions } from "../utils/sideMenuHelpers";
import { useContext, useEffect } from "react";
import Loader from "../components/UI/Loader";
import { MeContext } from "../App";

const Admin = () => {
    const me = useContext(MeContext);    

    useEffect(() => {
        document.title = "Manage Site"
    }, []);

    if (me.loading) {
        return <Loader/>;
    }

    const options = me.user?.organizationPlanCancelled || me.user?.organizationAccessDisabled || me.user?.organizationTrialEnded ? getLockedDownAdminOptions() : getAdminOptions();

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <SideMenu id="admin-side-menu"
                    title="Manage Site"
                    options={options}
                    mainContent={<Outlet />}
                    ariaLabel="Manage Site Side Menu"
                />
            </div>
        </section>
    )
};

export default Admin;