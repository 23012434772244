export default interface ValidateResponse {
    isValid: boolean;
    default: string[];
    value: string[];
    operator: string[];
}

export const defaultValue = (): ValidateResponse => {
    return {
        isValid: true,
        default: [],
        operator: [],
        value: []
    };
}