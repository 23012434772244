import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import CurrentPlan from "../components/UI/CurrentPlan";
import Loader from "../components/UI/Loader";
import NewPlan from "../components/UI/NewPlan";
import { PREMIUM_PLAN_TYPE_ID, STANDARD_PLAN_TYPE_ID, TRIAL_PLAN_TYPE_ID } from "../constants/planTypes";
import { OrganizationPlan } from "../models/serviceModels/OrganizationPlan";
import { getItem, setItem } from "../services/cacheService";
import { searchUsers } from "../services/userService";
import getclassNames from "../utils/getClassNames";
import { formatCurrency } from "../utils/stringHelpers";

const ChangePlan = () => {

    const [plan, setPlan] = useState<number>();
    const [currentPlan, setCurrentPlan] = useState<OrganizationPlan>();
    const [users, setUsers] = useState<number>(0);
    const [planCost, setPlanCost] = useState<number>(0);
    const [apiErrors, setApiErrors] = useState<any>();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Manage Plan"
    }
        , []);

    const newPlan = useMemo(() => {
        return getItem<{ users: number, plan: number }>('new_plan')
    }, []);

    useEffect(() => {
        if (!newPlan) return;

        setPlan(newPlan.plan);
    }, [newPlan]);

    useEffect(() => {
        if (plan === STANDARD_PLAN_TYPE_ID) {
            setPlanCost(15);
        } else if (plan === PREMIUM_PLAN_TYPE_ID) {
            setPlanCost(25);
        } else {
            setPlanCost(0);
        }
    }, [plan]);

    useEffect(() => {
        setLoading(true);

        searchUsers('', 0, 10000).then((users) => {
            setUsers(users.data.filter(d => !d.deactivated).length);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const onPlanLoaded = (plan?: OrganizationPlan) => {
        setCurrentPlan(plan);
        if (plan && !newPlan) {
            setPlan(plan.planTypeId);
        }
    };

    const handleNext = () => {
        if (!plan) return;

        setItem('new_plan', { plan, users });
        navigate('/admin/billing/plan/payment-details');
    };

    const getPlans = () => {
        return (<section id="main-content" tabIndex={-1}>
            <h3>Plans</h3>
            <div className="row justify-content-center">
                <div className="column-medium-4">
                    <div className={getclassNames('card', plan === STANDARD_PLAN_TYPE_ID ? 'selected' : '')}>
                        <div className="card-container">
                            <div>
                                <h4><strong>Standard</strong></h4>
                                $15 per month per user seat
                                <hr />
                                <ul>
                                    <li>Create unlimited forms</li>
                                    <li>Execute unlimited reports</li>
                                    <li>Export your data</li>
                                    <li>1 GB of data storage per user</li>
                                    <li>Self-help docs</li>
                                    <li>Email support</li>
                                    <li>Minimum of 1 user seat</li>
                                </ul>
                            </div>
                            <div>
                                <div className="row justify-content-end align-items-center">
                                    {currentPlan?.planTypeId === STANDARD_PLAN_TYPE_ID && <div className="column-auto">
                                        <i>Current Plan</i>
                                    </div>}
                                    <div className="column-auto">
                                        <Button id="select-standard-button" text="Select Standard" className="small" onClick={() => setPlan(STANDARD_PLAN_TYPE_ID)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column-medium-4">
                    <div className={getclassNames('card', plan === PREMIUM_PLAN_TYPE_ID ? 'selected' : '')}>
                        <div className="card-container">
                            <div>
                                <h4><strong>Premium</strong> - <i>Coming Soon</i></h4>

                                $25 per month per user seat
                                <hr />
                                <ul>
                                    <li>Everything with Standard</li>
                                    <li>Participant profile photo</li>
                                    <li>2GB of data storage per user</li>
                                    <li>Priority Email support  M-F</li>
                                    <li>Schedule Automatic Reports</li>
                                </ul>
                            </div>
                            <div>
                                <div className="row justify-content-end align-items-center">
                                    {currentPlan?.planTypeId === PREMIUM_PLAN_TYPE_ID && <div className="column-auto">
                                        <i>Current Plan</i>
                                    </div>}
                                    <div className="column-auto">
                                        <Button id="select-premium-button" text="Select Premium" disabled={true} className="small" onClick={() => setPlan(PREMIUM_PLAN_TYPE_ID)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
    };

    const getMainContent = () => {
        if (loading) {
            return <Loader />
        }

        return (<>
            <BackButton />
            <ApiErrorMessages error={apiErrors} />
            <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium">
                    <div className="row">
                        <div className="column-medium-4">
                            <CurrentPlan onLoad={onPlanLoaded} />
                        </div>
                        <div className="column-medium-4">
                            <NewPlan />
                        </div>
                    </div>
                    <div className="row margin-top-3">
                        <div className="column">
                            <hr />
                            {getPlans()}
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-end margin-top-5">
                        <div className="column-auto">
                            {users} Active User(s) x {formatCurrency(planCost)}
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <h4>Subtotal: {formatCurrency(planCost * users)}</h4>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <div className="row justify-content-end">
                                <div className="column-auto">
                                    <Button id="checkout-button" text="Next" className="action"
                                        disabled={!plan || users <= 0 || plan === TRIAL_PLAN_TYPE_ID}
                                        onClick={handleNext} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="column-auto">
                                    {(!plan || plan === TRIAL_PLAN_TYPE_ID) && <div><small>Please select a plan to continue</small></div>}
                                    {users <= 0 && <div><small>Please active atleast one user to continue</small></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    };

    return getMainContent();
};

export default ChangePlan;