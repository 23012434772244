import React, { useContext, useEffect, useRef, useState } from "react";
import './ParticipantSearch.css'
import Button from "../UI/Button";
import SearchResultsTable from "./SearchResultsTable";
import useElementVisible from "../../hooks/useElementVisible";
import Loader from "../UI/Loader";
import Protect from "../Layout/Protect";
import Search, { SearchEvent } from "../UI/Search";
import { executeSearch, getDefaultSearchFilters, getSearchFields } from "../../services/searchService";
import SearchFieldReference from "../../models/serviceModels/SearchFieldReference";
import { CLIENT_SEARCH } from "../../constants/searchType";
import Client from "../../models/serviceModels/Client";
import DefaultSearchFilter from "../../models/serviceModels/DeafultSearchFilter";
import ApiErrorMessages from "../UI/ApiErrorMessages";
import { MeContext } from "../../App";

const TAKE = 50;

export default function ParticipantSearch() {
    const [searching, setSearching] = useState(false);
    const [paging, setPaging] = useState(false);
    const [skip, setSkip] = useState(0);
    const [clients, setClients] = useState<Client[]>();
    const [totalCount, setTotalCount] = useState(0);
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [searchFields, setSearchFields] = useState<SearchFieldReference[]>([]);
    const [searchFilters, setSearchFilters] = useState<DefaultSearchFilter[]>([]);
    const [apiError, setApiError] = useState<any>();
    const loaded = useRef(false);
    const [searchEvent, setSearchEvent] = useState<SearchEvent>();
    const me = useContext(MeContext);

    const handleOnSearch = (e: SearchEvent) => {
        setSearching(true);
        setSkip(0);
        setSearchEvent(e);
        setApiError(undefined);

        executeSearch<Client>({
            fields: e.fields?.map(f => {
                return {
                    id: f.fieldId,
                    filterTypeId: f.filterTypeId,
                    value: f.value
                }
            }) || [],
            disabledFilters: e.disabledFilters,
            skip: 0,
            take: TAKE,
            searchTypeId: CLIENT_SEARCH,
            query: e.query
        }).then(r => {
            setClients(r.data);
            setTotalCount(r.totalCount)
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSearching(false);
        });
    }

    useEffect(() => {
        if (!loaded.current || skip === 0) {
            return;
        }
        
        setApiError(undefined);

        if (!searchEvent) {
            console.warn('Trying to page but no search event to assist in paging');
            return;
        }

        setPaging(true);

        executeSearch<Client>({
            fields: searchEvent.fields?.map(f => {
                return {
                    id: f.fieldId,
                    filterTypeId: f.filterTypeId,
                    value: f.value
                }
            }) || [],
            disabledFilters: searchEvent.disabledFilters,
            skip: skip,
            take: TAKE,
            searchTypeId: CLIENT_SEARCH,
            query: searchEvent.query
        }).then(r => {
            setClients(current => {
                if (!current) {
                    return [
                        ...r.data
                    ];
                }

                return [
                    ...current,
                    ...r.data
                ];
            });
            setTotalCount(r.totalCount)
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setPaging(false);
        });

    }, [skip]);

    useEffect(() => {
        if (visible && totalCount > 0) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible, totalCount]);

    useEffect(() => {
        loaded.current = true;
    }, []);

    useEffect(() => {
        Promise.all([getSearchFields(CLIENT_SEARCH), getDefaultSearchFilters(CLIENT_SEARCH)]).then(([fieldsResult, filtersResult]) => {
            setSearchFields(fieldsResult);
            setSearchFilters(filtersResult);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        });
    }, [])

    return (<section id="main-content" tabIndex={-1}>
        <div className="row justify-content-center">
            <div className="column-medium-10">
                <div className="row justify-content-end">
                    <div className="column-medium-auto">
                        <Protect user={me.user} roles={['Admin', 'Contributor']} behavior="hide"><Button id="create-participant" href="/participants/add" text="Add Participant" /></Protect>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="column-auto">
                        <h1>Participant Search</h1>
                    </div>
                </div>
                <ApiErrorMessages error={apiError} />
                {searchFields && searchFields.length !== 0 && <Search id="participant-search"
                    fields={searchFields}
                    defaultFilters={searchFilters}
                    searching={searching}
                    onSearch={handleOnSearch} />}                
                {clients && <SearchResultsTable clients={clients} />}
                {paging && <Loader />}
                {clients && clients.length < totalCount && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
            </div>
        </div>
    </section>
    )

}

