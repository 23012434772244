import axios from "axios";
import { FormEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import Checkbox from "../components/UI/Checkbox";
import Input from "../components/UI/Input";
import Loader from "../components/UI/Loader";
import ValidatedInput from "../components/UI/ValidatedInput";
import ValidatedSelect from "../components/UI/ValidatedSelect";
import { roleMap } from "../constants/userRoles";
import EditUserModel from "../models/pageModels/EditUserModel";
import { getUser, updateUser } from "../services/userService";
import { MeContext } from "../App";


const getDefaultModel = (): EditUserModel => {
    return {
        firstName: '',
        lastName: '',
        email: '',
        roleId: '',
        confirmEmail: '',
        changeEmail: false,
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        changePassword: false
    };
}

interface ModelErrors {
    [key: string]: string[]
}

const getDefaultErrors = (): ModelErrors => {
    const model = getDefaultModel();
    const keys = Object.keys(model);
    const errorsContainer: ModelErrors = {};
    keys.forEach(k => errorsContainer[k] = []);
    return errorsContainer;
};

const EditUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const me = useContext(MeContext);
    const [model, setModel] = useState<EditUserModel>(getDefaultModel());
    const [loading, setLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState(false);
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors());
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitErrors, setSubmitErrors] = useState<any>(null);
    const location = useLocation();

    useEffect(() => {
        document.title = "Edit User Profile"
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        getUser(id).then((user) => {
            setModel({
                ...model,
                roleId: user.userRoleId.toString(),
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber
            });
        }).catch(e => {
            console.error(e);
            setErrorLoading(true);
        }).finally(() => {
            setLoading(false);
        })
    }, [id]);

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model,
            [fieldName]: value
        });
    };

    const validate = () => {
        const newErrors = getDefaultErrors();
        let isValid = true;

        if (!model.firstName) {
            isValid = false;
            newErrors.firstName.push('First Name is required');
        }

        if (!model.lastName) {
            isValid = false;
            newErrors.lastName.push('Last Name is required');
        }

        if (!model.roleId) {
            isValid = false;
            newErrors.roleId.push('Role is required');
        }

        if (model.changeEmail) {
            if (!model.email) {
                isValid = false;
                newErrors.email.push('Email is required');
            } else if (model.email.indexOf('@') === -1 || model.email.indexOf('.') === -1) {
                isValid = false;
                newErrors.email.push('Email is invalid');
            } else if (model.confirmEmail !== model.email) {
                isValid = false;
                newErrors.confirmEmail.push('Confirm Email must match Email');
            }
        }

        if (model.changePassword) {
            if (!model.password) {
                isValid = false;
                newErrors.password.push('Password is required');
            } else if (model.password.length < 8 || !model.password.match(/[a-z]/) || !model.password.match(/[A-Z]/) || !model.password.match(/(!|@|#|$|%|^|&|\*)/) || !model.password.match(/\d/)) {
                isValid = false;
                newErrors.email.push('Password must be atleast 8 characters, contain 1 lower case letter, 1 upper case letter, 1 number, and 1 special character (!@#$%^&*)');
            } else if (model.confirmPassword !== model.password) {
                isValid = false;
                newErrors.confirmEmail.push('Confirm Email must match Email');
            }
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!id || !me.user?.id) {
            return;
        }
        setSubmitErrors(null);
        setSubmitted(true);
        if (!validate()) {
            return;
        }
        setSubmitted(false);
        setSubmitting(true);
        updateUser({
            id,
            firstName: model.firstName,
            lastName: model.lastName,
            phoneNumber: model.phoneNumber,
            password: model.changePassword ? model.password : undefined,
            email: model.changeEmail ? model.email : undefined,
            roleId: Number(model.roleId)
        }).then(() => {
            if (me.user!.id === id) {
                me.refresh();
            }
            
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(-1);
            }
        }).catch(e => {
            setSubmitErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    if (loading || me.loading) {
        return (<div className="section">
            <Loader />
        </div>);
    }

    if (errorLoading) {
        return (<div className="section">
            <div className="row justify-content-center">
                <div className="column-auto">
                    Failed to load...
                </div>
            </div>
        </div>);
    }

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <div className="row justify-content-center">
                    <div className="column-medium-6">
                        <BackButton />
                        <h1 className="form-header">Edit User Profile</h1>
                        <form onSubmit={handleOnSubmit}>
                            <ApiErrorMessages error={submitErrors} />
                            <ApiErrorMessages error={me.error} />
                            <div className="row margin-vertical-2">
                                <div className="column-medium-3">
                                    <ValidatedInput id="first-name"
                                        value={model.firstName}
                                        onChange={(e) => handleOnChange('firstName', e)}
                                        label="First Name"
                                        messages={(submitted && modelErrors.firstName) || []} />
                                </div>
                                <div className="column-medium-3">
                                    <ValidatedInput id="last-name"
                                        value={model.lastName}
                                        onChange={(e) => handleOnChange('lastName', e)}
                                        label="Last Name"
                                        messages={(submitted && modelErrors.lastName) || []} />
                                </div>
                                <div className="column-medium-3">
                                    <ValidatedInput id="phone-number"
                                        value={model.phoneNumber}
                                        onChange={(e) => handleOnChange('phoneNumber', e)}
                                        label="Phone Number"
                                        mask="(000)000-0000"
                                        useMaskedValue={true}
                                        messages={(submitted && modelErrors.phoneNumber) || []} />
                                </div>
                                <div className="column-medium-3">
                                    <ValidatedSelect id="role"
                                        value={model.roleId}
                                        onChange={(e) => handleOnChange('roleId', e)}
                                        label="Role"
                                        messages={(submitted && modelErrors.roleId) || []}>
                                        <option>--Select---</option>
                                        {Object.keys(roleMap).map(k => {
                                            return <option value={k.toString()}>{(roleMap as any)[k]}</option>
                                        })}
                                    </ValidatedSelect>
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column-auto">
                                    <div>
                                        <Checkbox id="change-email"
                                            label="Change Email"
                                            onChange={(checked) => handleOnChange('changeEmail', checked)}
                                            checked={model.changeEmail} />
                                    </div>
                                </div>
                            </div>
                            <div className="row margin-veritical-2">
                                <div className="column-medium-4">
                                    <ValidatedInput id="email"
                                        value={model.email}
                                        onChange={(e) => handleOnChange('email', e)}
                                        label="E-Mail"
                                        disabled={!model.changeEmail}
                                        messages={(submitted && modelErrors.email) || []} />
                                </div>
                                <div className="column-medium-4">
                                    <ValidatedInput id="confirm-email"
                                        value={model.confirmEmail}
                                        onChange={(e) => handleOnChange('confirmEmail', e)}
                                        label="Confirm E-Mail"
                                        disabled={!model.changeEmail}
                                        messages={(submitted && modelErrors.confirmEmail) || []} />
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column-medium-4">
                                    <Checkbox id="change-password"
                                        label="Change Password"
                                        onChange={(checked) => handleOnChange('changePassword', checked)}
                                        checked={model.changePassword} />
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column-medium-4">
                                    <ValidatedInput id="password"
                                        value={model.password}
                                        onChange={(e) => handleOnChange('password', e)}
                                        label="Password"
                                        type="password"
                                        disabled={!model.changePassword}
                                        messages={(submitted && modelErrors.password) || []} />
                                </div>
                                <div className="column-medium-4">
                                    <ValidatedInput id="confirm-password"
                                        value={model.confirmPassword}
                                        onChange={(e) => handleOnChange('confirmPassword', e)}
                                        label="Confirm Password"
                                        type="password"
                                        disabled={!model.changePassword}
                                        messages={(submitted && modelErrors.confirmPassword) || []} />
                                </div>
                            </div>
                            <div className="row justify-content-end margin-top-4">
                                <div className="column-auto">
                                    <Button id="submit-button" type="submit" text="Submit" loading={submitting} disabled={submitting} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>);
};

export default EditUser;