import { useEffect } from "react";

const AccessDisabled = () => {
    useEffect(() => {
        document.title = 'Access Disabled';
    }, []);

    return <section id="access-denied-section">
        <div className="row justify-content-center">
            <div className="column-medium-6 border margin-top-5 rounded padding-5">
                <h1>Access Disabled</h1>
                <p>Your organization's access has been disabled.</p>
                <p>In order to reinstate access please contact support at <a className='email-link' href="mailto:contact@countbubble.com?subject=Access Denied">contact@countbubble.com</a></p>
            </div>
        </div>
    </section>;
};

export default AccessDisabled;