import { Navigate, Outlet } from "react-router-dom";
import ErrorMessages from "../UI/ErrorMessages";
import MeUser from "../../models/serviceModels/MeUser";

export interface ProtectedProps {
    roles?: string[];
    behavior: 'redirect' | 'hide' | 'message';
    user?: MeUser;
    children?: any;
}

const Protect = ({ roles = undefined,
    behavior = 'redirect',
                    user = undefined,
                    children }: ProtectedProps) => {

    if (!user) {
        // by default when no user is provided just hide the children
        return null;
    }

    if (roles && !roles.includes(user.userRoleName) && !roles.includes('*')) {
        if (behavior === 'redirect') {
            return <Navigate to="/errors/unauthorized" />;
        }
        
        if (behavior === 'hide') {
            return null;
        }

        return <ErrorMessages messages={['You are not allowed to perform this action']} />
    }

    return children || <Outlet />;
}

export default Protect;