import { EVERYTHING, IN_PROGRESS_GOALS } from "../../../constants/planScopes";
import EverythingPlansResults from "./EverythingPlansResults";
import InProgressGoalsResults from "./InProgressGoalsResults";
import ResultProps from "./ResultProps";

const PlansResults = (props: ResultProps) => {
    if (props.parameters?.scope === IN_PROGRESS_GOALS) {
        return <InProgressGoalsResults {...props} />;
    } else if (props.parameters?.scope === EVERYTHING) {
        return <EverythingPlansResults {...props} />
    }

    return <></>;
};

export default PlansResults;