import getclassNames from "../../../utils/getClassNames";
import Button from "../../UI/Button";
import ReportStepProps from "./ReportStepProps";
import { format, isMatch, parse } from "date-fns";
import IconButton from "../../UI/IconButton";
import { AGE_AS_OF_SUMMARY } from "../../../constants/reportStep";
import Input from "../../UI/Input";
import Checkbox from "../../UI/Checkbox";

const INCLUDE_AGE_SUMMARY = 'include_age_summary';
const AGE_AS_OF_DATE = 'age_as_of_date';

const AgeAsOfSummary = (props: ReportStepProps) => {
    const handleToggleExpanded = () => {
        props.onExpand(AGE_AS_OF_SUMMARY, !props.expanded);
    };

    const handleOnChange = (fieldName: string, value: string) => {
        const matchingField = props.values.find(v => v.name === fieldName);
        if (matchingField) {
            matchingField.value = value;
            props.onChange({ 
                step: AGE_AS_OF_SUMMARY,
                values: [
                    ...props.values
                ]
            });
        } else {
            props.onChange({
                step: AGE_AS_OF_SUMMARY,
                values: [
                    ...props.values,
                    { name: fieldName, value }
                ]
            })
        }
    };

    const handleOnNext = () => {
        props.onNext(AGE_AS_OF_SUMMARY);
    };

    const includeAgeSummary = props.values.find(v => v.name === INCLUDE_AGE_SUMMARY)?.value === 'true' || false;
    const ageAsOfDate = props.values.find(v => v.name == AGE_AS_OF_DATE)?.value || '';
        
    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
    <div className="report-step-header">
        <div className="row justify-content-between align-items-center">
            <div className="column-auto">
                <h3>Configure Summary</h3>
            </div>
            <div className="column-1">
                <IconButton id="toggle-report-perspective-expansion"
                    className="small flat"
                    disabled={props.disabled}
                    icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                    onClick={handleToggleExpanded} />
            </div>
        </div>
    </div>
    <div className="report-step-body">
        <div className="row margin-bottom-3">
            <div className="column">
                <div className="row">
                    <div className="column">
                        <Checkbox id="include-age-summary"
                            label="Include Age Summary"
                            checked={includeAgeSummary}
                            onChange={(v) => handleOnChange(INCLUDE_AGE_SUMMARY, v.toString().toLowerCase())} />
                    </div>
                    <div className="column">
                        <Input id="age-as-of-date"
                            type="date"
                            label="As of Date"
                            value={ageAsOfDate}
                            disabled={!includeAgeSummary}
                            onChange={(v) => handleOnChange(AGE_AS_OF_DATE, v)} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-end margin-bottom-3">
            <div className="column-auto">
                <Button id="report-type-next"
                    text="Next"
                    icon={<i className="ri-arrow-right-s-line"></i>}
                    iconLocation="end"
                    className="secondary"
                    disabled={(includeAgeSummary && !ageAsOfDate) || props.disabled}
                    onClick={handleOnNext} />
            </div>
        </div>
    </div>
</div>);
};

export default AgeAsOfSummary;