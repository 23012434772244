import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FormViewer from "../components/UI/FormViewer";
import Loader from "../components/UI/Loader";
import { getClientFormInstance } from "../services/formsService";

const ParticipantAdditionalInformation = () => {
    const { id = '' } = useParams();
    const [loadingForm, setLoadingForm] = useState(true);
    const [clientForm, setClientForm] = useState<any>(null);

    useEffect(() => {
        getClientFormInstance(id).then(f => {
            setClientForm(f);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoadingForm(false);
        })
    }, []);

    if (loadingForm) {
        return <Loader />
    }

    return (
        <>
            {clientForm && (<div>
                <div className="row">
                    <div className="column-auto">
                        <h2>Additional Information</h2>
                    </div>
                </div>
                <FormViewer instance={clientForm} />
            </div>)}
        </>
    );
};

export default ParticipantAdditionalInformation;
