import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";
import ValidatedInput from "../components/UI/ValidatedInput";
import ModelErrors from "../models/pageModels/ModelErrors";
import EditOrganizationPageModel from "../models/pageModels/EditOrganizationPageModel";
import { getDefaultErrors } from "../utils/modelHelpers";
import { updateOrganization } from "../services/organizationService";
import { mapOrganizationToEditOrganizationPageModel } from "../mappers/organizationMapper";
import BackButton from "../components/UI/BackButton";
import Loader from "../components/UI/Loader";
import ValidatedSelect from "../components/UI/ValidatedSelect";
import states from "../constants/states";
import { OrganizationContext } from "../App";

const getDefaultModel = (): EditOrganizationPageModel => {
    return {
        organizationName: '',
        organizationUrl: '',
        organizationEmail: '',
        organizationPhoneNumber: '',
        organizationAddressLine1: '',
        organizationAddressLine2: '',
        organizationCity: '',
        organizationState: '',
        organizationZipCode: ''
    };
}

const EditOrganization = () => {
    const [model, setModel] = useState<EditOrganizationPageModel>();
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors(getDefaultModel()));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [apiErrors, setApiErrors] = useState<any>();
    const navigate = useNavigate();
    const organization = useContext(OrganizationContext);

    useEffect(() => {
        document.title = "Edit Organization"
    }
        , []);

    useEffect(() => {
        if (organization.loading) {
            return;
        }

        if (organization.error) {
            setApiErrors(organization.error);
        }

        if (organization.value) {
            setModel(mapOrganizationToEditOrganizationPageModel(organization.value));
        }
    }, [organization]);

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model!,
            [fieldName]: value
        });
    };

    const validate = () => {
        if (!model) {
            return false;
        }

        const newErrors = getDefaultErrors(getDefaultModel());
        let isValid = true;

        if (!model.organizationName) {
            isValid = false;
            newErrors.organizationName.push('Name is required');
        }

        if (!model.organizationEmail) {
            isValid = false;
            newErrors.organizationEmail.push('Email is required');
        } else if (model.organizationEmail.indexOf('@') === -1 || model.organizationEmail.indexOf('.') === -1) {
            isValid = false;
            newErrors.organizationEmail.push('Email is not a valid format');
        }

        if (!model.organizationPhoneNumber) {
            isValid = false;
            newErrors.organizationPhoneNumber.push('Phone Number is required');
        }

        if (!model.organizationAddressLine1) {
            isValid = false;
            newErrors.organizationAddressLine1.push('Address Line 1 is required');
        }

        if (!model.organizationCity) {
            isValid = false;
            newErrors.organizationCity.push('City is required');
        }

        if (!model.organizationState) {
            isValid = false;
            newErrors.organizationState.push('State is required');
        }

        if (!model.organizationZipCode) {
            isValid = false;
            newErrors.organizationZipCode.push('Zip is required');
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!model) {
            return;
        }

        setApiErrors(undefined);
        setSubmitted(true);
        if (!validate()) {
            return;
        }
        setSubmitted(false);
        setSubmitting(true);
        updateOrganization({
            name: model.organizationName,
            email: model.organizationEmail,
            phoneNumber: model.organizationPhoneNumber,
            addressLine1: model.organizationAddressLine1,
            addressLine2: model.organizationAddressLine2,
            city: model.organizationCity,
            state: model.organizationState,
            zipCode: model.organizationZipCode,
            url: model.organizationUrl
        }).then(() => {
            organization.refresh();
            navigate(-1);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    if (organization.loading || !model) {
        return <Loader />
    }

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <div className="row">
                    <div className="column">
                        <BackButton />
                        <h2 className="form-header">Edit Organization</h2>
                        <form onSubmit={handleOnSubmit}>
                            <ApiErrorMessages error={apiErrors} />
                            <div className="row margin-vertical-2">
                                <div className="column">
                                    <ValidatedInput id="organization-name" required={true}
                                        value={model.organizationName}
                                        onChange={(e) => handleOnChange('organizationName', e)}
                                        label="Name"
                                        messages={(submitted && modelErrors.organizationName) || []} />
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column-medium-4">
                                    <Input id="organization-url"
                                        value={model.organizationUrl}
                                        onChange={(e) => handleOnChange('organizationUrl', e)}
                                        label="URL" />
                                </div>
                                <div className="column-medium-4">
                                    <ValidatedInput id="organization-email" required={true}
                                        value={model.organizationEmail}
                                        onChange={(e) => handleOnChange('organizationEmail', e)}
                                        label="E-Mail"
                                        messages={(submitted && modelErrors.organizationEmail) || []} />
                                </div>
                                <div className="column-medium-4">
                                    <ValidatedInput id="organization-phone-number" required={true}
                                        value={model.organizationPhoneNumber}
                                        onChange={(e) => handleOnChange('organizationPhoneNumber', e)}
                                        label="Phone Number"
                                        mask="(000)000-0000"
                                        useMaskedValue={true}
                                        messages={(submitted && modelErrors.organizationPhoneNumber) || []} />
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column">
                                    <ValidatedInput id="organization-address-line-1" required={true}
                                        value={model.organizationAddressLine1}
                                        onChange={(e) => handleOnChange('organizationAddressLine1', e)}
                                        label="Address Line 1"
                                        messages={(submitted && modelErrors.organizationAddressLine1) || []} />
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column">
                                    <Input id="organization-address-line-2"
                                        value={model.organizationAddressLine2}
                                        onChange={(e) => handleOnChange('organizationAddressLine2', e)}
                                        label="Address Line 2" />
                                </div>
                            </div>
                            <div className="row margin-vertical-2">
                                <div className="column-medium-4">
                                    <ValidatedInput id="organization-city" required={true}
                                        value={model.organizationCity}
                                        onChange={(e) => handleOnChange('organizationCity', e)}
                                        label="City"
                                        messages={(submitted && modelErrors.organizationCity) || []} />
                                </div>
                                <div className="column-medium-4">
                                    <ValidatedSelect id="organization-state" required={true}
                                        value={model.organizationState}
                                        onChange={(e) => handleOnChange('organizationState', e)}
                                        label="State"
                                        messages={(submitted && modelErrors.organizationState) || []}>
                                        <option value="">Select One</option>
                                        {states.map((s, i) => {
                                            return <option key={i} value={s.value}>{s.label}</option>
                                        })}
                                    </ValidatedSelect>
                                </div>
                                <div className="column-medium-4">
                                    <ValidatedInput id="organization-zip" required={true}
                                        value={model.organizationZipCode}
                                        onChange={(e) => handleOnChange('organizationZipCode', e)}
                                        label="Zip"
                                        messages={(submitted && modelErrors.organizationZipCode) || []} />
                                </div>
                            </div>
                            <div className="row justify-content-end margin-top-4">
                                <div className="column-auto">
                                    <Button id="submit-button"
                                        type="submit" 
                                        className="action"
                                        text="Save" 
                                        loading={submitting} 
                                        disabled={submitting} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditOrganization;