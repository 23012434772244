import { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"
import Protect from '../components/Layout/Protect';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import BackButton from "../components/UI/BackButton"
import Button from '../components/UI/Button';
import Loader from '../components/UI/Loader';
import Form from '../models/serviceModels/Form';
import FormInstance from '../models/serviceModels/FormInstance';
import { getForm, getFormInstance } from '../services/formsService';
import { formatJSONDateWithoutTime } from '../utils/dateHelpers';
import FormEditor from '../components/Form/Editor/FormEditor';
import EditableFormField from '../models/pageModels/EditableFormField';
import { mapFormFieldToEditableFormField } from '../mappers/formFieldMapper';
import { MeContext } from '../App';

const ViewFormInstance = () => {

    const { formId, instanceId, versionId } = useParams();
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState<Form>();
    const [instance, setInstance] = useState<FormInstance>();
    const [apiErrors, setApiErrors] = useState<any>();
    const [fields, setFields] = useState<EditableFormField[]>([]);

    const me = useContext(MeContext);

    useEffect(() => {
        document.title = "View Submitted Form Data"
    }, []);

    useEffect(() => {
        setLoading(true);

        if (!formId || !instanceId || !versionId) {
            return;
        }

        Promise.all([getForm(formId, versionId), getFormInstance(formId, instanceId)]).then(([f, fi]) => {
            setInstance(fi);
            setForm(f);
            setFields([
                ...f.fields.map(ff => {
                    const instanceField = fi.instanceFields.find(iff => iff.name === ff.name);
                    return mapFormFieldToEditableFormField(ff, instanceField?.value || '', instanceField?.id || '', instanceField?.files || []);
                })
            ]);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (<section id="main-content" tabIndex={-1}>
        <div className="section">
            <div className="row justify-content-center">
                <div className="column-medium-6">
                    <div className="row justify-content-between align-items-center">
                        <div className="column-auto">
                            <BackButton />
                        </div>
                        <Protect user={me.user} roles={['Admin', 'Contributor']} behavior="hide">{instance && <div className="column-auto">
                            <Button id={`edit-${instance.id}`} className="small" href={`/forms/${formId}/versions/${versionId}/instances/${instanceId}/edit`}
                                text="Edit" />
                        </div>}</Protect>
                    </div>
                    <ApiErrorMessages error={apiErrors} />
                    {form && instance && <>
                        <div className="row align-items-center">
                            <div className="column-auto">
                                <h1>{form?.name}</h1>
                            </div>
                            {instance && <div className="column-auto">
                                <small>
                                    <div>Originally Submitted - {formatJSONDateWithoutTime(instance.created, 'MM/dd/yyyy')}</div>
                                    {(instance.updated && <div>Last Updated - {formatJSONDateWithoutTime(instance.updated, 'MM/dd/yyyy')}</div>) || null}
                                </small>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="column">
                                <FormEditor fields={fields} disabled={true} />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </div>


    </section>

    );
};

export default ViewFormInstance;