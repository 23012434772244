import { useContext, useEffect, useState } from "react";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Checkbox from "../components/UI/Checkbox";
import Loader from "../components/UI/Loader";
import Organization from "../models/serviceModels/Organization";
import { answerTermsOfService, getOrganization } from "../services/organizationService";
import BackButton from "../components/UI/BackButton";
import { useNavigate } from "react-router-dom";
import { OrganizationContext } from "../App";

const TermsOfService = () => {
    const [submitting, setSubmitting] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const navigate = useNavigate();
    const organization = useContext(OrganizationContext);

    const handleOnSubmit = () => {
        if (!agreed) {
            return;
        }
        setApiError(undefined);
        setSubmitting(true);
        answerTermsOfService().then(() => {
            organization.refresh();
            navigate('/');
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const getButtons = () => {
        if (!organization.value || organization.value.tosAnswerBy) return null;

        return (<div className="row justify-content-center margin-vertical-5">
            <div className="column-auto">
                <Checkbox id="agree-tos" label="I Agree" disabled={submitting} checked={agreed} onChange={setAgreed} containerClassName="unboxed margin-top-0" />
            </div>
            <div className="column-auto">
                <Button id="submit-agree-button" loading={submitting} disabled={submitting || !agreed} onClick={handleOnSubmit} text="Submit" />
            </div>
        </div>);
    };

    const getMainContent = () => {
        return <div>
            <div className="row">
                <div className="column">
                    You, your Permitted Affiliate(s), and your/their Authorized User(s) are all located in and residents of
                    the United States; will only use the Service within the territorial limits of the United States; and will
                    only submit, post, or otherwise transmit Client Data (as defined below) associated with Clients who
                    are located in the United States.
                </div>
            </div>
            {getButtons()}
            <div className="row justify-content-center">
                <div className="column">
                    <iframe src="/tos.pdf" width={'100%'} height={800}></iframe>
                </div>
            </div>
            {getButtons()}
        </div>
    };

    return (<section id="main-content" tabIndex={-1}>
        <div className="section">
            <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium-7">
                    <h1>Terms of Service</h1>
                    <ApiErrorMessages error={apiError} />
                    {organization.loading && <Loader />}
                    {!organization.loading && organization.value && getMainContent()}
                </div>
            </div>
        </div>
    </section>
    );

};

export default TermsOfService;