import { ForwardedRef, forwardRef } from "react";
import ErrorMessages, { ErrorMessagesProps } from "./ErrorMessages";
import Select, { SelectProps } from "./Select";
import './ValidatedInputSelect.css';

interface ValidatedSelectProps extends SelectProps, ErrorMessagesProps {
}

const ValidatedSelect = forwardRef((props: ValidatedSelectProps, ref?: ForwardedRef<any>) => {
    return (<>
        <Select {...props} ref={ref} />
        <div className="validated-message-container">
            <ErrorMessages {...props} />
        </div>
    </>
    );
});

export default ValidatedSelect;
