import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import FormEditor from "../components/Form/Editor/FormEditor";
import SideMenu from "../components/UI/SideMenu";
import TrackingHeader from "../components/UI/TrackingHeader";
import { ASSESSMENT_ID, DELIVERY_ID } from "../constants/formTypes";
import Client from "../models/serviceModels/Client";
import Form from "../models/serviceModels/Form";
import Program from "../models/serviceModels/Program";
import { getTrackingOptions } from "../utils/sideMenuHelpers";
import EditableFormField from '../models/pageModels/EditableFormField';
import { createFormInstance, getForm, getLatestFormInstances } from "../services/formsService";
import Loader from "../components/UI/Loader";
import Button from "../components/UI/Button";
import LatestFormInstance from "../models/serviceModels/LatestFormInstance";
import { formatDistance, parseISO } from "date-fns";
import ErrorMessages from "../components/UI/ErrorMessages";
import { mapFormFieldToEditableFormField } from "../mappers/formFieldMapper";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import useQuotas from "../hooks/useQuotas";
import { UPLOADED } from "../constants/fileUploadStatus";

export default function Tracking() {

    const { type = '' } = useParams();
    const [selectedClient, setSelectedClient] = useState<Client>();
    const [selectedProgram, setSelectedProgram] = useState<Program>();
    const [errors, setErrors] = useState<string[]>([]);
    const [submitError, setSubmitError] = useState<any>();
    const [selectedForm, setSelectedForm] = useState<Form>();
    const [fields, setFields] = useState<EditableFormField[]>([]);
    const [instances, setInstances] = useState<LatestFormInstance[]>();
    const [loadingFields, setLoadingFields] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingInstances, setLoadingInstances] = useState(false);
    const quotas = useQuotas();

    useEffect(() => {
        document.title = "Track Data"
    }, []);

    const typeLabel = useMemo(() => {
        const lowerType = type.toLowerCase();
        return lowerType === 'delivery' ? 'Outputs and Outcomes' :
            lowerType === 'assessments' ? 'Assessments' :
                lowerType === 'plan' ? 'Plans' :
                    '';
    }, [type]);

    const typeId = useMemo(() => {
        const lowerType = type.toLowerCase();
        return lowerType === 'delivery' ? DELIVERY_ID :
            lowerType === 'assessments' ? ASSESSMENT_ID :
                0;
    }, [type]);
    
    useEffect(() => {
        loadInstances(typeId);
    }, [typeId]);

    useEffect(() => {
        if (selectedForm && selectedForm.id) {
            setErrors([]);
            setLoadingFields(true);

            getForm(selectedForm.id, selectedForm.versionId).then(f => {
                setFields([
                    ...f.fields.map(fe => mapFormFieldToEditableFormField(fe))
                ]);
            }).catch(e => {
                setErrors(['Failed to get fields']);
                console.error(e);
            }).finally(() => {
                setLoadingFields(false);
            });
        } else {
            setFields([]);
        }
    }, [selectedForm, typeId]);
    
    const getHeaderContent = () => {
        return <TrackingHeader client={selectedClient}
            program={selectedProgram}
            form={selectedForm}
            title={`Track ${typeLabel}`}
            onClientSelected={(c) => setSelectedClient(c)}
            onProgramSelected={(p) => setSelectedProgram(p)}
            onFormSelected={(f) => setSelectedForm(f)}
            type={type} />;
    };

    const loadInstances = (formTypeId: number) => {
        setLoadingInstances(true);

        getLatestFormInstances(formTypeId).then(i => {
            setInstances([
                ...i
            ])
        }).catch((e) => {
            console.error(e);
            setErrors(['Failed to load previous submissions']);
        }).finally(() => {
            setLoadingInstances(false);
        })
    };

    const handleOnFieldsChange = (fields: EditableFormField[]) => {
        setFields([
            ...fields
        ]);
    };

    const handleSubmitForm = (e: React.FormEvent) => {
        e.preventDefault();
        setErrors([]);
        setSubmitError(undefined);

        if (!selectedClient || !selectedClient.id) {
            setErrors(['You must select a participant first before submission']);
            // we display a warning for not selecting
            return;
        }

        if (selectedForm && selectedForm.id) {
            setSubmitting(true);

            createFormInstance({
                formId: selectedForm.id,
                versionId: selectedForm.versionId,
                clientId: selectedClient.id,
                fields: fields.map(f => {
                    return {
                        fieldId: f.id,
                        value: f.value,
                        files: f.files?.map(f => f.fileId!) || []
                    }
                })
            }).then(() => {
                setSelectedClient(undefined);
                handleFormReset();
                loadInstances(typeId);
            }).catch(e => {
                setSubmitError(e);
                console.error(e);
            }).finally(() => {
                setSubmitting(false);
                quotas.refresh();
            })
        } else {
            setErrors(['No selected form']);
        }

    };

    const handleFormReset = () => {
        setFields([
            ...fields.map(f => {
                return {
                    ...f,
                    value: '',
                    files: []
                }
            })
        ])
    };

    const getMainContent = () => {
        return (<>
            {quotas.loading && <Loader />}
            {quotas.limits?.atOrBeyondFormDataLimit && <ErrorMessages messages={["You're organization has reached it's quota of submissions or data usage"]} />}
            {!quotas.loading && selectedForm && fields && fields.length > 0 && <div className="row margin-top-3">
                <div className="column">
                    <form onSubmit={handleSubmitForm}>
                        <ErrorMessages messages={errors} />
                        <ApiErrorMessages error={submitError} />
                        <div className="column">
                            <FormEditor disabled={false} fields={fields} onChange={handleOnFieldsChange} />
                        </div>
                        <div className="row margin-top-3 justify-content-between">
                            <div className="column-auto">
                                <Button id="reset-button" className="secondary" text="Reset" disabled={submitting || !selectedClient || quotas.limits?.atOrBeyondFormDataLimit} onClick={handleFormReset} />
                            </div>
                            <div className="column-auto">
                                <Button id="submit-button" text="Submit" disabled={submitting || !selectedClient || quotas.limits?.atOrBeyondFormDataLimit || fields.some(f => f.files && f.files.some(ff => ff.status !== UPLOADED))} loading={submitting} type="submit" />
                            </div>
                        </div>
                    </form>
                </div>                
            </div>}
            {loadingFields && <Loader />}
            <h3>Recent Submissions</h3>
            {loadingInstances && <Loader />}
            {!loadingInstances && <div className="list-item-container">
                {instances && instances.length > 0 && instances.map(i => {
                    return <div className="list-item" key={i.id}>
                        <div className="row justify-content-between align-items-center">
                            <div className="column-4">
                                {i.name}
                            </div>
                            <div className="column-6">
                                <div className="row">
                                    <div className="column">
                                        {i.clientFirstName} {i.clientLastName}
                                    </div>
                                    <div className="column">
                                        {formatDistance(parseISO(i.updated || i.created), new Date(), { addSuffix: true })}
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="row justify-content-end">
                                    <div className="column-auto">
                                        <Button id={`view-${i.id}`} text="View" className="small"
                                            href={`/forms/${i.formId}/versions/${i.formVersionId}/instances/${i.id}/view`} />
                                    </div>
                                    <div className="column-auto">
                                        <Button id={`edit-${i.id}`} text="Edit" className="small"
                                            href={`/forms/${i.formId}/versions/${i.formVersionId}/instances/${i.id}/edit`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
                {instances && instances.length === 0 && <div>No recent submissions</div>}
            </div>}
        </>
        );
    };

    const resetSelections = () => {
        setSelectedClient(undefined);
        setSelectedProgram(undefined);
        setSelectedForm(undefined);
        setFields([]);
    };

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="section">
                <SideMenu ariaLabel="Track Data Side Menu"
                    id="tracking-menu"
                    title="Track Data"
                    options={getTrackingOptions(resetSelections)}
                    headerContent={getHeaderContent()}
                    mainContent={getMainContent()}
                />
            </div>

        </section>)
        ;
}
