import getclassNames from '../../utils/getClassNames';
import './Toggle.css';

interface ToggleProps {
    id: string;
    option1: { label: string; value: string; };
    option2: { label: string; value: string; };
    value: string;
    onChange: (value: string) => void;
}

const Toggle = (props: ToggleProps) => {
    const handleOnChange = (value: string) => {
        props.onChange(value);
    };

    return <div className="toggle-container">
        <button id={`toggle-option-1-${props.id}`} className={getclassNames('toggle-option toggle-option-1', props.value === props.option1.value ? 'selected' : '')} onClick={() => handleOnChange(props.option1.value)}>
            {props.option1.label}
        </button>
        <button id={`toggle-option-2-${props.id}`} className={getclassNames('toggle-option toggle-option-2', props.value === props.option2.value ? 'selected' : '')} onClick={() => handleOnChange(props.option2.value)}>
            {props.option2.label}
        </button>
    </div>
};

export default Toggle;