import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import Protect from "../components/Layout/Protect";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Loader from "../components/UI/Loader";
import Select from "../components/UI/Select";
import { ASSESSMENT_ID, DELIVERY_ID, Mapping } from "../constants/formTypes";
import useElementVisible from "../hooks/useElementVisible";
import Form from "../models/serviceModels/Form";
import FormInstance from "../models/serviceModels/FormInstance";
import { searchFormsInstances } from "../services/formsService";
import { formatJSONDateWithoutTime } from "../utils/dateHelpers";
import { useParams } from "react-router-dom";
import { ACTIVITY_DATE_ID } from "../constants/fieldTypes";
import { executeSearch, getDefaultSearchFilters, getSearchFields } from "../services/searchService";
import { TRACKING_HISTORY_SEARCH } from "../constants/searchType";
import SearchFieldReference from "../models/serviceModels/SearchFieldReference";
import DefaultSearchFilter from "../models/serviceModels/DeafultSearchFilter";
import Search, { SearchEvent } from "../components/UI/Search";
import { format } from "date-fns";
import { MeContext } from "../App";

const TAKE = 10;

const ParticipantTrackingHistory = () => {
    const { id } = useParams();
    const [paging, setPaging] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState<Form[]>();
    const [instances, setInstances] = useState<FormInstance[]>();
    const [totalInstances, setTotalInstances] = useState<number>();
    const [skip, setSkip] = useState<number>(0);
    const [apiErrors, setApiErrors] = useState<any>();
    const [formTypeId, setFormTypeId] = useState('');
    const [formId, setFormId] = useState('');
    const [searching, setSearching] = useState(false);
    const loaded = useRef(false);
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [filteredForms, setFilteredForms] = useState<Form[]>([]);
    const [searchFields, setSearchFields] = useState<SearchFieldReference[]>([]);
    const [searchFilters, setSearchFilters] = useState<DefaultSearchFilter[]>([]);
    const [searchEvent, setSearchEvent] = useState<SearchEvent>();
    const me = useContext(MeContext);

    useEffect(() => {
        setLoading(true);
        if (id) {
            Promise.all([executeSearch<FormInstance>({
                searchTypeId: TRACKING_HISTORY_SEARCH,
                fields: [],
                disabledFilters: [],
                skip,
                clientId: id,
                take: TAKE
            }), getSearchFields(TRACKING_HISTORY_SEARCH, undefined, true), getDefaultSearchFilters(TRACKING_HISTORY_SEARCH)]).then(([fo, fi, df]) => {
                setSearchFields(fi);
                setSearchFilters(df);
                setInstances(fo.data);
                setTotalInstances(fo.totalCount);
                loaded.current = true;
            }).catch(e => {
                console.error(e);
                setApiErrors(e);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [id]);

    useEffect(() => {
        setFormId('');

        if (!forms || !formTypeId || isNaN(Number(formTypeId))) {
            return;
        }

        setFilteredForms([
            ...forms.filter(f => f.formTypeId === Number(formTypeId))
        ]);
    }, [formTypeId, forms]);

    useEffect(() => {
        if (visible) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible]);

    const executeFormSearch = useCallback((request: { skip: number, formTypeId?: string, formId?: string }) => {
        setApiErrors(undefined);
        if (!id) {
            return new Promise<any>((_, reject) => {
                reject('Cannot execute search without a participant ID')
            });
        }

        return searchFormsInstances({
            skip: request.skip,
            formTypeId: request.formTypeId && !isNaN(Number(request.formTypeId)) ? Number(request.formTypeId) : undefined,
            formId: request.formId,
            clientId: id
        });
    }, [id]);

    useEffect(() => {
        if (!loaded.current) {
            return;
        }

        setPaging(true);

        executeSearch<FormInstance>({
            fields: searchEvent?.fields?.map(f => {
                return {
                    id: f.fieldId,
                    filterTypeId: f.filterTypeId,
                    value: f.value
                }
            }) || [],
            disabledFilters: searchEvent?.disabledFilters || [],
            skip: skip,
            take: TAKE,
            clientId: id,
            searchTypeId: TRACKING_HISTORY_SEARCH,
            query: searchEvent?.query || ''
        }).then(r => {
            setInstances(current => {
                return current ? [
                    ...current,
                    ...r.data
                ] : r.data;
            });
            setTotalInstances(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setPaging(false);
        });
    }, [skip, executeFormSearch]);

    const handleOnSearchV2 = (e: SearchEvent) => {
        setSkip(0);
        setSearching(true);
        setSearchEvent(e);
        setApiErrors(undefined);

        executeSearch<FormInstance>({
            fields: e.fields?.map(f => {
                return {
                    id: f.fieldId,
                    filterTypeId: f.filterTypeId,
                    value: f.value
                }
            }) || [],
            disabledFilters: e.disabledFilters,
            skip: 0,
            take: TAKE,
            clientId: id,
            searchTypeId: TRACKING_HISTORY_SEARCH,
            query: e.query
        }).then(r => {
            setInstances(r.data);
            setTotalInstances(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setSearching(false);
        });
    };

    const handleOnSearch = () => {
        setSkip(0);
        setSearching(true);

        executeFormSearch({ skip: 0, formTypeId, formId }).then(i => {
            setInstances(i.data);
            setTotalInstances(i.totalCount);
        }).catch(e => {
            setApiErrors(e);
            console.error(e);
        }).finally(() => {
            setSearching(false);
        });
    };

    const handleReset = () => {
        setFormTypeId('');
        setFormId('');
        setSkip(0);
        setSearching(true);

        executeFormSearch({
            skip: 0,
            formTypeId: '',
            formId: ''
        }).then(i => {
            setInstances(i.data);
            setTotalInstances(i.totalCount);
        }).catch(e => {
            setApiErrors(e);
            console.error(e);
        }).finally(() => {
            setSearching(false);
        });
    };

    const getFilters = () => {
        if (!forms) {
            return null;
        }

        return (<section id="main-content" tabIndex={-1}>
            <h3>Filters</h3>
            <div className="row">
                <div className="column">
                    <Select id="form-type-filter" label="Form Type" value={formTypeId} onChange={(e) => setFormTypeId(e)}>
                        <option value="">Select One</option>
                        <option value={ASSESSMENT_ID}>{Mapping[ASSESSMENT_ID]}</option>
                        <option value={DELIVERY_ID}>{Mapping[DELIVERY_ID]}</option>
                    </Select>
                </div>
                <div className="column">
                    <Select id="form-name-filter" disabled={!formTypeId || filteredForms.length === 0} label="Form Name" value={formId} onChange={(e) => setFormId(e)}>
                        <option value="">Select One</option>
                        {filteredForms.map(f => {
                            return <option key={f.id} value={f.id}>{f.name}</option>
                        })}
                    </Select>
                </div>
            </div>
            <div className="row justify-content-between margin-top-3 align-items-center">
                <div className="column">
                    {totalInstances} submissions
                </div>
                <div className="column">
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <Button id="filter-button" text="Filter" onClick={handleOnSearch} disabled={searching} loading={searching} />
                        </div>
                        <div className="column-auto">
                            <Button id="reset-button" className="secondary" text="Reset" onClick={handleReset} disabled={searching} />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </section>);
    }

    const getMainContent = () => {
        if (loading) {
            return <Loader />
        }

        const getActivityDate = (instance: FormInstance) => {
            if (instance.activityDate) {
                return instance.activityDate;
            }

            const activityDateField = instance.instanceFields?.find(fi => fi.fieldTypeId === ACTIVITY_DATE_ID);
            if (!activityDateField) {
                return null;
            }

            return activityDateField.value;
        };

        return (
            <>
                <h3>Tracking History</h3>
                <ApiErrorMessages error={apiErrors} />
                {instances && (<>
                    <Search
                        id={`search-tracking-history`}
                        searching={searching}
                        fields={searchFields}
                        defaultFilters={searchFilters}
                        onSearch={handleOnSearchV2}
                        lockedOpen={true} />
                    <div className="list-item-container">
                        {instances.map(i => {
                            const activityDate = getActivityDate(i);
                            return (<div className="list-item">
                                <div className="row align-items-center justify-content-between">
                                    <div className="column-medium-4">
                                        <div><strong>{i.formName}</strong></div>
                                        <div>{Mapping[i.formTypeId]}</div>
                                    </div>
                                    <div className="column-medium-3">
                                        <div><small>Originally Submitted: {format(new Date(i.created), 'MM/dd/yyyy')}</small></div>
                                        {i.updated && <div><small>Last Updated: {i.updated && format(new Date(i.updated), 'MM/dd/yyyy')}</small></div>}
                                        {activityDate && <div><small>Activity Date: {formatJSONDateWithoutTime(activityDate, 'MM/dd/yyyy')}</small></div>}
                                    </div>
                                    <div className="column">
                                        <div className="row justify-content-end">
                                            <div className="column-auto">
                                                <Button id={`view-${i.id}`} text="View" className="small"
                                                    href={`/forms/${i.formId}/versions/${i.formVersionId}/instances/${i.id}/view`} />
                                            </div>
                                            <div className="column-auto">
                                                <Protect user={me.user} roles={['Admin', 'Contributor']} behavior="hide"><Button id={`edit-${i.id}`} text="Edit" className="small"
                                                    href={`/forms/${i.formId}/versions/${i.formVersionId}/instances/${i.id}/edit`} /></Protect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>);

                        })}
                    </div>
                    {paging && <Loader />}
                    {instances && totalInstances && instances.length < totalInstances && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
                </>)
                }
            </>
        );
    };

    return getMainContent();
};

export default ParticipantTrackingHistory;
