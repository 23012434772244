import { differenceInDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { TRIAL_PLAN_TYPE_ID } from '../../constants/planTypes';
import { OrganizationPlan } from '../../models/serviceModels/OrganizationPlan';
import { activateSubscription, deactivateSubscription, getPlan } from '../../services/paymentsService';
import { formatCurrency } from '../../utils/stringHelpers';
import ApiErrorMessages from './ApiErrorMessages';
import Button from './Button';
import Loader from './Loader';
import Modal from './Modal';
import ErrorMessages from './ErrorMessages';

interface CurrentPlanProps {
    onLoad?: (plan?: OrganizationPlan) => void;
    allowModify?: boolean;
}

const CurrentPlan = (props: CurrentPlanProps) => {
    const [currentPlan, setCurrentPlan] = useState<OrganizationPlan>();
    const [planLoading, setPlanLoading] = useState(false);
    const [planApiErrors, setPlanApiErrors] = useState<any>();
    const [confirmDeactivate, setConfirmDeactivate] = useState(false);
    const [confirmActivate, setConfirmActivate] = useState(false);
    const [modalErrors, setModalErrors] = useState<any>();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setPlanLoading(true);

        getPlan().then(plan => {
            setCurrentPlan(plan);
            if (props.onLoad) {
                props.onLoad(plan);
            }
        }).catch(e => {
            console.error(e);
            setPlanApiErrors(e);
        }).finally(() => {
            setPlanLoading(false);
        })
    }, []);

    const getPlanRate = () => {
        if (!currentPlan || currentPlan.planTypeId === TRIAL_PLAN_TYPE_ID) {
            return null;
        }

        return <>{formatCurrency(currentPlan.planTypeCost)} x {currentPlan.planUserCount} users</>;
    };

    const getPlanCost = () => {
        if (!currentPlan) {
            return null;
        }

        if (currentPlan.planTypeId === TRIAL_PLAN_TYPE_ID) {
            if (!currentPlan.planEndDate || isNaN(Number(new Date(currentPlan.planEndDate)))) {
                console.warn('No plan end date set for the trial');
                return null;
            }

            const daysLeft = differenceInDays(new Date(currentPlan.planEndDate), new Date());
            if (daysLeft < 0) {
                return <>Trial Ended</>
            }

            return <>{daysLeft} Days Left</>;
        }

        return <>Total per Month: {formatCurrency(currentPlan.planTypeCost * currentPlan.planUserCount)}</>;
    }

    const handleConfirmDeactivate = () => {
        setConfirmDeactivate(true);
        setModalErrors(undefined);
    };

    const handleConfirmActivate = () => {
        setConfirmActivate(true);
        setModalErrors(undefined);
    };

    const handleDeactivate = () => {
        setSubmitting(true);

        deactivateSubscription().then(p => {
            setCurrentPlan(p);
            setConfirmDeactivate(false);
            document.location.reload();
        }).catch(e => {
            console.error(e);
            setModalErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const handleActivate = () => {
        setSubmitting(true);

        activateSubscription().then(p => {
            setCurrentPlan(p);
            setConfirmActivate(false);
            document.location.reload();
        }).catch(e => {
            console.error(e);
            setModalErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const getCurrentPlan = () => {
        return (<>
            <ApiErrorMessages error={planApiErrors} />
            <div className="row">
                <div className="column">
                    <h3>Current Plan{currentPlan?.planCancelled && <> - <i>Cancelled</i></>}</h3>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <div className="card">
                        <div className="card-container small">
                            <div>
                                {planLoading && <Loader />}
                                {!planLoading && !planApiErrors && currentPlan && (
                                    <>
                                        <h4 style={{ marginTop: "10px", marginBottom: "10px" }}><strong>{currentPlan.planTypeName}</strong></h4>
                                        {getPlanRate()}
                                        <hr />
                                        {getPlanCost()}
                                    </>
                                )}
                            </div>
                            {props.allowModify && (
                                <>
                                    <div className="row justify-content-end align-items-center">
                                        {currentPlan?.planTypeId !== TRIAL_PLAN_TYPE_ID &&
                                            <>
                                                {!currentPlan?.planCancelled && <div className="column-auto">
                                                    <Button id="cancel-plan-button" text="Cancel Plan" className="small action" onClick={handleConfirmDeactivate} />
                                                </div>}
                                                {currentPlan?.planCancelled && <div className="column-auto">
                                                    <Button id="cancel-plan-button" text="Activate Plan" className="small action" onClick={handleConfirmActivate} />
                                                </div>}
                                            </>
                                        }
                                        <div className="column-auto">
                                            <Button id="modify-plan-button" text="Modify Current Plan" className="small" href="/admin/billing/plan/change" />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>);
    };

    return (
        <>
            <div className="row">
                <div className="column">
                    {getCurrentPlan()}
                </div>
            </div>
            <Modal id="deactivate-modal" visible={confirmDeactivate} className="small" title="Confirm Plan Cancellation" onClose={() => setConfirmDeactivate(false)}>
                <>
                    {modalErrors && <ApiErrorMessages error={modalErrors} />}
                    <div className="row">
                        <div className="column">
                            <p>Are you sure you want to cancel your plan?</p>
                            <p>This is change takes place immediately.</p>
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-deactivate" text="No" className="secondary" disabled={submitting} onClick={() => setConfirmDeactivate(false)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-deactivate" text="Yes" className="action" disabled={submitting} loading={submitting} onClick={handleDeactivate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
            <Modal id="activate-modal" visible={confirmActivate} className="small" title="Confirm Plan Activation" onClose={() => setConfirmActivate(false)}>
                <>
                    {modalErrors && <ApiErrorMessages error={modalErrors} />}
                    <div className="row">
                        <div className="column">
                            <p>Are you sure you want to activate your plan?</p>
                            <p>This is change takes place immediately.</p>
                        </div>
                    </div>
                    <div className="row margin-top-5">
                        <div className="column">
                            <div className="row justify-content-between">
                                <div className="column-medium-auto">
                                    <Button id="cancel-activate" text="No" className="secondary" disabled={submitting} onClick={() => setConfirmActivate(false)} />
                                </div>
                                <div className="column-medium-auto">
                                    <Button id="confirm-activate" text="Yes" className="action" disabled={submitting} loading={submitting} onClick={handleActivate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default CurrentPlan;