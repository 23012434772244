import ValidateResponse from "./ValidateResponse";

export default interface AgeValidateResponse extends ValidateResponse {
    from: string[];
    to: string[];
    asOfDate: string[];
    fromAndTo: string[];
}

export const defaultValue = (): AgeValidateResponse => {
    return {    
        value: [],
        operator: [],
        from: [],
        to: [],
        fromAndTo: [],
        asOfDate: [],
        isValid: true,
        default: []
    };
}