import React, { useState } from 'react';
import FieldModelOption from '../../../../models/pageModels/FieldModelOption';
import Button from '../../../UI/Button';
import Checkbox from '../../../UI/Checkbox';
import Input from '../../../UI/Input';
import EditFormProps from './Models/EditFormProps';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { manageFormsEnabled } from '../../../../utils/features';

const SelectListEditForm = (props: EditFormProps) => {
    const [label, setLabel] = useState(props.field.label);
    const [name, setName] = useState(props.field.name);
    const [options, setOptions] = useState<FieldModelOption[]>(props.field.options || []);
    const [required, setRequired] = useState(props.field.required);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [sortValues, setSortValues] = useState(props.field.sortValues || false);

    const handleOnSave = (e: any) => {
        e.preventDefault();

        props.onSave({
            ...props.field,
            label,
            name,
            required,
            sortValues,
            options: [
                ...options
            ]
        });
    };

    const handleOnOptionDelete = (option: FieldModelOption) => {
        if (option.id) {
            option.deleted = true;
            setOptions([
                ...options
            ]);
        } else {
            setOptions([
                ...options.filter(o => o !== option)
            ]);
        }
    };

    const handleOnOptionLabelChange = (option: FieldModelOption, label: string) => {
        const existingOption = options.find(o => o === option);
        if (!existingOption) return;

        existingOption.label = label;
        existingOption.value = label;

        setOptions([
            ...options
        ]);
    };

    const handleOnAdd = () => {
        setOptions([
            ...options,
            { value: '', label: '', order: options.length + 1, deleted: false }
        ])
    };

    const handleOnDragEnd = (result: any) => {
        const { source, destination, draggableId } = result;

        if (!destination || 
            destination.droppableId !== '/options' ||
            draggableId.indexOf('/options') === -1) {
            return;
        }

        const optionsNotDeleted = options.filter(o => !o.deleted);
        const targetOption = optionsNotDeleted[source.index];
        const newOptions = [];
        for (let i = 0; i < optionsNotDeleted.length; i++) {
            const option = optionsNotDeleted[i];
            if (option.deleted) continue;
            if (destination.index === i) {
                if (destination.index < source.index) {
                    newOptions.push(targetOption);
                }

                newOptions.push(option);

                if (destination.index  > source.index) {
                    newOptions.push(targetOption);
                }

            } else if (i !== source.index) {
                newOptions.push(option);
            }
        }

        for (let i = 0; i < newOptions.length; i++) {
            newOptions[i].order = i + 1;
        }

        for(const deletedOption of options.filter(o => o.deleted)){
            newOptions.push(deletedOption);
        }

        setOptions([
            ...newOptions
        ]);
    };

    return (<form onSubmit={handleOnSave}>
        <div className="row margin-top-2">
            <div className="column">
                <label>Current Label</label>
                <br />
                {props.field.label}
            </div>
        </div>
        <div className="row margin-top-2">
            <div className="column">
                <Input id="editing-field-label"
                    value={label}
                    onChange={(e) => setLabel(e)} />
            </div>
        </div>
        <div className="row margin-top-2">
            <div className="column">
                <Checkbox id="field-required"
                    label="Required"
                    checked={required}
                    onChange={(checked) => setRequired(checked)} />
            </div>
        </div>
        {!showAdvanced && <div><Button id="show-advanced-button" className="flat small" text="Show Advanced" icon={<i className="ri-arrow-down-s-line" />} iconLocation="end" onClick={() => setShowAdvanced(true)} /></div>}
        {showAdvanced && <div><Button id="hide-advanced-button" className="flat small" text="Hide Advanced" icon={<i className="ri-arrow-up-s-line" />} iconLocation="end" onClick={() => setShowAdvanced(false)} /></div>}
        {showAdvanced && <div className="advanced-content">
            <div className="row">
                <div className="column">
                    <Input id="editing-field-name"
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e)} />
                </div>
            </div>
            <div className="row margin-top-2">
                <div className="column">
                    <Checkbox id="sort-values"
                        label="Sort Options"
                        checked={sortValues}
                        onChange={(checked) => setSortValues(checked)} />
                </div>
            </div>
        </div>}
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="/options">
                {(droppableProvided) =>
                    <div ref={droppableProvided.innerRef} className="row margin-top-2">
                        <div className="column">
                            {manageFormsEnabled && sortValues && <div className="text-sub">Sort Values is enabled under Advanced.  Disable it to control the order of the options</div>}
                            {options.filter(o => !o.deleted).map((o, index: number) => {
                                return (
                                    <Draggable draggableId={`/options/${o.id}_${index}`}
                                        index={index}
                                        isDragDisabled={!manageFormsEnabled || sortValues}>
                                        {(providedDraggable) =>
                                            <div
                                                ref={providedDraggable.innerRef}
                                                {...providedDraggable.draggableProps}
                                                {...providedDraggable.dragHandleProps}
                                                className="row margin-top-1 align-items-center field-edit-option" key={index}>
                                                <div className="column">
                                                    <Input id={`${o.id}-${index}-option-label`}
                                                        value={o.label}
                                                        onChange={(e) => handleOnOptionLabelChange(o, e)}
                                                        label={`Option ${index + 1}`} />
                                                </div>
                                                <div className="column-auto">
                                                    <Button className="small margin-top-6 warning"
                                                        id={`${index}-delete-option`}
                                                        text="Delete"
                                                        onClick={() => handleOnOptionDelete(o)}
                                                        disabled={props.saving} />
                                                </div>
                                            </div>}
                                    </Draggable>)
                            })}
                        </div>
                        {droppableProvided.placeholder}
                    </div>}
            </Droppable>
        </DragDropContext>
        {(!options || options.length === 0) && <div margin-vertical-4>Select "Add Option" to add a option for this field</div>}
        <Button id="add-option-field-button" onClick={handleOnAdd} className="margin-vertical-4" text="Add Option" disabled={props.saving} />
        <div className="row margin-top-3 justify-content-between">
            <div className="column-medium-auto">
                <Button className="secondary"
                    id="cancel-edit-field-button"
                    onClick={props.onCancel}
                    text="Cancel"
                    disabled={props.saving} />
            </div>
            <div className="column-medium-auto">
                <Button type="submit"
                    id="save-edit-field-button"
                    text="Save"
                    className="action"
                    loading={props.saving}
                    disabled={props.saving} />
            </div>
        </div>
    </form>);
};

export default SelectListEditForm;

