import { useEffect, useRef, useState, KeyboardEvent } from "react";
import CasePlanGoal from "../../models/serviceModels/CasePlanGoal";
import getclassNames from "../../utils/getClassNames";
import Input from "./Input";
import { formatJSONDateWithoutTime } from "../../utils/dateHelpers";
import Select from "./Select";
import Button from "./Button";
import CasePlanGoalStatus from "../../models/serviceModels/CasePlanGoalStatus";
import Dropdown from "./Dropdown";
import { createCasePlanGoalStatus, createGoalTask, deleteGoalTask, updateCasePlanGoal, updateGoalTask } from "../../services/clientsService";
import { format } from "date-fns";
import ApiErrorMessages from "./ApiErrorMessages";
import TruncatedText from "./TruncatedText";
import GoalTask from "../../models/serviceModels/GoalTask";
import Modal from "./Modal";
import ParticipantCasePlanGoalTask from "./ParticipantCasePlanGoalTask";
import { QuotasModel } from "../../models/pageModels/QuotasModel";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "./Loader";
import ConfettiExplosion from "react-confetti-explosion";

interface ParticipantCasePlanGoalProps {
    casePlanStopped: boolean;
    goal: CasePlanGoal;
    goalNumber: number;
    clientId: string;
    onDelete: (goal: CasePlanGoal) => void;
    onGoalChanged: (goal: CasePlanGoal) => void;
    goalStatuses: CasePlanGoalStatus[];
    editing: boolean;
    focused: boolean;
    quotas: QuotasModel;
    reorderingGoal: boolean;
    otherGoalReorderingTasks: boolean;
    onReorderingTasks: (goal: CasePlanGoal, reordering: boolean) => void;
}

const ParticipantCasePlanGoal = (props: ParticipantCasePlanGoalProps) => {
    const [goal, setGoal] = useState<CasePlanGoal>(props.goal);
    const [editingGoalVersion, setEditingGoalVerison] = useState<CasePlanGoal | undefined>(props.editing ? {
        ...props.goal
    } : undefined);
    const [expanded, setExpanded] = useState(false);
    const [savingStatus, setSavingStatus] = useState(false);
    const [savingGoal, setSavingGoal] = useState(false);
    const [editingGoalStatusVersion, setEditingGoalStatusVersion] = useState<CasePlanGoal>();
    const [apiError, setApiError] = useState<any>();
    const [deletingGoalTask, setDeletingGoalTask] = useState<GoalTask>();
    const [modalApiError, setModalApiError] = useState<any>();
    const [deleting, setDeleting] = useState(false);
    const [addingTask, setAddingTask] = useState(false);
    const [recentlyCreatedTask, setRecentlyCreatedTask] = useState<GoalTask>();
    const [focused, setFocused] = useState(props.focused);
    const nameReference = useRef<HTMLInputElement>();
    const [reorderingGoal, setReorderingGoal] = useState(props.reorderingGoal);
    const [casePlanStopped, setCasePlanStopped] = useState(props.casePlanStopped);
    const [reorderingTasks, setReorderingTasks] = useState(false);
    const [savingReorder, setSavingReorder] = useState(false);
    const firstTaskReference = useRef<any>();
    const [celebrate, setCelebrate] = useState(false);
    const [otherGoalReorderingTasks, setOtherGoalReordingTasks] = useState(props.otherGoalReorderingTasks);

    useEffect(() => {
        if (focused && nameReference.current) {
            nameReference.current.focus();
            setFocused(false);
        }
    }, [focused]);

    useEffect(() => {
        setOtherGoalReordingTasks(props.otherGoalReorderingTasks);
    }, [props.otherGoalReorderingTasks]);

    useEffect(() => {
        if (otherGoalReorderingTasks) {
            setReorderingTasks(false);
        }
    }, [otherGoalReorderingTasks]);

    useEffect(() => {
        setCasePlanStopped(props.casePlanStopped);
        if (props.casePlanStopped) {
            setEditingGoalVerison(undefined);
            setEditingGoalStatusVersion(undefined);
        }
    }, [props.casePlanStopped]);

    useEffect(() => {
        setReorderingGoal(props.reorderingGoal);
    }, [props.reorderingGoal]);

    useEffect(() => {
        props.onReorderingTasks(goal, reorderingTasks);
    }, [reorderingTasks]);

    const handleOnEdit = () => {
        setEditingGoalVerison({
            ...goal
        });
        setFocused(true);
    };

    useEffect(() => {
        if (celebrate) {
            setTimeout(() => {
                setCelebrate(false);
            }, 3000);
        }
    }, [celebrate]);

    useEffect(() => {
        if (reorderingTasks) {
            firstTaskReference.current?.focus();
        }
    }, [reorderingTasks]);

    useEffect(() => {
        props.onGoalChanged(goal);
    }, [goal]);

    useEffect(() => {
        setGoal(props.goal);
    }, [props.goal]);

    useEffect(() => {
        if (!goal.tasks || goal.tasks.length === 0) {
            return;
        }

        const outOfOrderTasks = [];
        for (let i = 0; i < goal.tasks.length; i++) {
            const task = goal.tasks[i];
            if (task.order !== (i + 1)) {
                outOfOrderTasks.push({ task, newOrder: i + 1 });
            }
        }

        if (outOfOrderTasks.length !== 0) {
            setSavingReorder(true);

            Promise.all(outOfOrderTasks.map(ot => updateGoalTask({
                casePlanId: goal.casePlanId,
                taskId: ot.task.id,
                clientId: props.clientId,
                text: ot.task.text,
                order: ot.newOrder,
                goalId: goal.id
            }))).then((updatedTasks) => {
                setGoal(g => {
                    if (!g.tasks || g.tasks.length === 0) {
                        return g;
                    }

                    for (const ut of updatedTasks) {
                        const existingTask = g.tasks.find(t => t.id === ut.id);
                        if (existingTask) {
                            existingTask.updated = ut.updated;
                            existingTask.updatedBy = ut.updatedBy;
                            existingTask.order = ut.order;
                        }
                    }

                    return {
                        ...g,
                        tasks: [
                            ...g.tasks
                        ]
                    }
                });
            }).catch(e => {
                console.error(e);
                setApiError(e);
            }).finally(() => {
                setSavingReorder(false);
            })
        }
    }, [goal]);

    const handleOnDelete = () => {
        props.onDelete(goal);
    };

    const handleOnTaskDelete = () => {
        if (!deletingGoalTask) {
            return;
        }

        setDeleting(true);

        deleteGoalTask({
            casePlanId: goal.casePlanId,
            clientId: props.clientId,
            goalId: goal.id,
            taskId: deletingGoalTask.id
        }).then(() => {
            setGoal(g => {
                return {
                    ...g,
                    tasks: g.tasks.filter(t => t.id !== deletingGoalTask.id)
                }
            });

            setDeletingGoalTask(undefined);
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setDeleting(false);
        })
    };

    const handleOnCancelSaveGoal = () => {
        setEditingGoalVerison(undefined);
    };

    const handleOnKeyUp = (code: string) => {
        if (code === 'Enter') {
            handleOnSaveGoal();
        } else if (code === 'Escape') {
            handleOnCancelSaveGoal();
        }
    };

    const handleOnSaveGoal = () => {
        if (!editingGoalVersion) {
            return;
        }

        setApiError(undefined);
        setSavingGoal(true);

        updateCasePlanGoal({
            name: editingGoalVersion.name,
            dueDate: editingGoalVersion.dueDate || undefined,
            casePlanId: goal.casePlanId,
            goalId: goal.id,
            clientId: props.clientId,
            order: goal.order
        }).then(r => {
            setGoal(g => {
                return {
                    ...g,
                    name: r.name,
                    updated: r.updated,
                    order: r.order,
                    updatedBy: r.updatedBy,
                    dueDate: r.dueDate
                }
            });
            setEditingGoalVerison(undefined);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSavingGoal(false);
        });
    };

    const handleOnTaskChange = (task: GoalTask) => {
        if (recentlyCreatedTask?.id === task.id) {
            setRecentlyCreatedTask(undefined);
        }

        const existingTask = goal.tasks.find(t => t.id === task.id);
        if (!existingTask) {
            return;
        }

        existingTask.completed = task.completed;
        existingTask.order = task.order;
        existingTask.text = task.text;
        existingTask.updated = task.updated;
        existingTask.updatedBy = task.updatedBy;

        setGoal({
            ...goal,
            tasks: [
                ...goal.tasks
            ]
        });
    };

    const handleOnTaskKeyUp = (e: KeyboardEvent, task: GoalTask) => {
        if (!reorderingTasks || (e.code !== 'ArrowDown' && e.code !== 'ArrowUp') || !goal?.tasks || savingReorder) {
            return;
        }

        if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            e.stopPropagation();

            if (e.code === 'ArrowUp') {
                if (!goal?.tasks || goal.tasks.length === 0) {
                    return;
                }

                const taskIndex = goal.tasks.findIndex(t => t.id === task.id);
                if (taskIndex === -1 || taskIndex === 0) {
                    return;
                }

                const [startingTask] = goal.tasks.splice(taskIndex, 1);
                goal.tasks.splice(taskIndex - 1, 0, startingTask);

                setGoal({
                    ...goal,
                    tasks: [
                        ...goal.tasks
                    ]
                });
            } else if (e.key === 'ArrowDown') {
                if (!goal?.tasks || goal.tasks.length === 0) {
                    return;
                }

                const taskIndex = goal.tasks.findIndex(t => t.id === task.id);
                if (taskIndex === -1 || taskIndex === (goal.tasks.length - 1)) {
                    return;
                }

                const [startingTask] = goal.tasks.splice(taskIndex, 1);
                goal.tasks.splice(taskIndex + 1, 0, startingTask);

                setGoal({
                    ...goal,
                    tasks: [
                        ...goal.tasks
                    ]
                });
            }
        }
    };

    const handleOnSaveStatus = () => {
        if (!editingGoalStatusVersion || !editingGoalStatusVersion.statusId) {
            return;
        }
        setApiError(undefined);

        setSavingStatus(false);

        createCasePlanGoalStatus({
            casePlanId: goal.casePlanId,
            goalId: goal.id,
            clientId: props.clientId,
            statusId: editingGoalStatusVersion.statusId,
            statusDate: editingGoalStatusVersion.statusDate
        }).then(() => {
            const matchingStatus = props.goalStatuses.find(gs => gs.id.toString() === editingGoalStatusVersion?.statusId);

            setGoal(g => {
                return {
                    ...g,
                    endStatus: matchingStatus?.endStatus || false,
                    statusDate: editingGoalStatusVersion.statusDate,
                    statusId: editingGoalStatusVersion.statusId
                }
            });
            setEditingGoalStatusVersion(undefined);
            if (matchingStatus?.endStatus && Number(editingGoalStatusVersion?.statusId) === 3) {
                setCelebrate(true);
            }
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSavingStatus(false);
        });
    };

    const handleOnCancelEditStatus = () => {
        setEditingGoalStatusVersion(undefined);
    };

    const handleToggleGoalExpanded = () => {
        setExpanded(!expanded);
    };

    const handleOnGoalNameChange = (newName: string) => {
        setEditingGoalVerison(g => {
            if (!g) {
                return g;
            }

            return {
                ...g,
                name: newName
            };
        })
    };

    const handleOnGoalDueDateChange = (newDueDate: string) => {
        setEditingGoalVerison(g => {
            if (!g) {
                return g;
            }

            return {
                ...g,
                dueDate: newDueDate
            };
        });
    };

    const handleOnGoalStatusDateChange = (statusDate: string) => {
        setEditingGoalStatusVersion(g => {
            if (!g) {
                return {
                    ...goal,
                    statusDate
                };
            }

            return {
                ...g,
                statusDate
            };
        });
    };

    const handleOnRequestTaskDelete = (task: GoalTask) => {
        setDeletingGoalTask(task);
    };

    const handleTaskDeleteCancel = () => {
        setModalApiError(undefined);
        setDeleting(false);
        setDeletingGoalTask(undefined);
    };

    const handleOnGoalStatusChange = (statusId: string) => {
        setEditingGoalStatusVersion(g => {
            if (!g) {
                return {
                    ...goal,
                    statusId,
                    statusDate: goal.statusDate ? formatJSONDateWithoutTime(goal.statusDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
                }
            }

            return {
                ...g,
                statusId,
                statusDate: g.statusDate ? formatJSONDateWithoutTime(g.statusDate, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
            }
        });
    };

    const handleOnAddTask = () => {
        setAddingTask(true);

        createGoalTask({
            casePlanId: goal.casePlanId,
            goalId: goal.id,
            clientId: props.clientId,
            text: ''
        }).then(r => {
            setRecentlyCreatedTask(r);
            setGoal(g => {
                return {
                    ...g,
                    tasks: [
                        ...(g.tasks || []),
                        r
                    ]
                };
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setAddingTask(false);
        })
    };

    const options = [
        {
            onClick: handleOnEdit,
            label: 'Edit'
        },
        {
            onClick: handleOnDelete,
            label: 'Delete'
        }
    ];

    const handleOnReorderTasks = () => {
        if (reorderingTasks) {
            setReorderingTasks(false);
        } else {
            setReorderingTasks(true);
        }
    }

    return (
        <>
            <ApiErrorMessages error={apiError} />
            <div className={getclassNames('row padding-3', editingGoalVersion && !reorderingGoal ? 'border padding-4 rounded margin-vertical-3 border-bottom' : '')}>
                <div className="column">
                    <div className="row align-items-center justify-content-between">
                        <div className="column">
                            <div className="row align-items-center">
                                {!editingGoalVersion && <div className="column-2">
                                    <Button id={`expand-goal-${goal.id}`}
                                        text={expanded && !reorderingGoal ? 'Hide Tasks' : 'Show Tasks'}
                                        onClick={handleToggleGoalExpanded}
                                        className="small flat"
                                        iconLocation="end"
                                        disabled={reorderingGoal}
                                        icon={expanded ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />} />
                                </div>}
                                <div className={editingGoalStatusVersion ? "column-small-4" : (editingGoalVersion ? "column-small-9" : "column-small-5")}>
                                    <div className="row align-items-center">
                                        <div className="column-auto">
                                            <h4>{props.goalNumber}.</h4>
                                        </div>
                                        <div className="column">
                                            {editingGoalVersion && <Input id={`case-plan-goal-name-${goal.id}`}
                                                value={editingGoalVersion.name || ''}
                                                label="Description"
                                                disabled={reorderingGoal}
                                                ref={nameReference}
                                                onChange={handleOnGoalNameChange}
                                                onKeyUp={handleOnKeyUp} />}
                                            {!editingGoalVersion && (!goal.name ? <i>&lt;No goal entered&gt;</i> : <div><TruncatedText id={`goal-text-${goal.id}`} value={goal.name} maxLength={100} lengthVariance={10} /></div>)}
                                        </div>
                                    </div>
                                </div>
                                {!editingGoalStatusVersion && <div className="column-small-3">
                                    {editingGoalVersion && <Input id={`case-plan-goal-due-date-${goal.id}`}
                                        type="date"
                                        label="Achieve By"
                                        disabled={reorderingGoal}
                                        value={(editingGoalVersion.dueDate && formatJSONDateWithoutTime(editingGoalVersion.dueDate, 'yyyy-MM-dd')) || ''}
                                        onKeyUp={handleOnKeyUp}
                                        onChange={handleOnGoalDueDateChange} />}
                                    {!editingGoalVersion && goal.dueDate && <div>Achieve By: {formatJSONDateWithoutTime(goal.dueDate, 'MM/dd/yyyy')}</div>}
                                </div>}
                                {editingGoalStatusVersion && <div className="column-small-2">
                                    <Input id={`goal-status-date-${goal.id}`}
                                        type="date"
                                        label="Status Date"
                                        disabled={reorderingGoal}
                                        value={editingGoalStatusVersion?.statusDate || format(new Date(), 'yyyy-MM-dd')}
                                        onChange={handleOnGoalStatusDateChange} />
                                </div>}
                                {!editingGoalVersion && <>
                                    <div className="column-small-2">
                                        <Select id={`goal-status-${goal.id}`}
                                            value={editingGoalStatusVersion?.statusId || (goal.statusId === undefined || goal.statusId === null ? '' : goal.statusId)}
                                            label="Status"
                                            onChange={handleOnGoalStatusChange}
                                            disabled={reorderingGoal || casePlanStopped}>
                                            <option value="">Not Started</option>
                                            {props.goalStatuses.map(gs => <option key={gs.id} value={gs.id}>{gs.name}</option>)}
                                        </Select>
                                        {!editingGoalStatusVersion && goal.statusDate && <div className="text-sub">Status Date: {formatJSONDateWithoutTime(goal.statusDate, 'MM/dd/yyyy')}</div>}
                                    </div>
                                    {editingGoalStatusVersion && <div className="column-small-2">
                                        <div className="row margin-top-6">
                                            <div className="column-auto">
                                                <Button id={`save-goal-status-update-${goal.id}`}
                                                    text="Save"
                                                    className="small action"
                                                    disabled={savingStatus || reorderingGoal}
                                                    loading={savingStatus}
                                                    onClick={handleOnSaveStatus} />
                                            </div>
                                            <div className="column-auto">
                                                <Button id={`cancel-goal-status-update-${goal.id}`}
                                                    text="Cancel"
                                                    className="small secondary"
                                                    disabled={savingStatus || reorderingGoal}
                                                    onClick={handleOnCancelEditStatus} />
                                            </div>
                                        </div>
                                    </div>}                                
                                </>}
                            </div>
                        </div>
                        {!editingGoalStatusVersion && <div className="column-auto">
                            <Dropdown id={`goal-dropdown-${goal.id}`} 
                                disabled={reorderingGoal || casePlanStopped}
                                options={options} />
                        </div>}
                    </div>
                    {editingGoalVersion && <div className="row justify-content-end margin-top-4">
                        <div className="column-auto">
                            <Button id={`cancel-edit-goal-${goal.id}`}
                                text="Cancel"
                                disabled={savingGoal || reorderingGoal}
                                className="secondary"
                                onClick={handleOnCancelSaveGoal} />
                        </div>
                        <div className="column-auto">
                            <Button id={`save-goal-${goal.id}`}
                                text="Save Goal"
                                className="action"
                                disabled={savingGoal || reorderingGoal}
                                loading={savingGoal}
                                onClick={handleOnSaveGoal} />
                        </div>
                    </div>}
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            {celebrate && <ConfettiExplosion />}
                        </div>
                    </div>
                </div>
            </div >
            {expanded && !reorderingGoal && <div className="row margin-top-3 padding-3 border rounded padding-4">
                <div className="column">
                    <div className="row">
                        <div className="column">
                            <div className="row align-items-center justify-content-between">
                                <div className="column-auto">
                                    <h4>Tasks</h4>
                                </div>
                                <div className="column-auto">
                                    <div className="row align-items-center">
                                        {savingReorder && <div className="column-auto">
                                            <Loader noMarginTop={true} />
                                        </div>}
                                        <div className="column-auto">
                                            <Button id={`goal-${goal.id}-reorder-tasks`}
                                                text={reorderingTasks ? 'All Done' : 'Reorder Tasks'}
                                                onClick={handleOnReorderTasks}
                                                className="secondary" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {reorderingTasks && <div className="row justify-content-end">
                                <div className="column-auto">
                                    <p className="text-sub" aria-live="polite" aria-atomic="false">
                                        Drag and drop the tasks or use Ctrl+shift+up arrow or Ctrl+shift+down arrow
                                    </p>
                                </div>
                            </div>}
                            {goal.tasks && goal.tasks.length !== 0 &&
                                (<Droppable droppableId={`/goals/${goal.id}/tasks`} isDropDisabled={!reorderingTasks || reorderingGoal}>
                                    {(provided) =>
                                        <div className={getclassNames('list-item-container margin-vertical-3', reorderingTasks ? 'reordering' : '')} {...provided.droppableProps} ref={provided.innerRef}>
                                            {goal.tasks.map((t, i) => {
                                                return (<Draggable draggableId={`/goals/${goal.id}/tasks/${t.id}`} key={t.id} index={i} isDragDisabled={!reorderingTasks || reorderingGoal}>
                                                    {(providedDraggable) => {
                                                        return (<div className="list-item skinny"
                                                            onKeyUp={(e) => handleOnTaskKeyUp(e, t)}
                                                            tabIndex={reorderingTasks ? -1 : undefined}
                                                            ref={node => {
                                                                providedDraggable.innerRef(node)
                                                                if (i === 0) {
                                                                    firstTaskReference.current = node
                                                                }
                                                            }}
                                                            {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps}>
                                                            <ParticipantCasePlanGoalTask task={t}
                                                                casePlanStopped={casePlanStopped}
                                                                clientId={props.clientId}
                                                                casePlanId={goal.casePlanId}
                                                                onDelete={handleOnRequestTaskDelete}
                                                                editing={recentlyCreatedTask?.id === t.id}
                                                                focused={recentlyCreatedTask?.id === t.id}
                                                                onChange={handleOnTaskChange}
                                                                reorderingTasks={reorderingTasks} />
                                                        </div>)
                                                    }}
                                                </Draggable>)
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    }
                                </Droppable>)}
                            {(!goal.tasks || goal.tasks.length === 0) && <div className="margin-top-3">No tasks found</div>}
                            <Button id={`add-task-second-${goal.id}`}
                                text="Add Task"
                                onClick={handleOnAddTask}
                                className="action"
                                disabled={addingTask || reorderingTasks || casePlanStopped}
                                loading={addingTask} />
                        </div>
                    </div>
                </div>
            </div>
            }
            <Modal id={`delete-goal-task-${goal.id}`} visible={deletingGoalTask !== undefined} className="medium" onClose={handleTaskDeleteCancel} title="Confirm Delete Task">
                {deletingGoalTask && (
                    <div>
                        {modalApiError && <ApiErrorMessages error={modalApiError} />}
                        <div className="row">
                            <div className="column">
                                Are you sure you want to delete this task?
                                <br />
                                {deletingGoalTask.text}
                                <br />
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-delete-goal"
                                            text="Cancel"
                                            className="secondary"
                                            disabled={deleting}
                                            onClick={handleTaskDeleteCancel} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-delete-goal"
                                            text="Delete Task"
                                            className="warning"
                                            disabled={deleting}
                                            loading={deleting}
                                            onClick={handleOnTaskDelete} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ParticipantCasePlanGoal;