import { isMatch, parse } from "date-fns";
import {defaultValue} from "./ProgramEnrollmentDateRangeValidateResponse";
import { ValidateRequest } from "./ValidateRequest";
import ValidateResponse from "./ValidateResponse";

const GUID_REGEX = /^([\d|[a-zA-Z]:?){8}\-([\d|[a-zA-Z]:?){4}\-([\d|[a-zA-Z]:?){4}\-([\d|[a-zA-Z]:?){4}\-([\d|[a-zA-Z]:?){12}$/;

const validate = (request: ValidateRequest): Promise<ValidateResponse> => {
    const response = defaultValue();
    
    let parsedValue: { from?: string, to?: string, program?: string };

    try {
        parsedValue = JSON.parse(request.value);
    } catch (e) {
        console.error('Failed to parse age filter value', e);
        response.isValid = false;
        response.default.push('An error occurred.  Contact support for assistance.');
    }

    if (response.default.length === 0) {
        if (!parsedValue!.program) {
            response.isValid = false;
            response.program.push('Program is required');
        } else if(!GUID_REGEX.test(parsedValue!.program)) {
            response.isValid = false;
            response.program.push('Program is invalid. Contact support for assistance');
            console.warn('Program is expected to be a GUID');
        }

        if (!parsedValue!.from) {
            response.from.push('From is required');
            response.isValid = false;
        }

        if (!parsedValue!.to) {
            response.to.push('To is required');
            response.isValid = false;
        }

        if (response.from.length === 0 && response.to.length === 0) {
            if (!isMatch(parsedValue!.from!, 'yyyy-MM-dd')) {
                response.isValid = false;
                response.from.push('From must be a valid date');
            }

            if (!isMatch(parsedValue!.to!, 'yyyy-MM-dd')) {
                response.isValid = false;
                response.to.push('To must be a valid date');
            }
        }

        if (response.from.length === 0 && response.to.length === 0) {
            const parsedFrom = parse(parsedValue!.from!, 'yyyy-MM-dd', new Date());
            const parsedTo = parse(parsedValue!.to!, 'yyyy-MM-dd', new Date());

            if (parsedFrom > parsedTo) {
                response.isValid = false;
                response.default.push('From must be less than or equal to To');
            }
        }
    }

    return Promise.resolve(response);
};

export default validate;