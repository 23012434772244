const ADMIN_ROLE_ID = 1;
const CONTRIBUTOR_ROLE_ID = 2;
const READONLY_ROLE_ID = 3;

const roleMap = {
    [ADMIN_ROLE_ID]: 'Admin',
    [CONTRIBUTOR_ROLE_ID]: 'Contributor',
    [READONLY_ROLE_ID]: 'Readonly'
};

export {
    ADMIN_ROLE_ID,
    CONTRIBUTOR_ROLE_ID,
    READONLY_ROLE_ID,
    roleMap
};