import { manageFormsEnabled } from "./features";

const getParticipantOptions = (id?: string) => {
    if (!id) {
        return [];
    }

    const options = [
        {
            text: 'Profile',
            href: `/participants/${id}/profile-demographics`
        },
        {
            text: 'Notes Feed',
            href: `/participants/${id}/notes`
        },
        {
            text: 'Programs',
            href: `/participants/${id}/programs`
        },
        {
            text: 'Tracking History',
            href: `/participants/${id}/tracking/history`
        }
    ];

    options.push({
        text: 'Plans',
        href: `/participants/${id}/plans`
    });

    return options;
};

const getLockedDownAdminOptions = () => {
    return [
        {
            text: 'Users',
            href: '/admin/users'
        },
        {
            text: 'Organization',
            href: '/admin/organization'
        },
        {
            text: 'Billing',
            href: '/admin/billing'
        },
        {
            text: 'Usage',
            href: '/admin/usage'
        },
        {
            text: 'Terms of Service',
            href: '/admin/tos'
        }
    ];
};

const getAdminOptions = () => {
    const options = [];

    if (manageFormsEnabled) {
        options.push(
            {
                text: 'Forms',
                href: '/admin/forms'
            });
    }

    options.push(...[
        {
            text: 'Users',
            href: '/admin/users'
        },
        {
            text: 'Programs',
            href: '/admin/programs'
        },
        {
            text: 'Organization',
            href: '/admin/organization'
        },
        {
            text: 'Billing',
            href: '/admin/billing'
        },
        {
            text: 'Usage',
            href: '/admin/usage'
        },
        {
            text: 'Terms of Service',
            href: '/admin/tos'
        }
    ]);

    return options;
};

const getTrackingOptions = (onClick: () => void) => {
    return [
        {
            text: 'Outputs and Outcomes',
            href: '/tracking/delivery',
            onClick
        },
        {
            text: 'Assessments',
            href: '/tracking/assessments',
            onClick
        }
    ];
};

const getReportsOptions = () => {
    const options = [
        {
            text: 'Demographics',
            href: '/reports/demographics'
        },
        {
            text: 'Outputs and Outcomes',
            href: '/reports/outputs'
        }
    ];

    options.push({
        text: 'Plans',
        href: '/reports/plans'
    });

    return options;
};

const getExportOptions = () => {
    const options = [
        {
            text: 'Demographics',
            href: '/exports/demographics'
        },
        {
            text: 'Outputs and Outcomes',
            href: '/exports/outputs'
        },
        {
            text: 'Assessments',
            href: '/exports/assessments'
        }
    ];

    options.push({
        text: 'Plans',
        href: '/exports/plans'
    });

    return options;
};

export {
    getParticipantOptions,
    getTrackingOptions,
    getReportsOptions,
    getAdminOptions,
    getExportOptions,
    getLockedDownAdminOptions
}