import { useState, useEffect, FormEvent } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom"
import FormEditor from '../components/Form/Editor/FormEditor';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import BackButton from "../components/UI/BackButton"
import Button from '../components/UI/Button';
import Loader from '../components/UI/Loader';
import { mapFormFieldToEditableFormField } from '../mappers/formFieldMapper';
import EditableFormField from '../models/pageModels/EditableFormField';
import Form from '../models/serviceModels/Form';
import FormInstance from '../models/serviceModels/FormInstance';
import { getForm, getFormInstance, updateFormInstance } from '../services/formsService';
import { formatJSONDateWithoutTime } from '../utils/dateHelpers';
import { UPLOADED } from '../constants/fileUploadStatus';

const EditFormInstance = () => {

    const { formId, instanceId, versionId } = useParams();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [form, setForm] = useState<Form>();
    const [instance, setInstance] = useState<FormInstance>();
    const [fields, setFields] = useState<EditableFormField[]>([]);
    const [apiErrors, setApiErrors] = useState<any>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = "Edit Submitted Form Data"
    }, []);

    useEffect(() => {
        setLoading(true);

        if (!formId || !instanceId || !versionId) {
            return;
        }
        Promise.all([getForm(formId, versionId), getFormInstance(formId, instanceId)]).then(([f, fi]) => {
            setInstance(fi);
            setForm(f);
            setFields([
                ...f.fields.map(ff => {
                    const instanceField = fi.instanceFields.find(iff => iff.name === ff.name);
                    return mapFormFieldToEditableFormField(ff, instanceField?.value || '', instanceField?.id || '', instanceField?.files || []);
                })
            ]);
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleOnFieldChange = (ef: EditableFormField[]) => {
        setFields([
            ...ef
        ]);
    };

    const handleOnSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!formId || !instanceId) {
            return;
        }

        setSubmitting(true);

        updateFormInstance({
            formId,
            instanceId,
            fields: fields.map(f => {
                return {
                    instanceFieldId: f.instanceFieldId,
                    fieldId: f.id,
                    value: f.value,
                    files: f.files?.map(f => f.fileId!) || []
                }
            })
        }).then(() => {
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(-1);
            }
        }).catch(e => {
            console.error(e);
            setApiErrors(e)
        }).finally(() => {
            setSubmitting(false);
        })
    }

    if (loading) {
        return <Loader />;
    }

    return (<section tabIndex={-1}>
        <div className="section">
            <div className="row justify-content-center">
                <div className="column-medium-6">
                    <div className="row">
                        <div className="column-auto">
                            <BackButton />
                        </div>
                    </div>
                    <ApiErrorMessages error={apiErrors} />
                    {form && fields && <>
                        <div className="row align-items-center">
                            <div className="column-auto">
                                <h2>{form?.name}</h2>
                            </div>
                            {instance && <div className="column-auto">
                                <small>
                                    <div>Originally Submitted - {formatJSONDateWithoutTime(instance.created, 'MM/dd/yyyy')}</div>
                                    {(instance.updated && <div>Last Updated - {formatJSONDateWithoutTime(instance.updated, 'MM/dd/yyyy')}</div>) || null}
                                </small>
                            </div>}
                        </div>
                        <form onSubmit={handleOnSubmit}>
                            <div className="row">
                                <div className="column">
                                    <FormEditor disabled={false} fields={fields} onChange={handleOnFieldChange} />
                                </div>
                            </div>
                            <div className="row justify-content-end margin-top-5">
                                <div className="column-auto">
                                    <Button id="submit-button" type="submit" text="Save Form" loading={submitting} disabled={submitting || !form || !fields || fields.some(f => f.files && f.files.some(ff => ff.status !== UPLOADED))} />
                                </div>
                            </div>
                        </form>
                    </>}
                </div>
            </div>
        </div>
    </section>
    );
};

export default EditFormInstance;