import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Input from "../components/UI/Input";
import ValidatedInput from "../components/UI/ValidatedInput";
import ValidatedSelect from "../components/UI/ValidatedSelect";
import { ADMIN_ROLE_ID, CONTRIBUTOR_ROLE_ID, READONLY_ROLE_ID, roleMap } from "../constants/userRoles";

interface CreateUserModel {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    roleId: string;
    email: string;
}

const getDefaultModel = (): CreateUserModel => {
    return {
        roleId: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    };
}

interface ModelErrors {
    [key: string]: string[]
}

const getDefaultErrors = (): ModelErrors => {
    const model = getDefaultModel();
    const keys = Object.keys(model);
    const errorsContainer: ModelErrors = {};
    keys.forEach(k => errorsContainer[k] = []);
    return errorsContainer;
};

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const CreateUser = () => {
    const [model, setModel] = useState<CreateUserModel>(getDefaultModel());
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors());
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitErrors, setSubmitErrors] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = "Create User"
    }, []);

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model,
            [fieldName]: value
        });
    };

    const validate = () => {
        const newErrors = getDefaultErrors();
        let isValid = true;

        if (!model.email) {
            isValid = false;
            newErrors.email.push('Email is required');
        } else if (model.email.indexOf('@') === -1 || model.email.indexOf('.') === -1) {
            isValid = false;
            newErrors.email.push('Email is not a valid format');
        }

        if (!model.firstName) {
            isValid = false;
            newErrors.firstName.push('First Name is required');
        }

        if (!model.lastName) {
            isValid = false;
            newErrors.lastName.push('Last Name is required');
        }

        if (!model.roleId) {
            isValid = false;
            newErrors.roleId.push('Role is required');
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitErrors(null);
        setSubmitted(true);
        if (!validate()) {
            return;
        }
        setSubmitted(false);
        setSubmitting(true);
        axios.post(`${baseUrl}/users`, {
            ...model
        }).then(() => {
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(-1);
            }
        }).catch(e => {
            setSubmitErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (<section id="main-content" tabIndex={-1}>
        <div className="row justify-content-center">
            <div className="column-medium-6">
                <h1 className="form-header">Create User</h1>
                <form onSubmit={handleOnSubmit}>
                    <ApiErrorMessages error={submitErrors} />
                    <div className="row margin-vertical-2">
                        <div className="column-medium-4">
                            <ValidatedInput id="first-name" required={true}
                                value={model.firstName}
                                onChange={(e) => handleOnChange('firstName', e)}
                                label="First Name"
                                messages={(submitted && modelErrors.firstName) || []} />
                        </div>
                        <div className="column-medium-4">
                            <ValidatedInput id="last-name" required={true}
                                value={model.lastName}
                                onChange={(e) => handleOnChange('lastName', e)}
                                label="Last Name"
                                messages={(submitted && modelErrors.lastName) || []} />
                        </div>
                        <div className="column-medium-4">
                            <ValidatedInput id="email" required={true}
                                value={model.email}
                                onChange={(e) => handleOnChange('email', e)}
                                label="E-Mail"
                                messages={(submitted && modelErrors.email) || []} />
                        </div>
                    </div>
                    <div className="row margin-vertical-2">
                        <div className="column-medium-4">
                            <Input id="phone-number"
                                value={model.phoneNumber}
                                onChange={(e) => handleOnChange('phoneNumber', e)}
                                label="Phone Number"
                                mask="(000)000-0000"
                                useMaskedValue={true} />
                        </div>
                        <div className="column-medium-4">
                            <ValidatedSelect id="user-role" value={model.roleId} required={true}
                                onChange={(e) => handleOnChange('roleId', e)}
                                label="Role"
                                messages={(submitted && modelErrors.roleId) || []}>
                                <option value="">Select One</option>
                                <option value={ADMIN_ROLE_ID}>{roleMap[ADMIN_ROLE_ID]}</option>
                                <option value={CONTRIBUTOR_ROLE_ID}>{roleMap[CONTRIBUTOR_ROLE_ID]}</option>
                                <option value={READONLY_ROLE_ID}>{roleMap[READONLY_ROLE_ID]}</option>
                            </ValidatedSelect>
                        </div>
                    </div>
                    <div className="row justify-content-end margin-top-4">
                        <div className="column-auto">
                            <Button id="submit-button" type="submit" text="Submit" loading={submitting} disabled={submitting} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>

    );
};

export default CreateUser;