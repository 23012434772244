import { useContext, useEffect } from "react";
import CurrentPaymentDetails from "../components/UI/CurrentPaymentDetails";
import CurrentPlan from "../components/UI/CurrentPlan";
import { removeItem } from "../services/cacheService";
import Loader from "../components/UI/Loader";
import { INVOICED } from "../constants/billingType";
import InvoicedPaymentDetails from "../components/UI/InvoicedPaymentDetails";
import { OrganizationContext } from "../App";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";

const ManageBilling = () => {
    const organization = useContext(OrganizationContext);

    useEffect(() => {
        document.title = "Manage Plan and Billing"
    }, []);

    useEffect(() => {
        removeItem('new_plan');
    }, []);

    const getMainContent = () => {
        return (<section id="main-content" tabIndex={-1}>
            <h1>Manage Plan and Billing</h1>
            <ApiErrorMessages error={organization.error} />
            {organization.loading && <Loader />}
            {!organization.loading && organization.value && !organization.error && <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium">
                    <div className="row">
                        <div className="column-medium-4">
                            <CurrentPlan allowModify={true} />
                        </div>
                        <div className="column-medium-4">
                            {organization.value.billingTypeId !== INVOICED && <CurrentPaymentDetails allowModifiy={true} />}
                            {organization.value.billingTypeId === INVOICED && <InvoicedPaymentDetails />}
                        </div>
                    </div>
                </div>
            </div>}
        </section>)
    };

    return getMainContent();
};

export default ManageBilling;