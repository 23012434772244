import { Fragment, useEffect, useRef, useState } from "react";
import ResultProps from "./ResultProps";
import User from "../../../models/serviceModels/User";
import { exportReport, getReportResultsV2 } from "../../../services/reportService";
import useElementVisible from "../../../hooks/useElementVisible";
import { searchUsers } from "../../../services/userService";
import Loader from "../../UI/Loader";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import { formatJSONDateWithoutTime } from "../../../utils/dateHelpers";
import Dropdown from "../../UI/Dropdown";
import Button from "../../UI/Button";
import getclassNames from "../../../utils/getClassNames";
import CasePlanResult from "../../../models/serviceModels/CasePlanResult";
import IconButton from "../../UI/IconButton";
import { triggerDownloadOfUrl } from "../../../utils/reportHelpers";
import { scrollLeft, scrollRight } from "../../../utils/elementHelpers";

const TAKE = 50;

const EverythingPlansResults = (props: ResultProps) => {
    const [resultsLoading, setResultsLoading] = useState(true);
    const [usersLoading, setUserLoading] = useState(true);
    const [results, setResults] = useState<CasePlanResult[]>([]);
    const [skip, setSkip] = useState(0);
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState<User[]>();
    const [paging, setPaging] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [lastWorkedBy, setLastWorkedBy] = useState('');
    const [filterType, setFilterType] = useState('');
    const [expandedPlans, setExpandedPlans] = useState<string[]>([]);
    const [expandedGoals, setExpandedGoals] = useState<string[]>([]);
    const [exporting, setExporting] = useState(false);
    const loaded = useRef(false);

    useEffect(() => {
        if (loaded.current) {
            setSkip(0);
        }

        setResultsLoading(true);

        getReportResultsV2<CasePlanResult>({
            skip: 0,
            take: TAKE,
            reportId: props.reportId,
            filter: '',
            sortBy: 'planName',
            sortDirection: 'ASC'
        }).then(r => {
            setTotalCount(r.totalCount);
            setResults(r.data);
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setResultsLoading(false);
        })
    }, [props.reportId]);

    useEffect(() => {
        setUserLoading(true);
        searchUsers('', 0, 1000).then(r => {
            setUsers(r.data);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setUserLoading(false);
        });
    }, []);


    useEffect(() => {
        if (!loaded.current || skip === 0) {
            return;
        }

        setPaging(true);
        setApiError(undefined);

        getReportResultsV2<CasePlanResult>({
            reportId: props.reportId,
            skip,
            take: TAKE,
            sortBy: 'planName',
            sortDirection: 'Ascending',
            filter
        }).then(r => {
            setResults(er => {
                return [
                    ...er,
                    ...r.data
                ];
            });
            setTotalCount(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setPaging(false);
        });
    }, [skip]);

    useEffect(() => {
        if (visible && totalCount > 0) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible, totalCount]);

    useEffect(() => {
        loaded.current = true;
    }, []);
    
    const handleOnExport = () => {
        setApiError(undefined);
        setExporting(true);
        exportReport({
            reportId: props.reportId
        }).then(r => {
            triggerDownloadOfUrl(r.downloadUrl);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setExporting(false);
        });
    };

    const toggleGoalExpand = (goalId: string) => {
        if (expandedGoals.includes(goalId)) {
            setExpandedGoals([
                ...expandedGoals.filter(g => g !== goalId)
            ]);
        } else {
            setExpandedGoals([
                ...expandedGoals,
                goalId
            ]);
        }
    };

    const togglePlanExpand = (planId: string) => {
        if (expandedPlans.includes(planId)) {
            setExpandedPlans([
                ...expandedPlans.filter(p => p !== planId)
            ]);
        } else {
            setExpandedPlans([
                ...expandedPlans,
                planId
            ]);
        }
    };

    const getOptions = (clientId: string, casePlanId: string) => {
        return [
            {
                href: `/participants/${clientId}/profile-demographics`,
                label: 'Open Profile',
                newWindow: true
            },
            {
                href: `/participants/${clientId}/plans/${casePlanId}`,
                label: 'Open Plan',
                newWindow: true
            }
        ];
    };
    
    const handleScrollLeft = () => {
        scrollLeft('.report-results-table');
    };

    const handleScrollRight = () => {
        scrollRight('.report-results-table');
    };

    const getReportResults = () => {
        if (resultsLoading) {
            return <Loader />
        }

        return (<>
            <table className="table">
                <thead>
                    <tr className="repeating-header">
                        <th scope="col"></th>
                        <th scope="col">plan</th>
                        <th scope="col">total goals</th>
                        <th scope="col">participant</th>
                        <th scope="col">started</th>
                        <th scope="col">stopped</th>
                        <th scope="col">created</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {results && results.length !== 0 && results.map((r, i) => {
                        return <Fragment key={r.casePlanId}>
                            <tr className={getclassNames('', expandedPlans.includes(r.casePlanId) ? 'expanded-report-row' : '')}>
                                <td>
                                    <IconButton className="small flat"
                                        disabled={r.goals.length === 0}
                                        id={`${r.casePlanId}-expand-row`}
                                        icon={expandedPlans.includes(r.casePlanId) ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line" />}
                                        onClick={() => togglePlanExpand(r.casePlanId)} />
                                </td>
                                <td>{r.casePlanName}</td>
                                <td>{r.goals.length}</td>
                                <td>{r.clientFirstName} {r.clientLastName}</td>
                                <td>{r.casePlanStarted && formatJSONDateWithoutTime(r.casePlanStarted, 'MM/dd/yyyy')}</td>
                                <td>{r.casePlanStopped && formatJSONDateWithoutTime(r.casePlanStopped, 'MM/dd/yyyy')}</td>
                                <td>{r.casePlanCreated && formatJSONDateWithoutTime(r.casePlanCreated, 'MM/dd/yyyy')}</td>
                                <td><Dropdown id={`due-goal-${r.clientId}`} options={getOptions(r.clientId, r.casePlanId)} /></td>
                            </tr>
                            {expandedPlans.includes(r.casePlanId) && <tr className="sub-table-row">
                                <td colSpan={8}>
                                    <div className="sub-table-container">
                                        <div className="sub-table-container-header">
                                            <h4>Goals</h4>
                                        </div>
                                        <div className="sub-table-container-body">
                                            <table className="table sub-table">
                                                <thead>
                                                    <th scope="col"></th>
                                                    <th scope="col">total tasks</th>
                                                    <th scope="col">name</th>
                                                    <th scope="col">due date</th>
                                                    <th scope="col">status</th>
                                                    <th scope="col">status date</th>
                                                    <th scope="col">created</th>
                                                </thead>
                                                <tbody>
                                                    {r.goals.map(g => {
                                                        return <Fragment key={g.goalId}>
                                                            <tr className={getclassNames('', expandedGoals.includes(g.goalId) ? 'expanded-report-row' : '')}>
                                                                <td scope="col">
                                                                    <IconButton className="small flat"
                                                                        disabled={g.tasks.length === 0}
                                                                        id={`${g.goalId}-expand-row`}
                                                                        icon={expandedGoals.includes(g.goalId) ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line" />}
                                                                        onClick={() => toggleGoalExpand(g.goalId)} />
                                                                </td>
                                                                <td scope="col">{g.tasks.length}</td>
                                                                <td scope="col">{g.goalName}</td>
                                                                <td scope="col">{g.goalDueDate && formatJSONDateWithoutTime(g.goalDueDate, 'MM/dd/yyyy')}</td>
                                                                <td scope="col">{g.goalStatus}</td>
                                                                <td scope="col">{g.goalStatusDate && formatJSONDateWithoutTime(g.goalStatusDate, 'MM/dd/yyyy')}</td>
                                                                <td scope="col">{g.goalCreated && formatJSONDateWithoutTime(g.goalCreated, 'MM/dd/yyyy')}</td>
                                                            </tr>
                                                            {expandedGoals.includes(g.goalId) && <tr className="sub-table-row">
                                                                <td colSpan={7}>
                                                                    <div className="sub-table-container">
                                                                        <h5>Tasks</h5>
                                                                        <table className="table sub-table">
                                                                            <thead>
                                                                                <th scope="col">text</th>
                                                                                <th scope="col">completed</th>
                                                                                <th scope="col">created</th>
                                                                            </thead>
                                                                            <tbody>
                                                                                {g.tasks.map(t => {
                                                                                    return <tr key={t.taskId}>
                                                                                        <td scope="col">{t.taskText}</td>
                                                                                        <td scope="col">{t.taskCompleted}</td>
                                                                                        <td scope="col">{t.taskCreated && formatJSONDateWithoutTime(t.taskCreated, 'MM/dd/yyyy')}</td>
                                                                                    </tr>
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>}
                                                        </Fragment>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                            </tr>}
                        </Fragment>
                    })}
                    {results && results.length === 0 && (<tr>
                        <td colSpan={7}>
                            No results found
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </>);
    };

    return <>
        <h3>Detail of All Plans, Goals, and Tasks</h3>
        <ApiErrorMessages error={apiError} />
        {users && !usersLoading && <>
            <div className="row justify-content-between">
                <div className="column-auto">
                    <div className="row justify-content-between">
                        <div className="column-auto">
                            <IconButton id="scroll-left"
                                onClick={handleScrollLeft}
                                className="small flat"
                                icon={<i className="ri-arrow-left-s-line"></i>} />
                        </div>
                        <div className="column-auto">
                            <IconButton id="scroll-right"
                                onClick={handleScrollRight}
                                className="small flat"
                                icon={<i className="ri-arrow-right-s-line"></i>} />
                        </div>
                    </div>
                </div>
                <div className="column-auto">
                    <Button id="export-form-results"
                        text="Export Data"
                        onClick={handleOnExport}
                        loading={exporting}
                        disabled={exporting} />
                </div>
            </div>
            <div className="report-results-table">
                {getReportResults()}
            </div>
            {paging && <Loader />}
            {results && results.length < totalCount && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
        </>}
    </>;
};

export default EverythingPlansResults