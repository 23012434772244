import { format } from "date-fns";
import { ReportResultData } from "../models/serviceModels/ReportResultData";
import axios from "axios";

const convertAndTriggerDownload = (result: ReportResultData) => {
    let uri;
    if (result.sourceType === 'SQL') {
        uri = `data:text/csv;charset=utf-8;base64,` + btoa(result.data);
    } else if (result.sourceType === 'S3') {
        uri = result.data; // it's a S3 link
    } else  {
        console.warn(result.sourceType, 'Not supported yet');
        return;
    }

    const link = document.createElement("a");
    link.download = `report_${format(new Date(), 'yyyymmddHHMMSS')}`;
    link.setAttribute('download', 'download');
    link.href = uri;
    link.click();
};

const triggerDownloadOfUrl = (url: string) => {
    const link = document.createElement("a");
    link.download = `report_${format(new Date(), 'yyyymmddHHMMSS')}`;
    link.setAttribute('download', 'download');
    link.href = url;
    link.click();
}

const convertToReportResults = async <T>(result: ReportResultData): Promise<T> => {
    if (result.sourceType === 'SQL') {
        return JSON.parse(result.data);
    }

    if (result.sourceType === 'S3') {
        const response = await axios.get(result.data);
        return response.data;
    } 
    
    console.error(result.sourceType, 'Not supported yet');    
    throw Error('Unexpected error has occurred');
};

export {
    convertAndTriggerDownload,
    convertToReportResults,
    triggerDownloadOfUrl
}