import React, { ChangeEvent, ForwardedRef, forwardRef, useRef,KeyboardEvent } from 'react';
import getclassNames from '../../utils/getClassNames';
import './Input.css';
import { IMaskInput } from 'react-imask';

export interface InputProps {
    type?: string;
    name?: string;
    id: string;
    onChange?: (value: string) => void;
    value?: string;
    label?: string;
    labelClassName?: string;
    inputClassName?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    maxLength?: number;
    mask?: string;
    useMaskedValue?: boolean;
    onKeyUp?: (key: string) => void;
}

const Input = forwardRef((props: InputProps, ref?: ForwardedRef<any>) => {
    const inputRef = useRef(null);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(e.target.value);
        }
    }

    const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onKeyUp) {
            props.onKeyUp(e.key)
        }
    }

    if (props.type === "multi") {
        if (props.mask) {
            console.warn('Input mask not supported on multi-line inputs.  The mask will be ignored.');
        }

        return (<>
            {props.label && <label htmlFor={props.id} className={props.labelClassName}>{props.label}</label>}
            {props.required && <span className="margn-start-1 text-danger">*</span>}
            <textarea placeholder={props.placeholder} tabIndex={0}
                id={props.id}
                name={props.name || props.id}
                value={props.value}
                maxLength={props.maxLength}
                className={getclassNames('input textarea', props.inputClassName || '')}
                onChange={handleOnChange}
                disabled={props.disabled || false} 
                onKeyUp={handleOnKeyUp}/>
        </>);
    }

    if (props.mask) {
        const handleOnAccept = (value: any, _mask: IMask.InputMask<IMask.AnyMaskedOptions>) => {
            if (props.onChange) {
                props.onChange(value?.toString() || '');
            }
        };        

        return (<>
            {props.label && <label htmlFor={props.id} className={props.labelClassName}>{props.label}</label>}
            {props.required && <span className="margin-start-1 text-danger">*</span>}
            {props.mask === 'PositiveDecimalNumber' && <IMaskInput
                mask={/(?:^\d+\.\d+$)|(?:^\d+\.?\d*$)/}
                value={props.value}
                unmask={true} // true|false|'typed'
                ref={ref}
                className={getclassNames('input', props.inputClassName || '')}
                inputRef={inputRef}  // access to nested input
                onAccept={handleOnAccept}
                placeholder={props.placeholder}
                disabled={props.disabled}
                onKeyUp={handleOnKeyUp}
            />}
            {props.mask !== 'PositiveDecimalNumber' && <IMaskInput
                mask={props.mask}
                value={props.value}
                unmask={!props.useMaskedValue} // true|false|'typed'
                ref={ref}
                className={getclassNames('input', props.inputClassName || '')}
                inputRef={inputRef}  // access to nested input
                onAccept={handleOnAccept}
                onKeyUp={handleOnKeyUp}
                placeholder={props.placeholder}
            />}
        </>);
    }

    return (
        <>
            {props.label && <label htmlFor={props.id} className={props.labelClassName}>{props.label}</label>}
            {props.required && <span className="margin-start-1 text-danger">*</span>}
            <input placeholder={props.placeholder} ref={ref} tabIndex={0}
                type={props.type || 'text'}
                id={props.id}
                name={props.name || props.id}
                value={props.value}
                maxLength={props.maxLength}
                className={getclassNames('input', props.inputClassName || '')}
                onChange={handleOnChange}
                onKeyUp={handleOnKeyUp}
                disabled={props.disabled || false} />
        </>
    )
});

export default Input;