import { parse, format } from "date-fns";

const parseJSONDateWithoutTime = (date: string) => {
    const datePortion = date.split('T')[0];
    return parse(datePortion, 'yyyy-MM-dd', new Date());
}

const formatJSONDateWithoutTime = (date: string, desiredFormat: string) => {
    const parsedDate = parseJSONDateWithoutTime(date);
    return format(parsedDate, desiredFormat);
};



export {
    parseJSONDateWithoutTime,
    formatJSONDateWithoutTime
}