import React from "react";

const DeactivatedUser = () => {

    return (<section id="main-content" tabIndex={-1}>


        <div className="section">
            Account is deactivated.  Please contact your admin to reactivate your account
        </div>
    </section>)
};

export default DeactivatedUser;