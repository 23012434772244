const NUMBER = 1;
const DATE = 2;
const TEXT = 3;
const BOOL = 4;
const TIMESTAMP = 5;
const FULLTEXT = 6;

export {
    NUMBER,
    DATE, 
    TEXT,
    BOOL,
    TIMESTAMP,
    FULLTEXT
};