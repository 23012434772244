import { planScopeMap } from "../constants/planScopes";
import { PERSPECTIVE_NAME_MAP, reportTypePerspectivesMap } from "../constants/reportPerspective";
import { ALL_REPORT_TYPES, REPORT_TYPE_NAME_MAP } from "../constants/reportTypes";

const getReportTypeOptions = () => {
    return ALL_REPORT_TYPES.map(t => {
        return {
            label: REPORT_TYPE_NAME_MAP[t],
            value: t
        };
    });
};

const getReportPerspectiveOptions = (reportType: string) => {
    return reportTypePerspectivesMap[reportType].map(p => {
        return {
            label: PERSPECTIVE_NAME_MAP[p],
            value: p
        };
    });
};

const getPlanScopesOptions = () => {
    return Object.keys(planScopeMap).map(m => {
        return {
            label: planScopeMap[m],
            value: m
        }
    });
};

export {
    getReportTypeOptions,
    getReportPerspectiveOptions,
    getPlanScopesOptions
};