import { parse } from "date-fns";
import { FormEvent, useContext, useEffect, useState } from "react";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import CurrentPaymentDetails from "../components/UI/CurrentPaymentDetails";
import CurrentPlan from "../components/UI/CurrentPlan";
import Loader from "../components/UI/Loader";
import NewPlan from "../components/UI/NewPlan";
import ValidatedInput from "../components/UI/ValidatedInput";
import ModelErrors from "../models/pageModels/ModelErrors";
import { PaymentDetailsPageModel } from "../models/pageModels/PaymentDetailsPageModel";
import { PaymentMethod } from "../models/serviceModels/PaymentMethod";
import { createPaymentMethod, replacePaymentMethod, updatePaymentMethod } from "../services/paymentsService";
import { getDefaultErrors } from "../utils/modelHelpers";
import { INVOICED } from "../constants/billingType";
import InvoicedPaymentDetails from "../components/UI/InvoicedPaymentDetails";
import ValidatedSelect from "../components/UI/ValidatedSelect";
import { months, years } from "../constants/creditCardDates";
import { OrganizationContext } from "../App";

const getDefaultModel = (): PaymentDetailsPageModel => {
    return {
        name: '',
        number: '',
        postalCode: '',
        month: '',
        year: '',
        cvc: ''
    };
};

const PlanPaymentDetails = () => {
    const [loadApiErrors, setLoadApiErrors] = useState<any>();
    const [formApiErrors, setFormApiErrors] = useState<any>();
    const [adding, setAdding] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [replacing, setReplacing] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [model, setModel] = useState<PaymentDetailsPageModel>(getDefaultModel());
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors(getDefaultModel()));
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const organization = useContext(OrganizationContext);

    useEffect(() => {
        if (organization.loading) {
            return;
        }

        if (organization.error) {
            setLoadApiErrors(organization.error);
        }
    }, [organization]);

    const handleUpdatePaymentDetails = () => {
        setAdding(false);
        setReplacing(false);
        setUpdating(true);
    };

    const handleAddPaymentDetails = () => {
        setAdding(true);
        setReplacing(false);
        setUpdating(false);
    };

    const handleReplacePaymentDetails = () => {
        setAdding(false);
        setReplacing(true);
        setUpdating(false);
    };

    const handleUpdating = (): Promise<PaymentMethod> => {
        return updatePaymentMethod({
            year: Number(model.year),
            month: Number(model.month)
        });
    };

    const handleCreating = (): Promise<PaymentMethod> => {
        return createPaymentMethod({
            year: Number(model.year),
            month: Number(model.month),
            name: model.name,
            cvc: Number(model.cvc),
            number: model.number.replace(/ /g, ''),
            postalCode: model.postalCode
        });
    };

    const handleReplacing = (): Promise<PaymentMethod> => {
        return replacePaymentMethod({
            year: Number(model.year),
            month: Number(model.month),
            name: model.name,
            cvc: Number(model.cvc),
            number: model.number.replace(/ /g, ''),
            postalCode: model.postalCode
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setFormApiErrors(undefined);
        setSubmitted(true);
        if (!validate()) return;
        setSubmitted(false);
        setSubmitting(true);

        if (updating) {
            handleUpdating().then(r => {
                organization.refresh();
                setPaymentMethod(r);
                handleOnCancel();
            }).catch(e => {
                console.error(e);
                setFormApiErrors(e);
            }).finally(() => {
                setSubmitting(false);
            });
        } else if (adding) {
            handleCreating().then(r => {
                organization.refresh();
                setPaymentMethod(r);
                handleOnCancel();
            }).catch(e => {
                console.error(e);
                setFormApiErrors(e);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            handleReplacing().then(r => {
                organization.refresh();
                setPaymentMethod(r);
                handleOnCancel();
            }).catch(e => {
                console.error(e);
                setFormApiErrors(e);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model,
            [fieldName]: value
        });
    };

    const validate = () => {
        const newErrors = getDefaultErrors(getDefaultModel());
        let isValid = true;

        if (adding || replacing) {
            if (!model.name) {
                isValid = false;
                newErrors.name.push('Name is required');
            }

            if (!model.number) {
                isValid = false;
                newErrors.number.push('Card Number is required');
            } else if (isNaN(Number(model.number.replace(/ /g, ''))) || model.number.replace(/ /g, '').length !== 16) {
                isValid = false;
                newErrors.number.push('Card Number is invalid');
            }

            if (!model.cvc) {
                isValid = false;
                newErrors.cvc.push('CVC is required');
            } else if (isNaN(Number(model.cvc)) || model.cvc.length !== 3) {
                isValid = false;
                newErrors.cvc.push('CVC is invalid');
            }

            if (!model.postalCode) {
                isValid = false;
                newErrors.postalCode.push('Billing Zip Code is required');
            } else if (isNaN(Number(model.postalCode)) || model.postalCode.length !== 5) {
                isValid = false;
                newErrors.postalCode.push('Billing Zip Code is invalid');
            }
        }

        if (!model.year) {
            isValid = false;
            newErrors.year.push('Year is required');
        } else if (isNaN(Number(model.year)) || model.year.length !== 4) {
            isValid = false;
            newErrors.year.push('Year is invalid');
        }

        if (!model.month) {
            isValid = false;
            newErrors.month.push('Month is required');
        } else if (isNaN(Number(model.month))) {
            isValid = false;
            newErrors.month.push('Month is invalid');
        }

        if (newErrors.month.length === 0 && newErrors.year.length === 0) {
            const expirationDate = parse(`${model.year}-${model.month}-01`, 'yyyy-MM-dd', new Date());
            if (expirationDate <= new Date()) {
                isValid = false;
                newErrors.month.push('Date is expired.');
            }
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnCancel = () => {
        setModelErrors(getDefaultErrors(getDefaultModel()));
        setModel(getDefaultModel());
        setAdding(false);
        setUpdating(false);
        setReplacing(false);
        setSubmitted(false);
        setFormApiErrors(undefined);
    };

    const getMainContent = () => {
        if (organization.loading) {
            return <Loader />
        }

        return (<section id="main-content" tabIndex={-1}>
            <BackButton />
            <ApiErrorMessages error={loadApiErrors} />
            {organization.value && <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium">
                    <div className="row">
                        <div className="column-medium-4">
                            <CurrentPlan />
                        </div>
                        <div className="column-medium-4">
                            <NewPlan />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="column-medium-4">
                            {organization.value.billingTypeId !== INVOICED && <CurrentPaymentDetails submitting={submitting}
                                onAdd={handleAddPaymentDetails}
                                onReplace={handleReplacePaymentDetails}
                                onUpdate={handleUpdatePaymentDetails}
                                paymentMethod={paymentMethod}
                                onLoad={setPaymentMethod} />}
                            {organization.value.billingTypeId === INVOICED && <InvoicedPaymentDetails />}
                        </div>
                    </div>

                    {(adding || replacing) && <div className="row margin-top-3">
                        <div className="column-medium-4  padding-5">
                            <form onSubmit={handleSubmit}>
                                <h3>{adding ? 'Adding' : 'Replace'} Payment Method</h3>
                                <ApiErrorMessages error={formApiErrors} />
                                <div className="row margin-vertical-2">
                                    <div className="column">
                                        <ValidatedInput id="card-name" required={true}
                                            value={model.name} onChange={(e) => handleOnChange('name', e)}
                                            label="Name On Card"
                                            messages={(submitted && modelErrors.name) || []} />
                                    </div>
                                </div>
                                <div className="row margin-vertical-2">
                                    <div className="column">
                                        <ValidatedInput id="card-number" required={true}
                                            value={model.number} onChange={(e) => handleOnChange('number', e)}
                                            label="Card Number"
                                            mask="0000 0000 0000 0000"
                                            useMaskedValue={true}
                                            messages={(submitted && modelErrors.number) || []} />
                                    </div>
                                </div>
                                <div className="row margin-vertical-2">
                                    <div className="column-6">
                                        <ValidatedSelect id="card-month" required={true}
                                            value={model.month} onChange={(e) => handleOnChange('month', e)}
                                            label="Expiration Month"
                                            messages={(submitted && modelErrors.month) || []}>
                                            <option value="">Select One</option>
                                            {months.map((m, i) => {
                                                return <option key={i} value={m.value}>{m.label}</option>
                                            })}
                                        </ValidatedSelect>
                                    </div>
                                    <div className="column-6">
                                        <ValidatedSelect id="card-year" required={true}
                                            value={model.year} onChange={(e) => handleOnChange('year', e)}
                                            label="Expiration Year"
                                            messages={(submitted && modelErrors.year) || []}>
                                            <option value="">Select One</option>
                                            {years.map((y, i) => {
                                                return <option key={i} value={y}>{y}</option>
                                            })}
                                        </ValidatedSelect>
                                    </div>
                                </div>
                                <div className="row margin-vertical-2">
                                    <div className="column-4">
                                        <ValidatedInput id="card-cvc" required={true}
                                            value={model.cvc} onChange={(e) => handleOnChange('cvc', e)}
                                            label="CVC"
                                            mask="000"
                                            messages={(submitted && modelErrors.cvc) || []} />
                                    </div>
                                    <div className="column">
                                        <ValidatedInput id="card-postal-code" required={true}
                                            value={model.postalCode} onChange={(e) => handleOnChange('postalCode', e)}
                                            label="Billing Zip Code"
                                            mask="00000"
                                            messages={(submitted && modelErrors.postalCode) || []} />
                                    </div>
                                </div>
                                <div className="row margin-top-3 justify-content-between">
                                    <div className="column-auto">
                                        <Button id="cancel-button" text="Cancel" disabled={submitting} onClick={handleOnCancel} />
                                    </div>
                                    <div className="column-auto">
                                        <Button id="submit-button" text="Submit" className="action" type="submit" loading={submitting} disabled={submitting} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>}
                    {
                        updating && <div className="row margin-top-3">
                            <div className="column-medium-4  padding-5">
                                <form onSubmit={handleSubmit}>
                                    <h3>Update Payment Method</h3>
                                    <ApiErrorMessages error={formApiErrors} />
                                    <div className="row margin-vertical-2">
                                        <div className="column-6">
                                            <ValidatedSelect id="card-month" required={true}
                                                value={model.month} onChange={(e) => handleOnChange('month', e)}
                                                label="Expiration Month"
                                                messages={(submitted && modelErrors.month) || []}>
                                                <option value="">Select One</option>
                                                {months.map((m, i) => {
                                                    return <option key={i} value={m.value}>{m.label}</option>
                                                })}
                                            </ValidatedSelect>
                                        </div>
                                        <div className="column-6">
                                            <ValidatedSelect id="card-year" required={true}
                                                value={model.year} onChange={(e) => handleOnChange('year', e)}
                                                label="Expiration Year"
                                                messages={(submitted && modelErrors.year) || []}>
                                                <option value="">Select One</option>
                                                {years.map((y, i) => {
                                                    return <option key={i} value={y}>{y}</option>
                                                })}
                                            </ValidatedSelect>
                                        </div>
                                    </div>
                                    <div className="row margin-top-3 justify-content-between">
                                        <div className="column-auto">
                                            <Button id="cancel-button" text="Cancel" disabled={submitting} onClick={handleOnCancel} />
                                        </div>
                                        <div className="column-auto">
                                            <Button id="submit-button" text="Submit" className="action" type="submit" loading={submitting} disabled={submitting} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <div className="row justify-content-end">
                                <div className="column-auto">
                                    <Button id="checkout-button" disabled={!paymentMethod && organization.value.billingTypeId !== INVOICED} text="Review" className="action" href="/admin/billing/plan/confirm" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="column-auto">
                                    {!paymentMethod && organization.value.billingTypeId !== INVOICED && <div><small>Please enter a payment method to continue</small></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </section>)
    };

    return getMainContent();
};

export default PlanPaymentDetails;