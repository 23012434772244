import { Fragment, useEffect, useMemo, useState } from "react";
import RadioButton from "../components/UI/RadioButton";
import { getReportTypeOptions } from "../utils/radiobuttonHelpers";
import Button from "../components/UI/Button";
import './Reports.css';
import getclassNames from "../utils/getClassNames";
import { REPORT_TYPE_NAME_MAP } from "../constants/reportTypes";
import IconButton from "../components/UI/IconButton";
import { mapping } from '../components/reports/steps/stepMapping';
import * as results from '../components/reports/results/resultMapping';
import * as summary from '../components/reports/summaries/summaryMapping';
import { getNextStep } from "../utils/reportWorkflow";
import { runReportV2 } from "../services/reportService";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import ResultProps from "../components/reports/results/ResultProps";
import Tabs from "../components/UI/Tabs";
import Tab from "../components/UI/Tab";
import SummaryProps from "../components/reports/summaries/SummaryProps";
import { DONE } from "../constants/reportStep";
import ReportStep from "../models/pageModels/ReportStep";
import ErrorMessages from "../components/UI/ErrorMessages";

const Reports = () => {
    const [reportType, setReportType] = useState<string>('');
    const [reportCriteriaExpanded, setReportCriteriaExpanded] = useState(true);
    const [reportTypeExpanded, setReportTypeExpanded] = useState(true);
    const [reportResultsExpanded, setReportResultsExpanded] = useState(false);
    const [reportParameters, setReportParameters] = useState<{ [key: string]: string }>();
    const [reportId, setReportId] = useState<string>();
    const [showReportResults, setShowReportResults] = useState(false);
    const [showSubmitReport, setShowSubmitReport] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [steps, setSteps] = useState<ReportStep[]>([]);

    useEffect(() => {
        document.title = 'Run a Report';
    }, []);

    const ResportResultComponent: ((props: ResultProps) => JSX.Element) | null = useMemo(() => {
        return results.mapping[reportType];
    }, [reportType]);

    const ReportSummaryComponent: ((props: SummaryProps) => JSX.Element) | null = useMemo(() => {
        return summary.mapping[reportType];
    }, [reportType]);

    const handleRunReport = () => {
        if (!reportType) {
            return;
        }

        setSubmitting(true);
        setShowReportResults(false);
        setReportResultsExpanded(false);
        setApiError(undefined);

        const parameters: { [key: string]: string } = {};
        for (const step of steps) {
            for (const value of step.values) {
                parameters[value.name] = value.value;
            }
        }

        setReportParameters(parameters);

        runReportV2({
            reportType,
            parameters
        }).then(r => {
            setShowReportResults(true);
            setReportResultsExpanded(true);
            setReportCriteriaExpanded(false);
            setReportId(r.reportId);
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setSubmitting(false);
        })
    };

    const handleReportTypeNext = () => {
        if (!reportType) {
            return;
        }

        setReportTypeExpanded(false);

        const nextStep = getNextStep(reportType);
        if (nextStep) {
            setSteps([
                { step: nextStep.step, values: [], routeByValues: nextStep.routeByValues, expanded: true, disabled: false, validation: [] }
            ]);
        } else {
            setSteps([]);
        }
    };

    const toggleReportCriteriaExpanded = () => {
        setReportCriteriaExpanded(!reportCriteriaExpanded);
    };

    const toggleReportResultsExpanded = () => {
        setReportResultsExpanded(!reportResultsExpanded);
    };

    const toggleReportTypeExpanded = () => {
        setReportTypeExpanded(!reportTypeExpanded);
    };

    const getStepByName = (stepName: string) => {
        const matchingStep = steps.find(s => s.step === stepName);
        if (!matchingStep) {
            console.warn(`Unable to find matching step for ${stepName}`);
        }

        return matchingStep;
    }

    const handleOnStepExpanded = (stepName: string, expanded: boolean) => {
        const matchingStep = getStepByName(stepName);
        if (!matchingStep) return;

        matchingStep.expanded = expanded;

        setSteps([
            ...steps
        ]);
    };

    const handleOnStepNext = (stepName: string) => {
        const matchingStep = getStepByName(stepName);
        if (!matchingStep) return;

        matchingStep.expanded = false;

        const locator = `${reportType}/${steps.map(s => {
            if (s.routeByValues) {
                return `${s.step}[${s.values.map(v => v.value).join(',')}]`
            }
            return s.step;
        }).join('/')}`;

        const nextStep = getNextStep(locator);
        if (!nextStep) {
            const indexOfMatchStep = steps.findIndex(s => s === matchingStep);
            if (steps.length > indexOfMatchStep + 1) {
                steps.splice(indexOfMatchStep + 1);
            }
        } else {
            if (nextStep.step === DONE) {
                setShowSubmitReport(true);
            } else {
                setShowSubmitReport(false);
                const existingNextStep = getStepByName(nextStep.step);
                if (existingNextStep) {
                    existingNextStep.expanded = true;
                } else {
                    steps.push({
                        step: nextStep.step,
                        values: [],
                        routeByValues: nextStep.routeByValues,
                        expanded: true,
                        disabled: false,
                        validation: []
                    });
                }
            }
        }

        setSteps([
            ...steps
        ]);
    };

    const handleReportTypeChange = (value: string) => {
        setReportType(value);
        setSteps([]);
        setShowSubmitReport(false);
        setShowReportResults(false);
    };

    const handleOnStepChange = (data: { step: string, values: { name: string, value: string }[] }) => {
        const matchingStep = getStepByName(data.step);
        if (!matchingStep) return;

        setShowReportResults(false);
        setShowSubmitReport(false);

        matchingStep.values = data.values;
        const validationValue = data.values.find(v => v.name === 'validation');
        if (validationValue?.value) {
            matchingStep.validation = JSON.parse(validationValue.value);
            matchingStep.values = matchingStep.values.filter(v => v.name !== 'validation'); // we want to exclude this from the step values
        }

        const matchStepIndex = steps.findIndex(s => s === matchingStep);

        if (steps.length > 1 && matchStepIndex !== steps.length - 1) {
            steps.splice(matchStepIndex + 1);
            setShowSubmitReport(false);
        }

        setSteps([
            ...steps
        ]);
    };

    const handleOnBackToCriteria = () => {
        setReportCriteriaExpanded(true);
        setReportResultsExpanded(false);
    }

    const getAllStepValues = () => {
        const allStepValues: { [key: string]: { name: string, value: string }[] } = {};
        for (const s of steps) {
            allStepValues[s.step] = s.values;
        }

        return allStepValues
    };

    return (<section id="main-content" className="padding-1" tabIndex={-1}>
        <h1>Run a Report</h1>
        <div className={getclassNames('report-criteria', reportCriteriaExpanded ? 'expanded' : '')}>
            <div className="report-criteria-header">
                <div className="row justify-content-between align-items-center">
                    <div className="column-auto">
                        <h2>Criteria</h2>
                    </div>
                    <div className="column-1">
                        <IconButton id="toggle-report-type-expansion"
                            className="small flat"
                            icon={reportCriteriaExpanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                            onClick={toggleReportCriteriaExpanded} />
                    </div>
                </div>

            </div>
            <div className="report-criteria-body">
                <div className="report-steps">
                    <ApiErrorMessages error={apiError} />
                    {showSubmitReport && <ErrorMessages messages={steps.map(s => s.validation).flat()} />}
                    <div className={getclassNames('report-step', reportTypeExpanded ? 'expanded' : '')}>
                        <div className="report-step-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="column-auto">
                                    <h3>What do you want to see?{reportType ? ` - ${REPORT_TYPE_NAME_MAP[reportType]}` : ''}</h3>
                                </div>
                                <div className="column-1">
                                    <IconButton id="toggle-report-type-expansion"
                                        className="small flat"
                                        icon={reportTypeExpanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                                        onClick={toggleReportTypeExpanded} />
                                </div>
                            </div>
                        </div>
                        <div className="report-step-body">
                            <div className="row margin-bottom-3">
                                <div className="column">
                                    <RadioButton id="report-type"
                                        disabled={submitting}
                                        options={getReportTypeOptions()}
                                        onChange={handleReportTypeChange}
                                        value={reportType} />
                                </div>
                            </div>
                            <div className="row justify-content-end margin-bottom-3">
                                <div className="column-auto">
                                    <Button id="report-type-next"
                                        text="Next"
                                        icon={<i className="ri-arrow-right-s-line"></i>}
                                        iconLocation="end"
                                        className="secondary"
                                        onClick={handleReportTypeNext}
                                        disabled={!reportType} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {steps.map(s => {
                        const Step = mapping[s.step];
                        return <Step key={s.step}
                            values={s.values}
                            expanded={s.expanded}
                            reportType={reportType}
                            allStepValues={getAllStepValues()}
                            disabled={s.disabled || submitting}
                            onExpand={handleOnStepExpanded}
                            onNext={handleOnStepNext}
                            onChange={handleOnStepChange} />
                    })}
                    {showSubmitReport && <>
                        <div className="row justify-content-end padding-2">
                            <div className="column-auto">
                                <Button id="submit-report"
                                    text="Run Report"
                                    loading={submitting}
                                    onClick={handleRunReport}
                                    disabled={steps.some(s => s.values.some(v => !v.value)) || submitting} />
                            </div>
                        </div>
                        {steps.some(s => s.validation.length !== 0) && <div className="row justify-content-end padding-2">
                            <div className="column-auto">
                                <ErrorMessages messages={["Unable to submit, please check your steps for validation errors"]} />
                            </div>
                        </div>}
                    </>}
                </div>
            </div>
        </div>
        {showReportResults && reportId && <div className={getclassNames('report-results', reportResultsExpanded ? 'expanded' : '')}>
            <div className="report-results-header">
                <div className="row justify-content-between align-items-center">
                    <div className="column-auto">
                        <h2>Results</h2>
                    </div>
                    <div className="column-1">
                        <IconButton id="toggle-report-type-expansion"
                            className="small flat"
                            icon={reportResultsExpanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                            onClick={toggleReportResultsExpanded} />
                    </div>
                </div>
            </div>
            <div className="report-results-body">
                <div className="row justify-content-end padding-2 margin-bottom-3">
                    <div className="column-auto">
                        <Button id="go-back"
                            text="Back to Criteria"
                            className="secondary"
                            onClick={handleOnBackToCriteria} />
                    </div>
                </div>
                <Tabs>
                    {ReportSummaryComponent && <Tab title="Summary">
                        <ReportSummaryComponent reportId={reportId} parameters={reportParameters} />
                    </Tab>}
                    {ResportResultComponent && <Tab title="Detail">
                        <ResportResultComponent reportId={reportId} parameters={reportParameters} />
                    </Tab>}
                </Tabs>

            </div>
        </div>}
    </section>);
};

export default Reports;