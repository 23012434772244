import { FormEvent, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessages from '../components/UI/ErrorMessages';
import { approvedCountries, countries } from '../constants/countryNames';
import Select from '../components/UI/Select';
import Button from '../components/UI/Button';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import { useSearchParams } from 'react-router-dom';
import CheckboxGroup from '../components/UI/CheckboxGroup';
import { dropdownMapping, dropdownValues } from '../constants/emailTypes';
import { unsubscribe } from '../services/emailService';

const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const [emailTypes, setEmailTypes] = useState<{ label: string, value: string }[]>([]);
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState();
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [unsubscribingAll, setUnsubscribingAll] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    document.title = 'Unsubscribe';
  }, []);

  const handleUnsubscribe = () => {
    if (emailTypes.length === 0) {
      setError('Please select all emails you would like to no longer receive');
      return;
    }

    const email = searchParams.get('email');
    const emailMatches = email?.match(/.+@.+\..+/);
    if (!email || !emailMatches || emailMatches.length === 0) {
      setError('An unexpected error has occurred');
      console.warn('Valid email not found, unable to unsubscribe');
      return;
    }

    const mappedTypes: number[] = [];
    for (const type of emailTypes) {
      const values = dropdownMapping.has(type.value) && dropdownMapping.get(type.value);
      if (!values) {
        setError('An unexpected error has occurred');
        console.error(`${type.value} is not mapped.  Unable to unsubscribe`);
        return;
      }

      for (const matchingValue of values) {
        if (!mappedTypes.includes(matchingValue)) {
          mappedTypes.push(matchingValue);
        }
      }
    }

    if (mappedTypes.length === 0) {
      console.error('No mapped types found.  Unable to unsubscribe.');
      setError('An unexpected error has occurred');
      return;
    }

    setApiError(undefined);
    setError('');

    setUnsubscribing(true);

    unsubscribe({
      email,
      emailTypes: mappedTypes
    }).then(() => {
      setCompleted(true);
    }).catch(e => {
      console.error(e);
      setApiError(e);
    }).finally(() => {
      setUnsubscribingAll(false);
    });
  }

  const handleUnsubscribeAll = () => {
    setApiError(undefined);
    setError('');

    const email = searchParams.get('email');
    const emailMatches = email?.match(/.+@.+\..+/);
    if (!email || !emailMatches || emailMatches.length === 0) {
      setError('An unexpected error has occurred');
      console.warn('Valid email not found, unable to unsubscribe');
      return;
    }

    setUnsubscribingAll(true);

    unsubscribe({
      email,
      emailTypes: []
    }).then(() => {
      setCompleted(true);
    }).catch(e => {
      console.error(e);
      setApiError(e);
    }).finally(() => {
      setUnsubscribingAll(false);
    });
  }

  if (completed) {
    return (
      <div className="section">
        <h1>Unsubscribe</h1>
        <div className="row justify-content-center">
          <div className="column-medium-6">
            Successfully unsubscribed! You can close this window now.
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="section">
      <h1>Unsubscribe</h1>
      <div className="row justify-content-center">
        <div className="column-medium-6">
          <ErrorMessages messages={error ? [error] : []} />
          <ApiErrorMessages error={apiError} />
          <p>
            We are so sad to hear you no longer want to receive our emails.
          </p>
          <p> Please select which email types below you no longer want
            us to send to your mailbox at <strong>{searchParams.get('email')}</strong>.
          </p>
          <p>Alternatively you can easily unsubscribe from all the below email types
            and any future types using the "Unsubscribe All" option.</p>
          <div className="row">
            <div className="column">
              <CheckboxGroup id="email-types" options={dropdownValues} onChange={setEmailTypes} checkedOptions={emailTypes} />
            </div>
          </div>
          <div className="row justify-content-end margin-top-3">
            <div className="column-auto-medium">
              <Button id="unsubscribe-all-button"
                text="Unsubscribe All"
                className="secondary"
                loading={unsubscribingAll}
                disabled={unsubscribing || unsubscribingAll}
                onClick={handleUnsubscribeAll} />
            </div>
            <div className="column-auto-medium">
              <Button id="unsubscribe-button"
                text="Unsubscribe"
                loading={unsubscribing}
                disabled={unsubscribing || unsubscribingAll}
                onClick={handleUnsubscribe} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Unsubscribe;