import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import Loader from "../components/UI/Loader";

const Login = () => {
  const { loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      loginWithRedirect({});
    }
  }, [isLoading]);

  return (
    <div className="section">
      <Loader />
    </div>
  )
};

export default Login;
