import axios from "axios";
import GetUploadUrlRequest from "../models/serviceModels/GetUploadUrlRequest";
import GetUploadUrlResponse from "../models/serviceModels/GetUploadUrlResponse";
import { UploadFileRequest } from "../models/serviceModels/UploadFileRequest";
import GetDownloadUrlRequest from "../models/serviceModels/GetDownloadUrlRequest";
import GetDownloadUrlResponse from "../models/serviceModels/GetDownloadUrlResponse";
import DeleteFileUploadRequest from "../models/serviceModels/DeleteFileUploadRequest";
import DeleteFileUploadResponse from "../models/serviceModels/DeleteFileUploadResponse";
import UpsertClientFilesRequest from "../models/serviceModels/UpsertClientFilesRequest";
import ClientFile from "../models/serviceModels/ClientFile";
import * as fileScanStatus from "../constants/fileScanStatus";
import * as fileUploadStatus from "../constants/fileUploadStatus";
import { FileUpload } from "../models/serviceModels/FileUpload";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUploadUrl = async (request: GetUploadUrlRequest): Promise<GetUploadUrlResponse> => {
    const response = await axios.post(`${baseUrl}/files/uploads`, request);
    return {
        ...response.data,
        fileName: request.fileName
    };
};

const getFileUpload = async (fileId: string): Promise<FileUpload> => {
    const response = await axios.get(`${baseUrl}/files/${fileId}/uploads`);
    return response.data;
};

const uploadFile = async (request: UploadFileRequest): Promise<string> => {
    await axios.put(request.url, request.file, {
        cancelToken: request.cancellationToken?.token
    });

    return request.fileId;
};

const getDownloadUrl = async (request: GetDownloadUrlRequest): Promise<GetDownloadUrlResponse> => {
    const response = await axios.post(`${baseUrl}/files/downloads`, request);
    return response.data;
};

const deleteFileUpload = async (request: DeleteFileUploadRequest): Promise<DeleteFileUploadResponse> => {
    const response = await axios.delete(`${baseUrl}/files/${request.fileId}/uploads`);
    return response.data;
};

const upsertClientFiles = async (request: UpsertClientFilesRequest): Promise<ClientFile[]> => {
    const response = await axios.post(`${baseUrl}/files/clients/${request.clientId}`, {
        files: request.files
    });
    return response.data;
};

const getClientFiles = async (clientId: string): Promise<ClientFile[]> => {
    const response = await axios.get(`${baseUrl}/files/clients/${clientId}`);
    return response.data.map((d: any) => {
        return {
            fileId: d.fileId,
            id: d.id,
            name: d.name,
            status: d.status === fileScanStatus.INFECTED ? fileUploadStatus.INFECTED :
                d.status === fileScanStatus.CLEAN ? fileUploadStatus.UPLOADED :
                fileUploadStatus.SCANNING,
            created: d.created
        }
    });
};

export {
    getUploadUrl,
    uploadFile,
    getDownloadUrl,
    deleteFileUpload,
    upsertClientFiles,
    getClientFiles,
    getFileUpload
}