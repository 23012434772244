import { format } from 'date-fns';
import { useState, useEffect, useRef, FormEvent } from 'react';
import { useParams } from "react-router-dom";
import Loader from '../components/UI/Loader';
import { deleteClientNote, saveClientNote, searchClientNotes, updateClientNote } from '../services/clientsService';
import { ClientNote } from '../models/serviceModels/ClientNote';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import TruncatedText from '../components/UI/TruncatedText';
import Input from '../components/UI/Input';
import Button from '../components/UI/Button';
import Dropdown from '../components/UI/Dropdown';
import { ADHOC_ID, CASE_PLAN_ID, CLIENT_FORM_ID, TRACKING_FORM_ID } from '../constants/clientNoteSourceType';
import Modal from '../components/UI/Modal';
import useElementVisible from '../hooks/useElementVisible';
import getclassNames from '../utils/getClassNames';
import FileUpload from '../components/UI/FileUpload';
import UploadableFile from '../models/pageModels/UploadableFile';
import { UPLOADED } from '../constants/fileUploadStatus';
import useQuotas from '../hooks/useQuotas';
import ErrorMessages from '../components/UI/ErrorMessages';
import { mapToUploadableFiles } from '../mappers/fileMapper';
import { executeSearch, getDefaultSearchFilters, getSearchFields } from '../services/searchService';
import { CLIENT_NOTES_SEARCH } from '../constants/searchType';
import Search, { SearchEvent } from '../components/UI/Search';
import DefaultSearchFilter from '../models/serviceModels/DeafultSearchFilter';
import SearchFieldReference from '../models/serviceModels/SearchFieldReference';

const TAKE = 10;

const ParticipantNotes = () => {
    const { id = '' } = useParams();
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(false);
    const [searching, setSearching] = useState(false);
    const [skip, setSkip] = useState(0);
    const [query, setQuery] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [notes, setNotes] = useState<ClientNote[]>([]);
    const [apiError, setApiError] = useState<any>();
    const loaded = useRef(false);
    const [newNote, setNewNote] = useState('');
    const [newNoteFiles, setNewNoteFiles] = useState<UploadableFile[]>([]);
    const [saving, setSaving] = useState(false);
    const [addingNote, setAddingNote] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState('');
    const [editingNoteFiles, setEditingNoteFiles] = useState<UploadableFile[]>([]);
    const [updatedNote, setUpdatedNote] = useState('');
    const [deletingNote, setDeletingNote] = useState<ClientNote>();
    const [deleting, setDeleting] = useState(false);
    const [modalError, setModalError] = useState<any>();
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [recentlyChanged, setRecentlyChanged] = useState<string>();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [searchEvent, setSearchEvent] = useState<SearchEvent>();
    const [searchFields, setSearchFields] = useState<SearchFieldReference[]>();
    const [searchFilters, setSearchFilters] = useState<DefaultSearchFilter[]>();
    const quotas = useQuotas();

    useEffect(() => {
        setLoading(true);
        setApiError(undefined);

        Promise.all([executeSearch<ClientNote>({
            searchTypeId: CLIENT_NOTES_SEARCH,
            fields: [],
            skip: 0,
            clientId: id,
            disabledFilters: [],
            take: TAKE
        }), getSearchFields(CLIENT_NOTES_SEARCH, id, true), getDefaultSearchFilters(CLIENT_NOTES_SEARCH)]).then(([searchResult, fieldResult, filterResult]) => {
            setTotalCount(searchResult.totalCount);
            setNotes(searchResult.data);
            setSearchFields(fieldResult);
            setSearchFilters(filterResult);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });


    }, []);

    useEffect(() => {
        if (!recentlyChanged) {
            return;
        }

        setTimeout(() => {
            setRecentlyChanged(undefined);
        }, 3000);
    }, [recentlyChanged]);

    useEffect(() => {
        if (!loaded.current || skip === 0) {
            return;
        }

        setApiError(undefined);

        if (!searchEvent) {
            return;
        }

        setPaging(true);

        executeSearch<ClientNote>({
            searchTypeId: CLIENT_NOTES_SEARCH,
            fields: searchEvent.fields?.map(f => {
                return {
                    id: f.fieldId,
                    value: f.value,
                    filterTypeId: f.filterTypeId
                }
            }) || [],
            skip: 0,
            clientId: id,
            query: searchEvent.query,
            disabledFilters: searchEvent.disabledFilters || [],
            take: TAKE
        }).then(r => {
            setTotalCount(r.totalCount);
            setNotes(n => {
                return [
                    ...n,
                    ...r.data
                ];
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setPaging(false);
        });
    }, [skip]);

    useEffect(() => {
        if (!visible || totalCount === 0) {
            return;
        }

        setSkip(current => {
            return current + TAKE;
        });
    }, [visible, totalCount]);

    useEffect(() => {
        loaded.current = true;
    }, []);

    const handleOnSearchV2 = (e: SearchEvent) => {
        setSearchEvent(e);
        setSearching(true);
        setApiError(undefined);
        setSkip(0);

        executeSearch<ClientNote>({
            searchTypeId: CLIENT_NOTES_SEARCH,
            fields: e.fields?.map(f => {
                return {
                    id: f.fieldId,
                    value: f.value,
                    filterTypeId: f.filterTypeId
                }
            }) || [],
            skip: 0,
            query: e.query,
            clientId: id,
            disabledFilters: e.disabledFilters || [],
            take: TAKE
        }).then(r => {
            setTotalCount(r.totalCount);
            setNotes(r.data);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSearching(false);
        });
    };

    const handleOnSearch = (e: FormEvent) => {
        e.preventDefault();
        setSearching(true);
        setApiError(undefined);
        setSkip(0);

        searchClientNotes({
            skip: 0,
            query,
            take: TAKE,
            clientId: id
        }).then(notesResult => {
            setTotalCount(notesResult.totalCount);
            setNotes([
                ...notesResult.data
            ]);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSearching(false);
        });
    };

    const handleEditOnSave = () => {
        if (!id) {
            return;
        }

        setErrorMessages([]);

        if (!updatedNote && editingNoteFiles.length === 0) {
            setErrorMessages(['Note text or a file is required']);
            return;
        }

        setSaving(true);

        updateClientNote({
            id: editingNoteId,
            clientId: id,
            value: updatedNote,
            files: editingNoteFiles.map(f => f.fileId!)
        }).then(r => {
            setRecentlyChanged(editingNoteId);
            const existingNote = notes.find(n => n.id === editingNoteId);
            if (!existingNote) {
                console.warn('Existing note not found');
                return;
            }

            existingNote.value = r.value;
            existingNote.files = r.files;
            existingNote.updated = r.updated;
            existingNote.updatedBy = r.updatedBy;
            existingNote.updatedByFirstName = r.createdByFirstName;
            existingNote.updatedByLastName = r.updatedByLastName;

            setNotes([
                ...notes
            ]);

            handleEditingOnCancel();
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setSaving(false);
        })
    };

    const handleNewOnSave = () => {
        if (!id) {
            return;
        }

        setErrorMessages([]);

        if (!newNote && newNoteFiles.length === 0) {
            setErrorMessages(['Note text or a file is required']);
            return;
        }

        setSaving(true);

        saveClientNote({
            clientId: id,
            value: newNote,
            files: newNoteFiles.map(f => f.fileId!)
        }).then(n => {
            setRecentlyChanged(n.id);
            setNotes(current => [
                n,
                ...current
            ]);

            handleNewOnCancel();
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setSaving(false);
        })
    };

    const handleNewOnCancel = () => {
        setNewNote('');
        setNewNoteFiles([]);
        setAddingNote(false);
    };

    const handleEditingNote = (note: ClientNote) => {
        setEditingNoteId(note.id);
        setEditingNoteFiles(mapToUploadableFiles(note.files || []));
        setUpdatedNote(note.value);
    };

    const handleEditingOnCancel = () => {
        setEditingNoteId('');
        setEditingNoteFiles([]);
        setUpdatedNote('');
    };

    const handleConfirmDelete = (note: ClientNote) => {
        setModalError(undefined);
        setDeletingNote(note);
    };

    const handleOnDelete = () => {
        if (!id || !deletingNote) {
            return;
        }

        setModalError(undefined);
        setDeleting(true);

        deleteClientNote({
            clientId: id,
            id: deletingNote.id
        }).then(() => {
            setNotes(current => [
                ...current.filter(c => c.id !== deletingNote.id)
            ]);

            handleCancellingDelete();
        }).catch(e => {
            setModalError(e);
            console.error(e);
        }).finally(() => {
            setDeleting(false);
        });
    };

    const handleCancellingDelete = () => {
        setDeletingNote(undefined);
    }

    const getMainContent = () => {
        if (loading) {
            return <Loader />;
        }

        return (<>
            <ApiErrorMessages error={apiError} />
            <div className="row align-items-center">
                <div className="column-auto">
                    <h3>Notes Feed</h3>
                </div>
            </div>
            <div>
                {!addingNote && <Button id="start-adding-note-button" onClick={() => setAddingNote(true)} text="Add New Note" />}
                {addingNote && <>
                    <ErrorMessages messages={errorMessages} />
                    <div className="row">
                        <div className="column">
                            <Input type="multi" id="new-note-input" value={newNote} label="Note" onChange={setNewNote} />
                        </div>
                    </div>
                    <div className="row margin-top-2">
                        <div className="column">
                            {quotas?.limits?.atOrBeyondFormDataLimit && <ErrorMessages messages={["You are at or beyond your file upload limit"]} />}
                            <FileUpload id="new-files-upload" value={newNoteFiles} onChange={setNewNoteFiles} multiple={true} disableUpload={quotas?.limits?.atOrBeyondFormDataLimit} />
                        </div>
                    </div>
                    <div className="row justify-content-end margin-top-2">
                        <div className="column-auto">
                            <Button id="cancel-new-note-button"
                                onClick={handleNewOnCancel}
                                className="secondary"
                                text="Cancel"
                                disabled={saving} />
                        </div>
                        <div className="column-auto">
                            <Button id="new-note-button"
                                onClick={handleNewOnSave}
                                text="Create New Note"
                                className="action"
                                loading={saving}
                                disabled={saving || newNoteFiles.some(f => f.status !== UPLOADED)} />
                        </div>
                    </div>
                </>}
            </div>
            <hr />
            <div className="row margin-vertical-2">
                <div className="column">
                    {searchFields && searchFilters && <Search id="search-participant-notes"
                        searching={searching}
                        onSearch={handleOnSearchV2}
                        fields={searchFields}
                        defaultFilters={searchFilters}
                    />}
                </div>
            </div>
            {notes && notes.length !== 0 && <div className="list-item-container">
                {notes.map(n => {
                    const options = [];

                    options.push({
                        label: 'Edit',
                        onClick: () => handleEditingNote(n)
                    });

                    if (n.sourceId === ADHOC_ID) {
                        options.push({
                            label: 'Delete',
                            onClick: () => handleConfirmDelete(n)
                        });
                    } else if (n.sourceId === TRACKING_FORM_ID) {
                        options.push({
                            label: 'View Form',
                            href: `/forms/${n.formId}/versions/${n.formVersionId}/instances/${n.formInstanceId}/view`
                        });
                    } else if (n.sourceId === CLIENT_FORM_ID) {
                        options.push({
                            label: 'View Participant',
                            href: `/participants/${id}/profile-demographics`
                        });
                    } else if (n.sourceId === CASE_PLAN_ID) {
                        options.push({
                            label: 'View Plan',
                            href: `/participants/${id}/plans/${n.casePlanId}`
                        });
                    }

                    return (<div className={getclassNames('list-item', recentlyChanged === n.id ? 'highlight' : '')} key={n.id}>
                        {editingNoteId !== n.id && <>
                            <div className="row align-items-top">
                                <div className="column">
                                    {n.sourceId === CLIENT_FORM_ID && <div className="text-bold margin-bottom-2">Participant Additional Information</div>}
                                    {n.sourceId === TRACKING_FORM_ID && ((n.referenceName) || n.formName) && <div className="text-bold margin-bottom-2">{(<>{n.referenceName} - {n.sourceName}</>)}</div>}
                                    {n.sourceId === CASE_PLAN_ID && ((n.referenceName) || n.casePlanName) && <div className="text-bold margin-bottom-2">{n.referenceName} - Plan</div>}
                                    {n.value && <TruncatedText formatted={true}
                                        value={n.value} maxLength={100}
                                        lengthVariance={100} id={`${n.id}-note`} />}
                                    {!n.value && <span className="text-background text-italic">&lt;No note to display&gt;</span>}
                                </div>
                                <div className="column-auto">
                                    <Dropdown id={`options-dropdown-${n.id}`}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-between margin-top-5 text-sub">
                                <div className="column-auto">
                                    {n.updated ? format(new Date(n.updated), 'Pp') : format(new Date(n.created), 'Pp')}
                                </div>
                                <div className="column-auto">
                                    {n.updatedByFirstName ? `${n.updatedByFirstName} ${n.updatedByLastName}` : `${n.createdByFirstName} ${n.createdByLastName}`}
                                </div>
                            </div>
                            {n.files && n.files.length !== 0 && <div className="row margin-top-3">
                                <div className="column">
                                    <FileUpload id={`note-files-${n.id}`}
                                        value={mapToUploadableFiles(n.files)}
                                        disableRemove={true}
                                        disableUpload={true}
                                        label="Attachments" />
                                </div>
                            </div>}
                        </>}
                        {editingNoteId === n.id && <>
                            <ErrorMessages messages={errorMessages} />
                            <div className="row">
                                <div className="column">
                                    <Input type="multi"
                                        id="edit-note-input"
                                        value={updatedNote}
                                        label="Note"
                                        onChange={setUpdatedNote} />
                                </div>
                            </div>
                            <div className="row margin-top-2">
                                <div className="column">
                                    {quotas?.limits?.atOrBeyondFormDataLimit && <ErrorMessages messages={["You are at or beyond your file upload limit"]} />}
                                    <FileUpload id="edit-files-upload"
                                        value={editingNoteFiles}
                                        onChange={setEditingNoteFiles}
                                        multiple={true}
                                        disableUpload={quotas?.limits?.atOrBeyondFormDataLimit} />
                                </div>
                            </div>
                            <div className="row justify-content-end margin-top-2">
                                <div className="column-auto">
                                    <Button id="cancel-save-note-button"
                                        onClick={handleEditingOnCancel}
                                        className="secondary"
                                        text="Cancel"
                                        disabled={saving} />
                                </div>
                                <div className="column-auto">
                                    <Button id="save-note-button"
                                        onClick={handleEditOnSave}
                                        text="Update Note"
                                        loading={saving}
                                        disabled={saving || editingNoteFiles.some(f => f.status !== UPLOADED)} />
                                </div>
                            </div>
                        </>
                        }
                    </div>)
                })}
                {paging && <Loader />}
                {notes.length < totalCount && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
            </div>}
            {notes && notes.length === 0 && <div className="row">
                <div className="column">
                    No notes found
                </div>
            </div>}
        </>);

    };

    return (
        <section id="main-content" tabIndex={-1}>
            {getMainContent()}
            <Modal id="delete-note-modal" visible={deletingNote !== undefined} className="medium" onClose={handleCancellingDelete} title="Confirm Delete Note">
                {deletingNote && (
                    <div>
                        {modalError && <ApiErrorMessages error={modalError} />}
                        <div className="row">
                            <div className="column">
                                Are you sure you want to delete this note?
                                <br />
                                <br />
                                <TruncatedText id={`delete-note-${deletingNote.id}`} formatted={true} lengthVariance={100} value={deletingNote.value} maxLength={50} />
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-delete-note" text="Cancel" className="secondary" disabled={deleting} onClick={handleCancellingDelete} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-delete-note" text="Delete Note" className="warning" disabled={deleting} loading={deleting} onClick={handleOnDelete} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </section>);
};

export default ParticipantNotes; 