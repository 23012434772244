import React, { useEffect } from "react";
import ParticipantForm from "../components/AddParticipant/ParticpantForm";

const AddParticipant = () => {
    useEffect(() => {
        document.title = "Add New Participant"
    }, []);

    return (
        <ParticipantForm />
    )

}
export default AddParticipant;
