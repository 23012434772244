import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import CurrentPaymentDetails from "../components/UI/CurrentPaymentDetails";
import CurrentPlan from "../components/UI/CurrentPlan";
import NewPlan from "../components/UI/NewPlan";
import { TRIAL_PLAN_TYPE_ID } from "../constants/planTypes";
import { OrganizationPlan } from "../models/serviceModels/OrganizationPlan";
import { PaymentMethod } from "../models/serviceModels/PaymentMethod";
import { getItem, removeItem } from "../services/cacheService";
import { createSubscription, updateSubscription } from "../services/paymentsService";
import Organization from "../models/serviceModels/Organization";
import { getOrganization } from "../services/organizationService";
import Loader from "../components/UI/Loader";
import { INVOICED } from "../constants/billingType";
import InvoicedPaymentDetails from "../components/UI/InvoicedPaymentDetails";
import { OrganizationContext } from "../App";

const ConfirmPlanChange = () => {
    const [currentPlan, setCurrentPlan] = useState<OrganizationPlan>();
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [checkoutApiErrors, setCheckoutApiErrors] = useState<any>();
    const [submitting, setSubmitting] = useState(false);
    const organization = useContext(OrganizationContext);

    const navigate = useNavigate();

    const newPlan = useMemo(() => {
        return getItem<{ users: number, plan: number }>('new_plan')
    }, []);

    useEffect(() => {
        if (organization.loading) {
            return;
        }

        if (organization.error) {
            setCheckoutApiErrors(organization.error);
        }
    }, [organization])

    const handleCheckout = () => {
        if (!newPlan) return;
        if (!currentPlan) return;

        setSubmitting(true);
        setCheckoutApiErrors(undefined);

        if (currentPlan.planTypeId !== TRIAL_PLAN_TYPE_ID) {
            updateSubscription({
                planTypeId: newPlan.plan,
                userCount: Number(newPlan.users)
            }).then(() => {
                removeItem('new_plan');
                navigate('/admin/billing');
                organization.refresh();
            }).catch(e => {
                console.error(e);
                setCheckoutApiErrors(e);
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            createSubscription({
                planTypeId: newPlan.plan,
                userCount: Number(newPlan.users)
            }).then(() => {
                removeItem('new_plan');
                navigate('/admin/billing');
                organization.refresh();
            }).catch(e => {
                console.error(e);
                setCheckoutApiErrors(e);
            }).finally(() => {
                setSubmitting(false);
            });
        }
    };

    const getMainContent = () => {
        if (organization.loading) {
            return <Loader/>
        }

        return (<section id="main-content" tabIndex={-1}>
            <BackButton />            
            <ApiErrorMessages error={checkoutApiErrors} />
            {organization.value && <div className="row justify-content-center margin-vertical-2">
                <div className="column-medium">
                    <div className="row">
                        <div className="column-medium-4">
                            <CurrentPlan onLoad={setCurrentPlan} />
                        </div>
                        <div className="column-medium-4">
                            <NewPlan />
                        </div>
                    </div>
                    <hr />
                    <div className="row margin-top-3">
                        <div className="column-medium-4">
                            {organization.value.billingTypeId !== INVOICED && <CurrentPaymentDetails onLoad={setPaymentMethod} />}
                            {organization.value.billingTypeId === INVOICED && <InvoicedPaymentDetails />}
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="column">
                            Please review the plan changes and payment details.  If everything looks good. Select <strong>Checkout</strong>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <Button id="checkout-button" text="Checkout" className="action"
                                disabled={submitting || (!paymentMethod && organization.value.billingTypeId !== INVOICED)|| !newPlan} loading={submitting}
                                onClick={handleCheckout} />
                        </div>
                    </div>
                </div>
            </div>}
        </section>)
    };

    return getMainContent();
};

export default ConfirmPlanChange;