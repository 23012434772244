
const scrollLeft = (containerSelector: string) => {
    const tableContainer = document.querySelector(containerSelector) as HTMLElement;
    if (tableContainer.scrollLeft > 0) {
        tableContainer.scrollLeft -= 100;
        if (tableContainer.scrollLeft < 0) {
            tableContainer.scrollLeft = 0;
        }
        return;
    }
};

const scrollRight = (containerSelector: string) => {
    const tableContainer = document.querySelector(containerSelector) as HTMLElement;
    const maxWidth = tableContainer.scrollWidth - tableContainer.clientWidth;
    if (tableContainer.scrollLeft < maxWidth) {
        tableContainer.scrollLeft += 100;
        if (tableContainer.scrollLeft > maxWidth) {
            tableContainer.scrollLeft = maxWidth;
        }
        return;
    }
};

export { 
    scrollLeft,
    scrollRight
};