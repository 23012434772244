import { useCallback, useContext, useEffect, useState } from "react";
import { getMe } from "../services/userService";
import MeUser from "../models/serviceModels/MeUser";
import { useAuth0 } from "@auth0/auth0-react";
import { getItem, removeSuffix, setItem } from "../services/cacheService";

const MAX_RETRIES = 3;

interface MeProvider {
    loading: boolean;
    user?: MeUser;
    error?: any;
    refresh: () => void;
}

const useMe = (): MeProvider => {
    const [loading, setLoading] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState<any>();
    const [meUser, setMeUser] = useState<MeUser>();
    const { isAuthenticated, isLoading, user } = useAuth0();

    const callGetMe = useCallback((retryCount = 1) => {
        setError(undefined);
        if (!user) {
            return;
        }

        const cacheKey = `${user?.sub}-me`;
        const cachedUser = getItem<MeUser>(cacheKey);

        if (cachedUser) {
            setMeUser(cachedUser);
            setLoading(false);
            return;
        }

        getMe().then(u => {
            setMeUser(u);
            setItem<MeUser>(cacheKey, u);
            setLoading(false);
        }).catch(e => {
            console.error(e);
            if (e.response?.status === 401 && retryCount <= MAX_RETRIES) {
                setTimeout(() => {
                    callGetMe(retryCount + 1);
                }, 1000);
            } else {
                setError(e);
                setLoading(false);
            }
        });
    }, [user]);

    useEffect(() => {
        if (waiting && isAuthenticated && !isLoading && user) {
            setLoading(true);
            callGetMe();
        }
    }, [isAuthenticated, isLoading, waiting, callGetMe, user]);

    useEffect(() => {
        if (!isAuthenticated || !user) {
            setWaiting(true);
            return;
        }

        setLoading(true);
        callGetMe();
    }, [callGetMe, isAuthenticated, user]);

    const refresh = () => {
        removeSuffix('-me');
        setLoading(true);
        callGetMe();
    };

    return { loading, user: meUser, error, refresh };
};

export {
    useMe
};

export type { MeProvider };
