import React, { useState } from 'react';
import Button from '../../../UI/Button';
import Checkbox from '../../../UI/Checkbox';
import Input from '../../../UI/Input';
import EditFormProps from './Models/EditFormProps';

const NumberEditForm = (props: EditFormProps) => {
    const [label, setLabel] = useState(props.field.label);
    const [name, setName] = useState(props.field.name);
    const [required, setRequired] = useState(props.field.required);
    const [showAdvanced, setShowAdvanced] = useState(false);

    const handleOnSave = (e: any) => {
        e.preventDefault();

        props.onSave({
            ...props.field,
            name,
            required,
            label
        });
    };

    return (<form onSubmit={handleOnSave}>
        <div className="row">
            <div className="column">
                <label>Current Label</label>
                <br />{props.field.label}
            </div>
        </div>
        <div className="row margin-top-2">
            <div className="column">
                <Input id="editing-field-label" value={label} onChange={(e) => setLabel(e)} />
            </div>
        </div>
        <div className="row margin-top-2">
            <div className="column">
                <Checkbox id="field-required" label="Required" checked={required} onChange={(checked) => setRequired(checked)} />
            </div>
        </div>
        {!showAdvanced && <div><Button id="show-advanced-button" className="flat small" text="Show Advanced" icon={<i className="ri-arrow-down-s-line" />} iconLocation="end" onClick={() => setShowAdvanced(true)} /></div>}
        {showAdvanced && <div><Button id="hide-advanced-button" className="flat small" text="Hide Advanced" icon={<i className="ri-arrow-up-s-line" />} iconLocation="end" onClick={() => setShowAdvanced(false)} /></div>}
        {showAdvanced && <div className="advanced-content">
            <div className="row">
                <div className="column">
                    <Input id="editing-field-name" label="Name" value={name} onChange={(e) => setName(e)} />
                </div>
            </div>
        </div>}
        <div className="row justify-content-between margin-top-3">
            <div className="column-medium-auto">
                <Button type="button" 
                    className="secondary" 
                    id="cancel-edit-field-button" 
                    onClick={props.onCancel} 
                    text="Cancel" 
                    disabled={props.saving} />
            </div>
            <div className="column-medium-auto">
                <Button type="submit" 
                    id="save-edit-field-button" 
                    text="Save" 
                    className="action"
                    loading={props.saving} 
                    disabled={props.saving} />
            </div>
        </div>
    </form>);
};

export default NumberEditForm;