import axios from "axios";
import UsageMetrics from "../models/serviceModels/UsageMetrics";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUsageMetrics = async (): Promise<UsageMetrics[]> => {
    const response = await axios.get(`${baseUrl}/organizations/usage`);
    return response.data;
};

export {
    getUsageMetrics
}