import { useEffect, useRef, useState } from "react";
import ResultProps from "./ResultProps";
import CasePlanGoalDueResult from "../../../models/serviceModels/CasePlanGoalDueResult";
import User from "../../../models/serviceModels/User";
import { exportReport, getReportResultsV2 } from "../../../services/reportService";
import useElementVisible from "../../../hooks/useElementVisible";
import { searchUsers } from "../../../services/userService";
import Loader from "../../UI/Loader";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import TruncatedText from "../../UI/TruncatedText";
import { formatJSONDateWithoutTime } from "../../../utils/dateHelpers";
import Dropdown from "../../UI/Dropdown";
import Button from "../../UI/Button";
import getclassNames from "../../../utils/getClassNames";
import Select from "../../UI/Select";
import { triggerDownloadOfUrl } from "../../../utils/reportHelpers";
import IconButton from "../../UI/IconButton";
import { scrollLeft, scrollRight } from "../../../utils/elementHelpers";

const TAKE = 50;

const InProgressGoalsResults = (props: ResultProps) => {
    const [resultsLoading, setResultsLoading] = useState(true);
    const [usersLoading, setUserLoading] = useState(true);
    const [results, setResults] = useState<CasePlanGoalDueResult[]>([]);
    const [skip, setSkip] = useState(0);
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState<User[]>();
    const [paging, setPaging] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [lastWorkedBy, setLastWorkedBy] = useState('');
    const [filterType, setFilterType] = useState('');
    const [exporting, setExporting] = useState(false);
    const loaded = useRef(false);

    useEffect(() => {
        if (loaded.current) {
            setSkip(0);
        }

        setResultsLoading(true);

        getReportResultsV2<CasePlanGoalDueResult>({
            skip: 0,
            take: TAKE,
            reportId: props.reportId,
            filter: '',
            sortBy: 'goalName',
            sortDirection: 'ASC'
        }).then(r => {
            setTotalCount(r.totalCount);
            setResults(r.data);
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setResultsLoading(false);
        })
    }, [props.reportId]);

    useEffect(() => {
        setUserLoading(true);
        searchUsers('', 0, 1000).then(r => {
            setUsers(r.data);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setUserLoading(false);
        });
    }, []);


    useEffect(() => {
        if (!loaded.current || skip === 0) {
            return;
        }

        setPaging(true);
        setApiError(undefined);

        getReportResultsV2<CasePlanGoalDueResult>({
            reportId: props.reportId,
            skip,
            take: TAKE,
            sortBy: 'goalName',
            sortDirection: 'Ascending',
            filter
        }).then(r => {
            setResults(er => {
                return [
                    ...er,
                    ...r.data
                ];
            });
            setTotalCount(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setPaging(false);
        });
    }, [skip]);

    useEffect(() => {
        if (visible && totalCount > 0) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible, totalCount]);

    useEffect(() => {
        if (!loaded.current) {
            return;
        }

        setResultsLoading(true);
        setApiError(undefined);
        setSkip(0);
        const builtFilter = encodeURIComponent(JSON.stringify({
            filterType,
            lastWorkedBy
        }));

        setFilter(builtFilter)

        getReportResultsV2<CasePlanGoalDueResult>({
            reportId: props.reportId,
            skip,
            take: TAKE,
            sortBy: 'goalName',
            sortDirection: 'Ascending',
            filter: builtFilter
        }).then(r => {
            setResults(r.data);
            setTotalCount(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setResultsLoading(false);
        });
    }, [filterType, lastWorkedBy])

    useEffect(() => {
        loaded.current = true;
    }, []);

    const handleOnExport = () => {
        setApiError(undefined);
        setExporting(true);
        exportReport({
            reportId: props.reportId
        }).then(r => {
            triggerDownloadOfUrl(r.downloadUrl);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setExporting(false);
        });
    };

    const getOptions = (clientId: string, casePlanId: string) => {
        return [
            {
                href: `/participants/${clientId}/profile-demographics`,
                label: 'Open Profile',
                newWindow: true
            },
            {
                href: `/participants/${clientId}/plans/${casePlanId}`,
                label: 'Open Plan',
                newWindow: true
            }
        ];
    };
    
    const handleScrollLeft = () => {
        scrollLeft('.report-results-table');
    };

    const handleScrollRight = () => {
        scrollRight('.report-results-table');
    };

    const getReportResults = () => {
        if (resultsLoading){
            return <Loader />
        }

        return (<>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">participant</th>
                        <th scope="col">plan</th>
                        <th scope="col">goal</th>
                        <th scope="col">status</th>
                        <th scope="col">achieve by</th>
                        <th scope="col">last worked by</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {results && results.length !== 0 && results.map(r => {
                        return (<tr>
                            <td>{r.clientFirstName} {r.clientLastName}</td>
                            <td>{r.casePlanName}</td>
                            <td><div className="max-width-200"><TruncatedText id={`goal-name-${r.goalId}`} value={r.goalName} maxLength={20} lengthVariance={5} /></div></td>
                            <td>{r.goalStatus}</td>
                            <td>{r.goalDueDate && formatJSONDateWithoutTime(r.goalDueDate, 'MM/dd/yyyy')}</td>
                            <td>{r.lastWorkedByFirstName} {r.lastWorkedByLastName}</td>
                            <td><Dropdown id={`due-goal-${r.clientId}`} options={getOptions(r.clientId, r.casePlanId)} /></td>
                        </tr>);
                    })}
                    {results && results.length === 0 && (<tr>
                        <td colSpan={7}>
                            No results found
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </>);
    };

    return <>
        <h3>Detail of In Progress Goals</h3>
        <ApiErrorMessages error={apiError} />
        {users && !usersLoading && <>
            <div className="row justify-content-between">
                <div className="column-auto">
                    <div className="row justify-content-between">
                        <div className="column-auto">
                            <IconButton id="scroll-left"
                                onClick={handleScrollLeft}
                                className="small flat"
                                icon={<i className="ri-arrow-left-s-line"></i>} />
                        </div>
                        <div className="column-auto">
                            <IconButton id="scroll-right"
                                onClick={handleScrollRight}
                                className="small flat"
                                icon={<i className="ri-arrow-right-s-line"></i>} />
                        </div>
                    </div>
                </div>
                <div className="column-auto">
                    <Button id="export-form-results"
                        text="Export Data"
                        onClick={handleOnExport}
                        loading={exporting}
                        disabled={exporting} />
                </div>
            </div>
            <div className="report-results-table">
                <div className="row justify-content-between">
                    <div className="column-large-4">
                        <Select id="last-worked-by" value={lastWorkedBy} label="Last Worked By" onChange={setLastWorkedBy}>
                            <option value="">Anyone</option>
                            {users.map(u => <option key={u.id} value={u.id}>{u.firstName} {u.lastName}</option>)}
                        </Select>
                    </div>
                    <div className="column-large-auto">
                        <div className="row margin-top-5">
                            <div className="col-auto">
                                <Button id="all-filter-type-button" className={getclassNames('small', filterType ? 'flat' : '')} onClick={() => setFilterType('')} text="All" />
                            </div>
                            <div className="col-auto">
                                <Button id="past-due-filter-type-button" className={getclassNames('small', filterType !== 'past_due' ? 'flat' : '')} onClick={() => setFilterType('past_due')} text="Past Due" />
                            </div>
                            <div className="col-auto">
                                <Button id="due-this-week-fitler-type-button" className={getclassNames('small', filterType !== 'due_this_week' ? 'flat' : '')} onClick={() => setFilterType('due_this_week')} text="Due This Week" />
                            </div>
                        </div>
                    </div>
                </div>
                {getReportResults()}
            </div>
            {paging && <Loader />}
            {results && results.length < totalCount && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
        </>}
    </>;
};

export default InProgressGoalsResults;