import axios from "axios";
import UnsubscribeRequest from "../models/serviceModels/UnsubscribeRequest";

const baseUrl = process.env.REACT_APP_API_BASE_URL;


const unsubscribe = async (request: UnsubscribeRequest): Promise<void> => {
    const result = await axios.post(`${baseUrl}/emails/unsubscribe`, {
        ...request
    });
    return result.data;
};

export {
    unsubscribe
}
