import { ACTIVE_DATE_RANGE, ACTIVITY_DATE_RANGE, AGE_AS_OF_SUMMARY, ASSESSMENT_FORM_SELECTION, CUSTOM_FILTER, ENROLL_DATE_RANGE, FORM_SELECTION, OUTPUTS_AND_OUTCOMES_FORM_SELECTION, PLAN_SCOPE, PROGRAM_NAME, REPORT_PERSPECTIVE } from "../../../constants/reportStep";
import ActiveDateRange from "./ActiveDateRange";
import ActivityDateRange from "./ActivityDateRange";
import AgeAsOfSummary from "./AgeAsOfSummary";
import AssessmentFormSelection from "./AssessmentFormSelection";
import CustomFilter from "./CustomFilter";
import EnrollDateRange from "./EnrollDateRange";
import FormSelection from "./FormSelection";
import OutputsAndOutcomesFormSelection from "./OutputsAndOutcomesFormSelection";
import PlanScope from "./PlanScope";
import ProgramSelection from "./ProgramSelection";
import ReportPerspective from "./ReportPerspective";
import ReportStepProps from "./ReportStepProps";

const mapping: { [key: string]: (props: ReportStepProps) => JSX.Element } = {
    [REPORT_PERSPECTIVE]: ReportPerspective,
    [ACTIVE_DATE_RANGE]: ActiveDateRange,
    [ACTIVITY_DATE_RANGE]: ActivityDateRange,
    [OUTPUTS_AND_OUTCOMES_FORM_SELECTION]: OutputsAndOutcomesFormSelection,
    [ENROLL_DATE_RANGE]: EnrollDateRange,
    [ASSESSMENT_FORM_SELECTION]: AssessmentFormSelection,
    [PROGRAM_NAME]: ProgramSelection,
    [PLAN_SCOPE]: PlanScope,
    [CUSTOM_FILTER]: CustomFilter,
    [FORM_SELECTION]: FormSelection,
    [AGE_AS_OF_SUMMARY]: AgeAsOfSummary
}

export {
    mapping
};