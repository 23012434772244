import React, { ForwardedRef, forwardRef } from 'react';
import getclassNames from '../../utils/getClassNames';
import './InputWithFloatingButtons.css';
import Input, { InputProps } from './Input';
import Button from './Button';

interface FloatingButton {
    text: string;
    icon?: any;
    iconLocation?: 'start' | 'end';
    className?: string;
    onClick: () => void;

}

export interface InputWithFloatingButtonsProps extends InputProps {
    buttons: FloatingButton[];
    type?: 'text'
}

const InputWithFloatingButtons = forwardRef((props: InputWithFloatingButtonsProps, ref?: ForwardedRef<any>) => {
    return (
        <div className="input-button-container">
            <Input
                ref={ref}
                type="text"
                id={`${props.id}-text`}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                label={props.label}
                labelClassName={props.labelClassName}
                inputClassName={props.inputClassName}
                placeholder={props.placeholder}
                disabled={props.disabled}
                required={props.required}
                maxLength={props.maxLength}
                useMaskedValue={props.useMaskedValue}
                onKeyUp={props.onKeyUp} />
            <div className="button-container">
                <div className="row">
                    {props.buttons.map((b, i) => {
                        return <div key={b.text} className="column-auto"><Button
                            id={`${props.id}-${i}-button`}
                            text={b.text}
                            onClick={b.onClick}
                            icon={b.icon}
                            iconLocation={b.iconLocation}
                            className={getclassNames(b.className || '', 'input-button small')} /></div>;
                    })}
                </div>
            </div>
        </div>);
});

export default InputWithFloatingButtons;