import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessages from "../components/UI/ErrorMessages";
import Loader from "../components/UI/Loader";
import { getOrganization } from "../services/organizationService";
import { MeContext, OrganizationContext } from "../App";


const LoggedInPage = () => {
    const [error, setError] = useState('');
    const me = useContext(MeContext);
    const organization = useContext(OrganizationContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (me.loading || organization.loading) {
            return;
        }
        
        if (me.error) {
            setError('Failed to login');
        } else {
            if (me.user?.deactivated) {
                navigate('/users/deactivated');
            } else {
                if (me.user) {
                    if (organization.error) {
                        navigate('/');
                    } else {
                        if (organization.value?.createdBy === me.user?.id && !organization.value?.tosAnswerOn) {
                            navigate('/tos');
                        } else {
                            navigate('/');
                        }
                    }
                } else {
                    navigate('/register');
                }
            }
        }
    }, [me, organization]);

    return (<div className="section">
        {error && <ErrorMessages messages={[error]} />}
        {(me.loading || organization.loading) && <Loader />}
    </div>)
};

export default LoggedInPage;