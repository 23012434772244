import ValidateResponse from "./ValidateResponse";

export const getAllValidationMessages = (response?: ValidateResponse) => {
    const messages: string[] = [];

    if (!response) {
        return messages;
    }

    for (const prop in response) {
        if (!response.hasOwnProperty(prop)) {
            continue;
        }

        const anyResponse = response as any;
        if (Array.isArray(anyResponse[prop])) {
            for (const message of anyResponse[prop]) {
                messages.push(message);
            }
        }
    }

    return messages;
};