import { FormEvent, useEffect, useMemo, useState, ChangeEvent } from "react";
import { ASSESSMENT_ID, DELIVERY_ID } from "../../constants/formTypes";
import Client from "../../models/serviceModels/Client";
import Form from "../../models/serviceModels/Form";
import Program from "../../models/serviceModels/Program";
import { searchClients } from "../../services/clientsService";
import { getEnrolledPrograms, getFormsByProgramId, searchPrograms } from "../../services/programsService";
import { formatJSONDateWithoutTime } from "../../utils/dateHelpers";
import Button from "./Button";
import ErrorMessages from "./ErrorMessages";
import Input from "./Input";
import Select from "./Select";
import './TrackingHeader.css';

interface TrackingHeaderProps {
    client: Client | undefined;
    onClientSelected: (c?: Client) => void;
    program: Program | undefined;
    onProgramSelected: (p?: Program) => void;
    form: Form | undefined;
    onFormSelected: (f?: Form) => void;
    title: string;
    type: string;
}

const TAKE = 10;

const TrackingHeader = (props: TrackingHeaderProps) => {
    const [query, setQuery] = useState('');
    const [searching, setSearching] = useState(false);
    const [results, setResults] = useState<Client[]>();
    const [error, setError] = useState('');
    const [programs, setPrograms] = useState<Program[]>([]);
    const [loadingPrograms, setLoadingPrograms] = useState(false);
    const [forms, setForms] = useState<Form[]>([]);
    const [loadingForms, setLoadingForms] = useState(false);

    const handleOnClientSelect = (client: Client) => {
        if (props.onClientSelected) {
            props.onClientSelected(client);
        }

        setResults(undefined);
        setQuery('');
    };

    const handleOnCancel = () => {
        if (props.onClientSelected) {
            props.onClientSelected(undefined);
        }
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setResults(undefined);
        setSearching(true);

        searchClients({ query, skip: 0, take: TAKE, programId: props.program?.id }).then(response => {
            setResults(response.data);
        }).catch(e => {
            setError(e);
        }).finally(() => {
            setSearching(false);
        });
    }

    useEffect(() => {
        const cancelSearch = () => {
            setResults(undefined);
        };

        document.addEventListener('click', cancelSearch);

        return () => {
            document.removeEventListener('click', cancelSearch);
        };
    }, []);

    useEffect(() => {
        setLoadingPrograms(true);
        setError('');
        searchPrograms({
            skip: 0,
            take: 1000
        }).then(p => {
            setPrograms([...p.data]);
            if (p.totalCount === 0) {
                setError('No programs found');
            }
        }).catch(e => {
            console.error(e);
            setError('Failed to load programs');
        }).finally(() => {
            setLoadingPrograms(false);
        });
    }, []);

    useEffect(() => {
        if (props.program && props.program.id) {
            setLoadingForms(true);
            setError('');

            getFormsByProgramId(props.program.id).then(forms => {
                const newForms = forms.filter(f => f.formTypeId === typeId);
                setForms([...forms.filter(f => f.formTypeId === typeId)]);
                if (newForms.length === 0) {
                    setError(`No ${typeLabel} forms assigned to program`);
                }
            }).catch(e => {
                console.error(e);
                setError('Failed to load forms');
            }).finally(() => {
                setLoadingForms(false);
            })
        } else {
            setForms([]);
            handleOnCancel();
            if (props.onFormSelected) {
                props.onFormSelected(undefined);
            }
        }
    }, [props.program]);

    const typeLabel = useMemo(() => {
        const lowerType = props.type.toLowerCase();
        return lowerType === 'delivery' ? 'Outputs and Outcomes' :
            lowerType === 'assessments' ? 'Assessment' :
                lowerType === 'plan' ? 'Plan' :
                    '';
    }, [props.type]);

    const typeId = useMemo(() => {
        const lowerType = props.type.toLowerCase();
        return lowerType === 'delivery' ? DELIVERY_ID :
            lowerType === 'assessments' ? ASSESSMENT_ID :
                0;
    }, [props.type]);

    const handleOnProgramChange = (e: string) => {
        const matchingProgram = programs.find(p => p.id === e);
        if (props.onProgramSelected) {
            props.onProgramSelected(matchingProgram);
        }
    };

    const handleOnFormChange = (e: string) => {
        const matchingForm = forms.find(f => f.id === e);
        if (props.onFormSelected) {
            props.onFormSelected(matchingForm);
        }
    };

    return (
        <>
            <h1>{props.title}</h1>
            <ErrorMessages messages={[error]} />
            <div className="row">
                <div className="column-medium-6">
                    <Select id="form-select" label="Program" value={props.program?.id || ''} onChange={handleOnProgramChange}>
                        <option value="">Select One</option>
                        {programs.map(p => {
                            return <option value={p.id} key={p.id}>{p.name}</option>
                        })}
                    </Select>
                </div>
                <div className="column-medium-6">
                    <Select id="form-select" label={typeLabel} value={props.form?.id || ''} onChange={handleOnFormChange} disabled={!props.program}>
                        <option value="">Select One</option>
                        {forms.map(f => {
                            return <option value={f.id} key={f.id}>{f.name}</option>
                        })}
                    </Select>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="margin-top-5">
                <div className="row align-items-center">
                    <div className="column">
                        <label htmlFor="search-participants">Participant Name</label>
                        <Input id="search-participants"
                            value={query}
                            placeholder="Search for a participant"
                            onChange={(e) => setQuery(e)}
                            disabled={!props.program}
                        />
                    </div>
                    <div className="column-auto align-self-end">
                        <Button id="search-button"
                            text="Search"
                            type="submit"
                            loading={searching}
                            disabled={searching || !props.program} />
                    </div>
                </div>
            </form>
            <div className="results-container" onClick={(e) => e.stopPropagation()}>
                <div className="floating-container">
                    <div className="list-item-container">
                        {results && results.length === 0 && <div className="list-item">
                            <div className="row justify-content-center">
                                <div className="column-auto">
                                    No results found
                                </div>
                            </div>
                        </div>}
                        {results && results.length > 0 && results.map(r => {
                            return (<div className="list-item" key={r.id}>
                                <div className="row justify-content-between align-items-center">
                                    <div className="column">
                                        <div className="row">
                                            <div className="column-medium-4 column-6">
                                                {r.firstName} {r.middleName} {r.lastName}
                                            </div>
                                            <div className="column-auto">
                                                {formatJSONDateWithoutTime(r.dateOfBirth, 'MM/dd/yyyy')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column-auto">
                                        <Button id="select-participant" text="Select" onClick={() => handleOnClientSelect(r)} />
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
            {props.client && <div className="list-item-container selected-result margin-top-5">
                <div className="list-item">
                    <div className="row justify-content-between align-items-center">
                        <div className="column">
                            <div className="row">
                                <div className="column-medium-4 column-6">
                                    {props.client.firstName} {props.client.middleName} {props.client.lastName}
                                </div>
                                <div className="column-auto">
                                    {formatJSONDateWithoutTime(props.client.dateOfBirth, 'MM/dd/yyyy')}
                                </div>
                            </div>
                        </div>
                        <div className="column-auto">
                            <Button id="cancelselected-participant" className="secondary" text="Cancel" onClick={handleOnCancel} />
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
};

export default TrackingHeader;