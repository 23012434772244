import { useEffect, useState } from "react";
import { Notification } from "../../models/serviceModels/Notification";
import { getNotifications } from "../../services/notificationsService";
import getclassNames from "../../utils/getClassNames";
import './Notifications.css';

const Notifications = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);

    useEffect(() => {
        getNotifications().then(n => {
            n = n.filter(no => {
                const daysMatch = no.message.match(/\d+(?=.*Days Left in Trial)/)
                if (daysMatch && daysMatch.length > 0 && !isNaN(Number(daysMatch[0])) && Number(daysMatch[0]) > 1000) {
                    return false;
                }

                return true;
            });

            if (n) {
                setNotifications(n);
            } else {
                setNotifications([]);
            }
        })
    }, []);

    const getNotificationClass = (notification: Notification) => {
        if (!notification) {
            return '';
        }

        return notification.level === 'error' ? 'notification-error' :
            notification.level === 'warning' ? 'notification-warning' :
                'notification-information';
    };

    const getNotificationsSubset = () => {
        const noticationsSubset = [];

        for (let i = 0; i < 2 && i < notifications.length; i++) {
            noticationsSubset.push(notifications[i]);
        }

        return notifications;
    };

    if (!notifications || notifications.length === 0) return null;

    return (<>
        {getNotificationsSubset().map(n => {
            return (<div className={getclassNames('notification', getNotificationClass(n))}>
                <div className="row justify-content-center align-items-center">
                    <div className="column-auto" dangerouslySetInnerHTML={{ __html: n.message }}>
                    </div>
                </div>
            </div>);
        })}
    </>
    );
};

export default Notifications;