import React, { useEffect, useContext } from "react";
import ParticipantSearch from "../components/Search/ParticipantSearch";
import Loader from "../components/UI/Loader";
import { useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import { MeContext, OrganizationContext } from "../App";

const LandingPage: React.FC = () => {
    const me = useContext(MeContext);
    const organization = useContext(OrganizationContext);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Participant Search"
    }, []);

    useEffect(() => {
        if (organization.loading || me.loading) {
            return;
        }

        if (!organization.value || !me.user) {
            console.error('No organization or user found');
            return;
        }

        if (!organization.value?.tosAnswerOn && me.user?.id === organization.value?.createdBy) {
            navigate('/admin/tos');
        }
    }, [organization, me])

    if (organization.loading || me.loading) {
        return <Loader />
    }

    return (
        <div className="section">
            <ApiErrorMessages error={organization.error} />
            <ApiErrorMessages error={me.error} />
            {!me.error && !organization.error && <ParticipantSearch />}
        </div>
    )
}

export default LandingPage;
//   <SearchResultsTable  />