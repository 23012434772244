const InvoicedPaymentDetails = () => {
    return (<><div className="row">
        <div className="column">
            <h3>Payment Details</h3>
        </div>
    </div>
        <div className="row">
            <div className="column">
                <div className="card">
                    <div className="card-container small">
                        <div>
                            <p>Your organization is setup to receive invoices.</p>
                            <p>If you would like to change your payment details please contact support</p>
                        </div>
                    </div>
                </div>
            </div>
        </div></>);
};

export default InvoicedPaymentDetails;