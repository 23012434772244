import { useMemo, useEffect, useState } from 'react';
import { PREMIUM_PLAN_TYPE_ID, STANDARD_PLAN_TYPE_ID } from '../../constants/planTypes';
import { getCardBrandName } from '../../mappers/cardBrandMapper';
import { OrganizationPlan } from '../../models/serviceModels/OrganizationPlan';
import { PaymentMethod } from '../../models/serviceModels/PaymentMethod';
import { getItem } from '../../services/cacheService';
import { getPaymentMethod, getPlan } from '../../services/paymentsService';
import { formatCurrency } from '../../utils/stringHelpers';
import ApiErrorMessages from './ApiErrorMessages';
import Button from './Button';
import Loader from './Loader';

interface CurrentPaymentDetailsProps {
    onAdd?: () => void;
    onUpdate?: (method: PaymentMethod) => void;
    onReplace?: (method: PaymentMethod) => void;
    onLoad?: (method: PaymentMethod) => void;
    allowModifiy?: boolean;
    submitting?: boolean;
    paymentMethod?: PaymentMethod;
}

const CurrentPaymentDetails = (props: CurrentPaymentDetailsProps) => {
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [paymentApiErrors, setPaymentApiErrors] = useState<any>();
    const [paymentLoading, setPaymentLoading] = useState(false);

    useEffect(() => {
        if (props.paymentMethod) {
            setPaymentMethod(props.paymentMethod);
            return;
        }

        setPaymentLoading(true);

        getPaymentMethod().then((method) => {
            setPaymentMethod(method);
            if (props.onLoad) {
                props.onLoad(method);
            }
        }).catch(e => {
            console.error(e);
            setPaymentApiErrors(e);
        }).finally(() => {
            setPaymentLoading(false);
        })
    }, [props.paymentMethod]);

    return (
        <>
            <div className="row">
                <div className="column">
                    <h3>Payment Details</h3>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <div className="card">
                        <div className="card-container small">
                            <ApiErrorMessages error={paymentApiErrors} />
                            {paymentLoading && <Loader />}
                            {paymentMethod && !paymentLoading && (<div>
                                <div>{paymentMethod.name} {paymentMethod.postalCode}</div>
                                <div>XXXX-XXX-XXXX-{paymentMethod.last4}</div>
                                <div>{paymentMethod.month.toString().padStart(2, '0')} / {paymentMethod.year}</div>
                                <div>{getCardBrandName(paymentMethod.brand)}</div>
                            </div>)}
                            {!paymentMethod && !paymentLoading && <div>
                                No payment details found
                            </div>}
                            {!paymentLoading && <div>
                                <div className="row justify-content-end">
                                    {!paymentMethod && props.onAdd && <div className="column-auto">
                                        <Button id="add-payment-details" text="Add" disabled={props.submitting && !paymentApiErrors} onClick={props.onAdd} />
                                    </div>}
                                    {paymentMethod && <>
                                        <div className="column-auto">
                                            {props.onUpdate && <Button id="update-payment-details" text="Update" disabled={props.submitting && !paymentApiErrors} className="small" onClick={() => props.onUpdate && props.onUpdate(paymentMethod)} />}
                                        </div>
                                        <div className="column-auto">
                                            {props.onReplace && <Button id="update-payment-details" text="Replace" disabled={props.submitting && !paymentApiErrors} className="small" onClick={() => props.onReplace && props.onReplace(paymentMethod)} />}
                                        </div>
                                    </>}
                                    {props.allowModifiy && (
                                        <div className="column-auto">
                                            <Button id="modify-payment-details" className="small" text="Modify Payment Details" href="/admin/billing/manage/payment-details" />
                                        </div>
                                    )}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CurrentPaymentDetails;