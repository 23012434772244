import axios from "axios";
import { Notification } from "../models/serviceModels/Notification";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getNotifications = async (): Promise<Notification[]> => {
    const response = await axios.get(`${baseUrl}/notifications`);
    return response.data;
};

export {
    getNotifications
}