import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DisplayFieldMapping } from '../components/Form/Builder/DisplayFields';
import { EditFormMapping } from '../components/Form/Builder/EditForms';
import BackButton from '../components/UI/BackButton';
import Button from '../components/UI/Button';
import ErrorMessages from '../components/UI/ErrorMessages';
import Loader from '../components/UI/Loader';
import Modal from '../components/UI/Modal';
import './Form.css';
import { Mapping } from '../constants/formTypes';
import { createFormField, deletedField, getForm, publishFormVersion, reorderField, updateField } from '../services/formsService';
import * as uuid from 'uuid';
import LoadingPlaceholder from '../components/UI/LoadingPlaceholder';
import FieldModel from '../models/pageModels/FieldModel';
import { mapFormFieldToFieldModel } from '../mappers/formFieldMapper';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import getclassNames from '../utils/getClassNames';
import { manageFormsEnabled } from '../utils/features';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ACTIVITY_DATE_ID, BREAK_LINE_ID, CHECKBOX_GROUP_ID, CHECKBOX_ID, DATE_ID, HEADER_ID, MULTI_LINE_TEXT_ID, NOTES_ID, NUMBER_ID, RADIO_BUTTON_ID, SELECT_LIST_ID, TEXT_ID, TIME_SPENT_ID } from '../constants/fieldTypes';
import { NEW_BREAKLINE, NEW_CHECKBOX, NEW_CHECKBOX_GROUP, NEW_DATE, NEW_HEADING, NEW_MULTI_TEXT, NEW_NOTES, NEW_NUMBER, NEW_RADIO, NEW_SELECT, NEW_TEXT, NEW_TIME_SPENT } from '../constants/draggableIds';
import IconButton from '../components/UI/IconButton';

const queryAttr = "data-rbd-drag-handle-draggable-id";

const FormV2 = () => {

    const navigate = useNavigate();
    const { versionId = '', id = '' } = useParams();
    const [form, setForm] = useState<any>(null);
    const [fields, setFields] = useState<FieldModel[]>([]);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [editingField, setEditingField] = useState<FieldModel>();
    const [deletingField, setDeletingField] = useState<FieldModel>();
    const [apiError, setApiError] = useState<any>();
    const [modalError, setModalError] = useState('');
    const [reordering, setReordering] = useState(false);
    const location = useLocation();
    const [controlsHidden, setControlsHidden] = useState<string[]>([]);

    useEffect(() => {
        document.title = "Build or Edit a Form"
    }, []);

    const handleOnSave = (field: FieldModel) => {
        if (!field.id) {
            return;
        }

        if (!field.name) {
            setModalError('Field Name (under Advanced) is required');
            return;
        }

        if (fields.find(f => f.name === field.name && f.id !== field.id)) {
            setModalError('A field with the same name already exists');
            return;
        }

        setSaving(true);

        updateField({
            fieldTypeId: field.fieldTypeId,
            label: field.label,
            order: field.order,
            options: field.options,
            name: field.name,
            id: field.id,
            versionId,
            formId: id,
            required: field.required,
            sortValues: field.sortValues || false
        }).then(uf => {
            setEditingField(undefined);

            const matchingExistingField = fields.find(f => f.id === uf.id);
            if (!matchingExistingField) {
                console.warn('No matching form field found to update');
                return;
            }

            const indexOfField = fields.indexOf(matchingExistingField);
            fields[indexOfField] = mapFormFieldToFieldModel(uf);

            setFields([
                ...fields
            ]);
        }).catch(e => {
            console.error(e);
            setModalError('Failed to update field');
        }).finally(() => {
            setSaving(false);
        });
    }

    const handlePublish = () => {
        setPublishing(true);

        publishFormVersion(id, versionId).then(() => {
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(manageFormsEnabled ? '/admin/forms' : '/forms', {
                    state: {
                        publishedForm: {
                            id,
                            name: form.name
                        }
                    }
                });
            }
        }).finally(() => {
            setPublishing(false);
        })
    };

    const handleOnDelete = () => {
        setModalError('');
        if (!deletingField?.id) {
            console.warn('No field selected for delete.  Unable to trigger delete');
            return;
        }

        setDeleting(true);
        deletedField({
            id: deletingField.id,
            formId: id,
            versionId
        }).then(() => {
            setFields([
                ...fields.filter(f => f.id !== deletingField.id)
            ]);
            setDeletingField(undefined);
        }).catch(e => {
            console.error(e);
            setModalError('Failed to delete field');
        }).finally(() => {
            setDeleting(false);
        });
    };

    const handleOnEdit = (field: FieldModel) => {
        setModalError('');
        setEditingField(field);
    };

    const handleSetOrder = (fieldId: string, order: number) => {
        const existingField = fields.find(ef => ef.id === fieldId);
        if (!existingField) {
            return;
        }

        setReordering(true);
        const newFields = [];
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            if ((order - 1) === i) {
                if (order < existingField.order) {
                    newFields.push(existingField);
                }

                newFields.push(field);

                if (order > existingField.order) {
                    newFields.push(existingField);
                }

            } else if (fieldId !== field.id) {
                newFields.push(field);
            }
        }

        for (let i = 0; i < newFields.length; i++) {
            newFields[i].order = i + 1;
        }

        setFields([...newFields]);

        reorderField({
            formId: id,
            versionId,
            fieldId,
            order
        }).then(updatedFields => {
            setFields([
                ...updatedFields.map(uf => mapFormFieldToFieldModel(uf))
            ]);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setReordering(false);
        })
    };

    const newFieldsDisabled = false;

    useEffect(() => {
        getForm(id, versionId).then(form => {
            setForm(form);
            setFields([
                ...form.fields.map(f => mapFormFieldToFieldModel(f))
            ]);
        });
    }, [id]);

    if (!form) {
        return (<div className="section">
            <Loader />
        </div>);
    }

    const addField = (fieldTypeId: number, order: number) => {
        setApiError(undefined);
        setModalError('');
        const trackingId = uuid.v4();

        // order isn't 0 indexed
        fields.splice(order - 1, 0, {
            id: '',
            label: 'Unnamed Field',
            name: '',
            order: 0,
            fieldTypeId,
            options: [],
            required: false,
            adding: true,
            addingId: trackingId
        });

        setFields([...fields]);
        createFormField({
            formId: id,
            versionId: versionId,
            fieldTypeId,
            label: 'Unnamed Field',
            order
        }).then(field => {
            setFields(f => {
                f = f.filter(f => f.addingId !== trackingId);
                f.splice(order - 1, 0, mapFormFieldToFieldModel(field));

                return f;
            });

            // go right into editing
            if (EditFormMapping[field.fieldTypeId]) {
                setEditingField(mapFormFieldToFieldModel(field));
            }
        }).catch(e => {
            console.error(e);
            setApiError(e);
        });
    };

    const getField = (field: FieldModel) => {
        const MappedField = DisplayFieldMapping[field.fieldTypeId];
        return MappedField && <MappedField
            hideButtons={false}
            disabled={!form.draft}
            field={field}
            sortValues={field.sortValues}
            showAdvanced={false}
            onDelete={setDeletingField}
            onEdit={handleOnEdit} />;
    };

    const getEditForm = (field: FieldModel) => {
        const MappedForm = EditFormMapping[field.fieldTypeId];

        return (MappedForm &&
            <MappedForm
                field={field}
                onCancel={() => setEditingField(undefined)}
                onSave={handleOnSave}
                saving={saving} />);
    };

    const handleOnDragEnd = (result: any) => {
        const { source, destination, draggableId } = result;
        // make sure there is a destination (picked up and dropped outside range)
        // They droppining into the same location in the fields
        // Tried to drop into controls area (no sorry)

        if (!destination ||
            (source.droppableId === '/fields' && destination.droppableId === '/fields' && destination.index === source.index) ||
            destination.droppableId === '/controls') {
            return;
        }

        if (destination.droppableId === '/fields') {
            if (source.droppableId === '/controls') {
                let fieldId = null;
                switch (draggableId) {
                    case NEW_TEXT:
                        fieldId = TEXT_ID;
                        break;
                    case NEW_HEADING:
                        fieldId = HEADER_ID;
                        break;
                    case NEW_CHECKBOX:
                        fieldId = CHECKBOX_ID;
                        break;
                    case NEW_CHECKBOX_GROUP:
                        fieldId = CHECKBOX_GROUP_ID;
                        break;
                    case NEW_SELECT:
                        fieldId = SELECT_LIST_ID;
                        break;
                    case NEW_MULTI_TEXT:
                        fieldId = MULTI_LINE_TEXT_ID;
                        break;
                    case NEW_NOTES:
                        fieldId = NOTES_ID;
                        break;
                    case NEW_BREAKLINE:
                        fieldId = BREAK_LINE_ID;
                        break;
                    case NEW_RADIO:
                        fieldId = RADIO_BUTTON_ID;
                        break;
                    case NEW_TIME_SPENT:
                        fieldId = TIME_SPENT_ID;
                        break;
                    case NEW_NUMBER:
                        fieldId = NUMBER_ID;
                        break;
                    case NEW_DATE:
                        fieldId = DATE_ID;
                        break;
                }

                if (!fieldId) {
                    console.warn('Unable to add field. Field Type is unknown', draggableId);
                    return;
                }

                addField(fieldId, destination.index + 1);
                // they are wanting to add a new field at index destination.index
            } else if (source.droppableId === '/fields') {
                if (source.index > (fields.length - 1)) {
                    console.warn(`Unable to move field at index ${source.index}. Not enough fields found`);
                    return;
                }

                const sourceField = fields[source.index];

                handleSetOrder(sourceField.id, destination.index + 1);
            }
        }
    };


    const handleHideShowControls = (type: 'Styling' | 'Categorical' | 'Special' | 'Textual') => {
        if (controlsHidden.includes(type)) {
            setControlsHidden(controlsHidden.filter(ch => ch !== type));
        } else {
            setControlsHidden([
                ...controlsHidden,
                type
            ]);
        }
    }

    const getPlaceHolderHeight = (fieldTypeId: number) => {
        switch (fieldTypeId) {            
            case NOTES_ID:
                return 's-4';
            case MULTI_LINE_TEXT_ID:
                return 's-3';
            case TEXT_ID:
            case DATE_ID: 
            case NUMBER_ID:
            case HEADER_ID:
            case ACTIVITY_DATE_ID:
            case TIME_SPENT_ID:     
            case SELECT_LIST_ID: 
            case CHECKBOX_ID:  
                return 's-2';      
            case RADIO_BUTTON_ID:
            case CHECKBOX_GROUP_ID:   
                return 's-1';
            default:
                return '';
        }
    }

    return (<section id="main-content" tabIndex={-1}>
        <div className="section">
            <h1>Form Builder</h1>
            <div className={getclassNames('row margin-vertical-5 justify-content-between', !manageFormsEnabled ? 'sticky top-75' : '')}>
                <div className="column-medium-3">
                    <BackButton />
                </div>
                <div className="column-auto">
                    <div className="row align-items-center">
                        <div className="column-auto">
                            {reordering && <Loader noMarginTop={true} />}
                        </div>
                        <div className="column-auto">
                            {form.draft && <Button id="publish-button" className="small" onClick={handlePublish} text="Publish" loading={publishing} disabled={publishing} />}
                        </div>
                    </div>
                </div>
            </div>
            <ApiErrorMessages error={apiError} />
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <div className="row">
                    <div className="column-medium-4 sticky top-100">
                        <div className="row justify-content-center">
                            <div className="column-auto">
                                <h2>Field Options</h2>
                            </div>
                        </div>
                        <Droppable droppableId="/controls">
                            {(droppableProvided) =>
                                <div ref={droppableProvided.innerRef} className="controls-container">
                                    <h3><IconButton id={`hide-show-controls-styling`}
                                        onClick={() => handleHideShowControls('Styling')}
                                        className="small flat margin-end-5"
                                        icon={controlsHidden.includes('Styling') ? <i className="ri-arrow-down-s-line"></i> : <i className="ri-arrow-up-s-line"></i>} />Styling</h3>
                                    {!controlsHidden.includes('Styling') && <>
                                        <div className="row">
                                            <div className="column-large-6">
                                                <Draggable draggableId={NEW_HEADING} index={0}>
                                                    {(providedDraggable) =>
                                                    (<div className="control-item"
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <i className="ri-heading margin-start-1"></i>Heading
                                                    </div>)}
                                                </Draggable>
                                            </div>
                                            <div className="column-large-6">
                                                <Draggable draggableId={NEW_BREAKLINE} index={1}>
                                                    {(providedDraggable) =>
                                                    (<div className="control-item"
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <i className="ri-separator margin-start-1"></i>New Line
                                                    </div>
                                                    )}
                                                </Draggable>
                                            </div>
                                        </div>
                                    </>}
                                    <h3><IconButton id={`hide-show-controls-textual`}
                                        onClick={() => handleHideShowControls('Textual')}
                                        className="small flat margin-end-5"
                                        icon={controlsHidden.includes('Textual') ? <i className="ri-arrow-down-s-line"></i> : <i className="ri-arrow-up-s-line"></i>} />Textual</h3>
                                    {!controlsHidden.includes('Textual') && <>
                                        <div className="row">
                                            <div className="column-large-6">
                                                <Draggable draggableId={NEW_TEXT} index={2}>
                                                    {(providedDraggable) =>
                                                    (<div className="control-item"
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <i className="ri-text-snippet margin-start-1"></i>Text
                                                    </div>)}
                                                </Draggable>
                                            </div>
                                            <div className="column-large-6">

                                                <Draggable draggableId={NEW_MULTI_TEXT} index={3}>
                                                    {(providedDraggable) =>
                                                    (<div className="control-item"
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <i className="ri-text-block margin-start-1"></i>Mult-line Text

                                                    </div>)}
                                                </Draggable>
                                            </div>
                                            <div className="column-large-6">
                                                <Draggable draggableId={NEW_NUMBER} index={4}>
                                                    {(providedDraggable) =>
                                                    (<div className="control-item"
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}>
                                                        <span className="margin-start-1">12345</span>Number

                                                    </div>)}
                                                </Draggable>
                                            </div>
                                        </div>
                                    </>}
                                    <h3><IconButton id={`hide-show-controls-categorical`}
                                        onClick={() => handleHideShowControls('Categorical')}
                                        className="small flat margin-end-5"
                                        icon={controlsHidden.includes('Categorical') ? <i className="ri-arrow-down-s-line"></i> : <i className="ri-arrow-up-s-line"></i>} />Categorical</h3>
                                    {!controlsHidden.includes('Categorical') &&
                                        <>
                                            <div className="row">
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_RADIO} index={5}>
                                                        {(providedDraggable) =>
                                                        (<div className="control-item"
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}>
                                                            <i className="ri-radio-button-line margin-start-1"></i>Radio Button
                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_SELECT} index={6}>
                                                        {(providedDraggable) =>
                                                        (<div className="control-item"
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}><i className="ri-dropdown-list margin-start-1"></i>Select List

                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_CHECKBOX} index={7}>
                                                        {(providedDraggable) =>
                                                        (<div className="control-item"
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}><i className="ri-checkbox-line margin-start-1"></i>Checkbox

                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_CHECKBOX_GROUP} index={8}>
                                                        {(providedDraggable) =>
                                                        (<div className="control-item"
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}><i className="ri-checkbox-multiple-line margin-start-1"></i>Checkbox Group

                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_DATE} index={9}>
                                                        {(providedDraggable) =>
                                                        (<div className="control-item"
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}><i className="ri-calendar-line margin-start-1"></i>Date

                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                            </div>
                                        </>}
                                    <h3><IconButton id={`hide-show-controls-special`}
                                        onClick={() => handleHideShowControls('Special')}
                                        className="small flat margin-end-5"
                                        icon={controlsHidden.includes('Special') ? <i className="ri-arrow-down-s-line"></i> : <i className="ri-arrow-up-s-line"></i>} />Special</h3>
                                    {!controlsHidden.includes('Special') &&
                                        <>
                                            <div className="row margin-bottom-3">
                                                <div className="column text-sub">
                                                    Only one Time Spent and Notes field are allowed to be added per form
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_NOTES} index={10} isDragDisabled={fields.some(f => f.fieldTypeId === NOTES_ID)}>
                                                        {(providedDraggable) =>
                                                        (<div className={getclassNames('control-item', fields.some(f => f.fieldTypeId === NOTES_ID) ? 'disabled' : '')}
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}><i className="ri-sticky-note-line margin-start-1"></i>Notes

                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                                <div className="column-large-6">
                                                    <Draggable draggableId={NEW_TIME_SPENT} index={11} isDragDisabled={fields.some(f => f.fieldTypeId === TIME_SPENT_ID)}>
                                                        {(providedDraggable) =>
                                                        (<div className={getclassNames('control-item', fields.some(f => f.fieldTypeId === TIME_SPENT_ID) ? 'disabled' : '')}
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}>
                                                            <i className="ri-time-line margin-start-1"></i>
                                                            Time Spent
                                                        </div>)}
                                                    </Draggable>
                                                </div>
                                            </div>
                                        </>}
                                    {droppableProvided.placeholder}
                                </div>
                            }
                        </Droppable>
                    </div>
                    <div className="column-medium">
                        <div className="row justify-content-center">
                            <div className="column-auto">
                                <h2>Form</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="column-auto">
                                <h3 className="form-title">{form.name} - {Mapping[form.formTypeId]}</h3>
                            </div>
                        </div>
                        <div className="form-main">
                            {newFieldsDisabled && <div className="text-bold">Only one field is allowed on an Outputs and Outcomes Form</div>}
                            <Droppable droppableId={`/fields`}>
                                {droppableProvided =>
                                (<div className={getclassNames('fields-container', !fields || fields.length === 0 ? 'empty' : '')} ref={droppableProvided.innerRef}>
                                    {(!fields || fields.length === 0) && <div className="draggable-area-directions">Drag a field over to begin building your form</div>}
                                    {fields && fields.length > 0 && (
                                        fields.map((f, i) => {
                                            if (f.adding && f.addingId) {
                                                const DisplayField = DisplayFieldMapping[f.fieldTypeId];
                                                const dummyField = {
                                                    id: '',
                                                    label: 'Unnamed Field',
                                                    name: '',
                                                    order: 0,
                                                    formId: '',
                                                    fieldTypeId: f.fieldTypeId,
                                                    options: [],
                                                    required: false
                                                };
                                                return (<LoadingPlaceholder id={f.addingId} key={f.addingId} className={getPlaceHolderHeight(f.fieldTypeId)}>
                                                    <DisplayField hideButtons={true}
                                                        disabled={true}
                                                        showAdvanced={false}
                                                        field={dummyField}
                                                        onDelete={() => { }}
                                                        onEdit={() => { }} />
                                                </LoadingPlaceholder>)
                                            }

                                            const field = getField(f);
                                            if (!field) {
                                                return null;
                                            }
                                            return (<React.Fragment key={f.id}>
                                                <Draggable draggableId={`/fields/${f.id}`} index={i}>
                                                    {(draggableProvided) => (<div className="field-container"
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}>
                                                        <div className="row align-items-center">
                                                            <div className="column">
                                                                {field}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )}
                                                </Draggable>

                                            </React.Fragment>);
                                        })
                                    )}
                                    {droppableProvided.placeholder}
                                </div>)}
                            </Droppable>
                        </div>
                    </div>
                </div>
            </DragDropContext>
            <Modal id="edit-modal" visible={editingField !== undefined} className="medium" title="Edit Field" onClose={() => setEditingField(undefined)}>
                {editingField &&
                    <>
                        {modalError && <ErrorMessages messages={[modalError]} />}
                        {getEditForm(editingField)}
                    </>
                }
            </Modal>
            <Modal id="delete-modal" visible={deletingField !== undefined} className="small" title="Delete Field" onClose={() => setDeletingField(undefined)}>
                {deletingField &&
                    <>
                        {modalError && <ErrorMessages messages={[modalError]} />}
                        <div className="row">
                            <div className="column">
                                Are you sure you want to delete this field?
                                <br />
                                "{deletingField.label}"
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-delete" text="Cancel" className="secondary" disabled={deleting} onClick={() => setDeletingField(undefined)} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-delete" text="Delete" className="warning" disabled={deleting} loading={deleting} onClick={handleOnDelete} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </div>
    </section>)
};

export default FormV2;