import ValidateResponse from "./ValidateResponse";

export default interface AdditionalInformationValidationResponse extends ValidateResponse {
    field: string[];
    value: string[];
    to: string[];
    from: string[];
    fromAndTo: string[];
}

export const defaultValue = (): AdditionalInformationValidationResponse => {
    return  {
        value: [],
        operator: [],
        from: [],
        to: [],
        fromAndTo: [],
        field: [],
        isValid: true,
        default: []
    };
}