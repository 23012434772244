import Loader from "../components/UI/Loader";
import ErrorMessages from "../components/UI/ErrorMessages";
import getclassNames from "../utils/getClassNames";
import useQuotas from "../hooks/useQuotas";
import { useEffect } from "react";

const ManageUsage = () => {
    const { loading, failedToLoad, metrics, limits } = useQuotas();
    useEffect(() => {
        document.title = 'Organization Usage';
    }, []);

    return (<section id="main-content" tabIndex={-1}>
        {loading && <Loader />}
        {!loading && (
            <>
                <h1>Organization Usage</h1>
                <div className="row justify-content-center margin-vertical-2">
                    <div className="column-medium">
                        {failedToLoad && <ErrorMessages messages={['Failed to load usage']} />}
                        {metrics && limits && (<>
                            <div className="row">
                                <div className="column-medium-6 border rounded padding-3">
                                    <div className="row">
                                        <div className="column">
                                            <h2>Overall Usage</h2>
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total Form Data
                                        </div>
                                        {limits.maxFormDataOverall !== undefined && <div className="column">
                                            {metrics.totalFormDataUsage.toFixed(2)} MB / {(limits.maxFormDataOverall === -1 ? 'Unlimited' : limits.maxFormDataOverall)} MB
                                        </div>}
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total File Data
                                        </div>
                                        {limits.maxFileDataOverall !== undefined && <div className="column">
                                            {metrics.totalFileDataUsage.toFixed(2)} MB / {(limits.maxFileDataOverall === -1 ? 'Unlimited' : limits.maxFileDataOverall)} MB
                                        </div>}
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Forms Created
                                        </div>
                                        <div className="column">
                                            {metrics.totalFormCounts} / {(limits.maxForms === -1 ? 'Unlimited' : limits.maxForms)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Total Submissions
                                        </div>
                                        <div className="column">
                                            {metrics.totalSubmissions} / {(limits.maxRecords === -1 ? 'Unlimited' : limits.maxRecords)}
                                        </div>
                                    </div>
                                    <div className="row margin-vertical-2">
                                        <div className="column">
                                            Submitted Reports Today
                                        </div>
                                        <div className="column">
                                            {metrics.submittedReportsCount} / {(limits.maxReportRunsPerDay === -1 ? 'Unlimited' : limits.maxReportRunsPerDay)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column-medium-6">
                                    <h2>Submitted Reports Today</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">user</th>
                                                <th scope="col">submissions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {metrics.userSubmittedReports && metrics.userSubmittedReports.map((sr, i: number) => {
                                                return (
                                                    <tr key={i} className={getclassNames('table-row', i % 2 === 0 ? 'even-row' : '')}>
                                                        <td>{sr.name}</td>
                                                        <td>{sr.total}</td>
                                                    </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="column-medium-6">
                                    <h2>Data Usage</h2>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">user</th>
                                                <th scope="col">total form data</th>
                                                <th scope="col">total file data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {metrics.userDataUsage && metrics.userDataUsage.map((sr, i: number) => {
                                                return (<tr key={i} className={getclassNames('table-row', i % 2 === 0 ? 'even-row' : '')}>
                                                    <td>{sr.name}</td>
                                                    <td>{sr.totalFormData.toFixed(2)} MB</td>
                                                    <td>{sr.totalFileData.toFixed(2)} MB</td>
                                                </tr>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>)}
                    </div>
                </div>
            </>)
        }
    </section>);
};

export default ManageUsage;