import { useEffect, useMemo, useState } from 'react';
import { OrganizationPlan } from '../models/serviceModels/OrganizationPlan';
import UsageMetrics from '../models/serviceModels/UsageMetrics';
import { SubmittedReportResult } from '../models/serviceModels/SubmittedReportResult';
import { getSubmittedReports } from '../services/reportService';
import { addDays, addSeconds } from 'date-fns';
import { getUsageMetrics } from '../services/quotaService';
import { getPlan } from '../services/paymentsService';
import { QuotasModel, UserDataBreakdown } from '../models/pageModels/QuotasModel';
import { searchUsers } from '../services/userService';

const useQuotas = (): QuotasModel => {
    const [organizationPlan, setOrganizationPlan] = useState<OrganizationPlan>();
    const [usageMetrics, setUsageMetrics] = useState<UsageMetrics[]>();
    const [submittedReports, setSubmittedReports] = useState<SubmittedReportResult[]>();
    const [usersCount, setUsersCount] = useState<number>();
    const [loading, setLoading] = useState(false);
    const [failedToLoad, setFailedToLoad] = useState(false);

    useEffect(() => {
        const currentDate = new Date()
        const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const toDate = addSeconds(addDays(fromDate, 1), -1);

        setLoading(true);
        setFailedToLoad(false);
        Promise.all([getUsageMetrics(), getPlan(), getSubmittedReports(fromDate, toDate), searchUsers('', 0, 1000)]).then(([m, p, r, u]) => {
            setUsageMetrics(m);
            setOrganizationPlan(p);
            setSubmittedReports(r);
            setUsersCount(u.totalCount);
        }).catch(e => {
            console.error(e);
            setFailedToLoad(true);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const userSubmittedReports = useMemo(() => {
        const counts: { id: string, name: string, total: number }[] = [];
        if (!submittedReports) {
            return counts;
        }

        for (const report of submittedReports) {
            const existing = counts.find(c => c.id === report.createdBy);
            if (!existing) {
                counts.push({
                    id: report.createdBy,
                    name: `${report.createdByFirstName} ${report.createdByLastName}`,
                    total: 1
                });
            } else {
                existing.total++;
            }
        }

        return counts;
    }, [submittedReports]);

    
    const totalFormDataUsage = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        const totalClientSize = usageMetrics.reduce((pv, cv) => pv + Number(cv.clientSizeBytes), 0);
        const totalFormSize = usageMetrics.reduce((pv, cv) => pv + Number(cv.formSizeBytes), 0);
        const totalHouseholdMemberSize = usageMetrics.reduce((pv, cv) => pv + Number(cv.householdMemberSizeBytes), 0);

        return (totalClientSize + totalFormSize + totalHouseholdMemberSize) / 1024.0 / 1024;
    }, [usageMetrics]);

    
    const totalFileDataUsage = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        const totalFileSize = usageMetrics.reduce((pv, cv) => pv + Number(cv.fileDataSizeBytes), 0);

        return totalFileSize / 1024.0 / 1024;
    }, [usageMetrics]);

    const totalFormCounts = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        return usageMetrics.reduce((pv, cv) => pv + Number(cv.formCounts), 0);
    }, [usageMetrics]);

    const totalSubmissions = useMemo(() => {
        if (!usageMetrics) {
            return 0;
        }

        return usageMetrics.reduce((pv, cv) => pv + Number(cv.formInstanceCounts), 0);
    }, [usageMetrics]);

    const submittedReportsCount = useMemo(() => {
        return submittedReports?.reduce((pv) => pv + 1, 0);
    }, [submittedReports]);

    const userDataUsage = useMemo(() => {
        const totalData: UserDataBreakdown[] = [];
        if (!usageMetrics) {
            return totalData;
        }

        for (const metric of usageMetrics) {
            const existing = totalData.find(c => c.id === metric.userId);
            const currentFormData = (Number(metric.clientSizeBytes) + Number(metric.formSizeBytes) + Number(metric.householdMemberSizeBytes)) / 1024.0 / 1024;
            const currentFileData = Number(metric.fileDataSizeBytes) / 1024.0 / 1024;

            if (!existing) {
                totalData.push({
                    id: metric.userId,
                    name: `${metric.userFirstName} ${metric.userLastName}`,
                    totalFormData: currentFormData,
                    totalFileData: currentFileData
                });
            } else {
                existing.totalFileData += currentFileData;               
                existing.totalFormData += currentFormData;
            }
        }

        return totalData;
    }, [usageMetrics]);

    const atOrBeyondReportLimit = useMemo(() => {
        if (usageMetrics && organizationPlan && submittedReportsCount) {
            const beyondSubmissions = organizationPlan.maxReportRunsPerDay !== -1 && submittedReportsCount >= organizationPlan.maxReportRunsPerDay;
            return beyondSubmissions;
        }

        return false;
    }, [usageMetrics, organizationPlan, submittedReportsCount]);

    const atOrBeyondFormDataLimit = useMemo(() => {
        if (usageMetrics && organizationPlan && usersCount) {
            const beyondMaxRecords = organizationPlan.maxRecords !== -1 && totalSubmissions >= organizationPlan.maxRecords;
            const beyondLimitPerUser = organizationPlan.maxFormDataPerUser !== -1 && totalFormDataUsage >= (organizationPlan.maxFormDataPerUser * usersCount);
            const beyondMaxFormData = organizationPlan.maxFormData !== -1 && totalFormDataUsage >= organizationPlan.maxFormData;

            return beyondMaxRecords || beyondLimitPerUser || beyondMaxFormData;
        }

        return false;
    }, [usageMetrics, organizationPlan, usersCount, totalFormDataUsage, totalSubmissions]);
    
    const atOrBeyondFileDataLimit = useMemo(() => {
        if (usageMetrics && organizationPlan && usersCount) {
            const beyondMaxFileData = organizationPlan.maxFileData !== -1 && totalFileDataUsage >= organizationPlan.maxFileData;
            const beyondFileLimitPerUser = organizationPlan.maxFileDataPerUser !== -1 && totalFileDataUsage >= (organizationPlan.maxFileDataPerUser * usersCount)

            return beyondFileLimitPerUser || beyondMaxFileData;
        }

        return false;
    }, [usageMetrics, organizationPlan, usersCount, totalFileDataUsage]);

    const maxFormDataOverall = useMemo(() => {
        if (usageMetrics && organizationPlan && usersCount) {
            if (organizationPlan.maxFormData === -1) {
                if (organizationPlan.maxFormDataPerUser === -1) {
                    return -1;
                }
                
                return organizationPlan.maxFormDataPerUser * usersCount;
            }

            return organizationPlan.maxFormData;
        }

        return undefined;
    }, [usageMetrics, organizationPlan, usersCount]);

    const maxFileDataOverall = useMemo(() => {
        if (usageMetrics && organizationPlan && usersCount) {
            if (organizationPlan.maxFileData === -1) {
                if (organizationPlan.maxFileDataPerUser === -1) {
                    return -1;
                }
                
                return organizationPlan.maxFileDataPerUser * usersCount;
            }

            return organizationPlan.maxFileData;
        }

        return undefined;
    }, [usageMetrics, organizationPlan, usersCount]);

    const atOrBeyondFormLimit = useMemo(() => {
        if (usageMetrics && organizationPlan) {
            const beyondLimit = organizationPlan.maxForms !== -1 && totalFormCounts >= organizationPlan.maxForms;
            return beyondLimit;
        }

        return false;
    }, [usageMetrics, organizationPlan, totalFormCounts]);

    const handleRefresh = () => {
        const currentDate = new Date()
        const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        const toDate = addSeconds(addDays(fromDate, 1), -1);

        Promise.all([getUsageMetrics(), getSubmittedReports(fromDate, toDate), searchUsers('', 0, 1000)]).then(([m, r, u]) => {
            setUsageMetrics(m);
            setSubmittedReports(r);
            setUsersCount(u.totalCount);
        }).catch(e => {
            console.error(e);
        });
    };

    return {
        loading, 
        failedToLoad,
        refresh: handleRefresh,
        metrics: {
            userDataUsage,
            submittedReportsCount,
            totalSubmissions,
            totalFormCounts,
            totalFileDataUsage,
            totalFormDataUsage,
            userSubmittedReports
        },
        limits: organizationPlan && {
            maxFormData: organizationPlan.maxFormData,
            maxFileData: organizationPlan.maxFileData,
            maxForms: organizationPlan.maxForms,
            maxRecords: organizationPlan.maxRecords,
            maxReportRunsPerDay: organizationPlan.maxReportRunsPerDay,
            maxFormDataPerUser: organizationPlan.maxFormDataPerUser,
            maxFileDataPerUser: organizationPlan.maxFileDataPerUser,
            maxFormDataOverall,
            maxFileDataOverall,
            atOrBeyondFileDataLimit,
            atOrBeyondFormDataLimit,
            atOrBeyondFormLimit,
            atOrBeyondReportLimit
        }
    }
};

export default useQuotas;