import { useEffect, useState } from "react";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Checkbox from "../components/UI/Checkbox";
import Loader from "../components/UI/Loader";
import { searchPrograms } from "../services/programsService";

const TAKE = 1000;

const ManagePrograms = () => {
    const [loading, setLoading] = useState(true);
    const [programs, setPrograms] = useState<any>(null);
    const [includeDeactivated, setIncludeDeactivated] = useState<boolean>(false);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        document.title = "Manage Programs"
    }, []);

    useEffect(() => {
        setLoading(true);
        searchPrograms({ query: '', skip: 0, take: TAKE, includeDeactivated }).then(({ data }) => {
            data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            setPrograms(data);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, [includeDeactivated]);

    const getResults = () => {
        return (<div className="row margin-vertical-2 justify-content-center">
            <div className="column-medium">
                <table className="table">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {programs.map((p: any) => {
                            return (<tr key={p.id} className="table-row">
                                <td>{p.name}</td>
                                <td>{!p.deactivated ? 'Yes' : <span className="text-danger">No</span>}</td>
                                <td>
                                    <div className="row justify-content-end">
                                        <div className="column-auto">
                                            <Button text="Manage Forms" className="small" id={`manage-forms-button-${p.id}`} href={`/admin/programs/${p.id}/forms`} />
                                        </div>
                                        <div className="column-auto">
                                            <Button text="Edit" className="small" id={`edit-button-${p.id}`} href={`/admin/programs/${p.id}/edit`} />
                                        </div>
                                    </div>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>);
    };

    const getMainContent = () => {
        return (<section id="main-content" tabIndex={-1}>
            {loading && <Loader />}
            {!loading && (
                <>
                    <h1>Manage Programs</h1>
                    <div className="row justify-content-center margin-vertical-2">
                        <div className="column-medium">
                            <ApiErrorMessages error={apiError} />
                            <div className="row justify-content-between margin-vertical-4">
                                <div className="column-auto">
                                    <Checkbox id="include-deactivated" checked={includeDeactivated} containerClassName="margin-top-0 unboxed" label="Show Deactivated" onChange={setIncludeDeactivated} />
                                </div>
                                <div className="column-auto">
                                    <Button id="create-user-button" text="Create Program" href="/admin/programs/create" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {programs && programs.length > 0 && getResults()}
                    {programs && programs.length === 0 && <div className="row justify-content-center margin-vertical-2">
                        <div className="column-auto">
                            <p>No Programs Found</p>
                        </div>
                    </div>}
                </>
            )}
        </section>)
    };

    return getMainContent();
};

export default ManagePrograms;