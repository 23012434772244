import {defaultValue} from "./DateValidateResponse";
import { ValidateRequest } from "./ValidateRequest";
import ValidateResponse from "./ValidateResponse";
import * as filterOperators from "../../../constants/filterOperator";
import { isMatch, parse } from "date-fns";

const validate = (request: ValidateRequest): Promise<ValidateResponse> => {
    const response = defaultValue();

    let parsedValue: { from?: string, to?: string, value?: string };

    try {
        parsedValue = JSON.parse(request.value);
    } catch (e) {
        console.error('Failed to parse date filter value', e);
        response.isValid = false;
        response.default.push('An error occurred.  Contact support for assistance.');
        return Promise.resolve(response);
    }

    if (!request.operator) {
        response.operator.push('Operation is required');
        response.isValid = false;
    }
       
    if (response.default.length === 0 && response.operator.length === 0) {
        if (request.operator === filterOperators.BETWEEN) {
            if (!parsedValue!.from) {
                response.from.push('From is required');
                response.isValid = false;
            }

            if (!parsedValue!.to) {
                response.to.push('To is required');
                response.isValid = false;
            }

            if (response.from.length === 0 && response.to.length === 0) {
                console.log('parsedValue', parsedValue);
                if (!isMatch(parsedValue!.from!, 'yyyy-MM-dd')) {
                    response.isValid = false;
                    response.from.push('From must be a valid date');
                }

                if (!isMatch(parsedValue!.to!, 'yyyy-MM-dd')) {
                    response.isValid = false;
                    response.to.push('To must be a valid date');
                }
            }

            if (response.from.length === 0 && response.to.length === 0) {
                const parsedFrom = parse(parsedValue!.from!, 'yyyy-MM-dd', new Date());
                const parsedTo = parse(parsedValue!.to!, 'yyyy-MM-dd', new Date());

                if (parsedFrom > parsedTo) {
                    response.isValid = false;
                    response.default.push('From must be less than or equal to To');
                }
            }
        } else {
            if (!parsedValue!.value) {
                response.isValid = false;
                response.value.push('Value is required');
            } else if(!isMatch(parsedValue!.value, 'yyyy-MM-dd')) {
                response.isValid = false;
                response.value.push('Value must be a valid date');
            }
        }
    }

    return Promise.resolve(response);
};

export default validate;