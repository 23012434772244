const NEW_HEADING = '/controls/new-heading';
const NEW_BREAKLINE = '/controls/new-breakline';
const NEW_TEXT = '/controls/new-text';
const NEW_MULTI_TEXT = '/controls/new-multi-text';
const NEW_NUMBER = '/controls/new-number';
const NEW_RADIO = '/controls/new-radio';
const NEW_SELECT = '/controls/new-select';
const NEW_CHECKBOX = '/controls/new-checkbox';
const NEW_CHECKBOX_GROUP = '/controls/new-checkbox-group';
const NEW_DATE = '/controls/new-date';
const NEW_NOTES = '/controls/new-notes';                                    
const NEW_TIME_SPENT = '/controls/new-time-spent';
                                
export {
    NEW_HEADING,
    NEW_BREAKLINE,
    NEW_TEXT,
    NEW_MULTI_TEXT,
    NEW_NUMBER,
    NEW_RADIO,
    NEW_SELECT,
    NEW_CHECKBOX,
    NEW_CHECKBOX_GROUP,
    NEW_DATE,
    NEW_NOTES,
    NEW_TIME_SPENT
}