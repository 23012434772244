const EQUALS = 1;
const CONTAINS = 2;
const BETWEEN = 3;
const GREATER_THAN = 4;
const GREATER_THAN_OR_EQUAL = 5;
const LESS_THAN = 6;
const LESS_THAN_OR_EQUAL = 7;
const NOT_NULL = 8;
const NOT_EQUAL = 9;
const NOT_CONTAINS = 10;

export {
  EQUALS,
  CONTAINS,
  BETWEEN,
  GREATER_THAN,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN,
  LESS_THAN_OR_EQUAL,
  NOT_NULL,
  NOT_EQUAL,
  NOT_CONTAINS
};