import { useEffect, useState } from "react";
import Form from "../../../models/serviceModels/Form";
import { getForms } from "../../../services/formsService";
import { DELIVERY_ID } from "../../../constants/formTypes";
import Loader from "../../UI/Loader";
import ReportStepProps from "./ReportStepProps";
import getclassNames from "../../../utils/getClassNames";
import IconButton from "../../UI/IconButton";
import { PROGRAM_NAME } from "../../../constants/reportStep";
import Select from "../../UI/Select";
import Button from "../../UI/Button";
import Program from "../../../models/serviceModels/Program";
import { searchPrograms } from "../../../services/programsService";
import ApiErrorMessages from "../../UI/ApiErrorMessages";

const ProgramSelection = (props: ReportStepProps) => {
    const [programs, setPrograms] = useState<Program[]>([]);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        setLoading(true);
        searchPrograms({
            skip: 0,
            take: 1000
        }).then(r => {
            setPrograms(r.data.sort((a, b) => a.name > b.name ? 1 : -1));
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }, []);

    const handleToggleExpanded = () => {
        props.onExpand(PROGRAM_NAME, !props.expanded);
    };

    const programIdFieldName = 'program_id';
    const programNameFieldName = 'program_name';
    const handleOnChange = (value: string) => {
        const matchingProgram = programs.find(p => p.id === value);
        if (!matchingProgram) {
            return;
        }
        props.onChange({
            step: PROGRAM_NAME,
            values: [
                { name: programIdFieldName, value },
                { name: programNameFieldName, value: matchingProgram.name }
            ]
        });
    };

    const handleOnNext = () => {
        props.onNext(PROGRAM_NAME);
    };

    const programId = props.values.find(v => v.name === programIdFieldName)?.value || '';
    const programName = !loading && programId && programs.find(f => f.id === programId)?.name || '';

    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
        <div className="report-step-header">
            <div className="row justify-content-between align-items-center">
                <div className="column-auto">
                    <h3>Which Program?{programName ? ` - ${programName}` : ''}</h3>
                </div>
                <div className="column-1">
                    <IconButton id="toggle-report-perspective-expansion"
                        className="small flat"
                        disabled={props.disabled}
                        icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                        onClick={handleToggleExpanded} />
                </div>
            </div>
        </div>
        <div className="report-step-body">
            <ApiErrorMessages error={apiError} />
            {loading && <Loader />}
            {!loading && <><div className="row margin-bottom-3">
                <div className="column-medium-6">
                    <Select id="enrolled-program"
                        value={programId}
                        onChange={handleOnChange}
                        label="Program">
                        <option>Select One...</option>
                        {programs.map((p: Program) => {
                            return <option key={p.id} value={p.id}>{p.name}</option>
                        })}
                    </Select>
                </div>
            </div>
                <div className="row justify-content-end margin-bottom-3">
                    <div className="column-auto">
                        <Button id="report-type-next"
                            text="Next"
                            icon={<i className="ri-arrow-right-s-line"></i>}
                            iconLocation="end"
                            className="secondary"
                            disabled={!programId || props.disabled}
                            onClick={handleOnNext} />
                    </div>
                </div>
            </>}
        </div>
    </div>);
};

export default ProgramSelection;