import getclassNames from '../../utils/getClassNames';
import './Loader.css';

interface LoaderProps {
    iconClassName?: string;
    noMarginTop?: boolean;
}

const Loader = (props?: LoaderProps) => {
    return (<div className={getclassNames('row justify-content-center align-items-center', !props?.noMarginTop ? 'margin-top-5' : '')}>
        <div className="column-auto">
            <div className={getclassNames('ri-loader-4-line spin loader-icon', props?.iconClassName || '')}></div>
        </div>
    </div>)
};

export default Loader;