interface CachedItem<T> {
    data: T;
    expiresOn: number;
}

const getItem = <T>(key: string): T | undefined => {
    const itemJson = localStorage.getItem(key);
    if (!itemJson) {
        return undefined;
    }

    const parsedItem: CachedItem<T> = JSON.parse(itemJson);
    if (parsedItem.data === undefined || parsedItem.expiresOn === undefined) {
        return undefined;
    }

    if (new Date(parsedItem.expiresOn) <= new Date()) {
        removeItem(key);
        return undefined;
    }

    return parsedItem.data;
};

const setItem = <T>(key: string, data: T, expiresMs = 1800000) => {
    localStorage.setItem(key, JSON.stringify({
        data,
        expiresOn: new Date().getTime() + expiresMs
    }));
};

const removeItem = (key: string) => {
    localStorage.removeItem(key);
};

const removeSuffix = (suffix: string) => {
    suffix = suffix.toLowerCase();
    const keysToRemove = [];
    for (const key in localStorage) {
        if (key.toLowerCase().endsWith(suffix)) {
            keysToRemove.push(key);
        }
    }

    for (const key of keysToRemove) {
        localStorage.removeItem(key);
    }
} 

export {
    getItem,
    setItem,
    removeItem,
    removeSuffix
}