import { BOOLEAN, STRING, STRING_WITH_POSSIBLE_VALUES, DATE } from "../../constants/customFilterDataType";
import { ValidateRequest } from "./reportFilters/ValidateRequest";
import ValidateResponse from "./reportFilters/ValidateResponse";
import stringValidator from './reportFilters/stringFilterValidator';
import additionalInfoValidator from './reportFilters/additionalInformationFilterValidator';
import ageValidator from './reportFilters//ageFilterValidator';
import dateValidator from './reportFilters/dateFilterValidator';
import formFieldValidator from './reportFilters/formFieldFilterValidator';
import programEnrollmentValidator from './reportFilters/programEnrollmentDateRangeFilterValidator';
import boolFilterValidator from './reportFilters/boolFilterValidator';
import * as customFilterTypes from '../../constants/customFilterType';

export const reportFilterDataTypeValidationMap: { [key: string]: (request: ValidateRequest) => Promise<ValidateResponse> } = {
    [STRING]: stringValidator,
    [STRING_WITH_POSSIBLE_VALUES]: stringValidator,
    [DATE]: dateValidator,
    [BOOLEAN]: boolFilterValidator
};

export const reportFilterTypeValidationMap: { [key: string]: (request: ValidateRequest) => Promise<ValidateResponse> } = {
    [customFilterTypes.PARTICIPANT_ADDITIONAL_INFORMATION]: additionalInfoValidator,
    [customFilterTypes.PARTICIPANT_AGE]: ageValidator,
    [customFilterTypes.HOUSEHOLD_MEMBER_AGE]: ageValidator,
    [customFilterTypes.FORM_FIELD]: formFieldValidator,
    [customFilterTypes.PROGRAM_ENROLLED_DATE]: programEnrollmentValidator,
    [customFilterTypes.PROGRAM_UNENROLLED_DATE]: programEnrollmentValidator,
    [customFilterTypes.PROGRAM_ENROLLED_DURING_DATE]: programEnrollmentValidator
};