const ADHOC_ID = 1;
const CLIENT_FORM_ID = 2;
const TRACKING_FORM_ID = 3;
const CASE_PLAN_ID = 4;

export {
    ADHOC_ID,
    CLIENT_FORM_ID,
    TRACKING_FORM_ID,
    CASE_PLAN_ID
};