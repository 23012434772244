import React from "react";
import { ACTIVITY_DATE_ID, BREAK_LINE_ID, CHECKBOX_GROUP_ID, CHECKBOX_ID, CLIENT_ID, DATE_ID, HEADER_ID, MULTI_LINE_TEXT_ID, NOTES_ID, NUMBER_ID, RADIO_BUTTON_ID, SELECT_LIST_ID, TEXT_ID, TIME_SPENT_ID } from "../../../constants/fieldTypes";
import EditableFormField from "../../../models/pageModels/EditableFormField";
import Checkbox from "../../UI/Checkbox";
import CheckboxGroup from "../../UI/CheckboxGroup";
import Input from "../../UI/Input";
import RadioButton from "../../UI/RadioButton";
import Select from "../../UI/Select";
import FileUpload from "../../UI/FileUpload";
import UploadableFile from "../../../models/pageModels/UploadableFile";
import { mapToFieldFiles, mapToUploadableFiles } from "../../../mappers/fileMapper";

interface FormEditorProps {
    fields: EditableFormField[];
    onChange?: (fields: EditableFormField[]) => void;
    disabled: boolean;
}

const FormEditor = (props: FormEditorProps) => {
    const handleFieldChange = (field: EditableFormField, value: any) => {
        const indexOfExistingField = props.fields.indexOf(field);
        const fields = [
            ...props.fields
        ];

        fields[indexOfExistingField].value = value;
        if (props.onChange) {
            props.onChange(fields);
        }
    };

    const handleFieldFileChange = (field: EditableFormField, files: UploadableFile[]) => {
        const indexOfExistingField = props.fields.indexOf(field);
        const fields = [
            ...props.fields
        ];
        
        fields[indexOfExistingField].files = mapToFieldFiles(files, field.id);
        if (props.onChange) {
            props.onChange(fields);
        }
    };

    let fieldNumber = 1;
    for (const f of props.fields) {
        if (f.fieldTypeId !== HEADER_ID && f.fieldTypeId !== CLIENT_ID && f.fieldTypeId !== BREAK_LINE_ID) {
            f.fieldNumber = fieldNumber++
        }
    }

    const getField = (field: EditableFormField): { element: JSX.Element, requiresLineBreak: boolean } | null => {
        switch (field.fieldTypeId) {
            case CHECKBOX_GROUP_ID:
                return {
                    element: (
                        <CheckboxGroup id={field.id}
                            checkedOptions={JSON.parse(field.value || '[]')}
                            options={field.options || []}
                            label={`${field.fieldNumber}. ${field.label}`}
                            disabled={props.disabled}
                            onChange={(v) => handleFieldChange(field, JSON.stringify(v || []))}
                            required={field.required} />
                    ),
                    requiresLineBreak: true
                };
            case MULTI_LINE_TEXT_ID:
                return {
                    element: (<div><label>{`${field.fieldNumber}. ${field.label}`}</label>
                        <div>
                            <Input type="multi"
                                id={field.id}
                                value={field.value}
                                disabled={props.disabled}
                                onChange={(e) => handleFieldChange(field, e)}
                                required={field.required} />
                        </div>
                    </div>),
                    requiresLineBreak: true
                };
            case NOTES_ID:
                return {
                    element: (<div>
                        <label>{`${field.fieldNumber}. ${field.label}`}</label>
                        <div>
                            <Input type="multi"
                                id={field.id}
                                value={field.value}
                                disabled={props.disabled}
                                onChange={(e) => handleFieldChange(field, e)}
                                required={field.required} />
                        </div>
                        <div className="margin-top-4">
                            <FileUpload id={`${field.id}-files`}
                                value={mapToUploadableFiles(field.files || [])}
                                onChange={(e) => handleFieldFileChange(field, e)}
                                disableUpload={props.disabled}
                                disableRemove={props.disabled}
                                multiple={true} />
                        </div>
                    </div>),
                    requiresLineBreak: true
                };
            case ACTIVITY_DATE_ID:
            case DATE_ID:
                return {
                    element: <Input id={field.id}
                        disabled={props.disabled}
                        type="date"
                        value={field.value}
                        required={field.required}
                        label={`${field.fieldNumber}. ${field.label}`}
                        onChange={(e) => handleFieldChange(field, e)} />,
                    requiresLineBreak: false
                };
            case HEADER_ID:
                return { element: <h3 id={field.id}>{field.label}</h3>, requiresLineBreak: true };
            case TEXT_ID:
                return {
                    element: <Input id={field.id}
                        disabled={props.disabled}
                        value={field.value}
                        required={field.required}
                        label={`${field.fieldNumber}. ${field.label}`}
                        onChange={(e) => handleFieldChange(field, e)} />,
                    requiresLineBreak: false
                };
            case CHECKBOX_ID:
                return {
                    element: <Checkbox id={field.id}
                        disabled={props.disabled}
                        required={field.required}
                        checked={field.value?.toString()?.toLowerCase() === "true"}
                        label={`${field.fieldNumber}. ${field.label}`}
                        onChange={(checked) => handleFieldChange(field, checked)} />,
                    requiresLineBreak: false
                };
            case NUMBER_ID:
            case TIME_SPENT_ID:
                return {
                    element: <Input id={field.id}
                        disabled={props.disabled}
                        required={field.required}
                        value={field.value}
                        mask="PositiveDecimalNumber"
                        label={`${field.fieldNumber}. ${field.label}`}
                        type="number" onChange={(e) => handleFieldChange(field, e)} />,
                    requiresLineBreak: false
                };
            case BREAK_LINE_ID:
                return { element: <></>, requiresLineBreak: true }; 
            case RADIO_BUTTON_ID:
                return {
                    element: <RadioButton id={field.id}
                        disabled={props.disabled} required={field.required}
                        value={JSON.parse(field.value || '{}')?.value || ''}
                        label={`${field.fieldNumber}. ${field.label}`}
                        onChange={(v) => handleFieldChange(field, JSON.stringify((v && { label: field.options?.find((o: any) => o.value === v)?.label, value: v }) || {}))}
                        options={field.options || []} />, requiresLineBreak: false
                };
            case SELECT_LIST_ID:
                return {
                    element: (
                        <Select id={field.id} required={field.required}
                            disabled={props.disabled} value={JSON.parse(field.value || '{}')?.value || ''} label={`${field.fieldNumber}. ${field.label}`}
                            onChange={(e) => handleFieldChange(field, JSON.stringify((e && { label: field.options?.find((o: any) => o.value === e)?.label, value: e }) || {}))} >
                            <option>--Select--</option>
                            {field.options?.map((o: any) => {
                                return <option key={o.id} value={o.value}>{o.label}</option>
                            })}
                        </Select>
                    ),
                    requiresLineBreak: false
                }
        }

        return null;
    }

    return (
        <div className="row">
            {props.fields.filter(f => f.fieldTypeId !== CLIENT_ID).map((f, i) => {
                const field = getField(f);
                if (!field) {
                    return null;
                }

                if (field.requiresLineBreak) {
                    return (<div className="column-12 margin-top-6" key={f.id}>
                        {field.element}
                    </div>)
                }

                return (<div className="column-medium-4 margin-top-6" key={f.id}>
                    {field.element}
                </div>);
            })}
        </div>
    );
};

export default FormEditor;