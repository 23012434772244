import React from 'react';
import './ErrorMessages.css';

export interface ErrorMessagesProps {
    messages: string[];
}

const ErrorMessages = (props: ErrorMessagesProps) => {
    if (!props.messages || props.messages.length === 0) {
        return null;
    }

    let errorIcon = <i className="ri-error-warning-fill"><span>&nbsp;</span></i>;

    return (<div className="errors-container">
        {props.messages.map((m, i) => {
            if (!m) { errorIcon = <span>&nbsp;</span> }
            return <div className="error-message" key={i}>{errorIcon} {m}</div>
        })}
    </div>);
};

export default ErrorMessages;