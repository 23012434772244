import { format, parse } from 'date-fns';
import FormInstance from '../../models/serviceModels/FormInstance';
import { ACTIVITY_DATE_ID, BREAK_LINE_ID, CHECKBOX_GROUP_ID, CLIENT_ID, DATE_ID, HEADER_ID, MULTI_LINE_TEXT_ID, NOTES_ID, RADIO_BUTTON_ID, SELECT_LIST_ID } from '../../constants/fieldTypes';
import FileUpload from './FileUpload';
import FormInstanceField from '../../models/serviceModels/FormInstanceField';
import { mapToUploadableFiles } from '../../mappers/fileMapper';

interface FormViewerProps {
    instance: FormInstance;
}

const FormViewer = (props: FormViewerProps) => {
    if (!props.instance) {
        return null;
    }

    return (<div>
        <div className="row">
            {props.instance.instanceFields.filter(i => i.fieldTypeId !== CLIENT_ID).map((f: FormInstanceField) => {
                if (f.fieldTypeId === HEADER_ID) {
                    return (<div className="column-12" key={`client-form-field-${f.id}`}>
                        <div className="row">
                            <h3 className="column">
                                {f.label}
                            </h3>
                        </div>
                    </div>)
                }

                if (f.fieldTypeId === MULTI_LINE_TEXT_ID) {
                    return (<div key={`client-field-${f.id}`} className="column-12 margin-top-5">
                        <label>{f.label}</label>
                        <div dangerouslySetInnerHTML={{ __html: f.value.replace(/\n/g, '<br/>')}}></div>
                    </div>)
                }

                if (f.fieldTypeId === NOTES_ID) {
                    return (<div key={`client-field-${f.id}`} className="column-12 margin-top-5">
                        <label>{f.label}</label>
                        <div dangerouslySetInnerHTML={{ __html: f.value.replace(/\n/g, '<br/>')}}></div>
                        <div className="margin-top-4">
                            <FileUpload id={`client-field-${f.id}-files`} 
                                value={mapToUploadableFiles(f.files || [])} 
                                disableUpload={true} 
                                label="Attachments" 
                                disableRemove={true} />
                        </div>
                    </div>)
                }

                if (f.fieldTypeId === ACTIVITY_DATE_ID || f.fieldTypeId === DATE_ID) {
                    return (<div className="column-medium-3 margin-top-5" key={`client-form-field-${f.id}`}>
                        <label>{f.label}</label>
                        <div>{f.value && format(parse(f.value, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')}</div>
                    </div>)
                }

                if (f.fieldTypeId === CHECKBOX_GROUP_ID) {
                    return (<div className="column-medium-3 margin-top-5" key={`client-form-field-${f.id}`}>
                        <label>{f.label}</label>
                        <div>{JSON.parse(f.value || '[]').map((v: any) => v.label || v).join(', ')}</div>
                    </div>);
                }

                if (f.fieldTypeId === SELECT_LIST_ID || f.fieldTypeId === RADIO_BUTTON_ID) {
                    return (<div className="column-medium-3 margin-top-5" key={`client-form-field-${f.id}`}>
                        <label>{f.label}</label>
                        <div>{JSON.parse(f.value || '{}').label || ''}</div>
                    </div>);
                }

                if (f.fieldTypeId === BREAK_LINE_ID) {
                    return <div className="column-12 margin-top-5"></div>;
                }

                return (<div className="column-medium-3 margin-top-5" key={`client-form-field-${f.id}`}>
                    <label>{f.label}</label>
                    <div>{f.value}</div>
                </div>)
            })}
        </div>
    </div>);
};

export default FormViewer;