import { addYears } from "date-fns";

const months = [
    { label: 'January (01)', value: '01' },
    { label: 'February (02)', value: '02' },
    { label: 'March (03)', value: '03' },
    { label: 'April (04)', value: '04' },
    { label: 'May (05)', value: '05' },
    { label: 'June (06)', value: '06' },
    { label: 'July (07)', value: '07' },
    { label: 'August (08)', value: '08' },
    { label: 'September (09)', value: '09' },
    { label: 'October (10)', value: '10' },
    { label: 'November (11)', value: '11' },
    { label: 'December (12)', value: '12' }
];

const years = [
    new Date().getFullYear().toString(),
    addYears(new Date(), 1).getFullYear().toString(),
    addYears(new Date(), 2).getFullYear().toString(),
    addYears(new Date(), 3).getFullYear().toString(),
    addYears(new Date(), 4).getFullYear().toString(),
    addYears(new Date(), 5).getFullYear().toString(),
    addYears(new Date(), 6).getFullYear().toString(),
    addYears(new Date(), 7).getFullYear().toString(),
    addYears(new Date(), 8).getFullYear().toString(),
    addYears(new Date(), 9).getFullYear().toString(),
    addYears(new Date(), 10).getFullYear().toString()
];

export {
    months,
    years
};