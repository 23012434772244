import axios from "axios";
import ExecuteSearchRequest from "../models/serviceModels/ExecuteSearchRequest";
import PagedData from "../models/serviceModels/PagedData";
import SearchFieldReference from "../models/serviceModels/SearchFieldReference";
import DefaultSearchFilter from "../models/serviceModels/DeafultSearchFilter";
import { getItem, setItem } from "./cacheService";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const executeSearch = async <TResponse>(request: ExecuteSearchRequest): Promise<PagedData<TResponse>> => {
    const result = await axios.post(`${baseUrl}/search/${request.searchTypeId}`, {
        fields: request.fields,
        skip: request.skip,
        take: request.take,
        query: request.query || '',
        clientId: request.clientId || '',
        disabledFilters: request.disabledFilters || []
    });

    return result.data;
};

const getSearchFields = async (searchTypeId: number, clientId?: string, disableCache = false): Promise<SearchFieldReference[]> => {
    const cacheKey = `${searchTypeId}${clientId ? `-${clientId}` : ''}-search-fields-v2`;

    if (!disableCache) {
        const cachedFields = getItem<SearchFieldReference[]>(cacheKey);
        if (cachedFields) {
            return cachedFields;
        }
    }

    const result = await axios.get(`${baseUrl}/search/${searchTypeId}/fields?clientId=${clientId || ''}`);

    if (!disableCache) {
        // cache for a month
        setItem<SearchFieldReference[]>(cacheKey, result.data, 2628000000);
    }

    return result.data;
};

const getDefaultSearchFilters = async (searchTypeId: number): Promise<DefaultSearchFilter[]> => {
    const cacheKey = `${searchTypeId}-search-filters-v2`;
    const cachedFilters = getItem<DefaultSearchFilter[]>(cacheKey);
    if (cachedFilters) {
        return cachedFilters;
    }

    const result = await axios.get(`${baseUrl}/search/${searchTypeId}/filters`);

    // cache for a month
    setItem<DefaultSearchFilter[]>(cacheKey, result.data, 2628000000);

    return result.data;
};

export {
    executeSearch,
    getSearchFields,
    getDefaultSearchFilters
}