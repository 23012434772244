
const get = <T>(key: string): T | null => {
    const data = localStorage.getItem(key);
    if (!data) return null;

    const parsedData = JSON.parse(data);
    if (parsedData.expiresOn <= new Date().getTime()) {
        localStorage.removeItem(key);
        return null;
    }

    return parsedData.data;
};

const set = <T>(key: string, data: T, expiresInMs?: number) => {
    localStorage.setItem(key, JSON.stringify({
        data,
        expiresOn: new Date().getTime() + (expiresInMs || (1000 * 60 * 10)) // 10 min by default
    }));
};

const remove = (key: string) => {
    localStorage.removeItem(key);
}


export {
    get,
    set,
    remove
}