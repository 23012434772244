import { useState, useEffect, useRef } from "react";
import GoalTask from "../../models/serviceModels/GoalTask";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Button from "./Button";
import Dropdown from "./Dropdown";
import { completeGoalTask, uncompleteGoalTask, updateGoalTask } from "../../services/clientsService";
import ApiErrorMessages from "./ApiErrorMessages";
import Loader from "./Loader";

interface ParticipantCasePlanGoalTaskProps {
    task: GoalTask;
    editing: boolean;
    casePlanStopped: boolean;
    casePlanId: string;
    clientId: string;
    onDelete: (task: GoalTask) => void;
    onChange: (task: GoalTask) => void;
    focused: boolean;
    reorderingTasks: boolean;
}

const ParticipantCasePlanGoalTask = (props: ParticipantCasePlanGoalTaskProps) => {
    const [task, setTask] = useState<GoalTask>(props.task);
    const [completed, setCompleted] = useState(props.task.completed);
    const [editingTaskVersion, setEditingTaskVersion] = useState<GoalTask | undefined>(props.editing ? {
        ...task
    } : undefined);
    const [savingTask, setSavingTask] = useState(false);
    const [toggleCompleting, setToggleCompleting] = useState(false);
    const [apiError, setApiError] = useState<any>(undefined);
    const [reorderTasks, setReorderingTasks] = useState(props.reorderingTasks);
    const [focused, setFocused] = useState(props.focused);
    const [casePlanStopped, setCasePlanStopped] = useState(props.casePlanStopped);
    const textReference = useRef<HTMLInputElement>();

    useEffect(() => {
        props.onChange(task);
    }, [task]);

    useEffect(() => {
        setCasePlanStopped(props.casePlanStopped);
        if (props.casePlanStopped) {
            setEditingTaskVersion(undefined);
        }
    }, [props.casePlanStopped]);

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);

    useEffect(() => {
        setReorderingTasks(props.reorderingTasks);
    }, [props.reorderingTasks]);

    useEffect(() => {
        if (focused && textReference.current) {
            textReference.current.focus();
            setFocused(false);
        }
    }, [focused]);

    const handleOnEdit = () => {
        setEditingTaskVersion({
            ...task
        });
        setFocused(true);
    };

    const handleOnDelete = () => {
        props.onDelete(task);
    };

    const handleOnToggleComplete = (checked: boolean) => {
        setToggleCompleting(true);
        setApiError(undefined);

        setCompleted(checked);

        (checked ? completeGoalTask({
            clientId: props.clientId,
            casePlanId: props.casePlanId,
            goalId: task.casePlanGoalId,
            taskId: task.id
        }) : uncompleteGoalTask({
            clientId: props.clientId,
            casePlanId: props.casePlanId,
            goalId: task.casePlanGoalId,
            taskId: task.id
        })).then(() => {
            setTask(t => {
                return {
                    ...t,
                    completed: checked
                };
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
            setCompleted(!checked);
        }).finally(() => {
            setToggleCompleting(false);
        });
    };

    const handleOnSaveCancel = () => {
        setEditingTaskVersion(undefined);
    };

    const handleOnTaskTextChange = (text: string) => {
        setEditingTaskVersion(t => {
            if (!t) {
                return {
                    ...task,
                    text
                };
            }

            return {
                ...t,
                text
            };
        });
    };

    const handleOnSave = () => {
        if (!editingTaskVersion) {
            return;
        }

        setApiError(undefined);
        setSavingTask(true);

        updateGoalTask({
            casePlanId: props.casePlanId,
            taskId: task.id,
            text: editingTaskVersion.text,
            order: editingTaskVersion.order,
            goalId: editingTaskVersion.casePlanGoalId,
            clientId: props.clientId
        }).then(r => {
            setEditingTaskVersion(undefined);
            setTask(t => {
                return {
                    ...t,
                    updated: r.updated,
                    updatedBy: r.updatedBy,
                    text: r.text,
                    order: r.order
                }
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSavingTask(false);
        });
    };

    const handleOnKeyUp = (code: string) => {
        if (code === 'Enter') {
            handleOnSave();
        } else if (code === 'Escape') {
            handleOnSaveCancel();
        }
    };

    const options = [
        {
            onClick: handleOnEdit,
            label: 'Edit'
        },
        {
            onClick: handleOnDelete,
            label: 'Delete'
        }
    ];

    return (<>
        <ApiErrorMessages error={apiError} />
        <div className="row">
            <div className="column">
                <div className="row align-items-center padding-horizontal-3">
                    {!editingTaskVersion && <><div className="column-auto">
                        <div className="row">
                            <div className="column-auto">
                                <Checkbox id={`complete-uncomplete-task-${task.id}`}
                                    checked={completed}
                                    onChange={handleOnToggleComplete}
                                    disabled={toggleCompleting || reorderTasks || casePlanStopped}
                                    containerClassName="unboxed margin-0" />
                            </div>
                        </div>
                    </div>
                    {toggleCompleting && <div className="column-auto">
                        <Loader />
                    </div>}
                    </>}
                    {editingTaskVersion && <div className="column">
                        <Input id={`task-text-${task.id}`}
                            value={editingTaskVersion.text}
                            onChange={handleOnTaskTextChange}
                            label="Task"
                            ref={textReference}
                            disabled={reorderTasks}
                            onKeyUp={handleOnKeyUp} />
                    </div>}
                    {!editingTaskVersion && <div className="column">
                        {!task.text ? <i>&lt;No task entered&gt;</i> : task.text}
                    </div>}
                    {!editingTaskVersion && <div className="column-auto">
                        <Dropdown id={`task-options-${task.id}`}
                            options={options}
                            disabled={savingTask || toggleCompleting || reorderTasks || casePlanStopped} />
                    </div>}
                </div>
                {editingTaskVersion && <div className="row justify-content-end margin-top-3">
                    <div className="column-auto">
                        <Button id={`cancel-save-task-${task.id}`}
                            className="secondary"
                            text="Cancel"
                            onClick={handleOnSaveCancel}
                            disabled={savingTask || reorderTasks} />
                    </div>
                    <div className="column-auto">
                        <Button id={`save-task-${task.id}`}
                            text="Save Task"
                            className="action"
                            onClick={handleOnSave}
                            disabled={savingTask || reorderTasks}
                            loading={savingTask} />
                    </div>
                </div>}
            </div>
        </div>
    </>);
};

export default ParticipantCasePlanGoalTask;