import axios from "axios";
import ActivateClientRequest from "../models/serviceModels/ActivateClientRequest";
import Client from "../models/serviceModels/Client";
import CreateClientRequest from "../models/serviceModels/CreateClientRequest";
import DeactivateClientRequest from "../models/serviceModels/DeactivateClientRequest";
import Gender from "../models/serviceModels/Gender";
import PagedData from "../models/serviceModels/PagedData";
import Race from "../models/serviceModels/Race";
import Relationship from "../models/serviceModels/Relationship";
import SearchClientsRequest from "../models/serviceModels/SearchClientsRequest";
import UpdateClientRequest from "../models/serviceModels/UpdateClientRequest";
import { get, set } from "../utils/cacheData";
import { SearchClientNotesRequest } from "../models/serviceModels/SearchClientNotesRequest";
import { ClientNote } from "../models/serviceModels/ClientNote";
import SaveClientNoteRequest from "../models/serviceModels/SaveClientNoteRequest";
import UpdateClientNoteRequest from "../models/serviceModels/UpdateClientNoteRequest";
import DeleteClientNoteRequest from "../models/serviceModels/DeleteClientNoteRequest";
import Ethnicity from "../models/serviceModels/Ethnicity";
import ClientCasePlan from "../models/serviceModels/ClientCasePlan";
import CreateCasePlanRequest from "../models/serviceModels/CreateCasePlanRequest";
import DeletesCasePlanRequest from "../models/serviceModels/DeleteCasePlanRequest";
import GetCasePlanRequest from "../models/serviceModels/GetCasePlanRequest";
import UpdateCasePlanRequest from "../models/serviceModels/UpdateCasePlanRequest";
import CreateCasePlanGoalRequest from "../models/serviceModels/CreateCasePlanGoalRequest";
import CasePlanGoal from "../models/serviceModels/CasePlanGoal";
import UpdateCasePlanGoalRequest from "../models/serviceModels/UpdateCasePlanGoalRequest";
import DeleteCasePlanGoalRequest from "../models/serviceModels/DeleteCasePlanGoalRequest";
import CreateCasePlanGoalStatusRequest from "../models/serviceModels/CreateCasePlanGoalStatusRequest";
import CasePlanGoalStatus from "../models/serviceModels/CasePlanGoalStatus";
import { getItem, setItem } from "./cacheService";
import CompleteGoalTask from "../models/serviceModels/CompleteGoalTask";
import CreateGoalTask from "../models/serviceModels/CreateGoalTask";
import GoalTask from "../models/serviceModels/GoalTask";
import UpdateGoalTask from "../models/serviceModels/UpdateGoalTask";
import DeleteGoalTask from "../models/serviceModels/DeleteGoalTask";
import CreatePlanNoteRequest from "../models/serviceModels/CreatePlanNoteRequest";
import { PlanNote } from "../models/serviceModels/PlanNote";
import UpdatePlanNoteRequest from "../models/serviceModels/UpdatePlanNoteRequest";
import DeletePlanNoteRequest from "../models/serviceModels/DeletePlanNoteRequest";
import { GoalNote } from "../models/serviceModels/GoalNote";
import GetGoalNotesRequest from "../models/serviceModels/GetGoalNotesRequest";
import CreateGoalNoteRequest from "../models/serviceModels/CreateGoalNoteRequest";
import UpdateGoalNoteRequest from "../models/serviceModels/UpdateGoalNoteRequest";
import DeleteGoalNoteRequest from "../models/serviceModels/DeleteGoalNoteRequest";
import StopCasePlanRequest from "../models/serviceModels/StopCasePlanRequest";
import StartCasePlanRequest from "../models/serviceModels/StartCasePlanRequest";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getRelationships = async (): Promise<Relationship[]> => {
    const cachedData = get<Relationship[]>('relationships');
    if (cachedData) return cachedData;

    const results = await axios.get(`${baseUrl}/relationships`);

    set<Race[]>('relationships', results.data);

    return results.data;
};

const getEthnicities = async (): Promise<Ethnicity[]> => {
  const cachedData = get<Ethnicity[]>('ethnicities');
  if (cachedData) return cachedData;
  
  const results = await axios.get(`${baseUrl}/ethnicities`);

  set<Ethnicity[]>('ethnicities', results.data);

  return results.data;
};

const searchClientNotes = async (request: SearchClientNotesRequest): Promise<PagedData<ClientNote>> => {
    const queryString = [
        `take=${request.take}`,
        `skip=${request.skip}`,
        `query=${request.query || ''}`
    ].join('&');
    
    const results = await axios.get(`${baseUrl}/clients/${request.clientId}/notes?${queryString}`);
    return results.data;
} 

const getRaces = async (): Promise<Race[]> => {
    const cachedRaces = get<Race[]>('races');
    if (cachedRaces) return cachedRaces;

    const results = await axios.get(`${baseUrl}/races`);

    set<Race[]>('races', results.data);

    return results.data;
};

const getGenders = async (): Promise<Gender[]> => {
    const cachedData = get<Gender[]>('genders');
    if (cachedData) return cachedData;

    const results = await axios.get(`${baseUrl}/genders`);

    set<Gender[]>('genders', results.data);

    return results.data;
};

const getClientById = async (id: string): Promise<Client> => {
    const results = await axios.get(`${baseUrl}/clients/${id}`);
    return results.data;
};

const createClient = async (request: CreateClientRequest): Promise<Client> => {
    const result = await axios.post(`${baseUrl}/clients`, request);
    return result.data;
};

const updateClient = async (request: UpdateClientRequest): Promise<Client> => {
    const result = await axios.put(`${baseUrl}/clients/${request.id}`, request);
    return result.data;
};

const searchClients = async (request: SearchClientsRequest): Promise<PagedData<Client>> => {
    const result = await axios.get(`${baseUrl}/clients?query=${request.query}&skip=${request.skip}&take=${request.take}&programId=${request.programId || ''}&includeDeactivated=${request.includeDeactivated}`);
    return result.data;
}

const activateClient = async (request: ActivateClientRequest): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/activate`, {
        date: request.date
    });
};

const deactivateClient = async (request: DeactivateClientRequest): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/deactivate`, {
        date: request.date
    });
};

const saveClientNote = async (request: SaveClientNoteRequest): Promise<ClientNote> => {
    const result = await axios.post(`${baseUrl}/clients/${request.clientId}/notes`, {
        value: request.value,
        files: request.files || []
    });

    return result.data;
};

const updateClientNote = async (request: UpdateClientNoteRequest): Promise<ClientNote> => {
    const result = await axios.put(`${baseUrl}/clients/${request.clientId}/notes/${request.id}`, {
        value: request.value,
        files: request.files || []
    });
    
    return result.data;
};

const deleteClientNote = async (request: DeleteClientNoteRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/clients/${request.clientId}/notes/${request.id}`);
};

const getClientCasePlans = async (clientId: string): Promise<ClientCasePlan[]> => {
    const result = await axios.get(`${baseUrl}/clients/${clientId}/case-plans`);
    return result.data;
};

const createCasePlan = async (request: CreateCasePlanRequest): Promise<ClientCasePlan> => {
    const result = await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans`, {
        name: request.name, 
        started: request.started
    });
    
    return result.data;
};

const getCasePlan = async (request: GetCasePlanRequest): Promise<ClientCasePlan> => {
    const result = await axios.get(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}`);
    return result.data;
};

const updateCasePlan = async ( request: UpdateCasePlanRequest): Promise<ClientCasePlan> => {
    const result = await axios.put(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}`, {
        name: request.name,
        started: request.started
    });

    return result.data;
};

const createCasePlanGoal = async (request: CreateCasePlanGoalRequest): Promise<CasePlanGoal> => {
    const result = await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals`, {
        name: request.name, 
        dueDate: request.dueDate
    });

    return result.data;
};

const updateCasePlanGoal = async (request: UpdateCasePlanGoalRequest): Promise<CasePlanGoal> => {
    const result = await axios.put(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}`, {
        name: request.name, 
        dueDate: request.dueDate,
        order: request.order
    });

    return result.data;
};

const createCasePlanGoalStatus = async (request: CreateCasePlanGoalStatusRequest): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/status`, {
        statusId: request.statusId,
        statusDate: request.statusDate
    });
};

const deleteCasePlanGoal = async (request: DeleteCasePlanGoalRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}`);
};

const getCasePlanGoalStatuses = async (): Promise<CasePlanGoalStatus[]> => {
    const cachedStatuses = getItem<CasePlanGoalStatus[]>('goal-statuses');
    if (cachedStatuses) {
        return cachedStatuses;
    }

    const result = await axios.get(`${baseUrl}/clients/case-plans/goals/statuses`);
    setItem<CasePlanGoalStatus[]>('goal-statuses', result.data);
    return result.data;
};

const deleteCasePlan = async (request: DeletesCasePlanRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/clients/${request.clientId}/case-plans/${request.id}`);
};

const completeGoalTask = async (request: CompleteGoalTask): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/tasks/${request.taskId}/complete`);
};

const uncompleteGoalTask = async (request: CompleteGoalTask): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/tasks/${request.taskId}/uncomplete`);
};

const createGoalTask = async (request: CreateGoalTask): Promise<GoalTask> => {
    const result = await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/tasks`, {
        text: request.text || ''
    });

    return result.data;
};

const updateGoalTask = async (request: UpdateGoalTask): Promise<GoalTask> => {
    const result = await axios.put(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/tasks/${request.taskId}`, {
        text: request.text || '',
        order: request.order
    });

    return result.data;
};

const deleteGoalTask = async (request: DeleteGoalTask): Promise<void> => {
    await axios.delete(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/tasks/${request.taskId}`);
};

const stopCasePlan = async (request: StopCasePlanRequest): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/stop`, {
        stopped: request.stopped
    });
};

const startCasePlan = async (request: StartCasePlanRequest): Promise<void> => {
    await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/start`, {
        started: request.started
    });
};

const createPlanNote = async (request: CreatePlanNoteRequest): Promise<PlanNote> => {
    const result = await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/notes`,{
        text: request.text,
        files: request.files
    });

    return result.data;
};

const getGoalNotes = async (request: GetGoalNotesRequest): Promise<GoalNote[]> => {
    const result = await axios.get(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/notes`);
    return result.data;
};

const createGoalNote = async (request: CreateGoalNoteRequest): Promise<GoalNote> => {
    const result = await axios.post(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/notes`, {
        text: request.text,
        files: request.files
    });

    return result.data;
};

const updateGoalNote = async (request: UpdateGoalNoteRequest): Promise<GoalNote> => {
    const result = await axios.put(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/notes/${request.noteId}`, {
        text: request.text,
        files: request.files
    });

    return result.data;
};

const deleteGoalNote = async (request: DeleteGoalNoteRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/goals/${request.goalId}/notes/${request.noteId}`);
};

const updatePlanNote = async (request: UpdatePlanNoteRequest): Promise<PlanNote> => {
    const result = await axios.put(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/notes/${request.noteId}`,{
        text: request.text,
        files: request.files
    });

    return result.data;
};

const deletePlanNote = async (request: DeletePlanNoteRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/clients/${request.clientId}/case-plans/${request.casePlanId}/notes/${request.noteId}`);
};

export {
    getRelationships,
    getRaces,
    getGenders,
    getClientById,
    createClient,
    updateClient,
    searchClients,
    activateClient,
    deactivateClient,
    searchClientNotes,
    saveClientNote,
    updateClientNote,
    deleteClientNote,
    getEthnicities,
    getClientCasePlans,
    createCasePlan,
    deleteCasePlan,
    getCasePlan,
    updateCasePlan,
    createCasePlanGoal,
    updateCasePlanGoal,
    deleteCasePlanGoal,
    createCasePlanGoalStatus,
    getCasePlanGoalStatuses,
    completeGoalTask,
    uncompleteGoalTask,
    createGoalTask,
    updateGoalTask,
    deleteGoalTask,
    stopCasePlan,
    startCasePlan,
    createPlanNote,
    updatePlanNote,
    deletePlanNote,
    getGoalNotes,
    createGoalNote,
    updateGoalNote,
    deleteGoalNote
};