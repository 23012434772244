import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/grid.css';
import './styles/index.css';
import './styles/utils.css';
import  { App } from './App';
import { BrowserRouter } from "react-router-dom";
import 'remixicon/fonts/remixicon.css';
import { Auth0Provider } from "@auth0/auth0-react";

const clientId = process.env.REACT_APP_CLIENT_ID || '';
const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';


const root = createRoot(document.getElementById('root')!);
root.render(
    <BrowserRouter>
      <Auth0Provider
        domain={domain}
        useRefreshTokens={true}
        clientId={clientId}
        redirectUri={`${window.location.origin}/logged-in`}>
        <App />
      </Auth0Provider>
    </BrowserRouter>);

