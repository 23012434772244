const AMERICAN_EXPRESS = 'amex';
const VISA = 'visa';
const DINERS = 'diners';
const DISCOVER = 'discover';
const JCB = 'jcb';
const MASTERCARD = 'mastercard';
const UNION_PAY = 'unionpay';
const UNKOWN = 'unknown'; 

export {
    AMERICAN_EXPRESS,
    VISA,
    DINERS,
    DISCOVER,
    JCB,
    MASTERCARD,
    UNION_PAY,
    UNKOWN
}