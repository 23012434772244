import getclassNames from '../../utils/getClassNames';
import Loader from './Loader';
import './LoadingPlaceholder.css';

interface LoadingPlaceholderProps {
    id: string;
    className?: string;
    children?: any;
}

const LoadingPlaceholder = (props: LoadingPlaceholderProps) => {
    return (<div id={props.id} className={getclassNames('placeholder', props.className || '')}>
        <div className="placeholder-content">
            {props.children || null}
        </div>
        <div className="placeholder-loader">
            <Loader />
        </div>
        <div className="placeholder-overlay"></div>
    </div>);
};

export default LoadingPlaceholder;