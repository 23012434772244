import getclassNames from "../../../utils/getClassNames";
import IconButton from "../../UI/IconButton";
import RadioButton from "../../UI/RadioButton";
import { getPlanScopesOptions } from "../../../utils/radiobuttonHelpers";
import Button from "../../UI/Button";
import { PLAN_SCOPE } from "../../../constants/reportStep";
import ReportStepProps from "./ReportStepProps";
import { planScopeMap } from "../../../constants/planScopes";

const PlanScope = (props: ReportStepProps) => {    
    const value = props.values.length === 1 ? props.values[0].value : '';

    const handleOnNext = () => {
        props.onNext(PLAN_SCOPE);
    };

    const handleOnChange = (value: string) => {
        props.onChange({ step: PLAN_SCOPE, values: [{name: 'scope', value}] });
    };

    const handleToggleExpand = () => {
        props.onExpand(PLAN_SCOPE, !props.expanded);
    };

    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
        <div className="report-step-header">
            <div className="row justify-content-between align-items-center">
                <div className="column-auto">
                    <h3>What plans data?{value ? ` - ${planScopeMap[value]}` : ''}</h3>
                </div>
                <div className="column-1">
                    <IconButton id="toggle-report-perspective-expansion"
                        className="small flat"
                        disabled={props.disabled}
                        icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                        onClick={handleToggleExpand} />
                </div>
            </div>
        </div>
        <div className="report-step-body">
            <div className="row margin-bottom-3">
                <div className="column">
                    <RadioButton id="plan-scope"
                        options={getPlanScopesOptions()}
                        onChange={handleOnChange}
                        disabled={props.disabled}
                        value={value} />
                </div>
            </div>
            <div className="row justify-content-end margin-bottom-3">
                <div className="column-auto">
                    <Button id="report-type-next"
                        text="Next"
                        icon={<i className="ri-arrow-right-s-line"></i>}
                        iconLocation="end"
                        className="secondary"
                        disabled={!value || props.disabled}
                        onClick={handleOnNext} />
                </div>
            </div>
        </div>
    </div>);
};

export default PlanScope;