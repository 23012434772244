const NEW = 'New';
const STARTED = 'Started';
const UPLOADING = 'Uploading';
const UPLOADED = 'Uploaded';
const SCANNING = 'Scanning';
const FAILED = 'Failed';
const INFECTED = 'Infected';

export {
    NEW,
    STARTED,
    UPLOADING,
    SCANNING,
    UPLOADED,
    FAILED,
    INFECTED
};