export const PARTICIPANT = 'PARTICIPANT';
export const LOGICAL = 'LOGICAL';
export const HOUSEHOLD_MEMBER = 'HOUSEHOLD_MEMBER';
export const PROGRAMS = 'PROGRAMS';
export const FORMS = 'FORMS';

export const groupingNameMap: {[key:string]: string} = {
    [PARTICIPANT]: 'Participant',
    [HOUSEHOLD_MEMBER]: 'Householder Member',
    [LOGICAL]: 'Logical',
    [PROGRAMS]: 'Programs',
    [FORMS]: 'Forms'
};