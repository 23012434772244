import { ValidateRequest } from "./ValidateRequest";
import ValidateResponse, { defaultValue } from "./ValidateResponse";

const validate = (request: ValidateRequest): Promise<ValidateResponse> => {
    const response = defaultValue();

    if (request.value === '' || request.value === null || request.value === undefined) {
        response.value.push('Value is required');
        response.isValid = false;
    }

    return Promise.resolve(response);
};

export default validate;

