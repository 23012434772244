import React, { ForwardedRef, forwardRef, useEffect, useState, KeyboardEvent, MouseEvent, useRef } from "react";
import './Dropdown.css';
import IconButton from "./IconButton";
import { Link } from "react-router-dom";
import getclassNames from "../../utils/getClassNames";

interface DropdownProps {
    id: string;
    options: { label: string, href?: string, onClick?: () => void, newWindow?: boolean; }[];
    openTop?: boolean;
    disabled?: boolean;
    optionSize?: 'small' | 'medium' | 'large'
}

const Dropdown = forwardRef((props: DropdownProps, ref?: ForwardedRef<any>) => {
    const [visible, setVisible] = useState(false);
    const triggeredToggle = useRef<boolean>(false);

    useEffect(() => {
        if (visible) {
            const closeDropdownHandler = () => {
                if (triggeredToggle.current) {
                    triggeredToggle.current = false;
                } else {
                    setVisible(false);
                }
            };

            document.addEventListener('click', closeDropdownHandler);

            return () => {
                document.removeEventListener('click', closeDropdownHandler);
            }
        }
    }, [visible]);

    const handleDropdownOpen = () => {
        triggeredToggle.current = true;
        setVisible(!visible);
    };

    const handleOptionOnKeyUp = (e: KeyboardEvent<HTMLDivElement>, onClick?: () => void) => {
        if (!onClick) {
            return;
        }

        if (e.code === 'Space' || e.code === 'Enter') {
            onClick();
        }
    };

    const handleContainerKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Escape') {
            setVisible(false);
        }
    };

    return (
        <div className="dropdown-container" ref={ref} onKeyUp={handleContainerKeyUp}>
            <IconButton className="flat" 
                id={props.id} 
                onClick={handleDropdownOpen} 
                icon={<i className="ri-more-2-line"></i>} />
            {visible && <div className={getclassNames('dropdown-contents', props.openTop ? `top-${props.options.length}` : '')}>
                {props.options.map((o, i) => {
                    if (props.disabled) {
                        return <div key={i} tabIndex={0} className={getclassNames('dropdown-option', props.optionSize ?? 'medium')}>{o.label}</div>
                    }

                    if (o.href) {
                        return <Link key={i} to={o.href} target={o.newWindow ? '_blank' : '_self'} className={getclassNames('dropdown-option', props.optionSize ?? 'medium')} tabIndex={0}>{o.label}</Link>
                    }

                    return <div key={i} onKeyUp={(e) => handleOptionOnKeyUp(e, o.onClick)} tabIndex={0} className={getclassNames('dropdown-option', props.optionSize ?? 'medium')} onClick={o.onClick}>{o.label}</div>
                })}
            </div>}
        </div>
    )
});

export default Dropdown;