import TextEditForm from './TextEditForm';
import { ACTIVITY_DATE_ID, CHECKBOX_GROUP_ID, CHECKBOX_ID, DATE_ID, HEADER_ID, MULTI_LINE_TEXT_ID, NOTES_ID, NUMBER_ID, RADIO_BUTTON_ID, SELECT_LIST_ID, TEXT_ID, TIME_SPENT_ID } from '../../../../constants/fieldTypes';
import EditFormProps from './Models/EditFormProps';
import NumberEditForm from './NumberEditForm';
import RadioButtonEditForm from './RadioButtonEditForm';
import CheckboxEditForm from './CheckboxEditForm';
import CheckboxGroupEditForm from './CheckboxGroupEditForm';
import SelectListEditForm from './SelectListEditForm';
import MultiLineTextEditForm from './MultiLineTextEditForm';
import HeaderEditForm from './HeaderEditForm';
import DateEditForm from './DateEditForm';
import NotesEditForm from './NotesEditForm';
import TimeSpentEditForm from './TimeSpentEditForm';
import ActivityDateEditForm from './ActivityDateEditForm';

const EditFormMapping: { [id: number]: (props: EditFormProps) => JSX.Element } = {
    [TEXT_ID]: TextEditForm,
    [NUMBER_ID]: NumberEditForm,
    [RADIO_BUTTON_ID]: RadioButtonEditForm,
    [CHECKBOX_ID]: CheckboxEditForm,
    [CHECKBOX_GROUP_ID]: CheckboxGroupEditForm,
    [SELECT_LIST_ID]: SelectListEditForm,
    [MULTI_LINE_TEXT_ID]: MultiLineTextEditForm,
    [HEADER_ID]: HeaderEditForm,
    [DATE_ID]: DateEditForm,
    [NOTES_ID]: NotesEditForm,
    [TIME_SPENT_ID]: TimeSpentEditForm,
    [ACTIVITY_DATE_ID]: ActivityDateEditForm
};

export {
    TextEditForm,
    NumberEditForm,
    RadioButtonEditForm,
    CheckboxEditForm,
    CheckboxGroupEditForm,
    SelectListEditForm,
    MultiLineTextEditForm,
    NotesEditForm,
    TimeSpentEditForm,
    ActivityDateEditForm,
    EditFormMapping
}