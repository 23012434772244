import { customReportFiltersEnabled } from "../utils/features";

const ACTIVE_PARTICIPANTS = 'ACTIVE_PARTICIPANTS';
const ENROLLED_PARTICIPANTS = 'ENROLLED_PARTICIPANTS';
const ACTIVE_PARTICIPANTS_SUMMARY = 'ACTIVE_PARTICIPANTS_SUMMARY';
const ENROLLED_PARTICIPANTS_SUMMARY = 'ENROLLED_PARTICIPANTS_SUMMARY';
const DELIVERY_FORMS = 'DELIVERY_FORMS';
const CASE_PLANS = 'CASE_PLANS'; 
const DELIVERY_FORMS_SUMMARY = 'DELIVERY_FORMS_SUMMARY';
const ASSESSMENT_FORMS = 'ASSESSMENT_FORMS'; 
const PROGRAM_COUNTS = 'PROGRAM_COUNTS';
const DEMOGRAPHICS = 'DEMOGRAPHICS';
const OUTPUTS_AND_OUTCOMES = 'OUTPUTS_AND_OUTCOMES';
const SUBMITTED_FORMS = 'SUBMITTED_FORMS';
const ASSESSMENTS = 'ASSESSMENTS';
const PLANS = 'PLANS';



const ALL_REPORT_TYPES = customReportFiltersEnabled ? [
    SUBMITTED_FORMS,
    DEMOGRAPHICS, 
    PLANS
] : [
    ASSESSMENTS,
    DEMOGRAPHICS, 
    OUTPUTS_AND_OUTCOMES,
    PLANS
];

const REPORT_TYPE_NAME_MAP: {[key: string]: string} = {
    [DEMOGRAPHICS]: 'Participant Demographics',
    [OUTPUTS_AND_OUTCOMES]: 'Submitted Outputs & Outcomes',
    [ASSESSMENTS]: 'Submitted Assessments',
    [SUBMITTED_FORMS]: 'Submitted Tracking Forms',
    [PLANS]: 'Plans'
};

export {
    ALL_REPORT_TYPES,
    REPORT_TYPE_NAME_MAP,
    ASSESSMENTS,
    OUTPUTS_AND_OUTCOMES, 
    PLANS,
    DEMOGRAPHICS,
    ACTIVE_PARTICIPANTS_SUMMARY,
    ENROLLED_PARTICIPANTS_SUMMARY,
    ACTIVE_PARTICIPANTS,
    ENROLLED_PARTICIPANTS,
    DELIVERY_FORMS,
    ASSESSMENT_FORMS,
    PROGRAM_COUNTS,
    DELIVERY_FORMS_SUMMARY,
    CASE_PLANS,
    SUBMITTED_FORMS
}