import { useState, MouseEvent, useEffect } from "react";
import getclassNames from "../../utils/getClassNames";
import Button from "./Button";
import IconButton from "./IconButton";
import './SideMenu.css';

interface SideMenuOption {
    text: string;
    name?: string;
    onClick?: (name?: string) => void;
    href?: string;
    className?: string;
    hideIcon?: boolean;
    role?: string;
    disabled?: boolean
}

interface SideMenuProps {
    id: string;
    title?: string;
    options: SideMenuOption[];
    secondaryTitle?: string;
    secondaryOptions?: SideMenuOption[];
    headerContent?: any;
    headerContentStart?: any;
    headerContentEnd?: any;
    mainContent?: any;
    ariaLabel?: string;
}

const SideMenu = (props: SideMenuProps) => {
    const [showMenu, setShowMenu] = useState(false);

    const handleOnClick = (option: SideMenuOption) => {
        if (option.onClick) {
            option.onClick(option.name);
        }
        setShowMenu(false);
    };

    const handleMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const closeMenu = () => {
            setShowMenu(false);
        };

        document.addEventListener('click', closeMenu);

        return () => {
            document.removeEventListener('click', closeMenu);
        };
    }, []);

    return (

        <div className="side-menu-content-container" >
            <div className={getclassNames('side-menu-container', showMenu ? 'showing' : '')}>
                <div className="side-menu" onClick={(e) => e.stopPropagation()} role="navigation" aria-label={props.ariaLabel ? props.ariaLabel : "Page Navigation Menu"}>
                    <div className="side-menu-header">
                        <IconButton className={getclassNames('side-menu-button small sub-menu', showMenu ? 'showing' : '')} onClick={handleMenuClick} id="side-menu-button" icon={<i className="ri-menu-line" />} />
                        {props.title && <div className="side-menu-title">{props.title} </div>}
                    </div>
                    <div className="side-menu-options" >
                        {props.options.map((o, i) => {
                            return (<Button id={`${props.id}-option-${i}`} key={i}
                                role="menuitem"
                                onClick={() => handleOnClick(o)}
                                disabled={o.disabled}
                                href={o.href}
                                className={o.className}
                                contentsClassName="justify-content-between"
                                icon={!o.hideIcon ? (<i className="ri-arrow-right-s-line" />) : null}
                                iconLocation="end"
                                text={o.text} />)
                        })}
                    </div>
                    {props.secondaryTitle && (<div className="side-menu-header">
                        <div className="side-menu-title">{props.secondaryTitle} </div>
                    </div>)}
                    {props.secondaryOptions && <div className="side-menu-options" >
                        {props.secondaryOptions.map((o, i) => {
                            return (<Button id={`${props.id}-option-${i}`} key={i}
                                role="menuitem"
                                onClick={() => handleOnClick(o)}
                                href={o.href}
                                className={o.className}
                                disabled={o.disabled}
                                contentsClassName="justify-content-between"
                                icon={!o.hideIcon ? (<i className="ri-arrow-right-s-line" />) : null}
                                iconLocation="end"
                                text={o.text} />)
                        })}
                    </div>}
                </div>
            </div>
            {!props.headerContentStart && <div className="content-container">
                <div className="content">
                    {props.headerContent}
                    {props.mainContent && (<div className={getclassNames('main-content', props.headerContent ? 'border-top margin-top-6' : '')}>
                        {props.mainContent}
                    </div>)}
                </div>
            </div>}
            {props.headerContentStart && <div className="content-container">
                <div className="content">
                    <div className="row">
                        <div className="column-large-7 start-header">
                            <div className="start-header-content">
                                {props.headerContentStart}
                            </div>
                        </div>
                        <div className="column-large-5 end-header">
                            <div className="end-header-content">
                                {props.headerContentEnd}
                            </div>
                        </div>
                    </div>
                    {props.mainContent && (<div className="main-content">
                        {props.mainContent}
                    </div>)}
                </div>
            </div>}
        </div>
    );
};

export default SideMenu;