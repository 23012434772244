import { ASSESSMENTS, DEMOGRAPHICS, OUTPUTS_AND_OUTCOMES, SUBMITTED_FORMS } from "../../../constants/reportTypes";
import DemographicsSummary from "./DemographicsSummary";
import FormsSummary from "./FormsSummary";
import SummaryProps from "./SummaryProps";

const mapping: { [key: string]: (props: SummaryProps) => JSX.Element } = {
    [DEMOGRAPHICS]: DemographicsSummary,
    [OUTPUTS_AND_OUTCOMES]: FormsSummary,
    [ASSESSMENTS]: FormsSummary,
    [SUBMITTED_FORMS]: FormsSummary
};

export {
    mapping
};