import NumberDisplayField from './NumberDisplayField';
import TextDisplayField from './TextDisplayField';
import RadioButtonDisplayField from './RadioButtonDisplayField';
import { ACTIVITY_DATE_ID, BREAK_LINE_ID, CHECKBOX_GROUP_ID, CHECKBOX_ID, DATE_ID, HEADER_ID, MULTI_LINE_TEXT_ID, NOTES_ID, NUMBER_ID, RADIO_BUTTON_ID, SELECT_LIST_ID, TEXT_ID, TIME_SPENT_ID } from '../../../../constants/fieldTypes';
import DisplayFieldProps from './Models/DisplayFieldProps';
import CheckboxDisplayField from './CheckboxDisplayField';
import CheckboxGroupDisplayField from './CheckboxGroupDisplayField';
import SelectListDisplayField from './SelectListDisplayField';
import MultiLineTextDisplayField from './MultiLineTextDisplayField';
import HeaderDisplayField from './HeaderDisplayField';
import ActivityDateDisplayField from './ActivityDateDisplayField';
import NotesDisplayField from './NotesDisplayField';
import TimeSpentDisplayField from './TimeSpentDisplayField';
import DateDisplayField from './DateDisplayField';
import BreakLineDisplayField from './BreakLineDisplayField';

const DisplayFieldMapping: { [id: number]: (props: DisplayFieldProps) => JSX.Element } = {
    [TEXT_ID]: TextDisplayField,
    [NUMBER_ID]: NumberDisplayField,
    [RADIO_BUTTON_ID]: RadioButtonDisplayField,
    [CHECKBOX_ID]: CheckboxDisplayField,
    [CHECKBOX_GROUP_ID]: CheckboxGroupDisplayField,
    [SELECT_LIST_ID]: SelectListDisplayField,
    [MULTI_LINE_TEXT_ID]: MultiLineTextDisplayField,
    [HEADER_ID]: HeaderDisplayField,
    [ACTIVITY_DATE_ID]: ActivityDateDisplayField,
    [NOTES_ID]: NotesDisplayField,
    [TIME_SPENT_ID]: TimeSpentDisplayField,
    [DATE_ID]: DateDisplayField,
    [BREAK_LINE_ID]: BreakLineDisplayField
};

export {
    NumberDisplayField,
    TextDisplayField,
    RadioButtonDisplayField,
    CheckboxDisplayField,
    CheckboxGroupDisplayField,
    SelectListDisplayField,
    MultiLineTextDisplayField,
    DisplayFieldMapping
}