import React, { FormEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import Input from "../UI/Input";
import Button from "../UI/Button";
import Loader from "../UI/Loader";
import ValidatedInput from "../UI/ValidatedInput";
import ValidatedSelect from "../UI/ValidatedSelect";
import { format, isMatch } from "date-fns";
import FormEditor from "../Form/Editor/FormEditor";
import { formatJSONDateWithoutTime } from "../../utils/dateHelpers";
import { useNavigate } from "react-router-dom";
import { createClient, getClientById, getEthnicities, getGenders, getRaces, getRelationships, updateClient } from "../../services/clientsService";
import Relationship from "../../models/serviceModels/Relationship";
import Gender from "../../models/serviceModels/Gender";
import Race from "../../models/serviceModels/Race";
import Client from "../../models/serviceModels/Client";
import { getClientForm, getClientFormInstance } from "../../services/formsService";
import Form from "../../models/serviceModels/Form";
import FormInstance from "../../models/serviceModels/FormInstance";
import EditableFormField from "../../models/pageModels/EditableFormField";
import HouseholdMember from "../../models/serviceModels/HouseholdMember";
import CreateOrEditClientModel from "../../models/pageModels/CreateOrEditClientModel";
import CreateOrEditHouseholdMemberModel from "../../models/pageModels/CreateOrEditHouseholdMemberModel";
import BackButton from "../UI/BackButton";
import ApiErrorMessages from "../UI/ApiErrorMessages";
import Select from "../UI/Select";
import Ethnicity from "../../models/serviceModels/Ethnicity";
import CheckboxGroup from "../UI/CheckboxGroup";
import states from "../../constants/states";
import RadioButton from "../UI/RadioButton";
import { improvedAddPaticipantEnabled } from "../../utils/features";
import { enrollInProgram, searchPrograms } from "../../services/programsService";
import PagedData from "../../models/serviceModels/PagedData";
import Program from "../../models/serviceModels/Program";
import ErrorMessages from "../UI/ErrorMessages";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getParticipantDefaultErrors = () => {
    return {
        firstName: [],
        lastName: [],
        birthdate: [],
        race: [],
        ethnicity: [],
        gender: [],
        enrolled: [],
        activated: []
    };
}

const getHouseholdMemberDefaultErrors = () => {
    return {
        firstName: [],
        lastName: [],
        birthdate: [],
        race: [],
        ethnicity: [],
        gender: [],
        relationship: []
    };
}

interface ParticipantFormProps {
    id?: string;
}

const getDefaultModel = (): CreateOrEditClientModel => {
    return {
        id: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        genderId: '',
        ethnicityId: '',
        raceIds: [],
        pronouns: '',
        middleName: '',
        addressCity: '',
        addressLine1: '',
        addressLine2: '',
        addressState: '',
        addressZipCode: '',
        deletedHouseholdMembers: [],
        householdMembers: [],
        phoneNumber: '',
        email: '',
        activated: format(new Date(), 'yyyy-MM-dd'),
        enrolled: format(new Date(), 'yyyy-MM-dd'),
        errors: getParticipantDefaultErrors(),
        selectedPrograms: []
    };
}

const ParticipantForm = (props: ParticipantFormProps) => {
    const [model, setModel] = useState<CreateOrEditClientModel>(getDefaultModel());

    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [genderOptions, setGenderOptions] = useState<Gender[]>([]);
    const [racesOptions, setRacesOptions] = useState<Race[]>([]);
    const [ethnicityOptions, setEthnicityOptions] = useState<Ethnicity[]>([]);
    const [fields, setFields] = useState<EditableFormField[]>([]);
    const [form, setForm] = useState<Form | null>(null);
    const [formInstance, setFormInstance] = useState<FormInstance | null>(null);
    const [relationshipOptions, setRelationshipOptions] = useState<Relationship[]>([]);
    const [submitError, setSubmitError] = useState<any>();
    const [submittingAndCreateNew, setSubmittingAndCreateNew] = useState(false);
    const firstNameRef = useRef<HTMLInputElement>();
    const [programs, setPrograms] = useState<Program[]>([]);
    const loaded = useRef(false);
    const [showPrograms, setShowPrograms] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        const promises: [Promise<Race[]>, Promise<Gender[]>, Promise<Form>, Promise<Relationship[]>, Promise<Ethnicity[]>, Promise<PagedData<Program>>, Promise<Client>?, Promise<FormInstance>?] = [
            getRaces(),
            getGenders(),
            getClientForm(),
            getRelationships(),
            getEthnicities(),
            props.id || !improvedAddPaticipantEnabled ? Promise.resolve({ // we don't need to get the programs if we are just editing an existing participant
                data: [],
                totalCount: 0
            }) : searchPrograms({
                take: 1000
            })
        ];

        if (props.id) {
            promises.push(getClientById(props.id));
            promises.push(getClientFormInstance(props.id));
        }

        Promise.all(promises).then(([races, genders, clientForm, relationships, ethnicities, programsPage, client, clientFormInstance]) => {
            setGenderOptions([...genders]);
            setRacesOptions([...races]);
            setRelationshipOptions([...relationships]);
            setEthnicityOptions([...ethnicities]);
            setPrograms([...programsPage.data]);

            if (clientForm) {
                setFields([
                    ...clientForm.fields.map((f: any) => {
                        return {
                            ...f,
                            value: '',
                            files: []
                        };
                    })
                ]);
                setForm({ ...clientForm });
            }

            if (client) {
                model.id = client.id;
                model.firstName = client.firstName;
                model.middleName = client.middleName || '';
                model.lastName = client.lastName;
                model.dateOfBirth = formatJSONDateWithoutTime(client.dateOfBirth, 'yyyy-MM-dd');
                model.ethnicityId = client.ethnicityId?.toString() || '';
                model.genderId = client.genderId?.toString() || '';
                model.addressLine1 = client.addressLine1 || '';
                model.addressLine2 = client.addressLine2 || '';
                model.pronouns = client.pronouns || '';
                model.addressCity = client.addressCity || '';
                model.addressState = client.addressState || '';
                model.addressZipCode = client.addressZipCode || '';
                model.phoneNumber = client.phoneNumber || '';
                model.raceIds = client.races.map(r => {
                    return {
                        label: r.name,
                        value: r.id.toString()
                    };
                }).filter(r => r !== null);
                model.email = client.email || '';
                model.householdMembers = client.householdMembers.map((hhm: HouseholdMember) => {
                    return {
                        id: hhm.id,
                        firstName: hhm.firstName,
                        middleName: hhm.middleName || '',
                        lastName: hhm.lastName,
                        dateOfBirth: formatJSONDateWithoutTime(hhm.dateOfBirth, 'yyyy-MM-dd'),
                        genderId: hhm.genderId?.toString() || '',
                        ethnicityId: hhm.ethnicityId?.toString() || '',
                        email: hhm.email,
                        phoneNumber: hhm.phoneNumber,
                        raceIds: hhm.races.map(r => {
                            return {
                                label: r.name,
                                value: r.id.toString()
                            }
                        }),
                        relationshipId: hhm.relationshipId.toString(),
                        errors: getHouseholdMemberDefaultErrors()
                    }
                });

                setModel({ ...model });
            }

            if (clientFormInstance) {
                setFields([
                    ...clientForm.fields.map((cf: any) => {
                        const existingValue = clientFormInstance.instanceFields.find((inf: any) => inf.name === cf.name);
                        return {
                            ...cf,
                            instanceFieldId: existingValue?.id,
                            value: existingValue?.value || '',
                            files: existingValue?.files || []
                        };
                    })
                ]);

                setFormInstance(clientFormInstance);
            }
        }).finally(() => {
            setLoading(false);
            loaded.current = true;
        });
    }, [props.id]);

    useEffect(() => {
        if (!loading && firstNameRef.current) {
            firstNameRef.current.focus();
        }
    }, [loading]);

    const reset = () => {
        setModel(getDefaultModel());
        setFields([
            ...fields.map(f => {
                return {
                    ...f,
                    value: '',
                    files: []
                };
            })
        ]);

        if (firstNameRef.current) {
            firstNameRef.current.focus();
        }

        setShowPrograms(false);
    };

    const handleOnFieldsChange = (fields: any[]) => {
        setFields([
            ...fields
        ]);
    };

    const validate = () => {
        let isValid = true;

        for (let i = 0; i < model.householdMembers.length; i++) {
            const member = model.householdMembers[i];
            member.errors = getHouseholdMemberDefaultErrors();

            if (!member.firstName) {
                isValid = false;
                member.errors.firstName.push('First Name is required');
            }

            if (!member.lastName) {
                isValid = false;
                member.errors.lastName.push('Last Name is required');
            }

            if (!member.dateOfBirth) {
                isValid = false;
                member.errors.birthdate.push('Birthdate is required');
            } else if (!isMatch(member.dateOfBirth, 'yyyy-MM-dd')) {
                isValid = false;
                member.errors.birthdate.push('Birthdate is not valid');
            }

            if (!member.relationshipId) {
                isValid = false;
                member.errors.relationship.push('Relationship is required');
            }
        }

        model.errors = getParticipantDefaultErrors();
        if (!model.firstName) {
            isValid = false;
            model.errors.firstName.push('First Name is required');
        }

        if (!model.lastName) {
            isValid = false;
            model.errors.lastName.push('Last Name is required');
        }

        if (model.activated) {
            if (!isMatch(model.activated, 'yyyy-MM-dd')) {
                isValid = false;
                model.errors.activated.push('Activated date is not valid');
            }
        }

        if (model.selectedPrograms.length !== 0) {
            if (!model.enrolled) {
                isValid = false;
                model.errors.enrolled.push('Enrolled date is required if enrolling in programs');
            } else if (!isMatch(model.enrolled, 'yyyy-MM-dd')) {
                isValid = false;
                model.errors.enrolled.push('Enrolled date is not valid');
            }
        }

        if (!model.dateOfBirth) {
            isValid = false;
            model.errors.birthdate.push('Birthdate is required');
        } else if (!isMatch(model.dateOfBirth, 'yyyy-MM-dd')) {
            isValid = false;
            model.errors.birthdate.push('Birthdate is not valid');
        }

        setModel({ ...model });

        return isValid;
    };

    const handleUpdatingParticipant = async () => {
        try {
            const client = await updateClient({
                id: model.id,
                firstName: model.firstName,
                middleName: model.middleName,
                lastName: model.lastName,
                dateOfBirth: model.dateOfBirth,
                genderId: (model.genderId && Number(model.genderId)) || undefined,
                ethnicityId: (model.ethnicityId && Number(model.ethnicityId)) || undefined,
                raceIds: model.raceIds?.map(ri => Number(ri.value)) || undefined,
                pronouns: model.pronouns,
                addressCity: model.addressCity,
                addressLine1: model.addressLine1,
                addressLine2: model.addressLine2,
                addressState: model.addressState,
                addressZipCode: model.addressZipCode,
                phoneNumber: model.phoneNumber,
                email: model.email,
                householdMembers: [
                    ...model.householdMembers.map(hhm => {
                        return {
                            id: hhm.id,
                            firstName: hhm.firstName,
                            middleName: hhm.middleName,
                            lastName: hhm.lastName,
                            dateOfBirth: hhm.dateOfBirth,
                            ethnicityId: (hhm.ethnicityId && Number(hhm.ethnicityId)) || undefined,
                            raceIds: hhm.raceIds?.map(ri => Number(ri.value)) || undefined,
                            genderId: (hhm.genderId && Number(hhm.genderId)) || undefined,
                            relationshipId: Number(hhm.relationshipId),
                            phoneNumber: hhm.phoneNumber,
                            email: hhm.email
                        }
                    })
                ],
                deletedHouseholdMembers: model.deletedHouseholdMembers
            });
            
            if (form && fields.length > 0) {
                if (formInstance && form.versionId === formInstance.formVersionId) {
                    await axios.put(`${baseUrl}/forms/${form.id}/instances/${formInstance.id}`, {
                        clientId: client.id,
                        fields: fields.map(f => {
                            return {
                                instanceFieldId: f.instanceFieldId,
                                value: f.value,
                                files: f.files?.map(f => f.fileId!) || []
                            };
                        })
                    });
                } else {
                    await axios.post(`${baseUrl}/forms/${form.id}/versions/${form.versionId}/instances`, {
                        clientId: client.id,
                        fields: fields.map(f => {
                            return {
                                fieldId: f.id,
                                value: f.value,
                                files: f.files?.map(f => f.fileId!) || []
                            };
                        })
                    });
                    
                }
            }
            
            navigate(`/participants/${props.id}/profile-demographics`);
        } catch (e) {
            console.error(e);
            setSubmitError(e);
        } finally {
            setSubmitting(false);
        }
    }

    const handleCreatingParticipant = async (createNew = false) => {
        setSubmittingAndCreateNew(createNew);

        try {
            const client = await createClient({
                firstName: model.firstName,
                middleName: model.middleName,
                lastName: model.lastName,
                dateOfBirth: model.dateOfBirth,
                genderId: (model.genderId && Number(model.genderId)) || undefined,
                ethnicityId: (model.ethnicityId && Number(model.ethnicityId)) || undefined,
                raceIds: model.raceIds?.map(ri => Number(ri.value)) || undefined,
                pronouns: model.pronouns,
                addressCity: model.addressCity,
                addressLine1: model.addressLine1,
                addressLine2: model.addressLine2,
                addressState: model.addressState,
                addressZipCode: model.addressZipCode,
                phoneNumber: model.phoneNumber,
                email: model.email,
                activated: model.activated,
                householdMembers: [
                    ...model.householdMembers.map(hhm => {
                        return {
                            firstName: hhm.firstName,
                            middleName: hhm.middleName,
                            lastName: hhm.lastName,
                            dateOfBirth: hhm.dateOfBirth,
                            ethnicityId: (hhm.ethnicityId && Number(hhm.ethnicityId)) || undefined,
                            raceIds: hhm.raceIds?.map(ri => Number(ri.value)),
                            genderId: (hhm.genderId && Number(hhm.genderId)) || undefined,
                            relationshipId: Number(hhm.relationshipId),
                            phoneNumber: hhm.phoneNumber,
                            email: hhm.email
                        }
                    })
                ]
            });

            if (client) {
                if (form && form.fields.length !== 0) {
                    await axios.post(`${baseUrl}/forms/${form.id}/versions/${form.versionId}/instances`, {
                        clientId: client.id,
                        fields: fields.map(f => {
                            return {
                                fieldId: f.id,
                                value: f.value,
                                files: f.files?.map(f => f.fileId!) || []
                            };
                        })
                    });
                }

                if (model.selectedPrograms.length !== 0) {
                    for (const program of model.selectedPrograms) {
                        await enrollInProgram(program.value, client.id, model.enrolled);
                    }
                }

                if (createNew) {
                    reset();
                } else {
                    navigate(`/participants/${client.id}/profile-demographics`);
                }
            }
        } catch (e) {
            console.error(e);
            setSubmitError(e);
        } finally {
            setSubmitting(false);
            setSubmittingAndCreateNew(false);
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitError(undefined);
        if (!validate()) {
            return;
        }
        setSubmitting(true);

        if (props.id) {
            await handleUpdatingParticipant();
        } else {
            await handleCreatingParticipant();
        }
    };

    const handleCreating = async () => {
        setSubmitError(undefined);
        if (!validate()) {
            return;
        }
        setSubmitting(true);

        await handleCreatingParticipant(true);
    }

    const handleAddingHouseholdMember = () => {
        setModel({
            ...model,
            householdMembers: [{
                id: '',
                firstName: '',
                middleName: '',
                lastName: '',
                dateOfBirth: '',
                ethnicityId: '',
                raceIds: [],
                genderId: '',
                relationshipId: '',
                email: '',
                phoneNumber: '',
                errors: getParticipantDefaultErrors()
            },
            ...model.householdMembers
            ]
        });
    };

    const handleRemoveHouseholdMember = (member: any) => {
        model.householdMembers = [
            ...model.householdMembers.filter(hhm => hhm !== member)
        ];

        if (member.id) {
            model.deletedHouseholdMembers = [
                ...model.deletedHouseholdMembers,
                member.id
            ];
        }

        setModel({
            ...model,
        });
    };

    const handleChangingHouseholdMember = (updateField: (member: CreateOrEditHouseholdMemberModel) => void, index: number) => {
        updateField(model.householdMembers[index]);
        setModel({
            ...model
        });
    };

    if (loading) {
        return (<div className="section">
            <Loader />
        </div>);
    }

    return (
        <div className="section">
            <div className="row justify-content-center">
                <div className="column-medium-6">
                    <BackButton />
                    <h1 className="form-header">{props.id ? 'Edit' : 'Add New'} Participant</h1>
                    <form onSubmit={handleSubmit} className="participant-form">
                        <ApiErrorMessages error={submitError} />
                        <h2 className="section-header">Basic Information</h2>
                        {!model.id && <div className="row margin-vertical-4">
                            <div className="column-large-4">
                                <ValidatedInput id="activated-date"
                                    onChange={(e) => setModel({ ...model, activated: e })}
                                    value={model.activated}
                                    label="Activated Date"
                                    type="date"
                                    messages={model.errors.activated} />
                            </div>
                        </div>}
                        <div className="row margin-vertical-4">
                            <div className="column-large-3">
                                <ValidatedInput id="first-name"
                                    onChange={(e) => setModel({ ...model, firstName: e })}
                                    required={true} value={model.firstName}
                                    ref={firstNameRef}
                                    label="First Name"
                                    messages={model.errors.firstName} />
                            </div>
                            <div className="column-large-3">
                                <Input id="middle-name"
                                    onChange={(e) => setModel({ ...model, middleName: e })}
                                    value={model.middleName}
                                    label="Middle Name" />
                            </div>
                            <div className="column-large-3">
                                <ValidatedInput id="last-name"
                                    onChange={(e) => setModel({ ...model, lastName: e })}
                                    required={true}
                                    value={model.lastName}
                                    label="Last Name"
                                    messages={model.errors.lastName} />
                            </div>
                            <div className="column-large-3">
                                <ValidatedInput id="birth-date"
                                    onChange={(e) => setModel({ ...model, dateOfBirth: e })}
                                    required={true}
                                    value={model.dateOfBirth}
                                    label="Date of Birth"
                                    type="date"
                                    messages={model.errors.birthdate} />
                            </div>
                        </div>
                        <div className="row margin-vertical-4">
                            <div className="column-large-4">
                                <Input id="preferred-pronouns"
                                    onChange={(e) => setModel({ ...model, pronouns: e })}
                                    value={model.pronouns}
                                    label="Preferred Pronouns" />
                            </div>
                            <div className="column-large-4">
                                <Select id="gender"
                                    onChange={(e) => setModel({ ...model, genderId: e })}
                                    value={model.genderId}
                                    label="Gender">
                                    <option value="">--Select--</option>
                                    {genderOptions.map(go => {
                                        return <option key={go.id} value={go.id}>{go.name}</option>
                                    })}
                                </Select>
                            </div>
                            <div className="column-large-4">
                                <RadioButton id="ethnicity"
                                    onChange={(e) => setModel({ ...model, ethnicityId: e })}
                                    value={model.ethnicityId}
                                    label="Ethnicity"
                                    options={ethnicityOptions.map(e => {
                                        return {
                                            label: e.name,
                                            value: e.id.toString()
                                        }
                                    })} />
                            </div>
                        </div>
                        <div className="row margin-vertical-4">
                            <div className="column">
                                <CheckboxGroup id="race"
                                    onChange={(e) => setModel({ ...model, raceIds: e })}
                                    checkedOptions={model.raceIds}
                                    label="Race"
                                    options={racesOptions.map(ro => {
                                        return {
                                            label: ro.name,
                                            value: ro.id.toString()
                                        }
                                    })} />
                            </div>
                        </div>
                        <div className="row margin-vertical-4">
                            <div className="column-large-6">
                                <Input id="phone-number"
                                    onChange={(e) => setModel({ ...model, phoneNumber: e })}
                                    value={model.phoneNumber}
                                    label="Phone Number"
                                    mask="(000)000-0000"
                                    useMaskedValue={true} />
                            </div>
                            <div className="column-large-6">
                                <Input id="email"
                                    onChange={(e) => setModel({ ...model, email: e })}
                                    value={model.email}
                                    label="Email" />
                            </div>
                        </div>
                        <h2 className="section-header">Address</h2>
                        <div className="row margin-vertical-4">
                            <div className="column">
                                <Input id="address-line-1"
                                    onChange={(e) => setModel({ ...model, addressLine1: e })}
                                    value={model.addressLine1}
                                    label="Line 1" />
                            </div>
                        </div>
                        <div className="row margin-vertical-4">
                            <div className="column">
                                <Input id="address-line-2"
                                    onChange={(e) => setModel({ ...model, addressLine2: e })}
                                    value={model.addressLine2}
                                    label="Line 2" />
                            </div>
                        </div>
                        <div className="row margin-vertical-4">
                            <div className="column-large-4">
                                <Input id="city"
                                    onChange={(e) => setModel({ ...model, addressCity: e })}
                                    value={model.addressCity}
                                    label="City" />
                            </div>
                            <div className="column-large-4">
                                <Select id="state"
                                    onChange={(e) => setModel({ ...model, addressState: e })}
                                    value={model.addressState}
                                    label="State">
                                    <option>Select One</option>
                                    {states.map(s => {
                                        return <option key={s.value} value={s.value}>{s.label}</option>
                                    })}
                                </Select>
                            </div>
                            <div className="column-large-4">
                                <Input id="zip-code"
                                    onChange={(e) => setModel({ ...model, addressZipCode: e })}
                                    value={model.addressZipCode}
                                    label="Zip Code" />
                            </div>
                        </div>
                        <h2 className="section-header">Household and Family Members</h2>
                        <Button id="add-household-member"
                            onClick={handleAddingHouseholdMember}
                            text="Add Member" />
                        {model.householdMembers.map((hhm, i) => {
                            return (<React.Fragment key={i}>
                                <div className="row margin-vertical-4">
                                    <div className="column-large-3">
                                        <ValidatedInput id={`household-member-first-name-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.firstName = e, i)}
                                            required={true}
                                            value={hhm.firstName}
                                            label="First Name"
                                            messages={hhm.errors.firstName} />

                                    </div>
                                    <div className="column-large-3">
                                        <Input id={`household-member-middle-name-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.middleName = e, i)}
                                            value={hhm.middleName}
                                            label="Middle Name" />

                                    </div>
                                    <div className="column-large-3">
                                        <ValidatedInput id={`household-member-last-name-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.lastName = e, i)}
                                            required={true}
                                            value={hhm.lastName}
                                            label="Last Name"
                                            messages={hhm.errors.lastName} />

                                    </div>
                                    <div className="column-large-3">
                                        <ValidatedInput id={`household-member-birth-date-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.dateOfBirth = e, i)}
                                            required={true}
                                            value={hhm.dateOfBirth}
                                            label="Date of Birth"
                                            type="date"
                                            messages={hhm.errors.birthdate} />
                                    </div>
                                </div>
                                <div className="row margin-vertical-4">
                                    <div className="column-large-6">
                                        <Input id="phone-number"
                                            onChange={(e) => handleChangingHouseholdMember(member => member.phoneNumber = e, i)}
                                            value={hhm.phoneNumber}
                                            label="Phone Number"
                                            mask="(000)000-0000"
                                            useMaskedValue={true} />
                                    </div>
                                    <div className="column-large-6">
                                        <Input id="email"
                                            onChange={(e) => handleChangingHouseholdMember(member => member.email = e, i)}
                                            value={hhm.email}
                                            label="Email" />
                                    </div>
                                </div>
                                <div className="row margin-vertical-4">
                                    <div className="column-large-4">
                                        <ValidatedSelect id={`household-member-relationship-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.relationshipId = e, i)}
                                            value={hhm.relationshipId}
                                            label="Relationship to Participant"
                                            messages={hhm.errors.relationship}
                                            required={true}>
                                            <option value="">---Select---</option>
                                            {relationshipOptions.map(r => {
                                                return <option key={r.id} value={r.id}>{r.name}</option>
                                            })}
                                        </ValidatedSelect>
                                    </div>
                                    <div className="column-large-4">
                                        <Select id={`household-member-gender-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.genderId = e, i)}
                                            value={hhm.genderId} label="Gender">
                                            <option value="">--Select--</option>
                                            {genderOptions.map(go => {
                                                return <option key={go.id} value={go.id}>{go.name}</option>
                                            })}
                                        </Select>
                                    </div>
                                    <div className="column-large-4">
                                        <RadioButton id={`household-member-ethnicity-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.ethnicityId = e, i)}
                                            value={hhm.ethnicityId}
                                            label="Ethnicity"
                                            options={ethnicityOptions.map(e => {
                                                return {
                                                    label: e.name,
                                                    value: e.id.toString()
                                                }
                                            })} />
                                    </div>
                                </div>
                                <div className="row margin-vertical-4">
                                    <div className="column">
                                        <CheckboxGroup id={`household-member-race-${i}`}
                                            onChange={(e) => handleChangingHouseholdMember(member => member.raceIds = e, i)}
                                            checkedOptions={hhm.raceIds} label="Race" options={racesOptions.map(ro => {
                                                return {
                                                    label: ro.name,
                                                    value: ro.id.toString()
                                                }
                                            })} />
                                    </div>
                                </div>
                                <div className="row margin-vertical-4 justify-content-end">
                                    <div className="column-auto">
                                        <Button id={`remove-household-member-${i}`}
                                            text="Remove"
                                            onClick={() => handleRemoveHouseholdMember(hhm)} />
                                    </div>
                                </div>
                                {i !== model.householdMembers.length - 1 && <hr className="margin-vertical-5" />}
                            </React.Fragment>)
                        })}
                        {fields && fields.length > 0 && (<>
                            <h2 className="section-header">Additional Information</h2>
                            <FormEditor disabled={false} fields={fields} onChange={handleOnFieldsChange} />
                        </>)}
                        {!model.id && improvedAddPaticipantEnabled && <h2>Enroll in Programs</h2>}
                        {!model.id && programs.length !== 0 && improvedAddPaticipantEnabled && <div className="row margin-vertical-4 align-items-center">
                            <div className="column-auto">
                                <Button id="assign-to-programs"
                                    text={showPrograms ? 'Hide Programs' : 'Show Programs'}
                                    icon={showPrograms ? (<i className="ri-arrow-up-s-line" />) : (<i className="ri-arrow-down-s-line" />)}
                                    iconLocation="end"
                                    className="secondary"
                                    onClick={() => setShowPrograms(!showPrograms)} />
                            </div>
                            <div className="column-auto">
                                Selected Programs: {model.selectedPrograms.length}
                            </div>
                            {model.errors.enrolled.length !== 0 && model.selectedPrograms.length !== 0 && !showPrograms && <div className="column-auto">
                                <ErrorMessages messages={model.errors.enrolled} />
                            </div>}
                        </div>}
                        {showPrograms && <div className="row margin-vertical-4">
                            <div className="column-6">
                                <CheckboxGroup id="available-programs"
                                    label="Programs"
                                    options={programs.map(p => {
                                        return {
                                            label: p.name,
                                            value: p.id
                                        }
                                    })}
                                    checkedOptions={model.selectedPrograms}
                                    onChange={(o) => setModel({...model, selectedPrograms: o})} />
                            </div>
                            <div className="column">
                                <ValidatedInput id="enrolled-date"
                                    onChange={(e) => setModel({ ...model, enrolled: e })}
                                    value={model.enrolled}
                                    label="Enrolled Date"
                                    type="date"
                                    messages={model.errors.enrolled} />
                            </div>
                        </div>}
                        <div className="row justify-content-end margin-top-5">
                            {!props.id && improvedAddPaticipantEnabled && <div className="column-auto">
                                <Button id="save-and-create-new-button"
                                    text="Save & Create New"
                                    className="flat"
                                    onClick={handleCreating}
                                    loading={submittingAndCreateNew}
                                    disabled={submitting} />
                            </div>}
                            <div className="column-auto">
                                <Button id="save-and-go-to-profile-button"
                                    type="submit"
                                    text={improvedAddPaticipantEnabled && !props.id ? "Save & Go to Profile" : "Save Participant"}
                                    loading={submitting && !submittingAndCreateNew}
                                    disabled={submitting} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ParticipantForm;