import ErrorMessages from "../../components/UI/ErrorMessages";

const Unauthorized = () => {
    return (<div className="section">
        <div className="row justify-content-center">
            <div className="column-auto">
                <ErrorMessages messages={["You are not authorized to view this page"]} />
            </div>
        </div>
    </div>);
};

export default Unauthorized;