import { useContext, useEffect, useState } from "react";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import Button from "../components/UI/Button";
import Checkbox from "../components/UI/Checkbox";
import Loader from "../components/UI/Loader";
import { answerTermsOfService } from "../services/organizationService";
import BackButton from "../components/UI/BackButton";
import { formatJSONDateWithoutTime } from "../utils/dateHelpers";
import { OrganizationContext } from "../App";

const ManageTermsOfService = () => {
    const [submitting, setSubmitting] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const organization = useContext(OrganizationContext);

    useEffect(() => {
        document.title = "Terms of Service"
    }, []);

    const getTosAgreeStatus = () => {
        if (organization.value && organization.value.tosAnswerOn) {
            return (
                <h4>Agreed to on {formatJSONDateWithoutTime(organization.value.tosAnswerOn, 'MM/dd/yyyy')}</h4>
            );
        }

        return null;
    }

    const handleOnSubmit = () => {
        if (!agreed) {
            return;
        }
        setApiError(undefined);
        setSubmitting(true);
        answerTermsOfService().then(o => {
            organization.refresh();
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const getButtons = () => {
        if (!organization.value || organization.value.tosAnswerBy) return null;

        return (<div className="row justify-content-center margin-vertical-5">
            <div className="column-auto">
                <Checkbox id="agree-tos" label="I Agree" disabled={submitting} checked={agreed} onChange={setAgreed} />
            </div>
            <div className="column-auto">
                <Button id="submit-agree-button" loading={submitting} disabled={submitting || !agreed} onClick={handleOnSubmit} text="Submit" />
            </div>
        </div>);
    };

    const getMainContent = () => {
        return <div>
            <div className="row">
                <div className="column">
                    You, your Permitted Affiliate(s), and your/their Authorized User(s) are all located in and residents of
                    the United States; will only use the Service within the territorial limits of the United States; and will
                    only submit, post, or otherwise transmit Client Data (as defined below) associated with Clients who
                    are located in the United States.
                </div>
            </div>
            {getTosAgreeStatus()}
            {getButtons()}
            <div className="row justify-content-center">
                <div className="column">
                    <iframe src="/tos.pdf" width={'100%'} height={800}></iframe>
                </div>
            </div>
            {getButtons()}
        </div>
    };

    return (<section id="main-content" tabIndex={-1}>
        <div className="row justify-content-center margin-vertical-2">
            <div className="column">
                <BackButton />
                <h1>Terms of Service</h1>
                <ApiErrorMessages error={apiError} />
                {organization.loading && <Loader />}
                {!organization.loading && organization.value && getMainContent()}
            </div>
        </div>
    </section>
    );

};

export default ManageTermsOfService;