import ModelErrors from "../models/pageModels/ModelErrors";

const getDefaultErrors = <T>(defaultModel: T): ModelErrors => {
    const keys = Object.keys(defaultModel);
    const errorsContainer: ModelErrors = {};
    keys.forEach(k => errorsContainer[k] = []);
    return errorsContainer;
};

export {
    getDefaultErrors
}