import React, { useEffect, useState } from 'react';
import { DisplayFieldMapping } from '../../components/Form/Builder/DisplayFields';
import Loader from '../../components/UI/Loader';
import { Mapping } from '../../constants/formTypes';
import { getForm } from '../../services/formsService';
import FieldModel from '../../models/pageModels/FieldModel';
import { mapFormFieldToFieldModel } from '../../mappers/formFieldMapper';
import ApiErrorMessages from '../../components/UI/ApiErrorMessages';

interface PreviewFormProps {
    formId: string;
    versionId: string;
}

const PreviewForm = (props: PreviewFormProps) => {
    const [form, setForm] = useState<any>(null);
    const [fields, setFields] = useState<FieldModel[]>([]);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        getForm(props.formId, props.versionId).then(form => {
            setForm(form);
            setFields([
                ...form.fields.map(f => mapFormFieldToFieldModel(f))
            ]);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        });
    }, [props]);

    if (!form) {
        return (<div className="section">
            <Loader />
        </div>);
    }

    const getField = (field: FieldModel) => {
        const MappedField = DisplayFieldMapping[field.fieldTypeId];
        return MappedField && <MappedField
            hideButtons={true}
            disabled={true}
            field={field}
            sortValues={field.sortValues}
            showAdvanced={false}
            onDelete={() => { }}
            onEdit={() => { }} />;
    };

    return (<>
        <ApiErrorMessages error={apiError} />
        <div className="row">
            <div className="column">
                <div className="row justify-content-center">
                    <div className="column-auto">
                        <h3 className="form-title">{form.name} - {Mapping[form.formTypeId]}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        {(!fields || fields.length === 0) && <>No fields found for this form</>}
                        {fields && fields.length > 0 && (
                            fields.map((f) => {
                                const field = getField(f);
                                if (!field) {
                                    return null;
                                }
                                return (<React.Fragment key={f.id}>
                                    <div className="row align-items-center">
                                        <div className="column">
                                            {field}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="column"><hr /></div>
                                    </div>
                                </React.Fragment>);
                            })
                        )}
                    </div>

                </div>
            </div>
        </div>
    </>)
};

export default PreviewForm;