import {defaultValue} from "./AdditionalInformationValidateResponse";
import { ValidateRequest } from "./ValidateRequest";
import ValidateResponse from "./ValidateResponse";
import * as filterOperators from "../../../constants/filterOperator";
import { isMatch, parse } from "date-fns";
import { getClientForm } from "../../../services/formsService";
import * as fieldTypes from '../../../constants/fieldTypes';

const GUID_REGEX = /^([\d|[a-zA-Z]:?){8}\-([\d|[a-zA-Z]:?){4}\-([\d|[a-zA-Z]:?){4}\-([\d|[a-zA-Z]:?){4}\-([\d|[a-zA-Z]:?){12}$/;
const NUMBER_REGEX = /^-?((\d+\.\d+:?)|(\d+)|(\.\d+):?)$/;
const TIME_SPENT_REGEX = /^((\d+\.\d+:?)|(\d+)|(\.\d+):?)$/;

const validate = async (request: ValidateRequest): Promise<ValidateResponse> => {
    const response = defaultValue();

    let parsedValue: {field?: string, value?: string, from?: string; to?: string; };

    try {
        parsedValue = JSON.parse(request.value);
    } catch (e) {
        response.isValid = false;
        response.default.push('An unexpected error occurred. Please contact support for assistance.');
        console.error('Failed to parse value for validation', e);
        return response; // this is stopping exception we need them to contact us. 
    }
    
    if (!request.operator) {
        response.operator.push('Operation is required');
        response.isValid = false;
    }

    if (!parsedValue.field) {
        response.isValid = false;
        response.field.push('Field is required');
    } else if (!GUID_REGEX.test(parsedValue.field)) {
        response.isValid = false;
        response.field.push('Field is invalid. Please contact support for assistance');
    }

    const form = await getClientForm();
    const matchingField = form.fields.find(f => f.id === parsedValue.field);

    if (response.field.length === 0 && !matchingField) {
        response.isValid = false;
        response.field.push('Field is invalid. Please contact support for assistance');
    }
    
    if (response.field.length === 0 && response.operator.length === 0) {
        if (request.operator === filterOperators.BETWEEN) {
            if (!parsedValue.from) {
                response.isValid = false;
                response.from.push('From date is required');
            } else if(!isMatch(parsedValue.from, 'yyyy-MM-dd')) {
                response.isValid = false;
                response.from.push('From date is invalid');
            }
    
            if (!parsedValue.to) {
                response.isValid = false;
                response.to.push('To date is required');
            } else if(!isMatch(parsedValue.to, 'yyyy-MM-dd')) {
                response.isValid = false;
                response.to.push('To date is invalid');
            }
    
            // we don't want to verify more if the from or to date is already invalid
            if (response.from.length === 0 && response.to.length === 0) {
                const parsedFromDate = parse(parsedValue.from!, 'yyyy-MM-dd', new Date());
                const parsedToDate = parse(parsedValue.to!, 'yyyy-MM-dd', new Date());
    
                if (parsedToDate < parsedFromDate) {
                    response.isValid = false;
                    response.default.push('From must be less than or equal to To');
                }
            }
        } else  {
            switch (matchingField!.fieldTypeId) {
                case fieldTypes.NUMBER_ID:
                    if (!NUMBER_REGEX.test(parsedValue.value!)) {
                        response.isValid = false;
                        response.value.push('Must be a valid number (Ex: -1882.99, 34, 0.23, ');
                    }
                    break;
                case fieldTypes.TIME_SPENT_ID:
                    if (!TIME_SPENT_REGEX.test(parsedValue.value!)) {
                        response.isValid = false;
                        response.value.push('Must be a positive number (Ex: 133.2, 0.33, 4, 534543)')
                    }
                    break;
                case fieldTypes.ACTIVITY_DATE_ID:
                case fieldTypes.DATE_ID:
                    if (!isMatch(parsedValue.value!, 'yyyy-MM-dd')) {
                        response.isValid = false;
                        response.value.push('Must be a valid date');
                    }
                    break;
                default:
                    if (!parsedValue.value) {
                        response.isValid = false;
                        response.value.push("Value is required");
                    }
                    break;
            }
        }
    }
    
    return response;
};

export default validate;
