import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ParticipantForm from "../components/AddParticipant/ParticpantForm";


const EditParticipant = () => {

    useEffect(() => {
        document.title = "Edit Participant"
    }
        , []);

    const { id } = useParams();

    return (
        <ParticipantForm id={id} />
    )

}
export default EditParticipant;
