import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import Checkbox from "../components/UI/Checkbox";
import Loader from "../components/UI/Loader";
import Modal from "../components/UI/Modal";
import ValidatedInput from "../components/UI/ValidatedInput";
import EditProgamPageModel from "../models/pageModels/EditProgramPageModel";
import ModelErrors from "../models/pageModels/ModelErrors";
import { activateProgram, deactivateProgram, getProgramById, updateProgram } from "../services/programsService";
import { getDefaultErrors } from "../utils/modelHelpers";

const getDefaultModel = (): EditProgamPageModel => {
    return {
        name: ''
    };
}

const EditProgram = () => {

    const [model, setModel] = useState<EditProgamPageModel>(getDefaultModel());
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors(getDefaultModel()));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [apiErrors, setApiErrors] = useState<any>();
    const [modalApiErrors, setModalApiErrors] = useState<any>();
    const [deactivatingActivating, setDeactivatingActivating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [failedLoading, setFailedLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [requestDeactivate, setRequestDeactivate] = useState(false);
    const [requestActivate, setRequestActivate] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams<string>();

    useEffect(() => {
        document.title = "Edit Program Name"
    }
        , []);

    useEffect(() => {
        getProgramById(id as string).then(program => {
            setModel({
                name: program.name,
            });
            setActive(!program.deactivated ? true : false);
        }).catch(e => {
            console.error(e);
            setFailedLoading(true);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model,
            [fieldName]: value
        });
    };

    const validate = () => {
        const newErrors = getDefaultErrors(getDefaultModel());
        let isValid = true;

        if (!model.name) {
            isValid = false;
            newErrors.name.push('Name is required');
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setApiErrors(undefined);
        setSubmitted(true);
        if (!validate()) {
            return;
        }
        setSubmitted(false);
        setSubmitting(true);
        updateProgram({
            id: id as string,
            ...model
        }).then(() => {
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(-1);
            }
        }).catch(e => {
            console.error(e);
            setApiErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const handleToggleActivateDeactivate = () => {
        if (!id) return;
        setModalApiErrors(undefined);
        setDeactivatingActivating(true);

        (active ? deactivateProgram(id) : activateProgram(id)).then(() => {
            setActive(!active);
            setRequestActivate(false);
            setRequestDeactivate(false);
        }).catch(e => {
            console.error(e);
            setModalApiErrors(e);
        }).finally(() => {
            setDeactivatingActivating(false);
        })
    };

    if (loading) {
        return (<div className="section">
            <Loader />
        </div>);
    }

    if (failedLoading) {
        return (<div className="section">
            <div className="row justify-content-center">
                <div className="column-auto">
                    Failed to load
                </div>
            </div>
        </div>);
    }

    return (
        <section id="main-content" tabIndex={-1}>
            <div className="row justify-content-center">
                <div className="column">
                    <div className="row">
                        <div className="column-auto">
                            <BackButton />
                        </div>
                    </div>
                    <h2>Update Program</h2>
                    <form onSubmit={handleOnSubmit}>
                        <ApiErrorMessages error={apiErrors} />
                        <div className="row margin-vertical-2">
                            <div className="column">
                                <ValidatedInput id="program-name"
                                    value={model.name}
                                    onChange={(e) => handleOnChange('name', e)}
                                    label="Name"
                                    required={true}
                                    messages={(submitted && modelErrors.name) || []} />
                            </div>
                        </div>
                        <div className="row justify-content-between margin-top-4">
                            <div className="column-auto">
                                {!active && <Button id="deactivate-button" className="secondary" onClick={() => setRequestActivate(true)} text="Activate" disabled={submitting || deactivatingActivating} />}
                                {active && <Button id="activate-button" className="secondary" onClick={() => setRequestDeactivate(true)} text="Deactivate" disabled={submitting || deactivatingActivating} />}
                            </div>
                            <div className="column-auto">
                                <Button id="submit-button" type="submit" text="Submit" loading={submitting} disabled={submitting || deactivatingActivating} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal id="activate-modal" visible={requestActivate} className="small" title="Activate Program" onClose={() => setRequestActivate(false)}>
                {requestActivate &&
                    <>
                        <ApiErrorMessages error={modalApiErrors} />
                        <div className="row">
                            <div className="column">
                                Are you sure you want to <strong>activate</strong> this program?
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-activate" text="Cancel" className="secondary" disabled={deactivatingActivating} onClick={() => setRequestActivate(false)} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-activate" text="Activate" disabled={deactivatingActivating} loading={deactivatingActivating} onClick={handleToggleActivateDeactivate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>
            <Modal id="deactivate-modal" visible={requestDeactivate} className="small" title="Dectivate Program" onClose={() => setRequestDeactivate(false)}>
                {requestDeactivate &&
                    <>
                        <ApiErrorMessages error={modalApiErrors} />
                        <div className="row">
                            <div className="column">
                                Are you sure you want to <strong>deactivate</strong> this program?
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-deactivate" text="Cancel" className="secondary" disabled={deactivatingActivating} onClick={() => setRequestDeactivate(false)} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-deactivate" text="Deactivate" disabled={deactivatingActivating} loading={deactivatingActivating} onClick={handleToggleActivateDeactivate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </section>

    )
};

export default EditProgram;