import ErrorMessages from "./ErrorMessages";

interface ApiErrorMessagesProps {
    error?: any
}

const ApiErrorMessages = (props: ApiErrorMessagesProps) => {
    if (!props.error) {
        return null;
    }

    if (props.error.error === 'login_required') {
        return <ErrorMessages messages={['You are not authorized to perform that action']} />
    }

    if (props.error.name !== 'AxiosError') {
        return null;
    }

    const status = props.error.response?.status;

    if (!status) {
        console.warn('Error occurred with no response');
        return null;
    }

    const errorMessage = props.error.response?.data?.error;

    if (status === 401) {
        return <ErrorMessages messages={[errorMessage?.replace('Unauthorized:', '') || 'You are not authorized to do that']} />
    }

    if (status === 403) {
        return <ErrorMessages messages={[errorMessage?.replace('Forbidden:', '') || 'You are not authorized to do that']} />
    }

    if (status === 404) {
        return <ErrorMessages messages={[errorMessage?.replace('NotFound:', '') || 'We are unable to find the resource you requested']} />;    
    } 

    if (status === 400) {
        const data = props.error.response.data;
        if (data.validation) {
            return <ErrorMessages messages={data.validation.map((v:any) => v.messages).flat()} />
        } else if (Array.isArray(data)) {
            return <ErrorMessages messages={data}/>;
        }        

        console.warn('Received bad reqeust with an unknown response body');
        return <ErrorMessages messages={['Invalid request received']} />
    }
    
    return <ErrorMessages messages={["Unexpected error has occurred"]} />;
};

export default ApiErrorMessages;