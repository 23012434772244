import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import ErrorMessages from "../components/UI/ErrorMessages";
import Loader from "../components/UI/Loader";
import Form from "../models/serviceModels/Form";
import Program from "../models/serviceModels/Program";
import { getUnassignForms } from "../services/formsService";
import { getFormsByProgramId, getProgramById, unassignFormFromProgram, assignFormToProgram } from "../services/programsService";

const ManageProgramForms = () => {
    const { id = '' } = useParams();
    const [loading, setLoading] = useState(true);
    const [program, setProgram] = useState<Program>();
    const [unassignedForms, setUnassignedForms] = useState<Form[]>([]);
    const [assignedForms, setAssignedForms] = useState<Form[]>([]);
    const [failedToLoad, setFailedToLoad] = useState(false);
    const [error, setError] = useState<string>('');
    const [assigning, setAssigning] = useState(false);
    const [unassigning, setUnassigning] = useState(false);

    useEffect(() => {
        document.title = "Manage Program Forms"
    }, []);

    const handleAssignForm = (form: Form) => {
        setError('');
        setAssigning(true);
        setAssignedForms([
            ...assignedForms,
            form
        ]);
        setUnassignedForms([
            ...unassignedForms.filter(f => f !== form)
        ]);
        assignFormToProgram({ formId: form.id, programId: id }).catch(e => {
            console.error(e);
            setError('Failed to assign');
            setAssignedForms([
                ...assignedForms.filter(f => f !== form)
            ]);
            setUnassignedForms([
                ...unassignedForms,
                form
            ]);
        }).finally(() => {
            setAssigning(false);
        });
    };

    const handleUnassignForm = (form: Form) => {
        setError('');
        setUnassigning(true);
        setUnassignedForms([
            ...unassignedForms,
            form
        ]);
        setAssignedForms([
            ...assignedForms.filter(f => f !== form)
        ]);
        unassignFormFromProgram({ formId: form.id, programId: id }).catch(e => {
            console.error(e);
            setError('Failed to unassign');
            setUnassignedForms([
                ...unassignedForms.filter(f => f !== form)
            ]);
            setAssignedForms([
                ...assignedForms,
                form
            ]);
        }).finally(() => {
            setUnassigning(false);
        });
    };

    useEffect(() => {
        Promise.all([getProgramById(id), getFormsByProgramId(id), getUnassignForms()]).then(([programResult, programFormsResult, availableFormsResult]) => {
            setProgram(programResult);
            setUnassignedForms([...availableFormsResult]);
            setAssignedForms([...programFormsResult]);
        }).catch(e => {
            console.error(e);
            setFailedToLoad(true);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div className="section">
                <Loader />
            </div>
        );
    }

    if (failedToLoad) {
        return (
            <div className="section">
                <div className="row justify-content-center">
                    <div className="column-auto">
                        Failed to load
                    </div>
                </div>
            </div>
        );
    }

    return (<section id="main-content" tabIndex={-1}>
        <div className="row justify-content-center margin-vertical-2">
            <div className="column">
                <BackButton />
                <h1>Manage Program Forms</h1>
                <ErrorMessages messages={[error]} />
                <div className="row justify-content-between">
                    <div className="column-auto">
                        <h3>{program?.name}</h3>
                    </div>
                    <div className="column-auto">
                        {(assigning || unassigning) && <Loader />}
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="column-5">
                        <h4>Available Forms</h4>
                        <div className="selection-container">
                            {<div className="list-item-container">
                                {unassignedForms.length === 0 && <div>No forms found</div>}
                                {unassignedForms.length > 0 && (unassignedForms.map(f => {
                                    return (<div className="list-item selectable" key={f.id}>
                                        <div className="row justify-content-between align-items-center">
                                            <div className="column-auto">
                                                {f.name}
                                            </div>
                                            <div className="column-auto">
                                                <Button id={`unassign-${f.id}`} className="small" text="Assign" onClick={() => handleAssignForm(f)} />
                                            </div>
                                        </div>
                                    </div>)
                                }))}
                            </div>}
                        </div>
                    </div>
                    <div className="column-5">
                        <h4>Assigned Forms</h4>
                        <div className="selection-container">
                            {<div className="list-item-container">
                                {assignedForms.length === 0 && <div>No forms found</div>}
                                {assignedForms.length > 0 && (assignedForms.map(f => {
                                    return (<div className="list-item selectable" key={f.id}>
                                        <div className="row justify-content-between align-items-center">
                                            <div className="column-auto">
                                                {f.name}
                                            </div>
                                            <div className="column-auto">
                                                <Button id={`unassign-${f.id}`} className="small" text="Unassign" onClick={() => handleUnassignForm(f)} />
                                            </div>
                                        </div>
                                    </div>)
                                }))}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
};

export default ManageProgramForms;