const TEXT_ID: number = 1;
const NUMBER_ID: number = 2;
const RADIO_BUTTON_ID: number = 3;
const CHECKBOX_ID: number = 4;
const CHECKBOX_GROUP_ID: number = 5;
const SELECT_LIST_ID: number = 6;
const MULTI_LINE_TEXT_ID: number = 7;
const CLIENT_ID: number = 8;
const HEADER_ID: number = 9;
const ACTIVITY_DATE_ID: number = 10;
const NOTES_ID: number = 11;
const TIME_SPENT_ID: number = 12;
const DATE_ID: number = 13;
const BREAK_LINE_ID: number = 14;

export {
    TEXT_ID,
    NUMBER_ID,
    RADIO_BUTTON_ID,
    CHECKBOX_ID,
    CHECKBOX_GROUP_ID,
    SELECT_LIST_ID,
    MULTI_LINE_TEXT_ID,
    CLIENT_ID,
    HEADER_ID,
    ACTIVITY_DATE_ID,
    NOTES_ID,
    TIME_SPENT_ID,
    DATE_ID,
    BREAK_LINE_ID
}