import ValidateResponse from "./ValidateResponse";

export default interface ProgramEnrollmentDateRangeValidateResponse extends ValidateResponse {
    from: string[];
    to: string[];
    program: string[];
    fromAndTo: string[];
}

export const defaultValue = (): ProgramEnrollmentDateRangeValidateResponse => {
    return {
        value: [],
        operator: [],
        from: [],
        program: [],
        to: [],
        fromAndTo: [],
        isValid: true,
        default: []
    };
}