import * as cards from "../constants/cardBrands";

const getCardBrandName = (brand: string) => {
    switch(brand) {
        case cards.AMERICAN_EXPRESS: 
            return 'American Express';
        case cards.MASTERCARD: 
            return 'Mastercard';
        case cards.VISA:
            return 'Visa';
        case cards.DINERS:
            return 'Diners';
        case cards.DISCOVER:
            return 'Discover';
        case cards.JCB:
            return 'JCB';
        case cards.UNION_PAY:
            return 'Union Pay';
        default:
            return 'Unknown';
    }
};

export { 
    getCardBrandName
}