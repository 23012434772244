import getclassNames from "../../../utils/getClassNames";
import Button from "../../UI/Button";
import ReportStepProps from "./ReportStepProps";
import { format, isMatch, parse } from "date-fns";
import IconButton from "../../UI/IconButton";
import { ENROLL_DATE_RANGE } from "../../../constants/reportStep";
import Input from "../../UI/Input";

const ENROLL_FROM_DATE = 'enroll_from_date';
const ENROLL_TO_DATE = 'enroll_to_date';

const EnrollDateRange = (props: ReportStepProps) => {
    const handleToggleExpanded = () => {
        props.onExpand(ENROLL_DATE_RANGE, !props.expanded);
    };

    const handleOnChange = (fieldName: string, value: string) => {
        const matchingField = props.values.find(v => v.name === fieldName);
        if (matchingField) {
            matchingField.value = value;
            props.onChange({ 
                step: ENROLL_DATE_RANGE,
                values: [
                    ...props.values
                ]
            });
        } else {
            props.onChange({
                step: ENROLL_DATE_RANGE,
                values: [
                    ...props.values,
                    { name: fieldName, value }
                ]
            })
        }
    };

    const handleOnNext = () => {
        props.onNext(ENROLL_DATE_RANGE);
    };

    const fromDate = props.values.find(v => v.name === ENROLL_FROM_DATE)?.value || '';
    const toDate = props.values.find(v => v.name == ENROLL_TO_DATE)?.value || '';
    const formattedFromDate = !fromDate || !isMatch(fromDate, 'yyyy-MM-dd') ?
        '' : 
        format(parse(fromDate, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
    const formattedToDate = !toDate || !isMatch(toDate, 'yyyy-MM-dd') ? 
        '' :
        format(parse(toDate, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
        
    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
    <div className="report-step-header">
        <div className="row justify-content-between align-items-center">
            <div className="column-auto">
                <h3>Select a date range{formattedFromDate && formattedToDate ? ` - ${formattedFromDate} to ${formattedToDate}` : ''}</h3>
            </div>
            <div className="column-1">
                <IconButton id="toggle-report-perspective-expansion"
                    className="small flat"
                    disabled={props.disabled}
                    icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                    onClick={handleToggleExpanded} />
            </div>
        </div>
    </div>
    <div className="report-step-body">
        <div className="row margin-bottom-3">
            <div className="column">
                <div className="row">
                    <div className="column">
                        <Input id="date-range-from"
                            type="date"
                            label="Enrolled From Date"
                            value={fromDate}
                            onChange={(v) => handleOnChange(ENROLL_FROM_DATE, v)} />
                    </div>
                    <div className="column">
                        <Input id="date-range-to"
                            type="date"
                            label="Enrolled To Date"
                            value={toDate}
                            onChange={(v) => handleOnChange(ENROLL_TO_DATE, v)} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-end margin-bottom-3">
            <div className="column-auto">
                <Button id="report-type-next"
                    text="Next"
                    icon={<i className="ri-arrow-right-s-line"></i>}
                    iconLocation="end"
                    className="secondary"
                    disabled={!fromDate || !toDate || props.disabled}
                    onClick={handleOnNext} />
            </div>
        </div>
    </div>
</div>);
};

export default EnrollDateRange;