import { useCallback, useEffect, useState } from "react";

const useElementVisible = <T extends HTMLElement>(offset?: number): [((node: T) => void), boolean, boolean, boolean] => {
    const [visibleVertically, setVisibleVertically] = useState(false);
    const [visibleHorizontally, setVisibleHorizontally] = useState(false);
    const [nodeReference, setNodeReference] = useState<T | null>(null);

    const reference = useCallback((node: T) => {
        setNodeReference(node);
    }, []);

    useEffect(() => {
        const checkVisibility = setInterval(() => {
            if (!nodeReference) return;

            const boundedRectangle = nodeReference.getBoundingClientRect();

            if (!offset) {
                offset = 0;
            }

            if (boundedRectangle.top - offset >= 0 && Math.floor(boundedRectangle.bottom - offset) <= window.innerHeight) {
                setVisibleVertically(true);
            } else {
                setVisibleVertically(false);
            }

            if (boundedRectangle.left - offset >= 0 &&
                Math.floor(boundedRectangle.right - offset) <= window.innerWidth) {
                setVisibleHorizontally(true);
            } else {
                setVisibleHorizontally(false);
            }
        }, 300);

        return () => {
            clearInterval(checkVisibility);
        }
    }, [nodeReference]);


    return [reference, visibleHorizontally && visibleVertically, visibleHorizontally, visibleVertically];
};

export default useElementVisible;