import { useContext, useEffect } from "react";
import Loader from "../../components/UI/Loader";
import { ADMIN_ROLE_ID } from "../../constants/userRoles";
import { Link } from "react-router-dom";
import { MeContext } from "../../App";

const PlanCancelled = () => {
    const me = useContext(MeContext);

    useEffect(() => {
        document.title = 'Plan Cancelled';
    }, []);

    if (me.loading) {
        return <Loader />
    }

    return <section id="trial-ended-section">
        <div className="row justify-content-center">
            <div className="column-medium-6 border margin-top-5 rounded padding-5">
                <h1>Plan Cancelled</h1>
                <p>Your organization's plan has been cancelled.</p>
                {me.user?.userRoleId === ADMIN_ROLE_ID && <p>If you'd like to continue using countbubble please <Link to="/admin/billing">activate</Link> your plan</p>}
                {me.user?.userRoleId !== ADMIN_ROLE_ID && <p>If you'd like to continue using countbubble please have an Admin activate your plan</p>}
                <p>If you need any assistance please contact support at <a className='email-link' href="mailto:contact@countbubble.com?subject=Access Denied">contact@countbubble.com</a></p>
            </div>
        </div>
    </section>;
};

export default PlanCancelled;