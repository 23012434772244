const EVERYTHING = 'EVERYTHING';
const IN_PROGRESS_GOALS = 'IN_PROGRESS_GOALS';

const planScopeMap: { [key:string]: string } = {
    [EVERYTHING]: 'All Plans, Goals, and Tasks',
    [IN_PROGRESS_GOALS]: 'In Progress Goals'
};

export {
    EVERYTHING,
    IN_PROGRESS_GOALS, 
    planScopeMap
};