import axios from "axios";
import { RunReportRequest } from "../models/serviceModels/RunReportRequest";
import { ReportResult } from "../models/serviceModels/ReportResult";
import { ReportResultData } from "../models/serviceModels/ReportResultData";
import { SubmittedReportResult } from "../models/serviceModels/SubmittedReportResult";
import PagedData from "../models/serviceModels/PagedData";
import CasePlanGoalDueResult from "../models/serviceModels/CasePlanGoalDueResult";
import GetCasePlanDueGoalsRequest from "../models/serviceModels/GetCasePlanDueGoalsRequest";
import RunReportV2Response from "../models/serviceModels/RunReportV2Response";
import RunReportV2Request from "../models/serviceModels/RunReportV2Request";
import GetReportResultsRequest from "../models/serviceModels/GetReportResultsRequest";
import ExportReportRequest from "../models/serviceModels/ExportReportRequest";
import { ExportReportResponse } from "../models/serviceModels/ExportReportResultResponse";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const runReport = async (request: RunReportRequest): Promise<ReportResult> => {
    const result = await axios.post(`${baseUrl}/reports/run`, {
        type: request.type,
        ...request.parameters
    });

    return result.data;
};

const runReportV2 = async (request: RunReportV2Request): Promise<RunReportV2Response> => {
    const result = await axios.post(`${baseUrl}/reports/v2/run`, {
        ...request
    });

    return result.data;
}

const exportReport = async (request: ExportReportRequest): Promise<ExportReportResponse> => {
    const result = await axios.post(`${baseUrl}/reports/v2/${request.reportId}/export`);
    return result.data;
};

const getReportResultsV2 = async <T>(request: GetReportResultsRequest): Promise<PagedData<T>> => {
    const query = [
        `skip=${request.skip}`,
        `take=${request.take}`,
        `sortBy=${request.sortBy}`,
        `sortDirection=${request.sortDirection}`,
        `filter=${request.filter ?? ''}`
    ];

    const queryString = query.join('&');
    const result = await axios.get(`${baseUrl}/reports/v2/${request.reportId}/results?${queryString}`);
    return result.data;
};

const getReportSummaryV2 = async <T>(reportId: string): Promise<T> => {
    const result = await axios.get(`${baseUrl}/reports/v2/${reportId}/summary`);
    return result.data;
};

const getCasePlanDueGoals = async (request: GetCasePlanDueGoalsRequest): Promise<PagedData<CasePlanGoalDueResult>> => {
    const result = await axios.get(`${baseUrl}/reports/case-plans/goals-due?skip=${request.skip}&take=${request.take}&filterType=${request.filterType || ''}&lastWorkedBy=${request.lastWorkedBy || ''}`);
    return result.data;
};

const getReport = async (reportId: string): Promise<ReportResult> => {
    const result = await axios.get(`${baseUrl}/reports/${reportId}`);
    return result.data;
};

const getReportData = async (reportId: string): Promise<ReportResultData> => {
    const result = await axios.get(`${baseUrl}/reports/${reportId}/data`);
    return result.data;
}

const getSubmittedReports = async (from: Date, to: Date): Promise<SubmittedReportResult[]> => {
    const result = await axios.get(`${baseUrl}/reports/submitted?from=${from.toISOString()}&to=${to.toISOString()}`);
    return result.data;
};

export {
    runReport,
    getReport,
    getReportData,
    getSubmittedReports,
    getCasePlanDueGoals,
    runReportV2,
    getReportResultsV2,
    getReportSummaryV2,
    exportReport
}