export const EQUALS = 'EQUALS';
export const GREATER_THAN = 'GREATER_THAN';
export const GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO';
export const LESS_THAN = 'LESS_THAN';
export const LESS_THEN_OR_EQUAL_TO = 'LESS_THEN_OR_EQUAL_TO';
export const BETWEEN = 'BETWEEN'; 
export const NOT_IN = 'NOT_IN';
export const NOT_EQUALS = 'NOT_EQUALS';
export const IN = 'IN';
export const STARTS_WITH = 'STARTS_WITH';
export const ENDS_WITH = 'ENDS_WITH';
export const CONTAINS = 'CONTAINS';
export const NOT_STARTS_WITH = 'NOT_STARTS_WITH';
export const NOT_ENDS_WITH = 'NOT_ENDS_WITH';
export const NOT_CONTAINS = 'NOT_CONTAINS';
export const IS_TRUE = 'IS_TRUE';
export const IS_FALSE = 'IS_FALSE';
export const UNANSWERED = 'UNANSWERED';

export const operatorLabelMap: { [key: string]: string } = {
    [EQUALS]: 'equals',
    [GREATER_THAN]: 'greater than',
    [GREATER_THAN_OR_EQUAL_TO]: 'greater than or equal',
    [LESS_THAN]: 'less than',
    [BETWEEN]: 'between',
    [LESS_THEN_OR_EQUAL_TO]: 'less than or equal',
    [NOT_IN]: 'not in',
    [NOT_EQUALS]: 'not equal',
    [IN]: 'in',
    [STARTS_WITH]: 'starts with',
    [ENDS_WITH]: 'ends with',
    [CONTAINS]: 'contains',
    [NOT_STARTS_WITH]: 'not starts with',
    [NOT_ENDS_WITH]: 'not ends with',
    [NOT_CONTAINS]: 'not contains',
    [IS_TRUE]: 'is true',
    [IS_FALSE]: 'is false',
    [UNANSWERED]: 'is unaswered/none'
};