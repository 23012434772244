import { FormEvent, useEffect, useRef, useState } from "react";
import Program from "../../models/serviceModels/Program";
import { assignFormToProgram, createProgram, searchPrograms } from "../../services/programsService";
import Loader from "../UI/Loader";
import ApiErrorMessages from "../UI/ApiErrorMessages";
import Select from "../UI/Select";
import Button from "../UI/Button";
import Input from "../UI/Input";
import ErrorMessages from "../UI/ErrorMessages";

interface AssignProgramProps {
    formId: string;
    onAssigned: (programId: string, programName: string) => void;
}

const AssignProgram = (props: AssignProgramProps) => {
    const [loading, setLoading] = useState(true);
    const [assigning, setAssigning] = useState(false);
    const [program, setProgram] = useState<Program>();
    const [programs, setPrograms] = useState<Program[]>();
    const [apiError, setApiError] = useState<any>();
    const [creatingNewProgram, setCreatingNewProgram] = useState(false);
    const [newProgramName, setNewProgramName] = useState('');
    const [nameInvalid, setNameInvalid] = useState(false);
    const newNameRef = useRef<HTMLInputElement>();

    useEffect(() => {
        setLoading(true);

        searchPrograms({ take: 1000 }).then(programData => {
            setPrograms([...programData.data]);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (creatingNewProgram) {
            if (newNameRef.current) {
                newNameRef.current.focus();
            }
        }
    }, [creatingNewProgram]);

    const handleCreatingNewProgram = () => {
        setApiError(undefined);
        if (!newProgramName) {
            setNameInvalid(true);
            return;
        }

        setNameInvalid(false);
        setAssigning(true);

        createProgram({
            name: newProgramName
        }).then(r => {
            assignFormToProgram({
                formId: props.formId,
                programId: r.id
            }).then(() => {
                props.onAssigned(r.id, r.name);
            }).catch(e => {
                console.error(e);
                setApiError(e);
            }).finally(() => {
                setAssigning(false);
            });
        }).catch(e => {
            console.error(e);
            setApiError(e);
            setAssigning(false);
        });
    };

    const handleAssignForm = () => {
        if (!program) {
            return;
        }

        setApiError(undefined);
        setAssigning(true);
        assignFormToProgram({ formId: props.formId, programId: program.id }).then(() => {
            props.onAssigned(program.id, program.name);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setAssigning(false);
        });
    };

    const handleOnProgramChange = (programId: string) => {
        setProgram(programs?.find(p => p.id === programId));
    };

    const handleCancelCreatingNewProgram = () => {
        setCreatingNewProgram(false);
        setNewProgramName('');
        setNameInvalid(false);
        setApiError(undefined);
    };

    const handleOnSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (creatingNewProgram) {
            handleCreatingNewProgram();
        } else {
            handleAssignForm();
        }
    };

    const handleCreatingNewProgramStart = () => {
        setCreatingNewProgram(true);
    };

    if (loading) {
        return <Loader />;
    }

    return <>
        <form onSubmit={handleOnSubmit}>
            <ApiErrorMessages error={apiError} />
            {creatingNewProgram && nameInvalid && <ErrorMessages messages={['New Program Name is invalid']} />}
            {programs && <><div className="row">
                <div className="column">
                    {!creatingNewProgram && <Select id="programs" onChange={handleOnProgramChange} label="Programs">
                        <option>Select a Program</option>
                        {programs.map(p => {
                            return <option key={p.id} value={p.id}>{p.name}</option>
                        })}
                    </Select>}
                    {creatingNewProgram && <Input id="new-program-name"
                        onChange={setNewProgramName}
                        label="New Program Name"
                        ref={newNameRef}
                        value={newProgramName} />}
                </div>
            </div>
                <div className="row justify-content-between margin-top-5">
                    <div className="column-auto">
                        {!creatingNewProgram && <Button id="create-program"
                            text="Create New Program"
                            className="secondary"
                            onClick={() => setCreatingNewProgram(true)} />}
                        {creatingNewProgram && <Button id="cancel-create-program"
                            text="Cancel"
                            className="secondary"
                            onClick={handleCancelCreatingNewProgram} />}
                    </div>
                    <div className="column-auto">
                        <Button id="assign-to-program"
                            text="Submit"
                            type="submit"
                            loading={assigning}
                            disabled={assigning} />
                    </div>
                </div>
            </>}
        </form>
    </>;
};

export default AssignProgram;