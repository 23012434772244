import UploadableFile from "../models/pageModels/UploadableFile";
import * as fileScanStatus from "../constants/fileScanStatus";
import * as fileUploadStatus from "../constants/fileUploadStatus";
import { NoteFile } from "../models/serviceModels/NoteFile";
import { FieldFile } from "../models/serviceModels/FieldFile";

const mapToUploadableFile = (file: NoteFile | FieldFile): UploadableFile => {
    return {
        fileId: file.fileId,
        name: file.name,
        status: file.status === fileScanStatus.INFECTED ? fileUploadStatus.INFECTED :
            file.status === fileScanStatus.CLEAN ? fileUploadStatus.UPLOADED :
                fileUploadStatus.SCANNING,
        created: file.created
    }
};

const mapToUploadableFiles = (files: NoteFile[] | FieldFile[]): UploadableFile[] => {
    return files.map(f => mapToUploadableFile(f));
};

const mapToFieldFile = (file: UploadableFile, fieldId: string): FieldFile => {
    return {
        fileId: file.fileId,
        fieldId,
        name: file.name,
        size: (file.file?.size || 0).toString(),
        status: file.status,
        created: file.created || ''
    }
};

const mapToFieldFiles = (files: UploadableFile[], fieldId: string): FieldFile[] => {
    return files.map(f => mapToFieldFile(f, fieldId));
};

export {
    mapToUploadableFile,
    mapToUploadableFiles,
    mapToFieldFile,
    mapToFieldFiles
}