import { useLocation, useNavigate } from "react-router-dom";
import Button from "./Button";

const BackButton = () => {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.key === 'default') {
        return null;
    }

    return (
        <Button id="back-button"
            text="Back"
            icon={<i className="ri-arrow-left-s-line" />}
            iconLocation="start"
            className="small secondary"
            onClick={() => navigate(-1)} />
    )
};

export default BackButton;