import axios from "axios";
import PagedData from "../models/serviceModels/PagedData";
import UpdateUserRequest from "../models/serviceModels/UpdateUserRequest";
import User from "../models/serviceModels/User";
import MeUser from "../models/serviceModels/MeUser";


const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUser = async (id: string): Promise<User> => {
    const result = await axios.get(`${baseUrl}/users/${id}`);
    return result.data;
};

const updateUser = async (request: UpdateUserRequest): Promise<User> => {
    const result = await axios.put(`${baseUrl}/users/${request.id}`,{
        ...request
    });
    return result.data;
}

const getMe = async (): Promise<MeUser> => {
    const result = await axios.get(`${baseUrl}/users/me`);
    return result.data;
}

const searchUsers = async (query: string, skip: number, take: number): Promise<PagedData<User>> => {
    const result = await axios.get(`${baseUrl}/users?query=${query}&skip=${skip}&take=${take}`);
    return result.data;
};

const deactivateUser = async (userId: string): Promise<User> => {
    const result = await axios.post(`${baseUrl}/users/${userId}/deactivate`);
    return result.data;
};

const activateUser = async (userId: string): Promise<User> => {
    const result = await axios.post(`${baseUrl}/users/${userId}/activate`);
    return result.data;
};

export {
    getUser,
    updateUser,
    getMe,
    searchUsers,
    deactivateUser,
    activateUser
};