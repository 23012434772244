interface TabProps {
    title: string;
    children?: any;
}

const Tab = (props: TabProps) => {
    return <>
        {props.children}
    </>;
}

export default Tab;

