import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GroupedField } from './SectionGroupedFields';
import DemographicsSummaryField from '../../../models/serviceModels/DemographicsSummaryField';

interface DataVisualizationProps {
    data: GroupedField<DemographicsSummaryField>;
}
// Define the structure of the chart data
interface ChartData {
    fieldLabel: string;
    totalValue?: number;
    percentageValue?: number;
}

const DataVisualization: React.FC<DataVisualizationProps> = ({ data }) => {
    // Prepare the chart data
    const chartData: ChartData[] = data.fields.map((field) => ({
        fieldLabel: field.fieldLabel,
        fieldGrouping: field.fieldGrouping,
        totalValue: field.totalValue,
    }));

    return (
        <div style={{ marginBottom: '40px' }}>
            <h2>{data.label}</h2>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={chartData}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="fieldGrouping"  />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="totalValue" fill="#8884d8" name="Total" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DataVisualization;