import { useState } from "react";
import getclassNames from "../../utils/getClassNames";
import './Tabs.css';

interface TabsProps {
    children: JSX.Element[];
}

const Tabs = (props: TabsProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (<div className="tab-container">
        <div className="tab-headers">
            {props.children.filter(c => c).map((tab, i) => {
                return <div tabIndex={-1} className={getclassNames('tab-header', i === activeIndex ? 'active' : '')} onClick={() => setActiveIndex(i)}>
                    {tab.props.title}
                </div>
            })}
        </div>
        <div className="tab-body">
            {props.children.filter(c => c).map((tab, i) => {
                return <div key={i} className={getclassNames('tab-content', i !== activeIndex ? 'hidden' : '')}>
                    {tab}
                </div>
            })}
        </div>
    </div>)
};

export default Tabs;