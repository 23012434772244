import { useEffect, useRef, useState } from "react";
import ResultProps from "./ResultProps";
import { exportReport, getReportResultsV2 } from "../../../services/reportService";
import DemographicsReportResult from "../../../models/serviceModels/DemographicsReportResult";
import Loader from "../../UI/Loader";
import useElementVisible from "../../../hooks/useElementVisible";
import { getClientForm } from "../../../services/formsService";
import Form from "../../../models/serviceModels/Form";
import { formatJSONDateWithoutTime } from "../../../utils/dateHelpers";
import FormField from "../../../models/serviceModels/FormField";
import { ACTIVITY_DATE_ID, BREAK_LINE_ID, CHECKBOX_GROUP_ID, CLIENT_ID, DATE_ID, HEADER_ID, MULTI_LINE_TEXT_ID, NOTES_ID, RADIO_BUTTON_ID, SELECT_LIST_ID } from "../../../constants/fieldTypes";
import { format, parse } from "date-fns";
import TruncatedText from "../../UI/TruncatedText";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import './ReportResults.css';
import RelatedFormField from "../../../models/serviceModels/RelatedFormField";
import { Link } from "react-router-dom";
import Button from "../../UI/Button";
import { triggerDownloadOfUrl } from "../../../utils/reportHelpers";
import IconButton from "../../UI/IconButton";
import { scrollLeft, scrollRight } from "../../../utils/elementHelpers";

const TAKE = 10;

const DemographicsResults = (props: ResultProps) => {
    const [resultsLoading, setResultsLoading] = useState(false);
    const [clientFormLoading, setClientFormLoading] = useState(false);
    const [clientForm, setClientForm] = useState<Form>();
    const [totalCount, setTotalCount] = useState(0);
    const [results, setResults] = useState<DemographicsReportResult[]>([]);
    const [apiError, setApiError] = useState();
    const [skip, setSkip] = useState(0);
    const [paging, setPaging] = useState(false);
    const [reference, visible] = useElementVisible<HTMLDivElement>();
    const [exporting, setExporting] = useState(false);
    const loaded = useRef(false);

    useEffect(() => {
        setClientFormLoading(true);

        getClientForm().then(form => {
            setClientForm(form);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setClientFormLoading(false);
        });
    }, []);

    useEffect(() => {
        // make sure we reset this if the reportId changed
        if (loaded.current) {
            setSkip(0);
        }

        setResultsLoading(true);

        getReportResultsV2<DemographicsReportResult>({
            reportId: props.reportId,
            skip: 0,
            take: TAKE,
            sortBy: 'firstName',
            sortDirection: 'Ascending'
        }).then(r => {
            setResults(r.data);
            setTotalCount(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setResultsLoading(false);
        });
    }, [props.reportId]);

    useEffect(() => {
        if (!loaded.current || skip === 0) {
            return;
        }

        setPaging(true);
        setApiError(undefined);

        getReportResultsV2<DemographicsReportResult>({
            reportId: props.reportId,
            skip,
            take: TAKE,
            sortBy: 'firstName',
            sortDirection: 'Ascending'
        }).then(r => {
            setResults(er => {
                return [
                    ...er,
                    ...r.data
                ];
            });
            setTotalCount(r.totalCount);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setPaging(false);
        });
    }, [skip]);

    useEffect(() => {
        if (visible && totalCount > 0) {
            setSkip(current => {
                return current + TAKE;
            });
        }
    }, [visible, totalCount]);

    useEffect(() => {
        loaded.current = true;
    }, []);
    
    const handleOnExport = () => {
        setApiError(undefined);
        setExporting(true);
        exportReport({
            reportId: props.reportId
        }).then(r => {
            triggerDownloadOfUrl(r.downloadUrl);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setExporting(false);
        });
    };
    
    const handleScrollLeft = () => {
        scrollLeft('.report-results-table');
    };

    const handleScrollRight = () => {
        scrollRight('.report-results-table');
    };

    const getFieldValue = (field?: RelatedFormField): JSX.Element => {
        if (!field) {
            return <></>;
        }

        switch (field.fieldDataTypeId) {
            case ACTIVITY_DATE_ID:
            case DATE_ID:
                return <>{field.fieldValue && format(parse(field.fieldValue, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')}</>;
            case CHECKBOX_GROUP_ID:
                return <>{JSON.parse(field.fieldValue || '[]').map((v: any) => v.label || v).join(', ')}</>;
            case SELECT_LIST_ID:
            case RADIO_BUTTON_ID:
                return <>{JSON.parse(field.fieldValue || '{}').label || ''}</>;
            case MULTI_LINE_TEXT_ID:
            case NOTES_ID:
                return <TruncatedText id={`${field.id}-truncated-field`} maxLength={20} value={field.fieldValue} />
            default:
                return <>{field.fieldValue}</>;
        }
    };

    const shouldNotBeFilteredOut = (field: FormField) => {
        return field.fieldTypeId !== HEADER_ID && field.fieldTypeId !== BREAK_LINE_ID && field.fieldTypeId !== CLIENT_ID;
    };

    if ((clientFormLoading || resultsLoading) && !apiError) {
        return <Loader />
    }

    return <>
        <h3>Details for Participant Demographics</h3>
        <ApiErrorMessages error={apiError} />
        {!clientFormLoading && !resultsLoading && <>
            
            <div className="row justify-content-between">
                <div className="column-auto">
                    <div className="row justify-content-between">
                        <div className="column-auto">
                            <IconButton id="scroll-left"
                                onClick={handleScrollLeft}
                                className="small flat"
                                icon={<i className="ri-arrow-left-s-line"></i>} />
                        </div>
                        <div className="column-auto">
                            <IconButton id="scroll-right"
                                onClick={handleScrollRight}
                                className="small flat"
                                icon={<i className="ri-arrow-right-s-line"></i>} />
                        </div>
                    </div>
                </div>
                <div className="column-auto">
                    <Button id="export-form-results"
                        text="Export Data"
                        onClick={handleOnExport}
                        loading={exporting}
                        disabled={exporting} />
                </div>
            </div>
            <div className="report-results-table">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"><div className="fw-200">name</div></th>
                            <th scope="col"><div className="fw-200">date of birth</div></th>
                            <th scope="col"><div className="fw-200">gender</div></th>
                            <th scope="col"><div className="fw-200">ethnicity</div></th>
                            <th scope="col"><div className="fw-200">race(s)</div></th>
                            {clientForm?.fields?.filter(shouldNotBeFilteredOut).map(f => {
                                return <th key={f.id} scope="col"><div className="fw-200">{f.label}</div></th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(r => {
                            const nameParts = [r.firstName];
                            if (r.middleName) {
                                nameParts.push(r.middleName);
                            }
                            nameParts.push(r.lastName);

                            return (<tr key={r.id}>
                                <td><Link to={`/participants/${r.clientId}/profile-demographics`} target="_blank">{nameParts.join(' ')}</Link></td>
                                <td>{r.dateOfBirth && formatJSONDateWithoutTime(r.dateOfBirth, 'MM/dd/yyyy')}</td>
                                <td>{r.genderName}</td>
                                <td>{r.ethnicityName}</td>
                                <td><TruncatedText id={`${r.id}-races`} value={r.races.map(ra => ra.raceName).join(', ')} maxLength={20} /></td>
                                {clientForm?.fields?.filter(shouldNotBeFilteredOut).map(f => {
                                    const matchingAdditionalInfoField = r.relatedFields.find(af => af.fieldName === f.name);
                                    return <td key={f.id} scope="col">{getFieldValue(matchingAdditionalInfoField)}</td>;
                                })}
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
            {paging && <Loader />}
            {results && results.length < totalCount && <div ref={reference} className="padding-5 margin-bottom-5"></div>}
        </>
        }
    </>;
}

export default DemographicsResults;