import React, { useState, ChangeEvent, useEffect, ForwardedRef, forwardRef } from 'react';
import getclassNames from '../../utils/getClassNames';
import Checkbox from './Checkbox';
import './CheckboxGroup.css';

export interface CheckboxGroupProps {
    name?: string;
    id: string;
    onChange?: (values: { label: string, value: string }[]) => void;
    options: { label: string, value: string }[]
    label?: string;
    labelClassName?: string;
    optionLabelClassName?: string;
    optionInputClassName?: string;
    disabled?: boolean;
    checkedOptions: { label: string, value: string }[];
    required?: boolean;
}

const CheckboxGroup = forwardRef((props: CheckboxGroupProps, ref?: ForwardedRef<any>) => {
    const handleOnChange = (checked: boolean, option: { label: string, value: string }) => {
        if (!props.onChange) {
            console.warn('No onChange provided for Checkbox group');
            return;
        }

        if (checked) {
            props.onChange([
                ...props.checkedOptions,
                option
            ]);
        } else {
            props.onChange([
                ...props.checkedOptions.filter(co => co.value !== option.value)
            ]);
        }
    };

    return (
        <div>
            {props.label &&
                <label className={props.labelClassName}>{props.label}</label>
            } 
            {props.required && <span className="margin-start-1 text-danger">*</span>}
            <div>
                {props.options.map((o: any, i: number) => {
                    const containerClasses = [];

                    if (i === 0) {
                        containerClasses.push('first');
                    }
                    if (i === props.options.length - 1) {
                        containerClasses.push('last');
                    }

                    if (containerClasses.length === 0) {
                        containerClasses.push('middle');
                    }

                    return (<div key={i}>
                        <Checkbox ref={i === 0 ? ref : undefined} id={`group-${i}-${props.id}`} containerClassName={containerClasses.join(' ')}
                            onChange={(checked) => handleOnChange(checked, o)} label={o.label}
                            checked={(props.checkedOptions && props.checkedOptions.some(co => co.value === o.value)) || false}
                            labelClassName={getclassNames('text-normal', props.optionLabelClassName || '')}
                            inputClassName={props.optionInputClassName} />
                    </div>);
                })}
            </div>
        </div>
    )
});

export default CheckboxGroup;