import { ValidateRequest } from "./ValidateRequest";
import ValidateResponse, { defaultValue } from "./ValidateResponse";

const validate = (request: ValidateRequest): Promise<ValidateResponse> => {
    const response = defaultValue();

    if (request.value === undefined || request.value === null) {
        response.isValid = false;
        response.value.push("A value is required");
    } else if (request.hasPossibleValues && request.value.trim() === '') {
        response.isValid = false;
        response.value.push('A value is required');
    }

    if (!request.operator) {
        response.operator.push('Operation is required');
        response.isValid = false;
    }

    return Promise.resolve(response);
}

export default validate;