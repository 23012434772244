import React from 'react';
import Button from '../../../UI/Button';
import DisplayFieldProps from './Models/DisplayFieldProps';
import { manageFormsEnabled } from '../../../../utils/features';
import Dropdown from '../../../UI/Dropdown';

const BreakLineDisplayField = (props: DisplayFieldProps) => {
    const options = [
        {
            label: 'Delete',
            onClick: () => props.onDelete(props.field)
        }
    ];

    return (<div className="field-wrapper">
        <div className="row justify-content-between align-items-center">
            <div className="column">
                *New Line*
            </div>
            {!props.hideButtons && <div className="column-auto margin-top-5">
                {!manageFormsEnabled && <div className="field-control">
                    <Button disabled={props.disabled} id={`reorder-buton-${props.field.id}`} onClick={() => props.onReorder && props.onReorder(props.field)} className="small" text="Move" />
                    <Button id={`delete-buton-${props.field.id}`} disabled={props.disabled} onClick={() => props.onDelete(props.field)} className="small" text="Delete" />
                </div>}
                {manageFormsEnabled && <Dropdown id={`dropdown-${props.field.id}`} options={options} />}
            </div>}
        </div>
        {manageFormsEnabled && <div className="margin-vertical-3">
        <i className="ri-separator margin-start-1"></i> New Line 
        </div>}
    </div>);
};

export default BreakLineDisplayField;