import { ForwardedRef, forwardRef } from "react";
import ErrorMessages, { ErrorMessagesProps } from "./ErrorMessages";
import './ValidatedInputSelect.css';
import RadioButton, { RadioButtonProps } from "./RadioButton";

interface ValidatedRadioButtonProps extends RadioButtonProps, ErrorMessagesProps {
}

const ValidatedRadioButton = forwardRef((props: ValidatedRadioButtonProps, ref?: ForwardedRef<any>) => {
    return (<>
        <RadioButton {...props} ref={ref} />
        <div className="validated-message-container">
            <ErrorMessages {...props} />
        </div>
    </>
    );
});

export default ValidatedRadioButton;
