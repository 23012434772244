import { format } from 'date-fns';
import { useState, useEffect, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Loader from '../components/UI/Loader';
import { createCasePlan, deleteCasePlan, getCasePlan, getCasePlanGoalStatuses, getClientById, getClientCasePlans, startCasePlan, stopCasePlan, updateCasePlan } from '../services/clientsService';
import ApiErrorMessages from '../components/UI/ApiErrorMessages';
import Button from '../components/UI/Button';
import Modal from '../components/UI/Modal';
import ClientCasePlan from '../models/serviceModels/ClientCasePlan';
import Client from '../models/serviceModels/Client';
import { formatJSONDateWithoutTime } from '../utils/dateHelpers';
import Input from '../components/UI/Input';
import Dropdown from '../components/UI/Dropdown';
import CasePlanGoalStatus from '../models/serviceModels/CasePlanGoalStatus';
import TruncatedText from '../components/UI/TruncatedText';
import ErrorMessages from '../components/UI/ErrorMessages';

const ParticipantCasePlans = () => {
    const { id = '' } = useParams();
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<any>();
    const [plans, setPlans] = useState<ClientCasePlan[]>([]);
    const [client, setClient] = useState<Client>();
    const [creating, setCreating] = useState(false);
    const [deletingPlan, setDeletingPlan] = useState<ClientCasePlan>();
    const [renamingPlan, setRenamingPlan] = useState<ClientCasePlan>();
    const [closingPlan, setClosingPlan] = useState<ClientCasePlan>();
    const [reopeningPlan, setReopeningPlan] = useState<ClientCasePlan>();
    const [reopening, setReopening] = useState(false);
    const [reopenDate, setReopenDate] = useState<string>();
    const [stoppedDate, setStoppedDate] = useState<string>();
    const [newPlanName, setNewPlanName] = useState<string>('');
    const [closing, setClosing] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [renaming, setRenaming] = useState(false);
    const [modalValidationError, setModalValidationError] = useState<string>();
    const [modalApiError, setModalApiError] = useState<any>();
    const [goalStatuses, setGoalStatuses] = useState<CasePlanGoalStatus[]>([]);
    const [loadingPlan, setLoadingPlan] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        setApiError(undefined);

        Promise.all([getClientCasePlans(id), getClientById(id), getCasePlanGoalStatuses()]).then(([planResult, clientResult, goalStatusResults]) => {
            setPlans([
                ...planResult
            ]);
            setClient(clientResult);
            setGoalStatuses(goalStatusResults);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleCreateCasePlan = () => {
        if (!client) {
            return;
        }
        setApiError(undefined);

        setCreating(true);
        createCasePlan({
            clientId: id,
            name: `${client.firstName} ${client.lastName} Plan ${format(new Date(), 'MM/dd/yyyy')}`,
            started: format(new Date(), 'yyyy-MM-dd')
        }).then(createdPlan => {
            navigate(createdPlan.id);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setCreating(false);
        })
    };

    const handleCancellingClose = () => {
        setClosing(false);
        setClosingPlan(undefined);
        setModalApiError(undefined);
        setModalValidationError(undefined);
    };

    const handleCancellingReopen = () => {
        setReopening(false);
        setReopenDate(undefined);
        setReopeningPlan(undefined);
        setModalApiError(undefined);
        setModalValidationError(undefined);
    };

    const handleCancellingRename = () => {
        setRenaming(false);
        setRenamingPlan(undefined);
        setModalApiError(undefined);
    };

    const handleOnReopen = (e: FormEvent) => {
        e.preventDefault();

        if (!reopeningPlan || !id) {
            return;
        }

        setModalApiError(undefined);
        setModalValidationError(undefined);

        if (!reopenDate) {
            setModalValidationError('Start date is required');
            return;
        }

        setReopening(true);

        startCasePlan({
            clientId: id,
            casePlanId: reopeningPlan.id,
            started: reopenDate
        }).then(() => {
            setPlans(p => {
                const existingPlan = p.find(ep => ep.id === reopeningPlan.id);
                if (!existingPlan) {
                    return p;
                }

                existingPlan.computedStatusName = 'In Progress';
                existingPlan.started = reopenDate;
                existingPlan.stopped = undefined;

                return [
                    ...p
                ];
            });
            handleCancellingReopen();
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setReopening(false);
        });
    };

    const handleOnRename = (e: FormEvent) => {
        e.preventDefault();

        if (!renamingPlan) {
            return;
        }

        setModalApiError(undefined);
        setRenaming(true);

        updateCasePlan({
            name: newPlanName,
            started: renamingPlan.started,
            clientId: id,
            casePlanId: renamingPlan.id
        }).then(up => {
            setPlans(cp => {
                const existingPlan = renamingPlan && cp.find(p => p.id === renamingPlan.id);
                if (!existingPlan) {
                    return cp;
                }

                existingPlan.name = up.name;
                existingPlan.updatedBy = up.updatedBy;
                existingPlan.started = up.started;

                return [
                    ...cp
                ];
            });
            handleCancellingRename();
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setRenaming(false);
        })
    };

    const handleOnDelete = () => {
        if (!deletingPlan) {
            return;
        }
        setModalApiError(undefined);
        setDeleting(true);
        deleteCasePlan({
            clientId: id,
            id: deletingPlan.id
        }).then(() => {
            setPlans(cp => [
                ...cp.filter(p => p.id !== deletingPlan.id)
            ]);
            handleCancellingDelete();
        }).finally(() => {
            setDeleting(false);
        })
    };

    const handleOnClose = (e: FormEvent) => {
        e.preventDefault();

        if (!closingPlan) {
            return;
        }

        setModalApiError(undefined);
        setModalValidationError(undefined);

        if (!stoppedDate) {
            setModalValidationError('Close date is required');
            return;
        }

        setModalApiError(undefined);
        setClosing(true);
        stopCasePlan({
            clientId: id,
            casePlanId: closingPlan.id,
            stopped: stoppedDate
        }).then(() => {
            setPlans(p => {
                const existingPlan = p.find(ep => ep.id === closingPlan.id);
                if (!existingPlan) {
                    return p;
                }

                existingPlan.computedStatusName = 'Closed';
                existingPlan.stopped = stoppedDate;
                
                return [
                    ...p
                ];
            });
            handleCancellingClose();
        }).catch(e => {
            console.error(e);
            setModalApiError(e);
        }).finally(() => {
            setClosing(false);
        })
    }

    const handleCancellingDelete = () => {
        setModalApiError(undefined);
        setDeletingPlan(undefined);
        setDeleting(false);
    };

    const handleOnStartReopen = (plan: ClientCasePlan) => {
        setReopeningPlan(plan);
        setModalValidationError(undefined);
        setModalApiError(undefined);
        setReopenDate(format(new Date(), 'yyyy-MM-dd'));
    };

    const handleOnStartClose = (plan: ClientCasePlan) => {
        setClosingPlan(plan);
        setModalValidationError(undefined);
        setModalApiError(undefined);
        setStoppedDate(format(new Date(), 'yyyy-MM-dd'));

        if (!plan.goals || plan.goals.length === 0) {
            setLoadingPlan(true);
            setModalApiError(undefined);

            getCasePlan({
                clientId: id,
                casePlanId: plan.id
            }).then(loadedPlan => {
                setPlans(p => {
                    const matchingPlan = p.find(ep => ep.id === plan.id);
                    if (!matchingPlan) {
                        return p;
                    }
                    matchingPlan.goals = loadedPlan.goals;
                    return [
                        ...p
                    ];
                });
            }).catch(e => {
                console.error(e);
                setModalApiError(e);
            }).finally(() => {
                setLoadingPlan(false);
            })
        }
    };

    const getMainContent = () => {
        return (<>
            <ApiErrorMessages error={apiError} />
            <div className="row align-items-center justify-content-between">
                <div className="column-auto">
                    <h3>Plans</h3>
                </div>
                <div className="column-auto">
                    <Button id="create-plan"
                        text="Create Plan"
                        disabled={creating || loading}
                        loading={creating}
                        onClick={handleCreateCasePlan} />
                </div>
            </div>
            {loading && <Loader />}
            {!loading && plans && plans.length !== 0 && <div className="row">
                <div className="column">
                    <table className="table">
                        <thead>
                            <td>Name</td>
                            <td>Status</td>
                            <td>Started</td>
                            <td>Created</td>
                            <td></td>
                        </thead>
                        {plans.map(p => {
                            const options = [
                                {
                                    label: 'Open',
                                    href: `/participants/${id}/plans/${p.id}`
                                },
                                {
                                    label: 'Rename',
                                    onClick: () => setRenamingPlan(p)
                                },
                                {
                                    label: 'Delete',
                                    onClick: () => setDeletingPlan(p)
                                }
                            ];

                            if (p.stopped) {
                                options.push({
                                    label: 'Restart',
                                    onClick: () => handleOnStartReopen(p)
                                });
                            } else {
                                options.push({
                                    label: 'End',
                                    onClick: () => handleOnStartClose(p)
                                });
                            }

                            return (<tr key={p.id}>
                                <td>{p.name}</td>
                                <td>{p.computedStatusName}</td>
                                <td>{p.started && formatJSONDateWithoutTime(p.started, 'MM/dd/yyyy')}</td>
                                <td>{format(new Date(p.created), 'Pp')}</td>
                                <td>
                                    <Dropdown id={`plan-dropdown-${p.id}`}
                                        options={options} />

                                </td>
                            </tr>);
                        })}
                    </table>
                </div>
            </div>}
            {!loading && plans && plans.length === 0 && <div className="row">
                <div className="column">
                    No Plans found
                </div>
            </div>}
        </>);
    };

    return (
        <section id="main-content" tabIndex={-1}>
            {getMainContent()}
            <Modal id="delete-plan-modal" visible={deletingPlan !== undefined} className="small" onClose={handleCancellingDelete} title="Confirm Delete Plan">
                {deletingPlan && (
                    <div>
                        {modalApiError && <ApiErrorMessages error={modalApiError} />}
                        <div className="row">
                            <div className="column">
                                Are you sure you want to delete this plan?
                                <br />
                                {deletingPlan.name}
                                <br />
                            </div>
                        </div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-delete-plan"
                                            text="Cancel"
                                            className="secondary"
                                            disabled={deleting}
                                            onClick={handleCancellingDelete} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-delete-plan"
                                            text="Delete Plan"
                                            className="warning"
                                            disabled={deleting}
                                            loading={deleting}
                                            onClick={handleOnDelete} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal id="rename-plan-modal" visible={renamingPlan !== undefined} className="small" onClose={handleCancellingRename} title="Rename Plan">
                {renamingPlan && (
                    <div>
                        {modalApiError && <ApiErrorMessages error={modalApiError} />}
                        <form onSubmit={handleOnRename}>
                            <label>Existing Plan Name:</label>
                            <div>{renamingPlan.name}</div>
                            <br />
                            <Input id="plan-new-name"
                                label="New Name"
                                value={newPlanName}
                                onChange={setNewPlanName} />
                            <div className="row margin-top-5">
                                <div className="column">
                                    <div className="row justify-content-between">
                                        <div className="column-medium-auto">
                                            <Button id="cancel-rename-plan"
                                                text="Cancel" className="secondary"
                                                disabled={deleting}
                                                onClick={handleCancellingRename} />
                                        </div>
                                        <div className="column-medium-auto">
                                            <Button id="confirm-rename-plan"
                                                text="Save"
                                                className="action"
                                                disabled={renaming}
                                                loading={renaming}
                                                onClick={handleOnRename} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </Modal>
            <Modal id="end-plan-modal" visible={closingPlan !== undefined} className="small" onClose={handleCancellingClose} title="End Plan">
                {closingPlan && (
                    <form onSubmit={handleOnClose}>
                        {loadingPlan && <Loader />}
                        {!loadingPlan && <>
                            {modalApiError && <ApiErrorMessages error={modalApiError} />}
                            <ErrorMessages messages={[modalValidationError || '']} />
                            <label>{closingPlan.name}</label>
                            <div className="margin-top-5">
                                <Input id="ended-date"
                                    label="Plan End Date"
                                    type="date"
                                    value={stoppedDate || format(new Date(), 'yyyy-MM-dd')}
                                    onChange={setStoppedDate} />
                            </div>
                            <div className="margin-top-5">Are you sure you want to end this plan?</div>
                            <div>The following goals will be moved to Skipped in order to end this plan.</div>
                            {closingPlan.goals && <>
                                {closingPlan.goals.map((g, i) => {
                                    if (g.endStatus) {
                                        return null;
                                    }
                                    return (<div className="margin-start-4 margin-top-4 text-bold">{i + 1}. {
                                        !g.name ?
                                            <i>&lt;No goal entered&gt;</i> :
                                            <TruncatedText id={`goal-text-${g.id}`}
                                                value={g.name}
                                                maxLength={100}
                                                lengthVariance={10} />}
                                    </div>)
                                })}
                            </>}
                            <br />
                            <div className="row margin-top-5">
                                <div className="column">
                                    <div className="row justify-content-between">
                                        <div className="column-medium-auto">
                                            <Button id="cancel-end-plan"
                                                text="Cancel"
                                                className="secondary"
                                                disabled={closing}
                                                onClick={handleCancellingClose} />
                                        </div>
                                        <div className="column-medium-auto">
                                            <Button id="confirm-end-plan"
                                                text="End Plan"
                                                type="submit"
                                                className="action"
                                                disabled={closing}
                                                loading={closing} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </form>
                )}
            </Modal>
            <Modal id="restart-plan-modal" visible={reopeningPlan !== undefined} className="small" onClose={handleCancellingReopen} title="Restart Plan">
                {reopeningPlan && (
                    <form onSubmit={handleOnReopen}>
                        {modalApiError && <ApiErrorMessages error={modalApiError} />}
                        <ErrorMessages messages={[modalValidationError || '']} />
                        <label>{reopeningPlan.name}</label>
                        <div className="margin-top-5">
                            <Input id="restart-date"
                                label="Plan Restart Date"
                                type="date"
                                value={reopenDate || format(new Date(), 'yyyy-MM-dd')}
                                onChange={setReopenDate} />
                        </div>
                        <br />
                        <div>Are you sure you want to re-open this plan?</div>
                        <div className="row margin-top-5">
                            <div className="column">
                                <div className="row justify-content-between">
                                    <div className="column-medium-auto">
                                        <Button id="cancel-restart-plan"
                                            text="Cancel"
                                            className="secondary"
                                            disabled={reopening}
                                            onClick={handleCancellingReopen} />
                                    </div>
                                    <div className="column-medium-auto">
                                        <Button id="confirm-restart-plan"
                                            text="Restart Plan"
                                            className="action"
                                            disabled={reopening}
                                            loading={reopening}
                                            type="submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Modal>
        </section>);
};

export default ParticipantCasePlans; 