import { useState } from 'react';
import Button from './Button';

interface TruncatedTextProps {
    value: string;
    maxLength: number;
    lengthVariance?: number;
    formatted?: boolean;
    hideShowMore?:boolean;
    id: string;
}

const TruncatedText = (props: TruncatedTextProps) => {
    const [visible, setVisible] = useState(false);

    const formatValue = (value: string) => {
        if (!value || !props.formatted) {
            return <>{value}</>;
        }
        
        return <div dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, '<br/>')}}></div>
    }

    if (!props.value || props.value.length < props.maxLength || props.value.length < props.maxLength + (props.lengthVariance || 0)) {
        return <span>{formatValue(props.value)}</span>;
    }

    if (visible) {
        return <span>
                {formatValue(props.value)}
                {!props.hideShowMore && <div><Button id={`toggle-visible-${props.id}`} className="tertiary small margin-top-1" text="Show Less" onClick={() => setVisible(false)}/></div>}
            </span>
    }
    
    return <span>
            {formatValue(props.value.substring(0, props.maxLength))} ...
            {!props.hideShowMore && <div><Button id={`toggle-visible-${props.id}`} className="tertiary small margin-top-1" text="Show More" onClick={() => setVisible(true)}/></div>}
        </span>
}

export default TruncatedText;