import { useCallback, useEffect, useState } from "react";
import Organization from "../models/serviceModels/Organization";
import { getOrganization } from "../services/organizationService";

interface OrganizationProvider {
    value?: Organization;
    loading: boolean;
    error?: any;
    refresh: () => void;
}

const useOrganization = (): OrganizationProvider => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const [organization, setOrganization] = useState<Organization>();

    const callGetOrganization = useCallback(() => {
        setLoading(true);
        getOrganization().then(o => {
            setOrganization(o);
        }).catch(e =>{
            console.error(e);
            setError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        callGetOrganization();
    }, [callGetOrganization]);

    const refresh = () => {
        callGetOrganization();
    };

    return {
        loading, 
        value: organization, 
        error,
        refresh
    }
};

export type { OrganizationProvider }

export { useOrganization }