import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiErrorMessages from "../components/UI/ApiErrorMessages";
import BackButton from "../components/UI/BackButton";
import Button from "../components/UI/Button";
import ValidatedInput from "../components/UI/ValidatedInput";
import CreateProgamPageModel from "../models/pageModels/CreateProgramPageModel";
import ModelErrors from "../models/pageModels/ModelErrors";
import { createProgram } from "../services/programsService";
import { getDefaultErrors } from "../utils/modelHelpers";

const getDefaultModel = (): CreateProgamPageModel => {
    return {
        name: ''
    };
}

const CreateProgram = () => {

    const [model, setModel] = useState<CreateProgamPageModel>(getDefaultModel());
    const [modelErrors, setModelErrors] = useState<ModelErrors>(getDefaultErrors(getDefaultModel()));
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitErrors, setSubmitErrors] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = "Create Program"
    }
        , []);

    const handleOnChange = (fieldName: string, value: any) => {
        setModel({
            ...model,
            [fieldName]: value
        });
    };

    const validate = () => {
        const newErrors = getDefaultErrors(getDefaultModel());
        let isValid = true;

        if (!model.name) {
            isValid = false;
            newErrors.name.push('Name is required');
        }

        setModelErrors({ ...newErrors });

        return isValid;
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmitErrors(null);
        setSubmitted(true);
        if (!validate()) {
            return;
        }
        setSubmitted(false);
        setSubmitting(true);
        createProgram(model).then(() => {
            if (location.key === 'default') {
                navigate('/');
            } else {
                navigate(-1);
            }
        }).catch(e => {
            setSubmitErrors(e);
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <section id="main-content" tabIndex={-1}>
            <h2>Create Program</h2>
            <div className="row justify-content-center">
                <div className="column">
                    <div className="row">
                        <div className="column-auto">
                            <BackButton />
                        </div>
                    </div>
                    <form onSubmit={handleOnSubmit}>
                        <ApiErrorMessages error={submitErrors} />
                        <div className="row margin-vertical-2">
                            <div className="column">
                                <ValidatedInput id="program-name" required={true}
                                    value={model.name}
                                    onChange={(e) => handleOnChange('name', e)}
                                    label="Name"
                                    messages={(submitted && modelErrors.name) || []} />
                            </div>
                        </div>
                        <div className="row justify-content-end margin-top-4">
                            <div className="column-auto">
                                <Button id="submit-button" type="submit" text="Submit" loading={submitting} disabled={submitting} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
};

export default CreateProgram;