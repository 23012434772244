import { ForwardedRef, forwardRef } from "react";
import ErrorMessages, { ErrorMessagesProps } from "./ErrorMessages";
import Input, { InputProps } from "./Input";
import './ValidatedInputSelect.css';

interface ValidatedInputProps extends InputProps, ErrorMessagesProps {
}

const ValidatedInput = forwardRef((props: ValidatedInputProps, ref?: ForwardedRef<any>) => {
    return (<>
        <Input {...props} ref={ref} />
        <div className="validated-message-container">
            <ErrorMessages {...props} />
        </div>
    </>
    );
});

export default ValidatedInput;
