import axios from "axios";
import CreateFormFieldRequest from "../models/serviceModels/CreateFormFieldRequest";
import CreateFormInstanceRequest from "../models/serviceModels/CreateFormInstanceRequest";
import CreateFormRequest from "../models/serviceModels/CreateFormRequest";
import DeleteFieldRequest from "../models/serviceModels/DeleteFieldRequest";
import DeleteFormRequest from "../models/serviceModels/DeleteFormRequest";
import Form from "../models/serviceModels/Form";
import FormField from "../models/serviceModels/FormField";
import FormInstance from "../models/serviceModels/FormInstance";
import LatestFormInstance from "../models/serviceModels/LatestFormInstance";
import NewFormVerionResponse from "../models/serviceModels/NewFormVersionResponse";
import PagedData from "../models/serviceModels/PagedData";
import ReorderFieldRequest from "../models/serviceModels/ReorderFieldRequest";
import SearchFormsInstancesRequest from "../models/serviceModels/SearchFormsInstancesRequest";
import UpdateFieldRequest from "../models/serviceModels/UpdateFieldRequest";
import UpdateFormInstanceRequest from "../models/serviceModels/UpdateFormInstanceRequest";
import UpdateFormRequest from "../models/serviceModels/UpdateFormRequest";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const getUnassignForms = async (): Promise<Form[]> => {
    const result = await axios.get(`${baseUrl}/forms/unassigned`);
    return result.data;
};

const getClientForm = async (): Promise<Form> => {
    const result = await axios.get(`${baseUrl}/forms/client`);
    return result.data;
};

const getClientFormInstance = async (id: string): Promise<FormInstance> => {
    const result = await axios.get(`${baseUrl}/forms/client/instances/client/${id}`);
    return result.data;
};

const getForm = async (formId: string, versionId: string): Promise<Form> => {
    const result = await axios.get(`${baseUrl}/forms/${formId}/versions/${versionId}`);
    return result.data;
};

const getFormInstance = async (formId: string, instanceId: string): Promise<FormInstance> => {
    const result = await axios.get(`${baseUrl}/forms/${formId}/instances/${instanceId}`);
    return result.data;
};

const getForms = async (includeDeactivated = false, types?: number[], includeDrafts = true): Promise<Form[]> => {
    const result = await axios.get(`${baseUrl}/forms?includeDeactivated=${includeDeactivated}&types=${types ? types.join(',') : ''}&includeDrafts=${includeDrafts}`);
    return result.data;
};

const createForm = async (request: CreateFormRequest): Promise<Form> => {
    const result = await axios.post(`${baseUrl}/forms`, request);
    return result.data;
};

const createFormVersion = async (id: string): Promise<NewFormVerionResponse> => {
    const result = await axios.post(`${baseUrl}/forms/${id}/versions`);
    return result.data;
}

const createFormField = async (request: CreateFormFieldRequest): Promise<FormField> => {
    const result = await axios.post(`${baseUrl}/forms/${request.formId}/versions/${request.versionId}/fields`, request);
    return result.data;
};

const deleteForm = async (request: DeleteFormRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/forms/${request.formId}/versions/${request.versionId}`);
};

const activateForm = async (formId: string): Promise<void> => {
    await axios.post(`${baseUrl}/forms/${formId}/activate`);
};

const deactivateForm = async (formId: string): Promise<void> => {
    await axios.post(`${baseUrl}/forms/${formId}/deactivate`);
};

const updateForm = async (request: UpdateFormRequest): Promise<Form> => {
    const result = await axios.put(`${baseUrl}/forms/${request.id}`, {
        name: request.name
    });

    return result.data;
};

const createFormInstance = async (request: CreateFormInstanceRequest): Promise<FormInstance> => {
    const result = await axios.post(`${baseUrl}/forms/${request.formId}/versions/${request.versionId}/instances`, {
        ...request
    });

    return result.data;
};

const getLatestFormInstances = async (formTypeId: number): Promise<LatestFormInstance[]> => {
    const result = await axios.get(`${baseUrl}/forms/instances/recent?type=${formTypeId}`);
    return result.data;
};

const publishFormVersion = async (formId: string, versionId: string): Promise<void> => {
    await axios.post(`${baseUrl}/forms/${formId}/versions/${versionId}/publish`);
}

const updateField = async (request: UpdateFieldRequest): Promise<FormField> => {
    const result = await axios.put(`${baseUrl}/forms/${request.formId}/versions/${request.versionId}/fields/${request.id}`, { ...request });
    return result.data;
};

const deletedField = async (request: DeleteFieldRequest): Promise<void> => {
    await axios.delete(`${baseUrl}/forms/${request.formId}/versions/${request.versionId}/fields/${request.id}`);
};

const reorderField = async (request: ReorderFieldRequest): Promise<FormField[]> => {
    const result = await axios.post(`${baseUrl}/forms/${request.formId}/versions/${request.versionId}/fields/${request.fieldId}/reorder`, {
        order: request.order
    });
    return result.data;
};

const updateFormInstance = async (request: UpdateFormInstanceRequest): Promise<FormInstance> => {
    const result = await axios.put(`${baseUrl}/forms/${request.formId}/instances/${request.instanceId}`, request);
    return result.data
};

const searchFormsInstances = async (request?: SearchFormsInstancesRequest): Promise<PagedData<FormInstance>> => {
    const queryStringParts = [
        `id=${request?.formId || ''}`,
        `skip=${request?.skip || 0}`,
        `take=${request?.take || 10}`,
        `type=${request?.formTypeId || ''}`,
        `client=${request?.clientId || ''}`
    ];

    const queryString = `?${queryStringParts.join('&')}`;
    const result = await axios.get(`${baseUrl}/forms/instances/search${queryString}`);
    return result.data;
};

export {
    getUnassignForms,
    getClientForm,
    getClientFormInstance,
    getForms,
    createForm,
    createFormVersion,
    createFormField,
    deleteForm,
    activateForm,
    deactivateForm,
    updateForm,
    getForm,
    createFormInstance,
    getLatestFormInstances,
    updateField,
    publishFormVersion,
    deletedField,
    reorderField,
    getFormInstance,
    updateFormInstance,
    searchFormsInstances
}
