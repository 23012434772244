const PROSPECT_MARKETING = 1;
const CUSTOMER_MARKETING = 2;
const ORIENTATION_TIPS = 3;

const MARKETING = 'Marketing';
const TIPS_AND_TRICKS = 'Tips & Tricks';

const dropdownValues = [
    { 
        label: MARKETING,
        value: MARKETING
    },
    { 
        label: TIPS_AND_TRICKS,
        value: TIPS_AND_TRICKS
    }
];

const dropdownMapping = new Map<string, number[]>([
    [MARKETING, [
        PROSPECT_MARKETING,
        CUSTOMER_MARKETING
    ]],
    [TIPS_AND_TRICKS, [
        ORIENTATION_TIPS
    ]]
]);

export {
    dropdownValues,
    dropdownMapping
}