import { Fragment, useEffect, useMemo, useState } from "react";
import SummaryProps from "./SummaryProps";
import DemographicsSummaryField from "../../../models/serviceModels/DemographicsSummaryField";
import { getReportSummaryV2 } from "../../../services/reportService";
import Loader from "../../UI/Loader";
import ApiErrorMessages from "../../UI/ApiErrorMessages";
import './Summary.css';
import TruncatedText from "../../UI/TruncatedText";
import { GroupedField, SectionGroupedFields } from "./SectionGroupedFields";
import Button from "../../UI/Button";
import DataVisualization from "./DataVisualization";
import { dataVisualizationsEnabled } from "../../../utils/features";

const DemographicsSummary = (props: SummaryProps) => {
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(undefined);
    const [summaries, setSummaries] = useState<DemographicsSummaryField[]>([]);
    const [showingVisualizations, setShowingVisualizations] = useState<string[]>([]);

    useEffect(() => {
        setLoading(true);

        getReportSummaryV2<DemographicsSummaryField[]>(props.reportId).then(r => {
            setSummaries(r);
        }).catch(e => {
            console.error(e);
            setApiError(e);
        }).finally(() => {
            setLoading(false);
        });
    }, [props.reportId]);

    const handleToggleShowingVisualizations = (grouping: GroupedField<DemographicsSummaryField>) => {
        if (showingVisualizations.includes(grouping.name)) {
            setShowingVisualizations([
                ...showingVisualizations.filter(v => v !== grouping.name)
            ]);
        } else {
            setShowingVisualizations([
                ...showingVisualizations,
                grouping.name
            ]);
        }
    }

    const groupedFields: SectionGroupedFields<DemographicsSummaryField>[] = useMemo(() => {
        const grouping: SectionGroupedFields<DemographicsSummaryField>[] = [];


        for (const field of summaries) {
            let existingSection = grouping.find(g => g.name === field.sectionName);
            if (!existingSection) {
                existingSection = {
                    name: field.sectionName,
                    fields: []
                };
                grouping.push(existingSection);
            }

            let existingGroupedField = existingSection.fields.find(f => f.name === field.fieldName);
            if (!existingGroupedField) {
                existingGroupedField = {
                    name: field.fieldName,
                    label: field.fieldLabel,
                    fields: [field]
                };
                existingSection.fields.push(existingGroupedField);
            } else {
                existingGroupedField.fields.push(field);
            }
        }

        return grouping;
    }, [summaries]);

    if (loading) {
        return <Loader />
    }

    const getSummaryDisplay = (grouping: GroupedField<DemographicsSummaryField>) => {
        if (grouping.fields.length === 0) {
            return null;
        }

        const showGraph = showingVisualizations.includes(grouping.name);

        const firstField = grouping.fields[0];

        if (firstField.averageValue === null && firstField.percentageValue === null && firstField.medianValue === null &&
            grouping.fields.length === 1 && firstField.totalValue !== null) {
            return (
                <div className="column-medium-3">
                    <div className="summary">
                        <h4>{grouping.label}</h4>
                        <div className="summary-overall-value">
                            {firstField.totalValue}
                        </div>
                    </div>
                </div>
            )
        }
        
        if (firstField.averageValue === null && firstField.percentageValue === null && firstField.medianValue === null &&
            firstField.totalValue === null) {
            return (
                <div className="column-medium-3">
                    <div className="summary">
                        <h4>{grouping.label}</h4>
                    </div>
                </div>
            )
        }

        if (firstField.percentageValue === null) {
            return (<div className="column-medium-6">
                <div className="summary">
                    <h4>{grouping.label}</h4>
                    {dataVisualizationsEnabled && <Button id="toggle-visualizations" text="Toggle" onClick={() => handleToggleShowingVisualizations(grouping)} />}
                    {!showGraph && <table className="table">
                        <thead>
                            <tr>
                                <th>total</th>
                                <th>median</th>
                                <th>average</th>
                            </tr>
                        </thead>
                        <tbody>
                            {grouping.fields.map((f, i) => {
                                return <tr>
                                    <td>{f.totalValue}</td>
                                    <td>{f.medianValue && f.medianValue.toFixed(2)}</td>
                                    <td>{f.averageValue && f.averageValue.toFixed(2)}</td>
                                </tr>
                            })}
                            {firstField.totalValue !== null && <tr className="border-top">
                                <td>{grouping.fields.reduce((previous, currentField) => previous + currentField.totalValue!, 0)}</td>
                                <td></td>
                                <td></td>
                            </tr>}
                        </tbody>
                    </table>}
                    
                    {showGraph && <DataVisualization data={grouping} />}
                </div>
            </div>);
        }

        if (firstField.medianValue === null) {
            return (
                <div className="column-medium-6">
                    <div className="summary">
                        <h4>{grouping.label}</h4>
                        {dataVisualizationsEnabled && <Button id="toggle-visualizations" text="Toggle" onClick={() => handleToggleShowingVisualizations(grouping)} />}
                        {!showGraph && <table className="table">
                            <thead>
                                <tr>
                                    <th>value</th>
                                    <th>total</th>
                                    <th>percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grouping.fields.map((f, i) => {
                                    return <tr>
                                        <td>{f.fieldGrouping}</td>
                                        <td>{f.totalValue && f.totalValue}</td>
                                        <td>{f.percentageValue && f.percentageValue.toFixed(2)}%</td>
                                    </tr>
                                })}
                                {firstField.totalValue !== null && <tr className="border-top">
                                    <td></td>
                                    <td>{grouping.fields.reduce((previous, currentField) => previous + currentField.totalValue!, 0)}</td>
                                    <td></td>
                                </tr>}
                            </tbody>
                        </table>}
                        
                        {showGraph && <DataVisualization data={grouping} />}
                    </div>
                </div>);
        }

        return (
            <div className="column-medium-6">
                <div className="summary">
                    <h4>{grouping.label}</h4>
                    {dataVisualizationsEnabled && <Button id="toggle-visualizations" text="Toggle" onClick={() => handleToggleShowingVisualizations(grouping)} />}
                    {!showGraph && <table className="table">
                        <thead>
                            <tr>
                                <th>value</th>
                                <th>total</th>
                                <th>percentage</th>
                                <th>median</th>
                                <th>average</th>
                            </tr>
                        </thead>
                        <tbody>
                            {grouping.fields.map((f, i) => {
                                return <tr className="padding-2">
                                    <td><div className="fw-100"><TruncatedText value={f.fieldGrouping || ''} id={`${i}-value`} maxLength={20} /></div></td>
                                    <td>{f.totalValue && f.totalValue}</td>
                                    <td>{f.percentageValue && f.percentageValue.toFixed(2)}%</td>
                                    <td>{f.medianValue && f.medianValue.toFixed(2)}</td>
                                    <td>{f.averageValue && f.averageValue.toFixed(2)}</td>
                                </tr>
                            })}
                            {firstField.totalValue !== null && <tr className="border-top">
                                <td></td>
                                <td>{grouping.fields.reduce((previous, currentField) => previous + currentField.totalValue!, 0)}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>}
                        </tbody>
                    </table>}
                    
                    {showGraph && <DataVisualization data={grouping} />}
                </div>
            </div>);
    }

    return <div className="summary-container">
        <h3>Summary for Participant Demographics</h3>
        <ApiErrorMessages error={apiError} />
        {groupedFields.map(grouping => {
            return <Fragment key={grouping.name}>
                <h4>{grouping.name}</h4>
                <div className="row">
                    {grouping.fields.map(f => {
                        return <Fragment key={f.name}>
                            {getSummaryDisplay(f)}
                        </Fragment>
                    })}
                </div>
            </Fragment>
        })}
    </div>
}

export default DemographicsSummary;