import  {defaultValue} from "./AgeValidateResponse";
import { ValidateRequest } from "./ValidateRequest";
import ValidateResponse from "./ValidateResponse";
import * as filterOperators from "../../../constants/filterOperator";
import { isMatch, parse } from "date-fns";

const POSTIVE_WHOLE_NUMBER_REGEX = /^[1-9]\d*$/;

const validate = (request: ValidateRequest): Promise<ValidateResponse> => {
    const response = defaultValue();

    let parsedValue: { from?: string, to?: string, value?: string, asOfDate?: string };

    try {
        parsedValue = JSON.parse(request.value);
    } catch (e) {
        console.error('Failed to parse age filter value', e);
        response.isValid = false;
        response.default.push('An error occurred.  Contact support for assistance.');
    }
    
    if (!request.operator) {
        response.operator.push('Operation is required');
        response.isValid = false;
    }

    if (response.default.length === 0 && response.operator.length === 0) {
        if (!parsedValue!.asOfDate) {
            response.isValid = false;
            response.asOfDate.push('As of is required');
        } else if (!isMatch(parsedValue!.asOfDate, 'yyyy-MM-dd')) {
            response.isValid = false;
            response.asOfDate.push('As of is invalid')
        } else {
            const parsedAsOfDate = parse(parsedValue!.asOfDate, 'yyyy-MM-dd', new Date());
            if (parsedAsOfDate > new Date()) {
                response.isValid = false;
                response.asOfDate.push('As of cannot be a future date');
            }
        }

        if (request.operator === filterOperators.BETWEEN) {
            if (parsedValue!.from === undefined || parsedValue!.from === null || parsedValue!.from.trim() === '') {
                response.from.push('From is required');
                response.isValid = false;
            }

            if (parsedValue!.to === undefined || parsedValue!.to === null || parsedValue!.to.trim() === '') {
                response.from.push('To is required');
                response.isValid = false;
            }

            if (response.from.length === 0 && response.to.length === 0) {
                if (isNaN(Number(parsedValue!.from)) || !POSTIVE_WHOLE_NUMBER_REGEX.test(parsedValue!.from!)) {
                    response.isValid = false;
                    response.from.push('From must be a postive whole number');
                }

                if (isNaN(Number(parsedValue!.to)) || !POSTIVE_WHOLE_NUMBER_REGEX.test(parsedValue!.to!)) {
                    response.isValid = false;
                    response.to.push('To must be a positive whole number');
                }
            }


            if (response.from.length === 0 && response.to.length === 0) {
                const parsedFrom = Number(parsedValue!.from);
                const parsedTo = Number(parsedValue!.to);

                if (parsedFrom > parsedTo) {
                    response.isValid = false;
                    response.default.push('From must be less than or equal to To');
                }
            }
        } else if (isNaN(Number(parsedValue!.value)) || !POSTIVE_WHOLE_NUMBER_REGEX.test(parsedValue!.value!)) {
            response.isValid = false;
            response.value.push('Value must be a postive whole number');
        }
    }

    return Promise.resolve(response);
};

export default validate;