import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom"

const useNavigationEnabled = () => {
    const location = useLocation();  
    const { isAuthenticated } = useAuth0();

    const blacklistedLocationPaths = [
        '/logged-in',
        '/register',
        '/tos',
        '/unsubscribe'
    ];

    return !blacklistedLocationPaths.includes(location.pathname) && isAuthenticated;
}

export default useNavigationEnabled;