import EditOrganizationPageModel from "../models/pageModels/EditOrganizationPageModel";
import Organization from "../models/serviceModels/Organization";

const mapOrganizationToEditOrganizationPageModel = (organization: Organization): EditOrganizationPageModel => {
    return {
        organizationAddressLine1: organization.addressLine1,
        organizationAddressLine2: organization.addressLine2,
        organizationCity: organization.city,
        organizationEmail: organization.email,
        organizationName: organization.name,
        organizationPhoneNumber: organization.phoneNumber,
        organizationState: organization.state,
        organizationZipCode: organization.zipCode,
        organizationUrl: organization.url || ''
    };
};

export {
    mapOrganizationToEditOrganizationPageModel
}