import EditableFormField from "../models/pageModels/EditableFormField";
import FieldModel from "../models/pageModels/FieldModel";
import { FieldFile } from "../models/serviceModels/FieldFile";
import FormField from "../models/serviceModels/FormField";
import FormInstanceField from "../models/serviceModels/FormInstanceField";

const mapFormFieldToFieldModel = (source: FormField, deleted = false): FieldModel => {
    return {
        ...source,
        options: source.options && source.options.map(o => {
            return {
                ...o,
                deleted
            }
        })
    };
};

const mapFormFieldToEditableFormField = (source: FormField, value = '', instanceFieldId = '', files:FieldFile[] = []): EditableFormField => {
    return {
        ...source,
        value,
        instanceFieldId,
        fieldNumber: 0,
        files
    }
};

const mapFormFieldInstanceToEditableFormField = (source: FormInstanceField, formId: string): EditableFormField => {
    return {
        ...source,
        instanceFieldId: source.id,
        value: source.value,
        fieldNumber: 0,
        name: source.name,
        formId
    }
};

export {
    mapFormFieldToFieldModel,
    mapFormFieldToEditableFormField,
    mapFormFieldInstanceToEditableFormField
}