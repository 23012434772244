import { useEffect, useState } from "react";
import Form from "../../../models/serviceModels/Form";
import { getForms } from "../../../services/formsService";
import { ASSESSMENT_ID, DELIVERY_ID, Mapping } from "../../../constants/formTypes";
import Loader from "../../UI/Loader";
import ReportStepProps from "./ReportStepProps";
import getclassNames from "../../../utils/getClassNames";
import IconButton from "../../UI/IconButton";
import { FORM_SELECTION } from "../../../constants/reportStep";
import Select from "../../UI/Select";
import Button from "../../UI/Button";
import ApiErrorMessages from "../../UI/ApiErrorMessages";

const FormSelection = (props: ReportStepProps) => {
    const [forms, setForms] = useState<Form[]>([]);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState<any>();

    useEffect(() => {
        setLoading(true);
        getForms(false, [ASSESSMENT_ID, DELIVERY_ID], false).then(r => {
            setForms(r.sort((a, b) => `${Mapping[a.formTypeId]} ${a.name}` > `${Mapping[b.formTypeId]} ${b.name}` ? 1 : -1));
        }).catch(e => {
            setApiError(e);
            console.error(e);
        }).finally(() => {
            setLoading(false);
        })
    }, []);
    
    const handleToggleExpanded = () => {
        props.onExpand(FORM_SELECTION, !props.expanded);
    };

    const formIdFieldName = 'form_id';
    const versionIdFieldName = 'version_id';
    const formNameFieldName = 'form_name';
    const handleOnChange = (value: string) => {       
        const matchingForm = forms.find(f => f.id === value);
        if (!matchingForm) {
            return;
        }
        props.onChange({ 
            step: FORM_SELECTION,
            values: [
                { name: formIdFieldName, value },
                { name: versionIdFieldName, value: matchingForm.versionId },
                { name: formNameFieldName, value: matchingForm.name }
            ]
        });
    };

    const handleOnNext = () => {
        props.onNext(FORM_SELECTION);
    };

    const formId = props.values.find(v => v.name === formIdFieldName)?.value || '';
    const form = (!loading && formId && forms.find(f => f.id === formId)) || undefined;
    const formName = form?.name || '';
    const formType = (form?.formTypeId && Mapping[form.formTypeId]) || '';
      
    return (<div className={getclassNames('report-step', props.expanded ? 'expanded' : '')}>
    {loading && <Loader />}
    {!loading && <> 
    <div className="report-step-header">
        <div className="row justify-content-between align-items-center">
            <div className="column-auto">
                <h3>Select a Form{formName ? ` - ${formType} - ${formName}` : ''}</h3>
            </div>
            <div className="column-1">
                <IconButton id="toggle-report-perspective-expansion"
                    className="small flat"
                    disabled={props.disabled}
                    icon={props.expanded ? (<i className="ri-arrow-up-s-line"></i>) : (<i className="ri-arrow-down-s-line"></i>)}
                    onClick={handleToggleExpanded} />
            </div>
        </div>
    </div>
    <div className="report-step-body">
        <ApiErrorMessages error={apiError} />
        <div className="row margin-bottom-3">
            <div className="column-medium-6">
                <Select id="forms" 
                    value={formId} 
                    onChange={handleOnChange}
                    label="Form">
                    <option>Select One...</option>
                    {forms.map((f: Form) => {
                        const formTypeName = Mapping[f.formTypeId] || '';
                        return <option key={f.id} value={f.id}>{formTypeName} - {f.name}</option>
                    })}
                </Select>
            </div>
        </div>
        <div className="row justify-content-end margin-bottom-3">
            <div className="column-auto">
                <Button id="report-type-next"
                    text="Next"
                    icon={<i className="ri-arrow-right-s-line"></i>}
                    iconLocation="end"
                    className="secondary"
                    disabled={!formId || props.disabled}
                    onClick={handleOnNext} />
            </div>
        </div>
    </div>    
    </>}
</div>);
};

export default FormSelection;