import React, { useEffect, useState } from 'react';
import getclassNames from '../../../../utils/getClassNames';
import Button from '../../../UI/Button';
import Input from '../../../UI/Input';
import DisplayFieldProps from './Models/DisplayFieldProps';
import { manageFormsEnabled } from '../../../../utils/features';
import Dropdown from '../../../UI/Dropdown';

const ActivityDateDisplayField = (props: DisplayFieldProps) => {
    const [showAdvanced, setShowAdvanced] = useState(props.showAdvanced);

    useEffect(() => {
        setShowAdvanced(props.showAdvanced);
    }, [props.showAdvanced]);

    const options = [
        {
            label: 'Edit',
            onClick: () => props.onEdit(props.field)
        }
    ];

    return (<>
        <div className="field-wrapper">
            <div className="row justify-content-between align-items-center">
                <div className="column">
                    <Input id={props.field.id} type="date" label="Activity Date" disabled={true} required={props.field.required} placeholder="MM/DD/YYYY" />
                </div>
            {!props.hideButtons && <div className="column-auto margin-top-5">
                {!manageFormsEnabled && <div className="field-control">
                    <Button disabled={props.disabled} id={`reorder-buton-${props.field.id}`} onClick={() => props.onReorder && props.onReorder(props.field)} className="small" text="Move" />
                    <Button disabled={props.disabled} id={`edit-buton-${props.field.id}`} onClick={() => props.onEdit(props.field)} className="small" text="Edit" />
                </div>}
                {manageFormsEnabled && <Dropdown id={`dropdown-${props.field.id}`} options={options} />}
            </div>}
            </div>
            <div className="margin-vertical-3">
                <Button id="toggle-advanced-button"
                    className="flat small"
                    text={showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
                    icon={showAdvanced ? <i className="ri-arrow-up-s-line" /> : <i className="ri-arrow-down-s-line" />}
                    iconLocation="end" onClick={() => setShowAdvanced(!showAdvanced)} />

            </div>
            {showAdvanced && <div className="advanced-content">
                <label>Name:</label>{props.field.name}
            </div>}
        </div >
    </>);
};

export default ActivityDateDisplayField;