import { useContext, useEffect, useState } from "react";
import Loader from "../components/UI/Loader";
import Button from "../components/UI/Button";
import { MeContext } from "../App";

const UserProfile = () => {

    const [errorLoading, setErrorLoading] = useState(false);
    const me = useContext(MeContext);

    useEffect(() => {
        document.title = "My User Profile"
    }
        , []);

    useEffect(() => {
        if (me.loading) {
            return;
        }

        if (me.error) {
            setErrorLoading(true);
        }
    }, [me]);

    if (me.loading) {
        return (<div className="section">
            <Loader />
        </div>);
    }

    if (errorLoading) {
        return (<div className="section">
            <div className="row justify-content-center">
                <div className="column-auto">
                    Failed to load...
                </div>
            </div>
        </div>);
    }

    if (!me.user) {
        return null;
    }

    return (<section id="main-content" tabIndex={-1}>
        <div className="section">
            <h1>My User Profile</h1>
            <div className="row justify-content-center">
                <div className="column-medium-6 border padding-5 rounded">
                    <div className="row justify-content-end">
                        <div className="column-auto">
                            <Button id="edit-profile-button" text="Edit" className="small" href={`/users/${me.user.id}/edit`} />
                        </div>
                    </div>
                    <div className="row margin-vertical-2">
                        <div className="column-medium-4">
                            <label>Name</label>
                            <div>{me.user.firstName} {me.user.lastName}</div>
                        </div>
                        <div className="column-medium-4">
                            <label>Email</label>
                            <div>{me.user.email}</div>
                        </div>
                        <div className="column-medium-4">
                            <label>Phone Number</label>
                            <div>{me.user.phoneNumber}</div>
                        </div>
                    </div>
                    <div className="row margin-vertical-2">
                        <div className="column-medium-4">
                            <label>Role</label>
                            <div>{me.user.userRoleName}</div>
                        </div>
                        <div className="column-medium-4">
                            <label>Password</label>
                            <div>············</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default UserProfile;