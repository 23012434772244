import { FormEvent, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import ErrorMessages from '../components/UI/ErrorMessages';
import { approvedCountries, countries } from '../constants/countryNames';
import Select from '../components/UI/Select';
import Button from '../components/UI/Button';

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    document.title = 'Sign Up';
  }, []);

  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!country) {
      setError('Please select a country');
      return;
    }

    if (!approvedCountries.includes(country)) {
      setError('Unfortunately, we are not serving organizations in your country at this time. Please check back later to see if anything changes.');
      return;
    }

    setSubmitting(true);
    loginWithRedirect({ screen_hint: 'signup' });
  };

  return (
    <div className="section">
      <h1>Sign Up</h1>
      <div className="row justify-content-center">
        <div className="column-medium-6">
          <ErrorMessages messages={error ? [error] : []} />
          <form onSubmit={handleOnSubmit}>
            <p>
              <h3>Welcome!</h3>
              countbubble is only offered in limited geographical locations.
              Please select the country where your organization is located to see if countbubble is being offered in your area.
            </p>
            <div className="row margin-top-2 align-items-end">
              <div className="column">
                <Select id="country-select" label="Country" value={country} onChange={setCountry}>
                  <option value="">Select One..</option>
                  {countries.map(c => {
                    return <option key={c.value} value={c.value}>{c.label}</option>
                  })}
                </Select>
              </div>
              <div className="column-auto">
                <Button id="submit-button" type="submit" text="Submit" loading={submitting} disabled={submitting} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
};

export default SignUp;