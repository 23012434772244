const params = new URLSearchParams(window.location.search);
const keysToRemove = []
for(const key of Array.from(params.keys())) {
    if (key.indexOf('FEATURE_') === 0){
        const value = params.get(key);
        if (value) {
            localStorage.setItem(key, value);
            keysToRemove.push(key);
        }
    }
}

if (keysToRemove.length !== 0) {
    for(const key of keysToRemove) {
        params.delete(key);
    }

    if (Array.from(params.keys()).length === 0) {
        window.location.search = '';
    } else {
        window.location.search = `?${params}`;
    }
}

const overridenFeatures: { [key:string]: string } = {}
for(let i = 0; i < localStorage.length; i++) {
    const localStoragekey = localStorage.key(i);
    if (localStoragekey?.indexOf('FEATURE_') === 0) {
        const value = localStorage.getItem(localStoragekey);
        if (value) {
            overridenFeatures[localStoragekey] = value;
        }
    }
}

const manageFormsEnabled = process.env.REACT_APP_FEATURE_ENABLE_MANAGE_FORMS === 'true' || overridenFeatures['FEATURE_ENABLE_MANAGE_FORMS'] === 'true';
const improvedAddPaticipantEnabled = process.env.REACT_APP_FEATURE_ENABLE_IMPROVED_ADD_PARTICIPANT === 'true' || overridenFeatures['FEATURE_ENABLE_IMPROVED_ADD_PARTICIPANT'] === 'true';
const customReportFiltersEnabled = process.env.REACT_APP_FEATURE_ENABLE_CUSTOM_REPORT_FILTER === 'true' || overridenFeatures['FEATURE_ENABLE_CUSTOM_REPORT_FILTER'] === 'true';
const dataVisualizationsEnabled = process.env.REACT_APP_FEATURE_ENABLE_DATA_VISUALIZATIONS === 'true' || overridenFeatures['FEATURE_ENABLE_DATA_VISUALIZATIONS'] === 'true';

export {
    manageFormsEnabled,
    improvedAddPaticipantEnabled,
    customReportFiltersEnabled,
    dataVisualizationsEnabled
}