import { useState } from "react";
import SearchFieldReference from "../../models/serviceModels/SearchFieldReference";
import Button from "./Button";
import Input from "./Input";
import './Search.css';
import { BOOL, DATE, FULLTEXT, NUMBER, TEXT, TIMESTAMP } from "../../constants/dataType";
import RadioButton from "./RadioButton";
import { CONTAINS, EQUALS } from "../../constants/filterType";
import InputWithFloatingButtons from "./InputWithFloatingButtons";
import DefaultSearchFilter from "../../models/serviceModels/DeafultSearchFilter";
import Checkbox from "./Checkbox";
import Select from "./Select";
import getclassNames from "../../utils/getClassNames";
import { format, isMatch, parse } from "date-fns";
import { formatJSONDateWithoutTime } from "../../utils/dateHelpers";

export interface SearchEvent {
    fields?: { fieldId: string, value: string, filterTypeId: number }[];
    query?: string;
    disabledFilters: string[];
}

interface SearchFieldReferenceWithValue extends SearchFieldReference {
    value: string;
}

interface SearchProps {
    id: string;
    searching: boolean;
    fields: SearchFieldReference[];
    onSearch: (e: SearchEvent) => void;
    defaultFilters: DefaultSearchFilter[];
    lockedOpen?: boolean;
}

const Search = (props: SearchProps) => {
    const [fields, setFields] = useState<SearchFieldReferenceWithValue[]>(props.fields.map(f => {
        return {
            ...f,
            value: ''
        }
    }));
    const [disabledFilters, setDisabledFilters] = useState<string[]>([]);
    const [query, setQuery] = useState('');
    const [showingFilters, setShowingFilters] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);

    const handleSettingSelectedFieldValue = (currentField: SearchFieldReferenceWithValue, newValue: string) => {
        currentField.value = newValue;
        setFields([
            ...fields
        ]);

        const newQueryParts = buildQueryFilterParts(fields, disabledFilters);
        newQueryParts.push(cleanseQuery(query));

        setQuery(newQueryParts.join(' '));
    };

    const buildQueryFilterParts = (fieldReferences: SearchFieldReferenceWithValue[], filters: string[]) => {
        const newQueryParts = [];

        for (const field of fieldReferences) {
            if (!field.value) {
                continue;
            }

            if ((field.dataTypeId === DATE || field.dataTypeId === TIMESTAMP) && isMatch(field.value, 'yyyy-MM-dd')) {
                const parsedDate = parse(field.value, 'yyyy-MM-dd', new Date());
                newQueryParts.push(`"${field.fieldName}:${format(parsedDate, 'MM/dd/yyyy')}"`);
            } else {
                newQueryParts.push(`"${field.fieldName}:${field.value}"`);
            }
        }

        for (const filter of filters) {
            const matchingFilter = props.defaultFilters.find(df => df.id === filter);
            if (!matchingFilter) {
                continue;
            }

            newQueryParts.push(`"${matchingFilter.disableText}:True"`);
        }

        return newQueryParts
    }

    const cleanseQuery = (value: string) => {
        return value.replace(/".*:.*"/g, '').trim();
    }

    const handleSetQuery = (newQuery: string) => {
        const queryFieldMatches = newQuery.match(/".*?:.*?"/g) || [];
        const currentFields = [];
        const currentFilters = [];
        let fieldChangesMade = false;
        for (const match of queryFieldMatches) {
            const [fieldName, fieldValue] = match.replace(/"/g, '').split(':');
            const matchingField = fields.find(f => f.fieldName === fieldName);
            if (matchingField) {
                currentFields.push(fieldName);
                fieldChangesMade = true;
                if ((matchingField.dataTypeId === DATE || matchingField.dataTypeId === TIMESTAMP) && isMatch(fieldValue, 'MM/dd/yyyy')){
                    const parsedDate = parse(fieldValue, 'MM/dd/yyyy', new Date());
                    matchingField.value = format(parsedDate, 'yyyy-MM-dd');
                } else {
                    matchingField.value = fieldValue;
                }
            }
        }

        for (const match of queryFieldMatches) {
            const [filterName, filterValue] = match.replace(/"/g, '').split(':');
            const matchingFilter = props.defaultFilters.find(f => f.disableText === filterName);
            if (matchingFilter) {
                currentFilters.push(matchingFilter.id);
                handleOnDisableFilterChanged(matchingFilter.id, filterValue.toLowerCase() === 'true', false);
            }
        }

        for (const field of fields) {
            if (!currentFields.includes(field.fieldName)) {
                field.value = '';
                fieldChangesMade = true;
            }
        }

        for (const filter of disabledFilters) {
            if (!currentFilters.includes(filter)) {
                handleOnDisableFilterChanged(filter, false, false);
            }
        }

        if (fieldChangesMade) {
            setFields([
                ...fields
            ]);
        }

        setQuery(newQuery);
        setHasSearched(false); // toggle back to false since the query has changed
    }

    const getFilterType = (dataTypeId: number) => {
        switch (dataTypeId) {
            case NUMBER:
            case DATE:
            case TIMESTAMP:
            case BOOL:
                return EQUALS;
            case TEXT:
            case FULLTEXT:
                return CONTAINS;
        }

        return undefined;
    }

    const getInputField = (id: string, currentField: SearchFieldReferenceWithValue) => {
        switch (currentField.dataTypeId) {
            case NUMBER:
                return <Input id={id}
                    label={currentField.fieldName}
                    value={currentField.value}
                    type="number"
                    onChange={(v) => handleSettingSelectedFieldValue(currentField, v)} />;
            case DATE:
            case TIMESTAMP:
                return <Input id={id}
                    label={currentField.fieldName}
                    value={currentField.value}
                    type="date"
                    onChange={(v) => handleSettingSelectedFieldValue(currentField, v)} />;
            case TEXT:
            case FULLTEXT:
                if (currentField.options) {
                    return (<Select id={id}
                        label={currentField.fieldName}
                        value={currentField.value}
                        onChange={(v) => handleSettingSelectedFieldValue(currentField, v)}>
                        <option value="">Select One</option>
                        {currentField.options.map(o => {
                            return <option key={o.value} value={o.value}>{o.text}</option>
                        })}
                    </Select>);
                }

                return <Input id={id}
                    label={currentField.fieldName}
                    value={currentField.value}
                    onChange={(v) => handleSettingSelectedFieldValue(currentField, v)} />;
            case BOOL:
                return <RadioButton id={id}
                    label={currentField.fieldName}
                    value={currentField.value}
                    options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'False' }]}
                    onChange={(v) => handleSettingSelectedFieldValue(currentField, v)} />;
        }

        return null;
    }

    const handleOnSearch = () => {
        props.onSearch({
            fields: fields.filter(f => f.value).map(sf => {
                const mappedField = {
                    fieldId: sf.id,
                    value: sf.value,
                    filterTypeId: getFilterType(sf.dataTypeId)!
                };
                if (sf.dataTypeId === TIMESTAMP && sf.value) {
                    mappedField.value = `${mappedField.value}||${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
                }
                return mappedField;
            }),
            query: cleanseQuery(query),
            disabledFilters
        });
        setHasSearched(true);
        setShowingFilters(false);
    };

    const handleOnQueryKeyUp = (key: string) => {
        if (key === 'Enter') {
            handleOnSearch();
        }
    };

    const handleToggleFilters = () => {
        setShowingFilters(!showingFilters);
    };

    const handleOnDisableFilterChanged = (id: string, checked: boolean, syncUpQuery = true) => {
        let newDisabledFilters = [
            ...disabledFilters
        ];
        if (checked) {
            if (!disabledFilters.includes(id)) {
                newDisabledFilters.push(id);
                setDisabledFilters(newDisabledFilters);
            }
        } else {
            newDisabledFilters = [
                ...newDisabledFilters.filter(df => df !== id)
            ]
            setDisabledFilters(disabledFilters.filter(df => df !== id));
        }

        if (syncUpQuery) {
            const newQueryParts = buildQueryFilterParts(fields, newDisabledFilters);
            newQueryParts.push(cleanseQuery(query));

            setQuery(newQueryParts.join(' '));
            setHasSearched(false);
        }
    };

    const handleReset = () => {
        setQuery('');
        setDisabledFilters([]);
        fields.forEach(f => f.value = '');
        setFields([
            ...fields
        ]);
        if (hasSearched && query) {
            handleOnSearch();
        }
    };

    const getInputButtons = () => {
        const buttons = [
            {
                text: 'Reset',
                onClick: handleReset,
                className: 'secondary'
            }
        ];

        if (!props.lockedOpen) {
            buttons.push({
                text: showingFilters ? 'Hide Filters' : 'Show Filters',
                onClick: handleToggleFilters,
                className: 'secondary'
            });
        }
        return buttons;
    };

    return (
        <div className="search-container">
            <div className="row margin-vertical-2 align-items-center">
                <div className="column">
                    <InputWithFloatingButtons
                        id={`search-input-floating-${props.id}`}
                        value={query}
                        onKeyUp={handleOnQueryKeyUp}
                        onChange={handleSetQuery}
                        buttons={getInputButtons()} />
                </div>
                <div className="column-2">
                    <Button id={`search-button-${props.id}`}
                        text="Search"
                        className="block action"
                        onClick={handleOnSearch}
                        loading={props.searching}
                        disabled={props.searching} />
                </div>
            </div>
            {(showingFilters || props.lockedOpen) && <div className={getclassNames('search-filters-container', props.lockedOpen ? 'locked-open' : '')}>
                <div className="row padding-5">
                    {fields.map((f, i) => {
                        return (<div className="column-auto margin-vertical-3" key={i}>
                            <div className="border padding-3 rounded">{getInputField(`seleted-field-value-${i}`, f)}</div>
                        </div>);
                    })}
                    {props.defaultFilters.map((f, i) => {
                        return (<div className="column-auto margin-vertical-3" key={i}>
                            <div className="border padding-3 rounded">
                                <Checkbox id={`search-filter-${i}`}
                                    label={f.disableText}
                                    checked={disabledFilters.includes(f.id)}
                                    onChange={(c) => handleOnDisableFilterChanged(f.id, c)} />
                            </div>
                        </div>)
                    })}
                </div>
            </div>}
        </div>);
}

export default Search;