const DELIVERY_ID = 3;
const ASSESSMENT_ID = 4;
const CLIENT_ID = 1;

const Mapping: {[key: number]: string} = {
    [DELIVERY_ID]: 'Outputs and Outcomes',
    [ASSESSMENT_ID]: 'Assessment',
    [CLIENT_ID]: 'Client'
};

export {
    DELIVERY_ID,
    ASSESSMENT_ID,
    CLIENT_ID,
    Mapping
};