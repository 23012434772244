import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Loader from "../components/UI/Loader";
import ParticipantProfileHeader from "../components/UI/ParticipantProfileHeader";
import SideMenu from "../components/UI/SideMenu";
import { getClientById } from "../services/clientsService";
import { getParticipantOptions } from "../utils/sideMenuHelpers";

const Participant = () => {
    const { id = '' } = useParams();
    const [loadingClient, setLoadingClient] = useState(true);
    const [client, setClient] = useState<any>(null);

    useEffect(() => {
        document.title = "Manage Participant"
    }
        , []);

    useEffect(() => {
        getClientById(id).then((c) => {
            setClient(c);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoadingClient(false);
        })
    }, []);

    const handleOnParticipantUpdated = () => {
        setLoadingClient(true);
        setClient(null);

        getClientById(id).then(c => {
            setClient(c);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoadingClient(false);
        });
    };

    const getHeaderContent = () => {
        if (loadingClient) {
            return <Loader />
        }

        if (!client) {
            return <>Failed to load</>
        }

        return (
            <ParticipantProfileHeader client={client} onUpdated={handleOnParticipantUpdated} />
        );
    };

    return (
        <section id="main-content" tabIndex={-1}>

            <div className="section">
                <SideMenu ariaLabel="Manage Participant Side Menu"
                    id="participant-profile-side-menu"
                    title="Manage Participant"
                    options={getParticipantOptions(id)}
                    headerContent={getHeaderContent()}
                    mainContent={<Outlet />}
                />
            </div>
        </section>
    );
};

export default Participant;
