import { Fragment } from "react";

import MainNav from "./MainNav";
import Notifications from "../UI/Notifications";
import useNavigationEnabled from "../../hooks/useNavigationEnabled";

const Layout = (props: any) => {
  const navigationEnabled = useNavigationEnabled();

  return (
    <Fragment>

      <MainNav />

      <main>
        {navigationEnabled && <Notifications />}
        {props.children}
      </main>
    </Fragment>
  );
};

export default Layout;
